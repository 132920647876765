import types from "../../types";
const initialBoard = {
    dashboard: {},
    overdueAttandence: [],
    teacherNews: [],
    getCompensation: [],
    getSchools: [],
    getClassTypes: [],
    getProfile: [],
    getAvailibility: [],
    viewStudent: {},
};

const teacherReducer = (state = initialBoard, action) => {
    switch (action.type) {
        case types.GET_TEACHER_DASHBOARD:
            return { ...state, dashboard: action.payload };
        case types.GET_TEACHER_OVERDUE_ATTANDENCE:
            return { ...state, overdueAttandence: action.payload };
        case types.GET_TEACHER_NEWS:
            return { ...state, teacherNews: action.payload };
        case types.GET_COMPENSATION:
            return { ...state, getCompensation: action.payload };
        case types.GET_TEACHER_SCHOOL_DROPDOWN:
            return { ...state, getSchools: action.payload };
        case types.TEACHER_CLASS_TYPES:
            return { ...state, getClassTypes: action.payload };
        case types.TEACHER_PROFILE:
            return { ...state, getProfile: action.payload };
        case types.GET_TEACHER_AVAILIBILITY:
            return { ...state, getAvailibility: action.payload };
        case types.TEACHER_STUDENTS:
            return {
                ...state,
                viewStudent: action.payload,
            };
        default:
            return { ...state };
    }
};

export default teacherReducer;
