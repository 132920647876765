import { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useUnload = (fn) => {
    const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined
    const history = useHistory();
    useEffect(() => {
        cb.current = fn;
    }, [fn]);

    useEffect(() => {
        const onUnload = (...args) => cb.current?.(...args);

        window.addEventListener("beforeunload", onUnload);

        return () => window.removeEventListener("beforeunload", onUnload);
    }, []);
};

export default useUnload;
