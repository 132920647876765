import React, { useEffect, useState, useRef } from "react";
import Index from "../../../layout";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getParentAssignmentAction } from "../../../../../redux/actions/ParentPortal/assignmentAction";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import view from "../../../../../assets/icons/eyegreen.png";
import StudentIcon from "../../../../../assets/icons/student.png";
import ButtonComponent from "../../../../../components/Fields/Button/CustomButton";
import Dropblack from "../../../../../assets/icons/dropblack.png";
import Laptop from "../../../../../assets/icons/laptop.png";
import Assignments from "../../../../../assets/icons/Assignments.png";

import { Menu } from "primereact/menu";
import {
    getTeacherAssignmentAction,
    getTeacherStudentDropDownAction,
} from "../../../../../redux/actions/TeacherPortal/teacherAssignmentAction";
import CardHeader from "../../../../../pages/CardHeader";
import DropDownComponent from "../../../../../components/Fields/CustomDropdown";
import InputComponent from "../../../../../components/Fields/InputField";
import { getSearchedData } from "../../../../../utils/commonFunctions";

const TeacherAssignments = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getTeacherAssignmentAction());
        dispatch(getTeacherStudentDropDownAction());
    }, [dispatch]);

    const studentFilter = useRef(null);
    const assignmentList = useSelector((state) => state?.teacherAssignment?.teacherAssignments);
    const students = useSelector((state) => state?.teacherAssignment?.studentDropdown);
    const [keyword, setKeyword] = useState("");

    const ActionTemplate = (r) => {
        return (
            <>
                <img src={view} width="25px" alt="" onClick={() => history.push(`/teacher/view-attendence/${r?.id}`)} />
            </>
        );
    };

    const studentList1 = students?.map((item, i) => ({
        label: item.name,
        key: i + 1,
        command: () => {
            dispatch(getTeacherAssignmentAction(item.id));
        },
    }));
    const allLabel = { label: "All", key: 0, command: () => dispatch(getTeacherAssignmentAction()) };
    // studentList.unshift(allLabel);

    const studentList = [
        {
            template: (item, options) => {
                return <DropDownComponent />;
            },
        },
    ];
    const [studentsId, setStudentId] = useState("");
    const keys = ["user_name", "event_category_name", "date"];

    return (
        <Index>
            <div className="grid mb-3 border-1 border-round-lg border-300 p-5">
                <CardHeader icon={Assignments} heading="Assignments" />
                <div className="md:col-12">
                    <div className="table-top-header border-round-top-xl bg-main p-3 mb-3">
                        <div className="flex justify-content-between">
                            <DropDownComponent
                                extraClassName=""
                                required
                                placeholder="Students"
                                labelClassName=""
                                name="students"
                                optionLabel="label"
                                options={[
                                    { label: "All", value: "all" },
                                    ...(students?.map((item, index) => ({
                                        label: item?.name,
                                        value: item?.id,
                                    })) || []),
                                ]}
                                onChange={(e) => {
                                    setStudentId(e.value);
                                    dispatch(getTeacherAssignmentAction(e.value === "all" ? null : e.value));
                                }}
                                value={studentsId}
                            />
                            {/* <ButtonComponent
                                    className="flex align-items-center font-semibold bg-transparent border-none p-0 text-xs relative"
                                    onClick={(e) => studentFilter.current.toggle(e)}
                                >
                                    <img src={StudentIcon} alt="" className="bg-white p-2 border-round-lg" width={30} />
                                    &nbsp; Student &nbsp;
                                    <img src={Dropblack} alt="" className="" width={15} />
                                </ButtonComponent> */}
                            <Menu model={studentList} popup ref={studentFilter} className="" />
                            {setKeyword ? (
                                <div className="p-input-icon-left mx-2">
                                    <i className="pi pi-search z-3" />
                                    <InputComponent
                                        value={keyword}
                                        inputClass="pl-5"
                                        onChange={(e) => setKeyword(e.target.value)}
                                        placeholder="Search"
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <DataTable
                        showGridlines
                        className="text-dark online-lesson"
                        value={getSearchedData(assignmentList, keyword, keys)}
                    >
                        <Column field="user_name" header="Students" style={{ width: "30%" }}></Column>
                        <Column field="event_category_name" header="Event Category" style={{ width: "30%" }}></Column>
                        <Column field="date" header="Lesson Date" style={{ width: "30%" }}></Column>
                        <Column body={ActionTemplate} header="Action" style={{ width: "30%" }} />
                    </DataTable>
                </div>
            </div>
        </Index>
    );
};

export default TeacherAssignments;
