import React, { useEffect } from "react";
import Index from "../../../layout";
import DropDownComponent from "../../../../../components/Fields/CustomDropdown";
import StudentCard from "../../../../../assets/icons/studentcard.png";
import FormImage from "../../../../../components/Fields/FormImage";
import { Card } from "primereact/card";
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { viewTeacherStudentDetailAction } from "../../../../../redux/actions/TeacherPortal/studentAction";
import { getImageURL } from "../../../../../utils/javascript";
import UserProfile from "../../../../../assets/images/ProfilePictureViewer.png";
import moment from "moment";
import { getDays } from "../../../../../utils/commonFunctions";
import { firstLetterToUppercase } from "../../../../../utils/regex";
import ChevronLeft from "../../../../../assets/icons/chevronleft.png";

export default function StudentView() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(viewTeacherStudentDetailAction(id));
    }, [id, dispatch]);

    const studentDetail = useSelector((state) => state?.teacherStudent?.viewStudentDetail);

    return (
        <Index>
            <div className="p-4">
                <div className="grid">
                    <div className="flex align-items-center mb-3">
                        <h2 className="flex align-items-center ml-2">
                            <img src={ChevronLeft} className="mr-2" alt="" width={10} onClick={() => history.goBack(-1)} />
                            <img src={StudentCard} alt="" width={40} height={30} />
                        </h2>
                        &nbsp; &nbsp;
                        <span className=" flex text-main text-2xl open-sans font-semibold">Student Details</span>
                    </div>
                </div>
                <Card className="px-4 py-2">
                    <div className="grid">
                        <FormImage name="picture" image={false ? UserProfile : studentDetail?.picture} view={true} />
                        <div className="md:col-9">
                            <p className="text-main text-lg font-semibold mb-3">Student Details</p>
                            <div className="grid">
                                <div className="md:col-6 grid">
                                    <div className="md:col-6 text-900 font-semibold">Student Type</div>
                                    <div className="md:col-6">{studentDetail?.student_type}</div>
                                </div>
                                <div className="md:col-6 grid">
                                    <div className="md:col-6 text-900 font-semibold">Student First Name</div>
                                    <div className="md:col-6">{studentDetail?.first_name}</div>
                                </div>
                                <div className="md:col-6 grid">
                                    <div className="md:col-6 text-900 font-semibold">Student Last Name</div>
                                    <div className="md:col-6">{studentDetail?.last_name}</div>
                                </div>
                                <div className="md:col-6 grid">
                                    <div className="md:col-6 text-900 font-semibold">Skill Level</div>
                                    <div className="md:col-6">{studentDetail?.skill_level}</div>
                                </div>
                            </div>
                            <p className="text-main text-lg font-semibold mb-3">Unused Details</p>
                            <div className="grid">
                                <div className="md:col-6 grid">
                                    <div className="md:col-6 text-900 font-semibold">Birthday</div>
                                    <div className="md:col-6">
                                        {studentDetail?.dob
                                            ? moment(studentDetail?.dob).format("MMMM/DD/YYYY")
                                            : "-----------------"}
                                    </div>
                                </div>
                                <div className="md:col-6 grid">
                                    <div className="md:col-6 text-900 font-semibold">Student Since</div>
                                    <div className="md:col-6">
                                        {studentDetail?.student_since
                                            ? moment(studentDetail?.student_since).format("MMMM/DD/YYYY")
                                            : "-----------------"}
                                    </div>
                                </div>
                            </div>
                            <p className="text-main text-lg font-semibold mb-3">Location Details</p>
                            {studentDetail?.enroll_type === "school_lessons" && (
                                <div className="grid">
                                    <div className="md:col-6 grid">
                                        <div className="md:col-6 text-900 font-semibold">Location</div>
                                        <div className="md:col-6">
                                            {studentDetail?.enroll_type === "school_lessons" ? "School" : "Online"}
                                        </div>
                                    </div>
                                    <div className="md:col-6 grid">
                                        <div className="md:col-6 text-900 font-semibold">School Name</div>
                                        <div className="md:col-6">{studentDetail?.school_name}</div>
                                    </div>
                                    <div className="md:col-6 grid">
                                        <div className="md:col-6 text-900 font-semibold">Grade</div>
                                        <div className="md:col-6">{studentDetail?.grade}</div>
                                    </div>
                                    <div className="md:col-6 grid">
                                        <div className="md:col-6 text-900 font-semibold">Class type</div>
                                        <div className="md:col-6">
                                            {studentDetail?.class_type === "after_school"
                                                ? "Set Class Time"
                                                : studentDetail?.class_type === "during_school"
                                                    ? "Flex Class"
                                                    : studentDetail?.class_type === "semi_private_during_school"
                                                        ? "Flex Class"
                                                        : "Set Class Time"
                                            }{" "}
                                            ({studentDetail?.program_name})
                                        </div>
                                    </div>
                                </div>
                            )}
                            {studentDetail?.enroll_type === "online_lessons" && (
                                <div className="grid">
                                    <div className="md:col-6 grid">
                                        <div className="md:col-6 text-900 font-semibold">Location</div>
                                        <div className="md:col-6">
                                            {studentDetail?.enroll_type === "school_lessons" ? "School" : "Online"}
                                        </div>
                                    </div>
                                    <div className="md:col-6 grid">
                                        <div className="md:col-6 text-900 font-semibold">Lesson Program</div>
                                        <div className="md:col-6">{studentDetail?.program_id?.name}</div>
                                    </div>
                                    <div className="md:col-6 grid">
                                        <div className="md:col-6 text-900 font-semibold">Day</div>
                                        <div className="md:col-6">{getDays(studentDetail?.program_id?.day).join(",")}</div>
                                    </div>
                                    <div className="md:col-6 grid">
                                        <div className="md:col-6 text-900 font-semibold">Time</div>
                                        <div className="md:col-6">{studentDetail?.program_id?.time_format}</div>
                                    </div>
                                </div>
                            )}
                            <p className="text-main text-lg font-semibold mb-3">Instrument Purchase Program</p>
                            <div className="grid">
                                <div className="md:col-6 grid">
                                    <div className="md:col-6 text-900 font-semibold">Instrument</div>
                                    <div className="md:col-6">
                                        {studentDetail?.enroll_type === "school_lessons"
                                            ? studentDetail?.student_instruments
                                            : studentDetail?.student_instruments}
                                    </div>
                                </div>
                                <div className="md:col-6 grid">
                                    <div className="md:col-6 text-900 font-semibold">Status</div>
                                    <div className="md:col-6">{firstLetterToUppercase(studentDetail?.status)}</div>
                                </div>
                            </div>
                            <p className="text-main text-lg font-semibold mb-3">Primary Parent /Contact</p>
                            <div className="grid">
                                <div className="md:col-6 grid">
                                    <div className="md:col-6 text-900 font-semibold">Title</div>
                                    <div className="md:col-6">{studentDetail?.parent?.title ?? "-------------"}</div>
                                </div>
                                <div className="md:col-6 grid">
                                    <div className="md:col-6 text-900 font-semibold">First Name</div>
                                    <div className="md:col-6">{studentDetail?.parent?.first_name}</div>
                                </div>
                                <div className="md:col-6 grid">
                                    <div className="md:col-6 text-900 font-semibold">Last Name</div>
                                    <div className="md:col-6">{studentDetail?.parent?.last_name}</div>
                                </div>
                                <div className="md:col-6 grid">
                                    <div className="md:col-6 text-900 font-semibold">Preferred Invoice Recipient</div>
                                    <div className="md:col-6">
                                        {studentDetail?.parent?.preferred_invoice_recipient ? "Yes" : "No"}
                                    </div>
                                </div>

                                <div className="md:col-6 grid">
                                    <div className="md:col-6 text-900 font-semibold">Email</div>
                                    <div className="md:col-6">{studentDetail?.parent?.email}</div>
                                </div>
                                <div className="md:col-6 grid">
                                    <div className="md:col-6 text-900 font-semibold">Mobile phone</div>
                                    <div className="md:col-6">{studentDetail?.parent?.phone_number}</div>
                                </div>
                                <div className="md:col-9 grid">
                                    <div className="md:col-4 text-900 font-semibold">Address</div>
                                    <div className="md:col-7">{studentDetail?.parent?.address}</div>
                                </div>
                                <div className="md:col-12 grid">
                                    <div className="md:col-3 text-900 font-semibold">Notes</div>
                                    <div className="md:col-9">{studentDetail?.parent?.notes ?? "---------------"}</div>
                                </div>
                            </div>
                            <p className="text-main text-lg font-semibold mb-3">Billing Information</p>
                            <div className="grid">
                                <div className="md:col-12 grid">
                                    <div className="md:col-3 text-900 font-semibold">Billing Address Street</div>
                                    <div className="md:col-6">{studentDetail?.billing_info?.address}</div>
                                </div>
                                <div className="md:col-12 grid">
                                    <div className="md:col-3 text-900 font-semibold">City</div>
                                    <div className="md:col-6">{studentDetail?.billing_info?.city}</div>
                                </div>
                                <div className="md:col-12 grid">
                                    <div className="md:col-3 text-900 font-semibold">State</div>
                                    <div className="md:col-6">{studentDetail?.billing_info?.state}</div>
                                </div>
                                <div className="md:col-12 grid">
                                    <div className="md:col-3 text-900 font-semibold">Zip code</div>
                                    <div className="md:col-6">{studentDetail?.billing_info?.zipcode}</div>
                                </div>
                                <div className="md:col-12 grid">
                                    <div className="md:col-3 text-900 font-semibold">Bill Type</div>
                                    <div className="md:col-6">
                                        {studentDetail?.billing_info
                                            ? studentDetail?.billing_info?.payment_type === "monthly_payment"
                                                ? "Monthly"
                                                : "One Time Payment"
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </Index>
    );
}
