import React from 'react'
import { Dialog } from 'primereact/dialog';
import ButtonComponent from '../../../../components/Fields/Button/CustomButton';


const StudentWaitingList = ({ selectedProgramme, setSelectedProgramme, setStudentDetail, schoolPrograms }) => {
  return (
    <>
            <Dialog
                className="header-none text-center border-round-lg overflow-hidden"
                visible={selectedProgramme}
                style={{ width: "35vw" }}
                onHide={() => { setSelectedProgramme(false); setStudentDetail((prev => ({ ...prev, is_waiting: true }))) }}
            >
                <div className="px-3">
                    <p className="mb-3">
                        We're in the process of creating your class,which may take 1-2 weeks. To be on the waitlist, please make a deposit of  {schoolPrograms?.length > 0 && schoolPrograms?.map((item) => item.school_lesson.deposit_amount)} dollars

                    </p>
                    <div className="">
                        <ButtonComponent
                            label="Cancel"
                            className="bg-whitesmoke border-0"
                            onClick={() => { setSelectedProgramme(false); setStudentDetail((prev => ({ ...prev, is_waiting: true }))) }}
                        />
                        <ButtonComponent
                            label="OK"
                            className="ripple border-none bg-main font-semibold border-round-lg ml-2"
                            onClick={() => { setSelectedProgramme(false); setStudentDetail((prev => ({ ...prev, is_waiting: true }))) }}
                        />
                    </div>
                </div>
            </Dialog>
    </>
  )
}

export default StudentWaitingList
