import React from "react";
import Index from "../../layout";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import groupmessage from "../../../../assets/icons/groupmessage.png";
import send from "../../../../assets/icons/send.png";
import messageHistoryIcon from "../../../../assets/icons/messageHistory (2).png";
import { useEffect } from "react";
import { getStudentMessageHistoryAction } from "../../../../redux/actions/studentAction";
import CardHeader from "../../../../pages/CardHeader";
export default function StudentMessageHistory() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getStudentMessageHistoryAction());
    }, [dispatch]);
    const messageHistory = useSelector((state) => state?.studentInfo?.studentMessageHistory);
    const toTemplate = (rowData) => {
        return (
            <>
                {rowData?.receiver_data?.map((to) => {
                    return (
                        <span>
                            {to.name}, &lt;{to.email}&gt;
                        </span>
                    );
                })}
            </>
        );
    };
    const fromTemplate = (rowData) => {
        return (
            <>
                <span>
                    {rowData?.from_name}, &lt;{rowData?.from}&gt;
                </span>
            </>
        );
    };
    const TypeTemplate = (rowData) => {
        return (
            <>
                <img src={groupmessage} alt="" width="19"></img>
            </>
        );
    };
    const StatusTemplate = (rowData) => {
        return (
            <>
                <img src={send} alt="" width="19"></img>
            </>
        );
    };

    const dateTemplate = (rowData) => {
        return (
            <>
                {rowData?.scheduled_date
                    ? moment(rowData?.scheduled_date).format("MM-DD-YYYY")
                    : moment(rowData?.created_at).format("MM-DD-YYYY")}
            </>
        );
    };
    return (
        <>
            <Index>
                <div className="md:col-12 border-1 border-round-lg border-300 p-3 mb-3 ">
                    <CardHeader icon={messageHistoryIcon} heading="Message History" />
                    <DataTable
                        value={messageHistory}
                        paginator
                        className="bg-light-main"
                        stripedRows
                        rows={5}
                        scrollable
                        scrollHeight="400px"
                        style={{ minWidth: "50rem" }}
                    >
                        <Column field="current_attendees" body={TypeTemplate} header="Type "></Column>
                        <Column field="date" body={StatusTemplate} header="Status"></Column>
                        <Column field="category_name" body={toTemplate} header="To"></Column>
                        <Column field="category_name" body={fromTemplate} header="From"></Column>
                        <Column field="subject" header="Subject"></Column>

                        <Column field="category_name" body={dateTemplate} header="Date"></Column>
                    </DataTable>
                </div>
            </Index>
        </>
    );
}
