import React, { useState, useEffect, useRef } from "react";
import Index from "../../../layout";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import CalendarIcon from "../../../../../assets/icons/calendar.png";
import ButtonComponent from "../../../../../components/Fields/Button/CustomButton";
import { Menu } from "primereact/menu";
import Filter from "../../../../../assets/icons/filter.png";
import AddIcon from "../../../../../assets/icons/add.png";
import Dropblack from "../../../../../assets/icons/dropblack.png";
import viewIcon from "../../../../../assets/icons/viewIcon.png";
import SyncIcon from "../../../../../assets/icons/sinc.png";
import { getTeachersEventsAction } from "../../../../../redux/actions/calendarAction";
import { useDispatch, useSelector } from "react-redux";
import CalendarModalPopup from "./CalendarModalPopup";
import CardHeader from "../../../../../pages/CardHeader";
import { OverlayPanel } from "primereact/overlaypanel";
import MultiSelectComponent from "../../../../../components/Fields/CustomMultiSelect";
import DropDownComponent from "../../../../../components/Fields/CustomDropdown";

import { viewTeacherStudentAction } from "../../../../../redux/actions/TeacherPortal/studentAction";
import { getPriveledgesTeachersListing, getTeachersTabListing } from "../../../../../redux/actions/TeacherPortal/teachersTabAction";
import { getTeacherSchoolsAction } from "../../../../../redux/actions/TeacherPortal/teacherAction";
import { ViewAgendaAction, addCalendarFilterAction, getCategoryAction } from "../../../../../redux/actions/TeacherPortal/eventsAction";
import types from "../../../../../redux/types";
import Loader1 from "../../../../../utils/Loader1";

const TeacherCalendar = () => {
    const menu = useRef(null);
    const viewAgenda = useRef();
    const calendarRef = useRef(null);
    const [date, onDateChange] = useState(new Date());
    const [isMenuOpen, setIsMenuOpen] = useState(new Date());

    const [id, setId] = useState(null);

    const now = new Date();
    const dispatch = useDispatch();

    // const [view, setView] = useState("dayGridMonth");

    useEffect(() => {
        calendarRef.current.getApi().gotoDate(new Date());
    }, []);
    useEffect(() => {
        dispatch(viewTeacherStudentAction());
        dispatch(getPriveledgesTeachersListing());
        dispatch(getTeacherSchoolsAction());
        dispatch(getCategoryAction());
    }, [dispatch]);

    const teachersList = useSelector((state) => state.teachers.getPriviledgeteachers);
    const StudentList = useSelector((state) => state?.teacherStudent?.getStudentList);
    const schoolDropdown = useSelector((state) => state?.teacher?.getSchools);
    const category = useSelector((state) => state?.teacherEvents?.getCategories);
    const filterData = useSelector((state) => state?.teacherEvents?.filter);
    const viewAgend = useSelector((state) => state?.teacherEvents?.viewAgenda);

    useEffect(() => {
        if (viewAgend) {
            dispatch(ViewAgendaAction(viewAgend));
        } else {
            dispatch(ViewAgendaAction("dayGridMonth"));
        }
    }, [viewAgend]);

    const [loading, setLoading] = useState(false);
    const items = [
        {
            label: "Month",
            key: 1,
            style: viewAgend == "dayGridMonth" ? { backgroundColor: "#59c6bc" } : null,
            command: () => {
                const { current: calendarDom } = calendarRef;
                const API = calendarDom ? calendarDom.getApi() : null;
                API && API.changeView("dayGridMonth");
                dispatch(ViewAgendaAction("dayGridMonth"));
            },
        },
        {
            label: "Day",
            key: 2,
            style: viewAgend == "dayGridDay" ? { backgroundColor: "#59c6bc" } : null,
            command: () => {
                const { current: calendarDom } = calendarRef;
                const API = calendarDom ? calendarDom.getApi() : null;
                API && API.changeView("dayGridDay");
                dispatch(ViewAgendaAction("dayGridDay"));
            },
        },
        {
            label: "Week",
            key: 3,
            style: viewAgend == "dayGridWeek" ? { backgroundColor: "#59c6bc" } : null,
            command: () => {
                const { current: calendarDom } = calendarRef;
                const API = calendarDom ? calendarDom.getApi() : null;
                API && API.changeView("dayGridWeek");
                dispatch(ViewAgendaAction("dayGridWeek"));
            },
        },
    ];

    const [filter, setFilter] = useState(filterData);
    const [tempfilter, settempFilter] = useState({
        students: "",
        teachers: "",
        schools: "",
        category: "",
    });

    useEffect(() => {
        settempFilter(filterData);
        setFilter(filterData);
    }, [filterData]);
    useEffect(() => {
        dispatch(getTeachersEventsAction(filter, date, setLoading));
        return () => {
            dispatch({
                type: types.GET_TEACHER_EVENTS,
                payload: [],
            });
        };
    }, [date, filter, dispatch]);

    const events1 = useSelector((state) => state?.calendar?.getTeacherEvents);
    function getEvents() {
        const events = [];
        events1?.forEach((item, index) => {
            if (item?.teacher_events?.length) {
                item.teacher_events.forEach((event, i) => {
                    events.push({
                        id: event.id,
                        title: `${
                            event?.class_type == "after_school_program" || event?.select_location == "others"
                                ? event.start_time_format
                                : event.start_time_format
                        } ${event.attendees_list ?? ""} (${event?.category_name}  ${event.class_type === "during_school" ? "(DS)" : ""} with ${
                            event.teacher
                        }) ${event.public_description && event.show_public_description ? event.public_description : ""}`,
                        backgroundColor: `#${event.calendar_colour}`,
                        color: "#fff",
                        start: item.date,
                        end: item.date,
                    });
                });
            }
        });
        return events;
    }
    const overlayRef = useRef(null);
    const op = useRef(null);
    const handleEventRender = (info) => {
        const el = document.getElementById("primeMenu");
        if (el) {
            el.style.display = "none";
        }

        info.el.addEventListener("click", (event) => {
            overlayRef?.current?.show(event);
        });
        setId(info.event.id);
        info.el.click();
        overlayRef.current.toggle("");
        setIsMenuOpen(info.event.start);
    };
    const handleChange1 = (e) => {
        const { name } = e.target;
        settempFilter({ ...tempfilter, [name]: e.value });
    };
    const handleApply = () => {
        setFilter(tempfilter);
        op.current.toggle("");
        dispatch(addCalendarFilterAction(tempfilter));
    };
    const handleClear = () => {
        setFilter({});
        op.current.toggle("");
        dispatch(addCalendarFilterAction({}));
    };

    return (
        <Index>
            <div className="p-5">
                <CardHeader icon={CalendarIcon} heading="Calendar" />
                <div className="table-top-header border-round-top-xl bg-main p-3 mb-3">
                    <div className="flex justify-content-between">
                        <div className=""></div>
                        <div className="flex justify-content-between">
                            <div className="flex align-items-center open-sans font-semibold text-xs ml-5 cursor-pointer" onClick={(e) => op.current.toggle(e)}>
                                <img src={Filter} alt="" className="bg-white p-2 border-round-lg" width={30} />
                                &nbsp; Filter
                            </div>
                            <OverlayPanel ref={op} className="p-0 p-0 search-intrument border-round-lg" style={{ width: "35rem" }}>
                                <div className="overlay-header bg-main p-2 text-center">
                                    <h5 className="text-black">Filter Calendar</h5>
                                </div>
                                <div className="py-4 px-3">
                                    <div className="grid mb-2">
                                        <MultiSelectComponent
                                            extraClassName="md:col-8 pr-0 py-0"
                                            label="Students"
                                            inputClass="border-noround-right current-attendance"
                                            placeholder="No attendees. Click here to add some"
                                            name="students"
                                            display="chip"
                                            optionLabel="label"
                                            options={StudentList?.map((item, index) => {
                                                return { label: item?.name, value: item?.id };
                                            })}
                                            onChange={handleChange1}
                                            value={tempfilter?.students}
                                            filter
                                        />

                                        <DropDownComponent
                                            extraClassName=""
                                            inputClass="bg-main bg-green1 px-2 text-dark1 border-noround-left group-dropdown"
                                            placeholder="Groups"
                                        />
                                    </div>
                                    <div className="grid">
                                        <MultiSelectComponent
                                            extraClassName="md:col-12 mt-2"
                                            label="Teacher"
                                            name="teachers"
                                            display="chip"
                                            optionLabel="label"
                                            options={teachersList?.map((item, index) => {
                                                return { label: item?.name, value: item?.id };
                                            })}
                                            onChange={handleChange1}
                                            value={tempfilter?.teachers}
                                            filter
                                        />
                                        <MultiSelectComponent
                                            extraClassName="md:col-12"
                                            label="Location"
                                            name="schools"
                                            display="chip"
                                            optionLabel="label"
                                            options={schoolDropdown?.map((item, index) => {
                                                return { label: item?.name, value: item?.id };
                                            })}
                                            onChange={handleChange1}
                                            value={tempfilter?.schools}
                                            filter
                                        />
                                        <MultiSelectComponent
                                            extraClassName="md:col-12"
                                            label="Category"
                                            name="category"
                                            display="chip"
                                            optionLabel="label"
                                            options={category?.map((item, index) => {
                                                return { label: item?.name, value: item?.id };
                                            })}
                                            onChange={handleChange1}
                                            value={tempfilter?.category}
                                            filter
                                        />
                                    </div>
                                    <div className="mt-2">
                                        <ButtonComponent label="Apply" className="bg-gray-light text-dark ml-2" onClick={handleApply} />
                                        <ButtonComponent label="Clear" className="bg-gray-light  text-dark ml-2" onClick={handleClear} />
                                    </div>
                                </div>
                            </OverlayPanel>

                            <div
                                className={`flex align-items-center open-sans font-semibold text-xs ml-5 cursor-pointer${
                                    viewAgenda.current ? "" : `icon-rotate`
                                }`}
                                onClick={(e) => {
                                    viewAgenda.current.toggle(e);
                                }}
                            >
                                <img src={viewIcon} alt="" className="bg-white p-2 border-round-lg " width={30} />
                                &nbsp; View &nbsp;
                                <img src={Dropblack} alt="" className="" width={15} />
                            </div>
                            <Menu model={items} popup ref={viewAgenda} className={"view-menu "} />
                            <div className="flex align-items-center open-sans font-semibold text-xs ml-5">
                                <img src={SyncIcon} alt="" className="bg-white p-2 border-round-lg" width={30} />
                                &nbsp; Set Up Sync &nbsp;
                                <img src={Dropblack} alt="" className="" width={15} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="custom-calander">
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={{
                            left: "title",
                            center: "",
                            right: "prev today next",
                        }}
                        loading={loading}
                        initialView={viewAgend}
                        ref={calendarRef}
                        views={viewAgend}
                        // select={handleSelect}
                        // dateClick={(e) => EventPopover(e)}
                        hiddenDays="0"
                        weekends={true}
                        events={getEvents()}
                        eventTextColor="#111"
                        eventClick={handleEventRender}
                        // eventDidMount={EventPopover}
                        // validRange={{
                        //   start: new Date(),
                        //   end: null,
                        // }}
                        customButtons={{
                            prev: {
                                text: "Previous",
                                click: function () {
                                    let calendarApi = calendarRef.current.getApi();
                                    calendarApi.prev();
                                    onDateChange(calendarApi.getDate());
                                },
                            },
                            next: {
                                text: "Next",
                                click: function () {
                                    let calendarApi = calendarRef.current.getApi();
                                    calendarApi.next();
                                    onDateChange(calendarApi.getDate());
                                },
                            },
                        }}
                    />
                </div>
                {loading ? <Loader1 isLoading={loading} /> : null}
            </div>
            {overlayRef ? <CalendarModalPopup overlayRef={overlayRef} id={id} isMenuOpen={isMenuOpen} /> : null}
        </Index>
    );
};
export default TeacherCalendar;
