import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { onDeleteEventAction, onGetEventAction } from "../../../../../redux/actions/TeacherPortal/scheduleAction";
import { OverlayPanel } from "primereact/overlaypanel";
import { convertTo12HourFormat } from "../../../../../utils/dates";
import deleteIcon from "../../../../../assets/icons/delete.png";
export default function Popup({ op, events, setEvents, calendarRef }) {
    const dispatch = useDispatch();

    const { eventDetails } = useSelector((state) => state.teacherEvents);

    const onClickDelete = (event, event_id) => {
        confirmPopup({
            target: event.currentTarget,
            message: "Are you sure you want to delete?",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
                const scrollableEl = calendarRef.current.getApi().el.querySelector(".fc-scroller-liquid-absolute");
                const scrollPosition = scrollableEl ? scrollableEl.scrollTop : 0;
                dispatch(
                    onDeleteEventAction(event_id, (res) => {
                        let _events = events.filter((item) => item.event_id !== event_id);
                        setEvents(_events);
                        op?.current?.toggle(event);
                        setTimeout(() => {
                            if (scrollableEl) {
                                scrollableEl.scrollTop = scrollPosition;
                            }
                        }, 0);
                    })
                );
            },
        });
    };
    return (
        <div>
            <ConfirmPopup />
            <OverlayPanel ref={op} style={{ width: "450px" }} showCloseIcon={true}>
                <>
                    <div className="grid">
                        <div className="col-12 grid p-0">
                            <div className="font-semibold col-3 text-primary">Class Title</div>
                            <div className="p col-9">: {eventDetails?.class_title}</div>
                        </div>
                        <div className="col-12 grid p-0">
                            <div className="font-semibold col-3 text-primary">Category</div>
                            <div className="p col-9">: {eventDetails?.calendar_category_name}</div>
                        </div>
                        <div className="col-12 grid p-0">
                            <div className="font-semibold col-3 text-primary">Time</div>
                            <div className="p col-9">: {eventDetails?.start_time && convertTo12HourFormat(eventDetails?.start_time)}</div>
                        </div>
                        <div className="col-12 grid p-0">
                            <div className="font-semibold col-3 text-primary">Students</div>
                            <div className="p col-9">: {eventDetails?.current_attendees_name}</div>
                        </div>
                        <div className="col-12 grid p-0">
                            <div className="font-semibold col-3 text-primary">Description</div>
                            <div className="p col-9">: {eventDetails?.description}</div>
                        </div>
                    </div>
                    <hr className="" />
                    <div className="grid">
                        <img
                            src={deleteIcon}
                            onClick={(e) => {
                                onClickDelete(e, eventDetails?.event_id);
                            }}
                            width="25px"
                            className="mx-2 cursor-pointer"
                        />
                    </div>
                </>
            </OverlayPanel>
        </div>
    );
}
