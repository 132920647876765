import React, { useState,useEffect } from "react";
import ButtonComponent from "../../../../components/Fields/Button/CustomButton";
import AddIcon from "../../../../assets/icons/add.png";
import { DataTable } from "primereact/datatable";
import { Column } from "react-formio/lib/types";
import { useDispatch,useSelector } from "react-redux";
import { GetTeacherAvailability } from "../../../../redux/actions/TeacherPortal/teacherAction";
import { firstLetterToUppercase } from "../../../../utils/regex";
import AddAvailibility from "./AddAvailibility";

export const Availability = () => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch()
  const [value, setValue] = useState();
  useEffect(() => {
dispatch(GetTeacherAvailability())  
  }, [dispatch]);

  const {getAvailibility} = useSelector((state)=>state?.teacher);

  return (
    <div>
      <ButtonComponent
        className="border-300 bg-transparent mb-3"
        onClick={(e) => setVisible(true)}
      >
        <img src={AddIcon} alt="" className="mr-2 " />
        Add Availability
      </ButtonComponent>
      <DataTable
        className="border-round-lg overflow-hidden"
        stripedRows
        value={getAvailibility?.length && getAvailibility}
        tableStyle={{ minWidth: "50rem" }}
      >
        <Column field="days" body= {(rowData) => firstLetterToUppercase(rowData?.available_days)} header="Days"></Column>
        <Column field="start_time" header="Start Time"></Column>
        <Column field="end_time" header="End Time"></Column>
        <Column field="start_date" header="Start Date"></Column>
        <Column field="end_date" header="End Date"></Column>
        <Column field="notes" header="Notes"></Column>
      </DataTable>
    <AddAvailibility visible={visible} setVisible={setVisible}/>
     
    </div>
  );
};
