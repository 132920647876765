import Constants from "../../../services/constant";
import { loadStateFn } from "../../../utils/localStorage";
import { api } from "../../../services/api";
import { showLoaderAction, hideLoaderAction } from "../loadAction";
import types from "../../types";


export const getTeachersTabListing = (date) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api(
        "get",
        Constants.END_POINT.TEACHERS_LIST,
        {},
        loadStateFn("token")
    );

    if (res.success) {
        dispatch({
            type: types.TEACHERS_LIST,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
export const getPriveledgesTeachersListing = (date) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api(
        "get",
        Constants.END_POINT.PRIVELEGES_TEACHER,
        {},
        loadStateFn("token")
    );

    if (res.success) {
        dispatch({
            type: types.PRIVELEGES_TEACHER,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};