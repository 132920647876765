import React, { useState } from "react";
import Index from "../../layout";
import SettingsIcon from "../../../../assets/icons/settingred.png";
import InputComponent from "../../../../components/Fields/InputField";
import ButtonComponent from "../../../../components/Fields/Button/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getStudentProfileApi } from "../../../../redux/actions/loginAction";
import CustomCalendar from "../../../../components/Fields/CustomCalendar";
import CustomImageInput from "../../../../components/Fields/CustomImageInput";
import { StudentSettings } from "../../../../redux/actions/studentAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CardHeader from "../../../../pages/CardHeader";

export default function ProfileSettings() {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getStudentProfileApi());
  }, [dispatch]);

  const studentProfile = useSelector(
    (state) => state?.login?.getStudentProfile
  );

  const [profile, setProfile] = useState({
    first_name: "",
    last_name: "",
    email: "",
    dob: null,
    skype_username: "",
    is_picture_delete: 0,
    picture_url: [],
  });

  useEffect(() => {
    setProfile({
      first_name: studentProfile?.first_name,
      last_name: studentProfile?.last_name,
      email: studentProfile?.email,
      dob: studentProfile?.dob ? new Date(studentProfile?.dob) : null,
      skype_username: studentProfile?.skype_username,
      is_picture_delete: 0,
      picture_url: studentProfile.picture ? [studentProfile.picture] : [],
    });
  }, [studentProfile]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({ ...prev, [name]: value }));
  };

  const handleChange1 = (e) => {
    const { name } = e.target;
    setProfile((prev) => ({ ...prev, [name]: e.value }));
    setErr({...err,[name]:""})
  };
  const [err, setErr] = useState({});

  const profilehandleChange = ({ name, value }) => {
    setProfile((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Index>
      <div className="p-4">
        <CardHeader icon ={SettingsIcon} heading ="My Profile"/>
      </div>
      <div className="shadow-sm border-round-lg mx-3 p-3">
        <div className="grid align-items-start">
          <CustomImageInput
            data={profile}
            onFilesChange={profilehandleChange}
            name="picture_url"
            editable={true}
            view={false}
            removeable
          ></CustomImageInput>
          <div className="col grid align-items-top mr-3">
            <InputComponent
              extraClassName="md:col-6"
              label="First Name"
              name="first_name"
              size="md:col-6"
              placeholder="Enter your first name"
              required
              value={profile?.first_name}
              onChange={handleChange}
              errorMessage={err?.first_name}
            />
            <InputComponent
              extraClassName="md:col-6"
              label="Last Name"
              name="last_name"
              size="md:col-6"
              placeholder="Enter your last name"
              required
              value={profile?.last_name}
              onChange={handleChange}
              errorMessage={err?.last_name}
            />
            <InputComponent
              extraClassName="md:col-6"
              label="Email"
              name="email"
              size="md:col-6"
              placeholder="Enter your email"
              value={profile?.email}
              onChange={handleChange}
              errorMessage={err?.email}
              disabled
            />
            <CustomCalendar
              label="Birthday"
              name="dob"
              size="md:col-6"
              placeholder=""
              value={profile?.dob}
              onChange={handleChange1}
            />
            <InputComponent
              extraClassName="md:col-6"
              label="Skype"
              name="skype_username"
              size="md:col-6"
              placeholder=""
              value={profile?.skype_username}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="grid mt-3">
          <div className="lg:w-18rem lg:col-3 xl:col-3"></div>
          <div className="col ml-3 justify-content-center">
            <ButtonComponent
              label="Save"
              onClick={() => {
                dispatch(StudentSettings(profile, history));
              }}
            />
            {/* <ButtonComponent label="Cancel" /> */}
          </div>
          
        </div>
      </div>
    </Index>
  );
}
