import React, { useState } from "react";
import CheckboxComponent from "../../../../components/Fields/Checkbox";
import InputTextAreaComponent from "../../../../components/Fields/CustomInputTextArea";
import CustomCalendar from "../../../../components/Fields/CustomCalendar";
import { Dialog } from "primereact/dialog";
import ButtonComponent from "../../../../components/Fields/Button/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { adjustTime } from "../../../../utils/commonFunctions";
import { addAvailibilityAction } from "../../../../redux/actions/TeacherPortal/teacherAction";

const AddAvailibility = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  var [checkedDays, setCheckedDays] = useState([0, 1, 2, 3, 4, 5, 6]);
  const [err, setErr] = useState({});

  const handleCheckboxChange = (event, i) => {
    let newArr = [...checkedDays];
    if (event.target.checked) {
      newArr.push(i);
      setErr({ ...err, ["checkedDays"]: "" });
    } else {
      let indx = newArr.findIndex((item) => item == i);
      newArr.splice(indx, 1);
    }
    setCheckedDays(newArr);
  };

  const [availabilty, setAvailabilty] = useState({
    start_time: null,
    end_time: null,
    start_date: null,
    end_date: null,
    notes: "",
  });

  const handleChange = (e) => {
    const { name } = e.target;

    if (name == "start_time" || name == "end_time") {
      const time = adjustTime(e);
      setAvailabilty({ ...availabilty, [name]: time });
    } else {
      setAvailabilty({ ...availabilty, [name]: e.value });
    }
    setErr({ ...err, [name]: "" });
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setAvailabilty({ ...availabilty, [name]: value });
  };

  const handleAddAvailability = () => {
    const form = validateForm();
    if (form) {
      dispatch(
        addAvailibilityAction(
          availabilty,
          checkedDays,
          setVisible,
          setAvailabilty,
          setCheckedDays
        )
      );
    }
  };
  const handleSave = (e) => {
    e.preventDefault();
    handleAddAvailability();
  };

  const handleCancel = () => {
    setVisible(false);
    setAvailabilty({});
    setCheckedDays([0, 1, 2, 3, 4, 5, 6]);
  };

  const handleClear = (type) => {
    if (type == "start_date") {
      setAvailabilty({ ...availabilty, start_date: null });
    } else {
      setAvailabilty({ ...availabilty, end_date: null });
    }
  };
  const validateForm = () => {
    let errors = {};
    if (availabilty?.start_time == null) {
      errors.start_time = "Start Time is required";
    }
    if (availabilty?.end_time == null) {
      errors.end_time = "End Time is required";
    } else if (availabilty?.end_time < availabilty?.start_time) {
      errors.end_time = "End Time should be greater than start time";
    }
    if (checkedDays?.length == 0) {
      errors.checkedDays = "Please Select One day";
    }

    setErr(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div>
      <Dialog
        header="Teachers Availability"
        className="assign-student border-round-lg overflow-hidden"
        visible={visible}
        style={{ width: "48vw" }}
        draggable={false}
        resizable={false}
      >
        <div className="p-3">
          <div className="grid justify-content-center">
            <div className="md:col-11">
              <div className="flex align-items-center mb-3">
                <label className="text-sm font-semibold">Days</label>
                <div className="flex mb-3 ml-2">
                  {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
                    (day, i) => (
                      <div key={day}>
                        <CheckboxComponent
                          extraClassName="ml-2"
                          label={day}
                          checked={checkedDays.includes(i)}
                          onChange={(e) => handleCheckboxChange(e, i)}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>

              <CustomCalendar
                name="start_time"
                label="Start Time"
                extraClassName="mb-3"
                icon="pi pi-clock"
                showIcon
                required
                timeOnly
                stepMinute={5}
                hourFormat="12"
                value={availabilty?.start_time}
                onChange={handleChange}
                errorMessage={err?.start_time}
              />
              <CustomCalendar
                extraClassName="mb-3"
                label="End Time"
                name="end_time"
                icon="pi pi-clock"
                showIcon
                required
                timeOnly
                stepMinute={5}
                hourFormat="12"
                value={availabilty?.end_time}
                onChange={handleChange}
                errorMessage={err?.end_time}
              />
              <label className="text-sm">Start Date</label>
              <div className="p-inputgroup mb-3">
                <CustomCalendar
                  name="start_date"
                  iconPos="left"
                  size="md:col-11 p-0"
                  placeholder="Optional"
                  showIcon
                  inputClass="border-noround-right border-none"
                  minDate={new Date()}
                  onChange={handleChange}
                  value={availabilty?.start_date}
                />
                <span
                  className="p-inputgroup-addon text-xs open-sans text-900 p-3 font-normal h-full cursor-pointer"
                  onClick={() => handleClear("start_date")}
                >
                  Clear
                </span>
              </div>
              <label className="text-sm">End Date</label>
              <div className="p-inputgroup mb-3">
                <CustomCalendar
                  name="end_date"
                  iconPos="left"
                  size="md:col-11 p-0"
                  placeholder="Optional"
                  inputClass="border-noround-right border-none"
                  showIcon
                  minDate={new Date()}
                  onChange={handleChange}
                  value={availabilty?.end_date}
                />
                <span
                  className="p-inputgroup-addon text-xs open-sans text-900 p-3 font-normal h-full cursor-pointer"
                  onClick={() => handleClear("end_date")}
                >
                  Clear
                </span>
              </div>
              <label className="text-sm">Notes</label>
              <InputTextAreaComponent
                name="notes"
                extraClassName="mb-4"
                rows="3"
                inputClass="w-full border-1 border-round-lg border-400"
                placeholder=""
                onChange={handleChange1}
                value={availabilty?.notes}
              />
              <div className="flex justify-content-end">
                <ButtonComponent
                  label="Save"
                  onClick={handleSave}
                ></ButtonComponent>

                <ButtonComponent
                  label="Cancel"
                  className=" bg-gray-light px-4 ml-2 border-none border-round-lg"
                  onClick={() => setVisible(false)}
                ></ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddAvailibility;
