export const options = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
    { label: "10", value: 10 },
    { label: "11", value: 11 },
    { label: "12", value: 12 },
];
export const titleOptions = [
    { name: "Principal", value: "principal" },
    { name: "Secretary", value: "secretary" },
];

export const SelectName = [
    { name: "Unrecorded", value: "unrecorded" },
    { name: "Present", value: "present" },
    { name: "Absent", value: "absent" },
    { name: "Teacher Absent", value: "teacher_absent" },
];
export const Days = [
    {
        name: "Monday",
        value: 1,
    },
    {
        name: "Tuesday",
        value: 2,
    },
    {
        name: "Wednesday",
        value: 3,
    },
    {
        name: "Thursday",
        value: 4,
    },
    {
        name: "Friday",
        value: 5,
    },
    {
        name: "Saturday",
        value: 6,
    },
];

export const skillLevel = [
    {
        name: "Beginner",
        value: "beginner",
    },
    {
        name: "Intermediate",
        value: "intermediate",
    },
    {
        name: "Advanced",
        value: "advanced",
    },
];

export const schoolTypes = [
    { name: "Flex Class", value: "during_school" },
    { name: "Set Class Time", value: "after_school" },
    { name: "Flex Class", value: "semi_private_during_school" },
    { name: "Set Class Time", value: "semi_private" },
];

export const GradeOptions = [
    { label: "Kindergarten (K)", value: "k" },
    { label: "Grade 1", value: "1" },
    { label: "Grade 2", value: "2" },
    { label: "Grade 3", value: "3" },
    { label: "Grade 4", value: "4" },
    { label: "Grade 5", value: "5" },
    { label: "Grade 6", value: "6" },
    { label: "Grade 7", value: "7" },
    { label: "Grade 8", value: "8" },
    { label: "Grade 9", value: "9" },
    { label: "Grade 10", value: "10" },
    { label: "Grade 11", value: "11" },
    { label: "Grade 12", value: "12" },
];

export const InstrumentPurchaseType = [
    {
        name: "Rent",
        value: "rent",
    },
    {
        name: "Purchase",
        value: "purchase",
    },
    {
        name: "Nothing Needed",
        value: "nothing_needed",
    },
];
export const genderOptions = [
    {
        name: "Male",
        value: "male",
    },
    {
        name: "Female",
        value: "female",
    },
    {
        name: "Others",
        value: "others",
    },
    {
        name: "Prefer Not To Say",
        value: "prefer_not_to_say",
    },
];

export const classType = [
    {
        name: "Set Class Time",
        value: "after_school",
    },
    {
        name: "Flex Class",
        value: "during_school",
    },
];
export const DropOptions = [
    {
        name: "Yes",
        value: "yes",
    },
    {
        name: "No",
        value: "no",
    },
];

export const fieldsToValidate = ["number_of_student", "discount", "installment", "number_of_installment", "prepayment_discount"];

export const errorMessages1 = [
    "No. of Student is required",
    "Discount is required",
    "Installment is required",
    "No. of Installment is required",
    "Prepayment Discount  is required",
];
export const requiredFields1 = ["number_of_student", "discount", "installment", "number_of_installment", "prepayment_discount"];

export const validationRules = {
    number_of_student: "No. of Student is required",
    discount: "Discount is required",
    installment: "Installment is required",
    number_of_installment: "No. of Installment is required",
    prepayment_discount: "Prepayment Discount is required",
};
export const optionsFrequency = [
    { label: "Daily", value: "daily" },
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" },
    { label: "Yearly", value: "yearly" },
];

export const instrumentRequiredFields = {
    name: "Instrument Name",
    purchase_amount: "Purchase Amount",
    monthly_installment: "Rent Amount",
    number_of_installment: "No. of Installment",
    deposit_amount: "Deposit Amount",
};
