import React, { useEffect } from "react";
import Logo from "../../../assets/logo/logo.png";
import LessonImg from "../../../assets/images/Select-amico1.png";
import MusicLyric from "../../../assets/icons/music-lyric.png";
import { Link, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import { ViewOnlineSchoolDetail } from "../../../redux/actions/schoolAction";
import OnlineSchoolDetail from "./OnlineSchoolDetail";
import { useHistory } from "react-router-dom";
import ArrowRight from "../../../assets/icons/arrow-right.png";

export default function SchoolLesson() {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ViewOnlineSchoolDetail(id));
  }, [dispatch,id]);

  const viewOnlineSchoolDetail = useSelector(
    (state) => state?.school?.viewOnlineLesson
  );
  const history = useHistory();

  return (
    <div className="">
    <div className="px-2 md:px-7 shadow-2 bg-main block md:hidden">
        <div className="p-2 flex justify-content-between align-items-center">
          <div className="logo">
            <div className="logo" onClick={() => history.push("/")}>
              <img src={Logo} alt="" width="200" className="mr-2"></img>
            </div>
          </div>
        </div>
      </div>
      <div
        className="banner login-banner"
        style={{ height: "100vh" }}
      >
        <div className="grid bg-main m-0 h-full ">
          <div className=" lg:col-6 md:col-12 sm:col-12 flex align-items-center h-auto md:h-full relative">
            <div className="px-8 absolute w-100 top-0 left-0 z-1 logo-desktop">
              <div className="logo cursor-pointer">
                <img
                  src={Logo}
                  alt=""
                  width="200"
                  className="mr-2"
                  onClick={() => history.push("/")}
                ></img>
              </div>
            </div>
            <img
              src={LessonImg}
              alt=""
              className="m-auto mt-5 py-5"
              style={{ width: "80%" }}
            />
          </div>
          <div className=" bg-white lg:col-6 md:col-12 p-2 md:p-4 py-2 h-auto md:h-full relative overflow-scroll">
            <img
              src={MusicLyric}
              alt=""
              className="absolute opacity-20 w-5"
              style={{ right: "2rem", top: "-2rem" }}
            />
            <div className=" relative ml-0 md:ml-5 mt-5  h-full p-3">
              <div className="relative flex flex-column h-full align-items-center justify-content-center">
                <Link to="" className="absolute top-0 left-0">
                  <img src={ArrowRight} width={20} alt="img"/>
                </Link>
                <div className="shadow-1 p-3 py-5 border-round-lg w-11 md:w-9 ms-3 relative z-3 bg-white">
                  <h1 className="text-2xl mb-3 font-bold">
                    Enroll Now in  -
                    <span className="text-main"> Private lessons</span>
                  </h1>
                  <form action="">
                    <p className="text-dark-purple text-xs mb-2 font-normal open-sans">
                      Lesson Program
                    </p>
                    <InputText
                      placeholder=""
                      className="w-full mb-3"
                      value={viewOnlineSchoolDetail?.online_lesson?.name}
                      readOnly
                    />

                    {/* <div className="dropdown mb-3">
                      <p className="font-bold text-red mb-2 open-sans">
                        <b>Class are on Wednesday</b>
                      </p>
                    </div> */}
                    <OnlineSchoolDetail
                      viewOnlineSchoolDetail={viewOnlineSchoolDetail}
                    />

                    <Link to={`/online-music-classes/online_lessons/${id}`}>
                      <button className=" btn bg-dark px-4 w-full  p-3 border-none text-white font-semibold border-round-lg cursor-pointer open-sans">
                        Online Registration
                      </button>
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
