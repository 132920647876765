import types from "../types";
const initialState = {
  ViewEvent: {},
  viewAttandence: {},
  viewTeacherAttendence: [],
};
const AttendanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.VIEW_EVENT:
      return {
        ...state,
        ViewEvent: action.payload,
      };
    case types.VIEW_ATTENDENCE:
      return {
        ...state,
        viewAttandence: action.payload,
      };
    case types.VIEW_TEACHER_ATTENDENCE:
      return {
        ...state,
        viewTeacherAttendence: action.payload,
      };
    default:
      return { ...state };
  }
};

export default AttendanceReducer;
