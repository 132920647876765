import React, { useEffect } from "react";
import Logo from "../../../assets/logo/logo.png";
import LessonImg from "../../../assets/images/Select-amico1.png";
import MusicLyric from "../../../assets/icons/music-lyric.png";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ViewSchoolAction } from "../../../redux/actions/schoolAction";
import SchoolDetail from "./SchoolDetail";
import ArrowRight from "../../../assets/icons/arrow-right.png";
import InputComponent from "../../../components/Fields/InputField";
import types from "../../../redux/types";

export default function SchoolLesson() {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ViewSchoolAction(id));
    return(()=>{
      dispatch({
        type: types.VIEW_SCHOOL,
        payload: {},
      });
    })
  }, [dispatch, id]);

  const viewSchoolDetail = useSelector((state) => state?.school?.viewSchool);
  const history = useHistory();

  return (
    <div className="">
      <div className="px-2 md:px-7 shadow-2 bg-main block md:hidden">
        <div className="p-2 flex justify-content-between align-items-center">
          <div className="logo">
            <div className="logo" onClick={() => history.push("/")}>
              <img src={Logo} alt="" width="200" className="mr-2"></img>
            </div>
          </div>
        </div>
      </div>
      <div
        className="banner login-banner overflow-hidden"
        style={{ height: "100vh" }}
      >
        <div className="grid bg-main m-0 h-full overflow-auto">
          <div className=" lg:col-6 md:col-12 sm:col-12 flex align-items-center h-auto md:h-full relative ">
            <div className="px-8 absolute w-100 top-0 left-0 z-1 logo-desktop">
              <div
                className="logo cursor-pointer"
                onClick={() => history.push("/")}
              >
                <img src={Logo} alt="" width="200" className="mr-2"></img>
              </div>
            </div>
            <img
              src={LessonImg}
              alt=""
              className="m-auto mt-5 py-5"
              style={{ width: "80%" }}
            />
          </div>
          <div className=" bg-white lg:col-6 md:col-12 p-1 py-2 h-auto md:h-full  overflow-auto">
            <div className="relative p-3 mt-5 ml-0 md:ml-5">
              <Link to="/" className="absolute top-0 left-0">
                <img src={ArrowRight} width={20} alt="" />
              </Link>
              <img
                src={MusicLyric}
                alt=""
                className="absolute opacity-20 w-5"
                style={{ right: "2rem", top: "-2rem" }}
              />
              <div className="flex flex-column justify-content-center align-items-center relative ml-0 md:ml-3 mt-5 h-100 school-detail-view">
                <div className="shadow-1 p-3 py-5 border-round-lg w-full md:w-9 ms-3 relative z-3 bg-white">
                  <h1 className="text-2xl mb-3 font-bold">
                    Enroll Now in at-
                    <span className="text-main"> School Lesson</span>
                  </h1>
                  <form action="">
                    <p className="text-dark-purple text-xs mb-2 font-normal open-sans">
                      School
                    </p>
                    <InputComponent
                      placeholder=""
                      className="w-full mb-3"
                      value={viewSchoolDetail?.school?.name}
                      readOnly
                    />

                    <SchoolDetail viewSchoolDetail={viewSchoolDetail} />

                    <Link to={`/student-form2/school_lessons/${id}`}>
                      <button className=" btn bg-dark px-4 w-full  p-3 border-none text-white font-semibold border-round-lg cursor-pointer open-sans mt-3">
                        Online Registration
                      </button>
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
