import React, { useState } from "react";
import ClockIcon from "../assets/icons/clockgreen.png";
import ButtonComponent from "../components/Fields/Button/CustomButton";
import NextDialog from "../components/popup/AllModal";

export default function SchoolClassCard({
    title,
    extraClassName,
    tagClass,
    instrumentname,
    Seatsinfo,
    time,
    className,
    handleProgram,
    program,
    setData,
    schoolType,
}) {
    const [visible, setVisible] = useState(false);
    return (
        <div className={extraClassName}>
            <div className={`school-card-pc bg-whitesmoke p-3 border-round-lg cursor-pointer ${className}`} onClick={handleProgram}>
                <div className="flex justify-content-between mb-3">
                    <h5 className="text-main font-semibold">{title}</h5>
                    {schoolType !== "during_school" && (
                        <div className={`rounded-pill text-xs flex align-items-center justify-content-center p-1 font-semibold px-3 ${tagClass} `}>
                            {Seatsinfo}
                        </div>
                    )}
                </div>
                <div className="flex gap-3 justify-content-between align-items-end">
                    <div className="w-7">
                        <p className="text-lg font-semibold text-dark">Instrument</p>
                        <small className="text-gray">{instrumentname}</small>
                    </div>
                    {Seatsinfo === "Seats are full" ? (
                        <ButtonComponent label="Join Waitlist" className="p-1 px-2 md:px-3" onClick={() => setVisible(true)} />
                    ) : (
                        <div className="flex gap-2 align-items-center">
                            <img src={ClockIcon} alt="" />
                            <span className="text-main font-semibold text-2xl">{time}</span>
                        </div>
                    )}
                </div>
            </div>

            {/* For Mobile  */}
            <div className={`school-card-mobile bg-whitesmoke p-3 border-round-lg cursor-pointer ${className}`} onClick={handleProgram}>
                {Seatsinfo === "Seats are full" && <ButtonComponent label="Join Waitlist" className="p-1 px-2 md:px-3 mb-1" onClick={() => setVisible(true)} />}

                {schoolType !== "during_school" && (
                    <div className={`rounded-pill text-xs flex align-items-center justify-content-center p-1 font-semibold px-3 mb-2 ${tagClass} `}>
                        {Seatsinfo}
                    </div>
                )}

                {instrumentname && (
                    <div className="flex gap-3 justify-content-start align-items-end mb-2">
                        <p className="text-lg font-semibold text-dark">Instrument</p>
                        <p className="text-gray font-semibold">{instrumentname}</p>
                    </div>
                )}
                <div className="flex justify-content-between">
                    <h6 className="text-main font-semibold">{title}</h6>
                </div>
                {time && (
                    <div className="flex gap-2 justify-content-end">
                        <img src={ClockIcon} alt="" />
                        <span className="text-main font-semibold text-lg">{time}</span>
                    </div>
                )}
            </div>
            <NextDialog
                visible={visible}
                modalClass="header-none border-round-lg overflow-hidden"
                closebtn={() => setVisible(false)}
                lightbtnlabel="Cancel"
                darkbtnlabel="OK"
                okBtnClick={() => {
                    setVisible(false);
                    setData((prev) => ({ ...prev, is_waiting: true }));
                }}
                detail={`We're in the process of creating your class, which may take 1-2 weeks. To be on the waitlist, please make a deposit of $${program.school_lesson.deposit_amount}.`}
            />
        </div>
    );
}
