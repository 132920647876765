import React, { useState, useEffect } from 'react';
import UserProfile from "../../assets/images/ProfilePictureViewer.png";
import { getImageURL } from '../../utils/javascript';
import EditPensilIcon from "../../assets/icons/editpensil.png";
import DeleteIcon from "../../assets/icons/delete.png";

const CustomImageInput = ({
    label,
    name,
    onFilesChange,
    errorMessage,
    extraClassName,
    value,
    data,
    limit,
    multiple,
    col,
    required,
    removeable,
    editable,
    disabled = false,
    view,
    ...props
}) => {
    const [files, setFiles] = useState([]);
    useEffect(() => {
        if (value || data?.[name]) {
            if (JSON.stringify(data?.[name]) !== JSON.stringify(files)) {
                setFiles(data?.[name]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value || data?.[name]]);

    useEffect(() => {
        if (onFilesChange) {
            onFilesChange({ name, value: files });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files, name]);
    const onDelete = (id) => {
        const s = files.filter((item, index) => index !== id);
        setFiles([...s]);
    };
    const onFileChange = (e) => {
        if (editable) {
            const chossenFile = e.target.files;
            handlefileChange(Object.values(chossenFile));
        }
    };
    const handlefileChange = (uploadedfile) => {
        let uploadedFiles = [];
        uploadedfile.forEach((file) => {
            if (files.findIndex((f) => f.name === file.name) === -1) {
                uploadedFiles.push(file);
            }
        });
        if (multiple) {
            if (limit) {
                let newarr = [...files, ...uploadedFiles];
                if (newarr.length > limit) {
                    newarr = newarr.slice(0, limit);
                    // dispatch(showToast({ severity: "warn", summary: "Limit Exceeded", detail: `Max. file limit is ${limit}.` }));
                }
                setFiles(newarr);
            } else {
                setFiles([...files, ...uploadedFiles]);
            }
        } else {
            setFiles([...uploadedFiles]);
        }
    };

    return (
        <div className={`lg:w-18rem lg:col-3 xl:col-3 p-1 ${extraClassName}`}>
            <div className="user-profile relative border-round-lg overflow-hidden" >
                <div className="w-full h-full">
                    {files.length
                        ? Object.values(files).map((image, i) => (
                            <div
                                key={i}
                                className=" h-full w-full"

                            >
                                <img
                                    src={
                                        image
                                            ? typeof image === "string"
                                                ? getImageURL(image)
                                                : URL.createObjectURL(image)
                                            : UserProfile
                                    }
                                    onClick={(e) => (e.target.value = "")}
                                    alt="imh"
                                    className='w-full h-full fit-cover'

                                />
                            </div>
                        ))
                        : <img
                            src={
                                UserProfile
                            }
                            onClick={(e) => (e.target.value = "")}
                            alt="imh"
                            className='w-full h-full fit-cover'
                        />}
                    {/* <img
                        className="w-full h-full fit-cover"
                        src={
                            image
                                ? typeof image === "string"
                                    ? getImageURL(image)
                                    : URL.createObjectURL(image)
                                : UserProfile
                        }
                        alt=""
                    /> */}
                </div>
                {!view ? <div className="flex absolute bottom-0 justify-content-center w-full mb-2">
                    <button className="image-edit-btn border-circle bg-white-transparent  overflow-hidden cursor-pointer">
                        <div className="relative cursor-pointer">
                            <input
                                name={name}
                                onChange={onFileChange}
                                onClick={(e) => (e.target.value = "")}
                                id="formImage"
                                type="file"
                                accept="image/*"
                                className="opacity-0 absolute top-0 left-0 cursor-pointer"
                            />
                            {<img src={EditPensilIcon} alt="" />}
                        </div>
                    </button>
                    {files.length
                        ? Object.values(files).map((image, i) => (<button className="image-delete-btn border-circle bg-white-transparent ml-3 ">
                            {removeable && (
                                <img src={DeleteIcon} alt="" onClick={() => onDelete(i)} />
                            )}

                        </button>)) : null}

                </div> : <><div className="relative cursor-pointer">
                    <input
                        name={name}
                        onChange={onFileChange}
                        onClick={(e) => (e.target.value = "")}
                        id="formImage"
                        type="file"
                        accept="image/*"
                        className="opacity-0 absolute top-0 left-0 cursor-pointer"
                    />
                </div></>}
            </div>
        </div>
    );
}

export default CustomImageInput
