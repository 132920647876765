import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import ButtonComponent from "../../../../components/Fields/Button/CustomButton";
import AddNewCard from "../Settings/AddNewCard";
import CardImg from "../../../../assets/icons/creditcard.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getParentProfileApi } from "../../../../redux/actions/loginAction";
import { ParentCardAction } from "../../../../redux/actions/ParentPortal/parentAction";
import { Column } from "primereact/column";
import RadioButtonComponent from "../../../../components/Fields/Radio";
import { makePaymentAction } from "../../../../redux/actions/ParentPortal/accountsandInvoicesAction";
import Loader from "../../../../utils/Loader";

const MakePayment = ({ makePayment, setMakePayment, openCard, setOpenCard, amount }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        dispatch(getParentProfileApi());
        dispatch(ParentCardAction());
    }, [dispatch]);
    const [cardId, setCardId] = useState(0);

    const parentProfile = useSelector((state) => state?.login?.getParentProfile);

    const cards = useSelector((state) => state?.parent?.getCards);

    useEffect(() => {
        if (cards?.card_details?.length > 0) {
            setCardId(cards?.default_card_id);
        }
    }, [cards]);
    const RadioTemp = (rowData) => {
        return (
            <>
                <RadioButtonComponent
                    name="card_id"
                    value={rowData?.card_id}
                    checked={cardId === rowData?.card_id}
                    onChange={(e) => setCardId(e.value)}
                />
            </>
        );
    };
    const handlePay = () => {
        dispatch(makePaymentAction(makePayment, cardId, setMakePayment));
    };

    return (
        <div>
            <Dialog
                header="Manage Payment Methods"
                visible={makePayment}
                // style={{ width: "50vw" }}
                onHide={() => setMakePayment(null)}
            >
                <h5 className="font-semibold ml-2">Fundamental Music Instruction</h5>
                <small className="text-sm ml-2 mb-3">
                    <span className="font-semibold">Account : {parentProfile?.name}</span>
                </small>
                <DataTable
                    className="mb-2"
                    tableStyle={{ minWidth: "50rem" }}
                    value={cards?.card_details?.length && cards?.card_details}
                >
                    <Column field="card_type" header="Type"></Column>
                    <Column field="card_number" header="Account Number"></Column>
                    <Column body={RadioTemp} header="Action"></Column>
                </DataTable>
                <ButtonComponent className="bg-light-main text-sm font-semibold mb-2" onClick={() => setOpenCard(true)}>
                    <img src={CardImg} alt="" width={20} />
                    <span className="ml-2">Add New Card</span>
                </ButtonComponent>
                <p className="text-sm ml-2">
                    <span className="font-semibold"> Amount : {amount} </span>
                </p>
                <Loader />
                <ButtonComponent className="bg-light-main text-sm font-semibold mb-2 m-auto block " onClick={handlePay}>
                    <span className="ml-2">Pay Now</span>
                </ButtonComponent>
                <Dialog
                    header="Add New Card"
                    className="assign-student border-round-lg overflow-hidden "
                    visible={openCard}
                    style={{ width: "48vw" }}
                    draggable={false}
                    resizable={false}
                    onHide={() => setOpenCard(false)}
                >
                    <AddNewCard openCard={openCard} setOpenCard={setOpenCard} />
                </Dialog>
            </Dialog>
        </div>
    );
};

export default MakePayment;
