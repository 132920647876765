import React from "react";
import { Dialog } from "primereact/dialog";
import ButtonComponent from "../../../components/Fields/Button/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { removeStudentAction } from "../../../redux/actions/newEnrollmentAction";
import AlertIcon from "../../../assets/icons/question.png";

const RemoveStudentDialog = ({ removeStudent, setRemoveStudent, index, setActiveIndex }) => {
    const schoolForms = useSelector((state) => state?.enroll?.students);

    const dispatch = useDispatch();
    const handleRemoveClick = (indexToRemove) => {
        dispatch(removeStudentAction(indexToRemove));
        setActiveIndex(index - 1);
        // setActiveIndex(indexToRemove - 1);
        // setSchoolForms((prevForms) => {
        //   return prevForms.filter((form, index) => index !== indexToRemove);
        // });
        setRemoveStudent(null);
    };
    return (
        <div>
            <Dialog className="header-none border-round-lg overflow-hidden" visible={removeStudent} style={{ width: "400px" }}>
                <div className="text-center">
                <img src={AlertIcon} alt="" className="w-3rem mb-3" />
                    <p className="text-lg text-center font-bold inter mb-3">Are you sure you want to Remove Student?</p>
                    <div className="flex justify-content-center">
                        <ButtonComponent
                            className="btn no-underline border-set bg-transparent p-2 inter px-5 text-main border-round-lg"
                            onClick={() => handleRemoveClick(index)}
                        >
                            <span className="inter font-semibold">Yes</span>
                        </ButtonComponent>
                        <ButtonComponent
                            className="btn no-underline bg-dark text-white p-2 inter px-5 ml-2 border-none border-round-lg"
                            onClick={() => setRemoveStudent(null)}
                        >
                            <span className="inter font-semibold">No</span>
                        </ButtonComponent>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default RemoveStudentDialog;
