import React, { useEffect } from "react";
import Loader1 from "../utils/Loader1";
import { CheckboxComponent, DropDownComponent, InputComponent1, RadioButtonComponent } from "./AllInputs";
import { SkillLevel } from "./constants1";
import SchoolClassCard from "./SchoolClassCard";
import InputGroup from "../components/Fields/InputGroup";
import { NumberComponent } from "./AllInputs";
import AtSchoolNewAdmissionForm1 from "./AtSchoolNewAdmission";

const AtSchoolNewAdmissionForm = ({ data, setData, initialData, index, onChangeActiveIndex }) => {
    const {
        handleChange,
        filteredInstrumentsPurchaseType,
        handlePrograms,
        selectJrJamFun,
        handleExtraFields,
        history,
        ProgramList,
        programType,
        loading,
        removeStudent,
        setRemoveStudent,
        admissionForm,
        schoolForms,
        instrument,
    } = AtSchoolNewAdmissionForm1(data, setData, initialData, index, onChangeActiveIndex);

    return (
        <div>
            {loading ? <Loader1 isLoading={loading} /> : null}

            <div className="">
                <div className="mb-3 p-3">
                    <div className="grid flex-wrap mb-2 more-info-dialog">
                        <InputComponent1
                            extraClassName="col-12 md:col-6"
                            placeholder="Student First Name*"
                            label="First Name"
                            name="first_name"
                            value={data?.first_name}
                            onChange={handleChange}
                            labelClassName="relative left-0 text-dark text-base"
                            required
                            errorMessage={data?.formErrors?.first_name}
                        />
                        <InputComponent1
                            name="last_name"
                            extraClassName="col-12 md:col-6"
                            placeholder="Student Last Name*"
                            label="Last Name"
                            value={data?.last_name}
                            onChange={handleChange}
                            required
                            labelClassName="relative left-0 text-dark text-base"
                            errorMessage={data?.formErrors?.last_name}
                        />
                        <InputComponent1
                            name="school"
                            extraClassName="col-12 md:col-6"
                            label="School Name"
                            labelClassName="relative left-0 text-dark text-base"
                            placeholder=""
                            required
                            value={schoolForms?.[index]?.school_name}
                            readOnly
                        />
                        <DropDownComponent
                            name="skill_level"
                            extraClassName="col-12 md:col-6"
                            placeholder=" Select Skill Level"
                            labelClassName="relative left-0 text-dark text-base"
                            label="Skill Level"
                            required
                            value={data?.skill_level}
                            options={SkillLevel}
                            onChange={handleChange}
                            optionLabel="name"
                            errorMessage={data?.formErrors?.skill_level}
                        />
                        {admissionForm?.admission_form?.student_extra_fields?.map((item, itemIndex) => (
                            <div className="col-12 md:col-6" key={itemIndex}>
                                <InputComponent1
                                    label={item?.label}
                                    labelClassName="relative left-0 text-dark text-base"
                                    id={`${item?.label?.toLowerCase()}`}
                                    name={`${item?.label?.toLowerCase()}`}
                                    placeholder={item?.placeholder}
                                    value={admissionForm?.student_extra_fields?.label?.toLowerCase()} // Use index to access data
                                    onChange={(e) => handleExtraFields(e, item)} // Pass index to handleExtraFields
                                />
                                <label className="">{item?.text}</label>
                            </div>
                        ))}
                    </div>

                    <div className="grid flex-wrap  align-items-end mb-4 more-info-dialog">
                        <InputComponent1
                            name="grade"
                            extraClassName="col-12 md:col-6"
                            label="Grade"
                            labelClassName="relative left-0 text-dark text-base"
                            placeholder=""
                            required
                            value={schoolForms?.[index]?.grade1?.name}
                            readOnly
                        />
                        <DropDownComponent
                            name="class_type"
                            extraClassName="col-12 md:col-6"
                            label="Choose your class type"
                            labelClassName="relative left-0 text-dark text-base"
                            placeholder="Select Program Type"
                            required
                            options={programType}
                            value={data?.class_type}
                            onChange={handleChange}
                            optionLabel="name"
                            errorMessage={data?.formErrors?.class_type}
                        />
                    </div>
                    {(data?.class_type === "after_school" || data?.class_type === "semi_private") && (
                        <div className="relative mb-3">
                            <h6 className="text-900 font-bold flex align-items-center justify-content-between">Dismissal Programs</h6>
                            <div className="grid align-items-center w-full md:w-auto m-0">
                                <div className="md:col-5 mb-2">
                                    <p>Does your child attend aftercare?</p>
                                </div>
                                <div className="md:col-3">
                                    <div className="flex align-items-center">
                                        <RadioButtonComponent
                                            name="prefer_drop_picked_up_for_class"
                                            label="Yes"
                                            value="yes"
                                            onChange={handleChange}
                                            checked={data?.prefer_drop_picked_up_for_class === "yes"}
                                        />
                                        <RadioButtonComponent
                                            name="prefer_drop_picked_up_for_class"
                                            label="No"
                                            value="no"
                                            onChange={handleChange}
                                            checked={data?.prefer_drop_picked_up_for_class === "no"}
                                        />
                                    </div>
                                    {data?.formErrors?.prefer_drop_picked_up_for_class ? (
                                        <small className="p-error open-sans text-sm">{data?.formErrors?.prefer_drop_picked_up_for_class}</small>
                                    ) : null}
                                </div>
                            </div>
                            {data?.prefer_drop_picked_up_for_class === "yes" && (
                                <div className="grid w-full md:w-auto m-0  align-items-center">
                                    <div className="md:col-5 pl-0">
                                        <p>If so, what time are they picked up?</p>
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <InputComponent1
                                            type="time"
                                            inputClass="select_time_zone"
                                            name="aftercare_picked_up_time"
                                            onChange={handleChange}
                                            value={data?.aftercare_picked_up_time}
                                            errorMessage={data?.formErrors?.aftercare_picked_up_time}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <div className="bg-main p-3 border-round-lg my-4">
                        <p className="font-semibold text-black-alpha-90	">Select Your Class</p>
                    </div>
                    <div className="grid">
                        {ProgramList?.length > 0 &&
                            ProgramList?.map((program, i) =>
                                data?.class_type === "during_school" ? (
                                    <SchoolClassCard
                                        title={program.school_lesson.name}
                                        extraClassName="col-12 md:col-6 lg:col-4"
                                        tagClass={program.is_full ? "text-red bg-red-100" : "text-green bg-green-100"}
                                        instrumentname={program?.instruments}
                                        Seatsinfo={program.is_full ? "Seats are full" : `Open to ${program?.school_lesson?.no_of_seats} Student`}
                                        time={program?.school_program_session?.session_start_time}
                                        className={data?.program_id === program.id ? "programme-selected" : ""}
                                        handleProgram={() => handlePrograms(program)}
                                        program={program}
                                    />
                                ) : data?.class_type === "after_school" || data?.class_type === "semi_private" ? (
                                    <SchoolClassCard
                                        title={program.school_lesson.name}
                                        extraClassName="col-12 md:col-6 lg:col-4"
                                        tagClass={program.is_full ? "text-red bg-red-100" : "text-green bg-green-100"}
                                        instrumentname={program?.instruments}
                                        Seatsinfo={program.is_full ? "Seats are full" : `Open to ${program?.school_lesson?.no_of_seats} Student`}
                                        time={program?.school_program_session?.session_start_time}
                                        className={data?.program_id === program.id ? "programme-selected" : ""}
                                        handleProgram={() => handlePrograms(program)}
                                        program={program}
                                    />
                                ) : (
                                    <></>
                                )
                            )}
                    </div>
                    {ProgramList?.length > 0 && data?.class_type && <div className="text-danger text-sm open-sans">{data?.formErrors?.program_id}</div>}
                    <div className="bg-main p-3 border-round-lg my-4">
                        <p className="font-semibold text-black-alpha-90	">Instrument Purchase Program</p>
                    </div>
                    <div className="grid w-full">
                        <div className="col-12 md:col-6">
                            <div className="flex gap-3">
                                <p className="font-semibold">Do you need Instrument?</p>
                                <RadioButtonComponent
                                    label="Yes"
                                    name="instrument_needed"
                                    value="yes"
                                    checked={data?.instrument_needed === "yes"}
                                    onChange={handleChange}
                                />
                                <RadioButtonComponent
                                    label="No"
                                    name="instrument_needed"
                                    value="no"
                                    checked={data?.instrument_needed === "no"}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    {data?.instrument_needed === "yes" && (
                        <>
                            <div className="grid mt-2">
                                <>
                                    <DropDownComponent
                                        label="Select Instrument"
                                        labelClassName="absolute text-xs z-3 text-500 open-sans bg-white form-label m-0"
                                        extraClassName="col-12 md:col-4 relative z-3"
                                        optionLabel="name"
                                        onChange={handleChange}
                                        name="instrument_id"
                                        value={data?.instrument_id}
                                        options={instrument?.instruments?.map((item) => {
                                            return { name: item.name, value: item.id };
                                        })}
                                        errorMessage={data?.formErrors?.instrument_id}
                                    />
                                    <DropDownComponent
                                        name="type"
                                        label="Purchase type"
                                        labelClassName="absolute text-xs z-3 text-500 open-sans bg-white form-label m-0"
                                        extraClassName="col-12 md:col-4 relative z-3"
                                        optionLabel="name"
                                        options={filteredInstrumentsPurchaseType}
                                        value={data?.type}
                                        onChange={handleChange}
                                        errorMessage={data?.formErrors?.type}
                                    />
                                    <InputGroup dollar="dollar" size="col-12 md:col-4">
                                        <NumberComponent
                                            label="Amount"
                                            labelClassName="absolute text-xs z-3 text-500 open-sans bg-white form-label m-0"
                                            placeholder="0"
                                            extraClassName="w-full"
                                            name="amount"
                                            value={data?.amount}
                                            dollar="dollar"
                                            disabled
                                        />
                                    </InputGroup>
                                </>
                            </div>

                            <p className="text-xs font-normal text-900 mb-3 open-sans">*If you need to rent an instrument, a link will be sent to you on your enrollment confirmation email. </p>
                            <p className="font-bold text-900 mb-3">$30.00 Registration Fee (Which also covers lesson materials needed)</p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AtSchoolNewAdmissionForm;
