import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loginAction } from "../../redux/actions/loginAction";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../services/auth";
var base64 = require("base-64");

const initialState = {
    user_name: "",
    password: "",
};

const Login = () => {
    const history = useHistory();
    useEffect(() => {
        document.documentElement.style.fontSize = 14 + "px";
        if (isAuthenticated()) {
            history.push("/student/dashboard");
        }
    }, [history]);

    const dispatch = useDispatch();
    const [studentData, setstudentData] = useState(initialState);

    useEffect(() => {
        const rememberMe = localStorage.getItem("rememberMe") === "true";
        const user_name = rememberMe ? localStorage.getItem("userName") : "";
        const password = rememberMe ? localStorage.getItem("Detail") : "";
        if (localStorage.getItem("rememberMe") === "false") {
            localStorage.removeItem("userName");
            localStorage.removeItem("Detail");
            setstudentData({
                user_name: "",
                password: "",
                rememberMe: false,
            });
        }

        setstudentData({
            user_name: user_name,
            password: base64.decode(password),
            rememberMe,
        });
    }, []);

    const [err, setErr] = useState({});
    const validateForm = () => {
        let errors = {};
        if (!studentData?.user_name) {
            errors.user_name = "User Name is required ";
        }
        if (!studentData?.password) {
            errors.password = "Password is required ";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            dispatch(loginAction(studentData, history, "STUDENT"));
        }
    };

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        if (name === "rememberMe") {
            setstudentData({ ...studentData, [name]: checked });
        } else {
            setstudentData({ ...studentData, [name]: value });
            setErr({ ...err, [name]: "" });
        }
    };

    return {
        studentData,
        handleSubmit,
        handleChange,
        err,
    };
};

export default Login;
