import types from "../../types";
const initialBoard = {
  getParentContact: {},
  
};

const contactReducer = (state = initialBoard, action) => {
  switch (action.type) {
    case types.GET_PARENT_CONTACT:
      return { ...state, getParentContact: action.payload };
    default:
      return { ...state };
  }
};

export default contactReducer;