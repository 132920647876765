import React, { useEffect, useState } from "react";
import Logo from "../../../../assets/icons/logo.png";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { jsPDF } from "jspdf";
import PaidImg from "../../../../assets/icons/paid-img.png";
import DeclinedImg from "../../../../assets/icons/declined.png";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Constants from "../../../../services/constant";
import ButtonComponent from "../../../../components/Fields/Button/CustomButton";
import { api } from "../../../../services/api";

export const InvoiceGenerate = ({ data }) => {
    const [invoiceDetails, setInvoiceDetails] = useState();
    const history = useHistory();

    const getInvoiceDetails = async (invoiceId, type) => {
        let data = await api("GET", `${Constants.END_POINT.VIEW_INVOICE}/${invoiceId}`);
        if (data.success === true) {
            let tableData = data?.data?.invoice_data?.map((invoice) => {
                return {
                    date: invoice.date.split(" ")[0],
                    description: invoice.description,
                    charges: invoice.payment_type === "charge" ? "$" + invoice.amount : "-",
                    payment: invoice.payment_type === "payment" ? "$" + invoice.amount : "-",
                };
            });

            setInvoiceDetails({
                date: data?.data?.date?.split(" ")[0],
                invoiceId: data?.data?.quickbook_invoice_id,
                billedTo: data?.data?.parent_user_name,
                previousBalance: data?.data?.previous_balance,
                payment: data?.data?.total_payment,
                newCharges: data?.data?.total_charges,
                total: data?.data?.total_due,
                data: tableData,
                status: data?.data?.status,
                address: {
                    address: data?.data?.parent_user?.address,
                    city: data?.data?.parent_user?.city,
                    state: data?.data?.parent_user?.state,
                    zipCode: data?.data?.parent_user?.zip_code,
                },
            });
        }

        type === "download" && getPDF();
    };

    useEffect(() => {
        if (data.invoiceId) {
            getInvoiceDetails(data.invoiceId, data.type);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const getPDF = () => {
        let pdfContainer = document.querySelector("#pdfData");
        let doc = new jsPDF("p", "", "a4");
        doc.html(pdfContainer, {
            callback: function (doc) {
                doc.save(`invoice.pdf`);
            },
            margin: 10,
            width: 190,
            windowWidth: 800,
            autoPaging: "text",
        });
    };

    return (
        <div className="p-6 pdf-container relative" style={{ fontFamily: "sans-serif", position: "relative" }}>
            {invoiceDetails?.status === "paid" || invoiceDetails?.status === "Paid" ? (
                <div
                    className="flex justify-content-center align-items-center "
                    style={{ position: "absolute", zIndex: 999999, width: "100%", height: "100%", opacity: "0.3" }}
                >
                    <img src={PaidImg} alt="" style={{ marginRight: "50px" }} />
                </div>
            ) : invoiceDetails?.status === "declined" || invoiceDetails?.status === "decline" ? (
                <div
                    className="flex justify-content-center align-items-center "
                    style={{ position: "absolute", zIndex: 999999, width: "100%", height: "100%", opacity: "0.1" }}
                >
                    <img src={DeclinedImg} alt="" style={{ marginRight: "50px" }} width="600px" />
                </div>
            ) : (
                ""
            )}
            <div className="grid justify-content-between mb-2">
                <div className="md:col-4">
                    <img src={Logo} alt="" className="w-full" />
                </div>
                <div className="md:col-5 text-right text-green-700">
                    <h2 className=" text-4xl font-bold">UPCOMING LESSONS OR EVENTS</h2>
                    <p>
                        <span className="font-bold">Date:</span> <span className="ml-2">{invoiceDetails?.date}</span>
                    </p>
                    <p>
                        <span className="font-bold">Invoice #:</span> <span className="ml-2">{invoiceDetails?.invoiceId}</span>
                    </p>
                </div>
            </div>
            <div className="flex justify-content-between mb-4">
                <div className="w-3">
                    <h4 className="font-bold" style={{ fontFamily: "sans-serif" }}>
                        Fundamental Music Instruction
                    </h4>
                    <p style={{ fontFamily: "sans-serif" }}>Fundamental Music Instruction</p>
                </div>
                <div className="w-3">
                    <h4 className="font-bold">Bill To:</h4>
                    <p className="">{invoiceDetails?.billedTo}</p>
                    {/* <p className="font-bold">Address:</p> */}
                    <i>
                        <p>
                            ({invoiceDetails?.address?.address}, {invoiceDetails?.address?.city},
                        </p>
                        <p>
                            {invoiceDetails?.address?.state}, {invoiceDetails?.address?.zipCode})
                        </p>
                    </i>
                </div>
                <div className="w-3 ">
                    <div className="mb-3">
                        <p className="flex justify-content-between">
                            <span>Previous Balance:</span>
                            <span>${invoiceDetails?.previousBalance}</span>
                        </p>
                        <p className="flex justify-content-between">
                            <span>Payments:</span>
                            <span>${invoiceDetails?.payment}</span>
                        </p>
                        <p className="flex justify-content-between">
                            <span>New Charges:</span>
                            <span>${invoiceDetails?.newCharges}</span>
                        </p>
                    </div>
                    <h3 className="text-right text-green-700 font-semibold">Total Due: ${invoiceDetails?.total}</h3>
                </div>
            </div>
            <DataTable value={invoiceDetails?.data} className="invoice-table mb-3">
                <Column field="date" header="Date" style={{ fontFamily: "sans-serif !important" }}></Column>
                <Column field="description" header="Description" style={{ fontFamily: "sans-serif !important" }}></Column>
                <Column field="charges" header="Charges" style={{ fontFamily: "sans-serif" }}></Column>
                <Column field="payment" header="Payments" style={{ fontFamily: "sans-serif" }}></Column>
            </DataTable>
            <div className="grid m-0">
                <div className="md:col-7 bg-gray-light">
                    <p>Thank you for being a part of our Fundamental Music Instruction Family!</p>
                </div>
                <div className="md:col-5 text-center">
                    <h3 className=" text-green-700 font-semibold">Total Due: ${invoiceDetails?.total}</h3>
                    {/* <ButtonComponent
                        label="Click Here to Pay Online"
                        className="drk-green-bg text-white border-round-3xl"
                        onClick={() => history.push("/invoice-payments")}
                    /> */}
                </div>
            </div>
            <div className="drk-green-bg text-white pdf-footer p-3 justify-content-between flex align-items-center mb-3">
                <p>Fundamental Music Instruction </p>
                <p className="text-sm">Upcoming Lessons or Events</p>
            </div>
        </div>
    );
};
