import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { authenticate1, isAuthenticated } from "../../../services/auth";
import { getParentProfileApi } from "../../../redux/actions/loginAction";
import { useDispatch } from "react-redux";
import { showLoaderAction } from "../../../redux/actions/loadAction";

const ParentLandingPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();
    const token = query.get("token");
    const role = query.get("role");

    useEffect(() => {
        showLoaderAction(true);
        authenticate1(token, role, () => {
            if (isAuthenticated()) {
                if (role === "PARENT") {
                    history.push("/parent/dashboard");
                } else {
                    history.push("/teacher-dashboard");
                }
                // history.replace("/parent/dashboard");
            }
            if (!isAuthenticated()) {
                // Fixed: Added parentheses to invoke the function
                // history.push("/");
            }
        });
    }, [token, history, dispatch]);

    return <div>We are Redirecting to ........</div>;
};

export default ParentLandingPage;
