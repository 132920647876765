import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GuitarIcon from "../assets/icons/vecteezy_music-instrument-electric-guitar_1206670.png";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import MoreInfo from "./MoreInfo";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import MoreInfo1 from "./MoreInfo1.jsx";
import { CustomDropdownComponent } from "./AllInputs.js";

const SubEnrollmentLessons = ({
    data,
    handleChange,
    addSchoolType,
    setAddSchoolType,
    handleClick,
    getSchools,
    getSchoolGrades,
    getSchoolInstruments,
    handleInstrumentClick,
    getSchoolInstrumentsPrograms,
    InstrumentTemplate,
    ActionTemplate,
    visible,
    setVisible,
    enrollInstrument,
    handleEnroll,
    index,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <div>
            <>
                <div className="grid my-3 ">
                    <CustomDropdownComponent
                        label="Select School"
                        name="school_id"
                        extraClassName="col-12 md:col-6"
                        placeholder="Select school"
                        value={data?.school_id}
                        options={getSchools}
                        onChange={handleChange}
                        optionLabel="name"
                        errorMessage={data?.formErrors?.school_id}
                    />
                    {data?.school_id && (
                        <CustomDropdownComponent
                            label={`Select Grade (of the ${index + 1}${index === 1 ? "nd" : index === 2 ? "rd" : index === 3 ? "th" : ""} Student)`}
                            extraClassName="col-12 md:col-6"
                            placeholder="Select grade"
                            name="grade1"
                            sublabel="Other ages can be chosen on the next page"
                            value={data?.grade1}
                            options={getSchoolGrades}
                            onChange={handleChange}
                            optionLabel="name"
                            errorMessage={data?.formErrors?.grade1}
                        />
                    )}
                </div>
                {data?.grade1 && (
                    <>
                        <div className="my-3">
                            <h2 className="text-dark font-bold text-2xl">Choose Instrument</h2>
                            <div className="flex mt-3 flex-wrap gap-3">
                                {getSchoolInstruments?.map((item, i) => {
                                    return (
                                        <>
                                            <div
                                                className={`bg-skinlight-color flex flex-column align-items-center justify-content-center instrument-box p-3 cursor-pointer ${
                                                    data?.enrollInstrument == item.value && "programme-selected"
                                                }`}
                                                onClick={() => {
                                                    handleInstrumentClick(item.value);
                                                }}
                                                key={item.value}
                                            >
                                                <img src={GuitarIcon} alt="" className="mb-2" />
                                                <p className="text-sm text-dark ">{item.name}</p>
                                            </div>
                                        </>
                                    );
                                })}
                                {<div className="text-danger text-sm">{data?.formErrors?.enrollInstrument}</div>}
                            </div>
                        </div>
                        {data?.enrollInstrument && (
                            <div className="">
                                <h2 className="text-dark font-bold text-2xl mb-3">Program Types</h2>
                                <DataTable value={getSchoolInstrumentsPrograms} className="border-round-lg overflow-hidden text-dark">
                                    <Column field="program_name" header="Program Name"></Column>
                                    <Column field="instrument_name" body={InstrumentTemplate} header="Instrument"></Column>
                                    <Column field="grade_name" header="Grade"></Column>
                                    <Column body={ActionTemplate} header="Action"></Column>
                                </DataTable>
                            </div>
                        )}
                        <MoreInfo1 visible={visible} setVisible={setVisible} instrumentId={enrollInstrument} handleEnroll={handleEnroll} btnText="Enroll now" />
                    </>
                )}
            </>
        </div>
    );
};

export default SubEnrollmentLessons;
