import React from "react";
import { InputTextarea } from "primereact/inputtextarea";

export default function InputTextAreaComponent({
  label,
  name,
  errorMessage,
  extraClassName,
  required,
  inputClass,
  placeholder,
  labelClassName,

  ...props
}) {
  return (
    <div className={`relative ${extraClassName}`}>
      {label ? (
        <label htmlFor={name} className={`absolute text-xs text-500 m-0 top-0 bg-white open-sans  bg-white-color form-label z-1 ${labelClassName}`}>
          {label} {required && <span className="p-error">*</span>}
        </label>
      ) : null}
      <InputTextarea
        id={name}
        name={name}
        placeholder={placeholder}
        className={`w-full ${inputClass} ${errorMessage ? "p-invalid" : null}`}
        {...props}
      />
      {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
    </div>
  );
}
