import React, { useState, useEffect } from "react";
import InputComponent from "../../../components/Fields/InputField";
import DropDownComponent from "../../../components/Fields/CustomDropdown";
import RadioButtonComponent from "../../../components/Fields/Radio";
import { api } from "../../../services/api";
import Constants from "../../../services/constant";
import { useParams } from "react-router-dom";
import NumberComponent from "../../../components/Fields/NumberField";
import { allValidations } from "../../../utils/formValidations";
import CustomCalendar from "../../../components/Fields/CustomCalendar";
import MultiSelectComponent from "../../../components/Fields/CustomMultiSelect";
import { Days } from "../../../utils/constants";
import { useDispatch } from "react-redux";
import types from "../../../redux/types";
import { getInstrumentsAction } from "../../../redux/actions/studentAction";
import { useSelector } from "react-redux";
import { adjustTime } from "../../../utils/commonFunctions";
import Loader1 from "../../../utils/Loader1";

export default function OnlineAdmissionForm({ schoolId, index, setFormState, programId, data }) {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [onlineSchoolGrades, setOnlineSchoolGrades] = useState(null);
    const [instrument, setInstrument] = useState(null);
    const [program, setProg] = useState();
    const [loading, setIsLoading] = useState(false);
    const [onlineAdmissionFields, setOnlineAdmissionFields] = useState({
        student_type: "child",
        first_name: "",
        last_name: "",
        email: "",
        skill_level: {},
        current_school: "",
        grade_id: "",
        instrument_id: "",
        instrument_on_purchase_or_rent: "",
        monthly_installment: "",
        one_time_payment: "",
        day: [],
        time: "",
        alternate_day: "",
        alternate_time: "",
        program_id: index === 0 ? id : program,
    });

    useEffect(() => {
        ViewOnlineSchoolGrades(index === 0 ? id : programId);
        setOnlineAdmissionFields((prev) => ({
            ...prev,
            program_id: index === 0 ? id : programId,
        }));
        setProg(programId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programId]);

    useEffect(() => {
        dispatch(getInstrumentsAction());

        return () => {
            dispatch({
                type: types.GET_ALL_INSTRUMENTS,
                payload: [],
            });
        };
    }, [dispatch]);

    useEffect(() => {
        setFormState(onlineAdmissionFields, index);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onlineAdmissionFields, index]);

    const allInstruments = useSelector((state) => state?.studentInfo?.getAllInstruments);

    const ViewOnlineSchoolGrades = async (id) => {
        setIsLoading(true);
        const res = await api("get", Constants.END_POINT.ONLINE_LESSON_GRADES + id);
        if (res.success) {
            setOnlineSchoolGrades(res.data.grade);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        if (onlineAdmissionFields?.instrument_id) {
            ViewInstrument(onlineAdmissionFields?.instrument_id);
        }
    }, [onlineAdmissionFields?.instrument_id]);
    const ViewInstrument = async (id) => {
        setIsLoading(true);
        const res = await api("get", Constants.END_POINT.VIEW_INSTRUMENT + id);
        if (res.success) {
            setInstrument(res.data);
        }
        setIsLoading(false);
    };

    const handleChange1 = (e) => {
        const { name, id } = e.target;
        if (name === "grade") {
            setOnlineAdmissionFields((prev) => ({
                ...prev,
                grade: e.value,
                instrument: "",
                program_id: index !== 0 ? program : id,
            }));
        }
        if (name === "instrument_id") {
            setOnlineAdmissionFields((prev) => ({
                ...prev,
                instrument_on_purchase_or_rent: "purchase",
            }));
        }

        if (id === "rent") {
            setOnlineAdmissionFields((prev) => ({
                ...prev,
                monthly_installment: instrument?.monthly_installment,
                one_time_payment: "",
            }));
        }
        if (id === "purchase") {
            setOnlineAdmissionFields((prev) => ({
                ...prev,
                one_time_payment: instrument?.purchase_amount,
                monthly_installment: "",
            }));
        }
        const formErrors = allValidations(name, e.value, data);

        setOnlineAdmissionFields((prev) => ({
            ...prev,
            [name]: e.value,
            formErrors,
        }));
    };

    useEffect(() => {
        if (onlineAdmissionFields?.instrument_on_purchase_or_rent) {
            setOnlineAdmissionFields((prev) => ({
                ...prev,
                monthly_installment: onlineAdmissionFields?.instrument_on_purchase_or_rent === "rent" ? instrument?.monthly_installment : "",
                one_time_payment: onlineAdmissionFields?.instrument_on_purchase_or_rent === "purchase" ? instrument?.purchase_amount : "",
            }));
        }
    }, [onlineAdmissionFields?.instrument_id, onlineAdmissionFields?.instrument_on_purchase_or_rent, instrument]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const time = adjustTime(e);
        const formErrors = allValidations(name, value, data);
        if (name === "time") {
            setOnlineAdmissionFields((prev) => ({
                ...prev,
                [name]: time,
                formErrors,
            }));
        } else {
            setOnlineAdmissionFields((prev) => ({
                ...prev,
                [name]: value,
                formErrors,
            }));
        }
    };

    const SkillLevel = [
        { name: "Beginner", value: "Beginner" },
        { name: "Intermediate", value: "Intermediate" },
        { name: "Advanced", value: "Advanced" },
    ];
    const selectedOnlineInstrument =
        onlineSchoolGrades?.length && onlineSchoolGrades?.filter((relatedInstrument) => relatedInstrument.id === onlineAdmissionFields?.grade_id);

    return (
        <>
            <div className="info px-4">
                {loading ? <Loader1 isLoading={loading} /> : null}
                <h5 className="text-500 mb-3 open-sans">Student Type</h5>
                <div className="grid mb-2 ml-1">
                    <RadioButtonComponent
                        extraClassName="flex align-items-center"
                        name="student_type"
                        label="Child"
                        labelClassName="m-0 open-sans"
                        value="child"
                        checked={onlineAdmissionFields?.student_type === "child"}
                        onChange={handleChange1}
                    />
                    <RadioButtonComponent
                        extraClassName="flex align-items-center ml-3"
                        name="student_type"
                        inputId="adult"
                        label="Adult"
                        labelClassName="m-0 open-sans"
                        value="adult"
                        checked={onlineAdmissionFields?.student_type === "adult"}
                        onChange={handleChange1}
                    />
                </div>
                <div className="grid mb-3">
                    <InputComponent
                        extraClassName="col-12 md:col-6"
                        inputClass="w-full border-1 border-round-lg border-700"
                        placeholder="Student First Name*"
                        name="first_name"
                        value={onlineAdmissionFields?.first_name}
                        onChange={handleChange}
                        errorMessage={data?.formErrors?.first_name}
                    />

                    <InputComponent
                        extraClassName="col-12 md:col-6"
                        inputClass="w-full border-1 border-round-lg border-700"
                        placeholder="Student Last Name*"
                        name="last_name"
                        value={onlineAdmissionFields?.last_name}
                        onChange={handleChange}
                        errorMessage={data?.formErrors?.last_name}
                    />
                    <InputComponent
                        extraClassName="col-12 md:col-6"
                        inputClass="w-full border-1 border-round-lg border-700"
                        placeholder="Student Email*"
                        name="email"
                        value={onlineAdmissionFields?.email}
                        onChange={handleChange}
                        errorMessage={data?.formErrors?.email}
                    />
                    <DropDownComponent
                        name="skill_level"
                        extraClassName="col-12 md:col-6"
                        inputClass="w-full border-round-lg border-1 border-700 overflow-hidden"
                        placeholder="Select Skill Level"
                        value={onlineAdmissionFields?.skill_level}
                        options={SkillLevel}
                        onChange={handleChange1}
                        optionLabel="name"
                        errorMessage={data?.formErrors?.skill_level}
                    />
                    {onlineAdmissionFields?.student_type === "child" && (
                        <InputComponent
                            extraClassName="col-12 md:col-6"
                            inputClass="w-full border-1 border-round-lg border-700"
                            placeholder="Current School"
                            name="current_school"
                            onChange={handleChange}
                            value={onlineAdmissionFields?.current_school}
                        />
                    )}

                    {onlineAdmissionFields?.student_type === "adult" && (
                        <NumberComponent
                            extraClassName="col-12 md:col-6"
                            inputClass="w-full border-1 border-round-lg border-700"
                            placeholder="Age"
                            name="age"
                            onValueChange={handleChange1}
                            useGrouping={false}
                            value={onlineAdmissionFields?.age}
                            errorMessage={data?.formErrors?.age}
                        />
                    )}

                    {onlineAdmissionFields?.student_type === "child" && (
                        <DropDownComponent
                            name="grade_id"
                            extraClassName="col-12 md:col-6"
                            inputClass="w-full border-round-lg border-1 border-700 overflow-hidden"
                            placeholder="Select Grade"
                            value={onlineAdmissionFields?.grade_id}
                            options={
                                onlineSchoolGrades?.length &&
                                onlineSchoolGrades?.map((item, index) => {
                                    return { label: item?.grade, value: item?.id };
                                })
                            }
                            onChange={handleChange1}
                            errorMessage={data?.formErrors?.grade_id}
                        />
                    )}
                    <DropDownComponent
                        name="instrument_id"
                        extraClassName="col-12 md:col-6"
                        placeholder="Choose an Instrument"
                        inputClass="w-full border-1 border-round-lg border-700"
                        options={
                            onlineAdmissionFields?.student_type === "child"
                                ? selectedOnlineInstrument?.[0]?.grade_instruments?.map((item, index) => {
                                      return { label: item?.name, value: item?.id };
                                  })
                                : allInstruments?.map((item, index) => {
                                      return { label: item?.name, value: item?.id };
                                  })
                        }
                        value={onlineAdmissionFields?.instrument_id}
                        onChange={handleChange1}
                        optionLabel="label"
                        emptyMessage="Please select grade first"
                        errorMessage={data?.formErrors?.instrument_id}
                    />
                    <div className="col-12 md:col-3">
                        <div className="flex align-items-center overflow-hidden relative calendar-box">
                            <MultiSelectComponent
                                label=""
                                placeholder="Select Preferred Day*"
                                extraClassName="w-100"
                                name="day"
                                options={Days}
                                optionLabel="name"
                                value={onlineAdmissionFields?.day}
                                onChange={handleChange1}
                                errorMessage={data?.formErrors?.day}
                            />
                        </div>
                        <small className="open-sans drk-gray-color">Put Day and Time option here</small>
                    </div>
                    <CustomCalendar
                        size="col-12 md:col-3"
                        name="time"
                        value={onlineAdmissionFields?.time}
                        extraClassName="w-100 border-0"
                        className="calendar-date w-full z-1 cursor-pointer"
                        onChange={handleChange}
                        inputClass="z-2 bg-transparent w-full z-1 border-700 "
                        timeOnly
                        hourFormat="12"
                        placeholder="Select Time"
                        errorMessage={data?.formErrors?.time}
                    />
                    {/* <div className="md:col-3">
            <div className="flex align-items-center border-1 border-700 border-round-lg overflow-hidden relative">
              <Calendar
                name="alternate_day"
                value={onlineAdmissionFields?.alternate_day}
                className="calendar-date w-full z-1 cursor-pointer"
                onChange={handleChange}
                placeholder="Select Day"
                minDate={onlineAdmissionFields?.day || new Date()}
              />
              <img
                src={Calendaricon}
                width="20px"
                alt=""
                className="absolute calender-labeling"
              />
            </div>
            <small className="open-sans drk-gray-color">
              Put Alternate Day and Time option here
            </small>
          </div>
          <div className="md:col-3">
            <div className="flex align-items-center border-1 border-700 border-round-lg overflow-hidden relative">
              <Calendar
                name="alternate_time"
                value={onlineAdmissionFields?.alternate_time}
                className="calendar-date w-full z-1 cursor-pointer"
                onChange={handleChange}
                timeOnly
                placeholder="Select Time"
              />
              <img
                src={clockDark}
                width="20px"
                alt=""
                className="absolute calender-labeling"
              />
            </div>
          </div> */}
                </div>
                <p className="font-bold text-base mb-2">
                    Instrument Purchase Program (Choose Nothing_needed Needed if you are not ordering an instrument with us)
                </p>
                <div className="block md:flex  mb-3">
                    <RadioButtonComponent
                        extraClassName="flex align-items-center mb-2 md:mb-0"
                        label="Rent"
                        labelClassName="m-0"
                        name="instrument_on_purchase_or_rent"
                        id="rent"
                        value="rent"
                        checked={onlineAdmissionFields?.instrument_on_purchase_or_rent === "rent"}
                        onChange={handleChange1}
                    />
                    <RadioButtonComponent
                        extraClassName="flex align-items-center ml-2 mb-2 md:mb-0"
                        name="instrument_on_purchase_or_rent"
                        label="Purchase"
                        labelClassName="m-0"
                        id="purchase"
                        value="purchase"
                        checked={onlineAdmissionFields?.instrument_on_purchase_or_rent === "purchase"}
                        onChange={handleChange1}
                    />
                    <RadioButtonComponent
                        extraClassName="flex align-items-center ml-2 mb-2 md:mb-0"
                        name="instrument_on_purchase_or_rent"
                        label="Nothing Needed - we have an instrument"
                        labelClassName="mb-0 open-sans"
                        value="nothing_needed"
                        checked={onlineAdmissionFields?.instrument_on_purchase_or_rent === "nothing_needed"}
                        onChange={handleChange1}
                    />
                </div>
                {onlineAdmissionFields?.instrument_on_purchase_or_rent !== "nothing_needed" && (
                    <div className="grid align-items-center mb-3">
                        <div className="col-12 md:col-3">
                            <NumberComponent
                                placeholder="$0.00"
                                inputClass="w-full border-round-lg open-sans border-1 border-700 overflow-hidden"
                                value={
                                    onlineAdmissionFields?.instrument_on_purchase_or_rent === "rent"
                                        ? instrument?.monthly_installment
                                        : onlineAdmissionFields?.instrument_on_purchase_or_rent === "purchase"
                                        ? instrument?.purchase_amount
                                        : null
                                }
                                readOnly
                            />
                        </div>
                        <div className="col-12 md:col-3">
                            <p className="font-bold open-sans text-gray-color m-0 mr-2">
                                {onlineAdmissionFields?.instrument_on_purchase_or_rent === "rent"
                                    ? "Monthly Installment"
                                    : onlineAdmissionFields?.instrument_on_purchase_or_rent === "purchase"
                                    ? "One Time Payment"
                                    : null}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
