import React, { useState } from "react";
import Index from "../../layout";
import AddIcon from "../../../../assets/icons/add.png";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ButtonComponent from "../../../../components/Fields/Button/CustomButton";
import { Dialog } from "primereact/dialog";
import InputComponent from "../../../../components/Fields/InputField";
import { Password } from "primereact/password";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getParentStudentsAction,
  setStudentLogin,
} from "../../../../redux/actions/ParentPortal/parentAction";
import EditIcon from "../../../../assets/icons/edit.png";
import ViewIcon from "../../../../assets/icons/view.png";
import { useHistory } from "react-router-dom";
import CardHeader from "../../../../pages/CardHeader";
import StudentIcon from "../../../../assets/icons/student.png";

export default function StudentList() {
  // const { StudentListing } = StudentContent();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(null);
  const history = useHistory();

  useEffect(() => {
    dispatch(getParentStudentsAction());
  }, [dispatch]);

  let { getParentStudents } = useSelector((state) => state.parent);

  const buttonTemplate = (rowData) => {
    return rowData?.user_name ? (
      rowData?.user_name
    ) : (
      <ButtonComponent
        label="Set Login Details"
        className="p-2 px-4"
        onClick={() => setVisible(rowData?.id)}
      />
    );
  };
  const actionTemplate = (col) => {
    return (
      <div className="flex align-items-center">
        <img
          src={EditIcon}
          alt=""
          width="auto"
          height="18.5px"
          className="product-image m-1"
          onClick={() => history.push(`/parent/students/edit/${col?.id}`)}
        />
        <img
          src={ViewIcon}
          alt=""
          width="auto"
          height="16px"
          className="product-image m-1 cusor-pointer"
          onClick={()=>history.push(`/parent/students/view/${col?.id}`)}
        />
      </div>
    );
  };

  const [data, setData] = useState({
    user_name: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleSave = (e) => {
    e.preventDefault();
    dispatch(setStudentLogin(visible, data, setData, setVisible));
  };

  return (
    <div>
      <Index>
        <div className="p-4">
          <CardHeader icon ={StudentIcon} heading ="Student Information"/>
          <div className="shadow-sm border-round-lg overflow-hidden">
            <div className="table-top-header border-round-top-xl bg-main p-3">
              <div className="flex justify-content-between">
                <div className="flex justify-content-between">
                  <div className="">
                    <Link
                      to="/parent/students/add-student"
                      className="flex align-items-center open-sans font-semibold text-xs ml-4 no-underline text-900"
                    >
                      <img
                        src={AddIcon}
                        alt=""
                        className="bg-white p-2 border-round-lg"
                        width={30}
                      />
                      &nbsp; Add Student
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              className="student-info-table"
              value={getParentStudents?.length && getParentStudents}
              stripedRows
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column field="first_name" header="Student Name"></Column>
              <Column field="school.name" header="School"></Column>
              <Column field="phone_number" header="Phone"></Column>
              <Column field="email" header="Email"></Column>
              <Column
                field="loginDetail"
                header="Login Details"
                body={buttonTemplate}
              ></Column>
              <Column
                field="action"
                header="Action"
                body={actionTemplate}
              ></Column>
            </DataTable>
          </div>
        </div>
        <Dialog
          header="Set Login Details"
          visible={visible}
          style={{ width: "30vw" }}
          onHide={()=>setVisible(false)}
        >
          <InputComponent
            extraClassName="relative mb-3"
            label="Username"
            labelClassName="absolute text-xs text-500 open-sans bg-white form-label"
            inputClass="w-full border-1 border-round-lg border-700"
            name="user_name"
            value={data?.user_name}
            onChange={handleChange}
          />
          <div className="relative mb-3">
            <label className="absolute text-xs text-500 open-sans bg-white form-label z-2">
              Password
            </label>
            <Password
              className="w-full border-1 border-round-lg border-700"
              name="password"
              value={data?.password}
              onChange={handleChange}
              feedback={false}
            />
          </div>
          <div className="text-center">
            <ButtonComponent
              label="Save"
              className="p-2 px-3"
              onClick={handleSave}
            />
          </div>
        </Dialog>
      </Index>
    </div>
  );
}
