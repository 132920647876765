import { HashRouter, Switch, Route } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import StudentLogin from "./pages/Login/StudentLogin";
import TeacherLogin from "./pages/Login/TeacherLogin";
import ParentLogin from "./pages/Login/ParentLogin";
import SchoolLessons from "./pages/Enroll/schoollesson/SchoolLesson";
// Css import
// import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "react-form-builder2/dist/app.css";
import "formiojs/dist/formio.full.css";
import SelectSchool from "./pages/Enroll/schoollesson/SelectSchool";
import ParentInformation from "./pages/Enroll/schoollesson/ParentInformation";
import BillingInformation from "./pages/Enroll/schoollesson/BillingInformation";
import PaymentInfo from "./pages/Enroll/schoollesson/PaymentInfo";
import ForgotPassword from "./pages/Forgot/ForgotPassword";
import SendCode from "./pages/Forgot/SendCode";
import CreatePassword from "./pages/Forgot/CreatePassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectOnlineLesson from "./pages/Enroll/onlinelesson/SelectOnlineLesson";
import SchoolOnlineLesson from "./pages/Enroll/onlinelesson/SchoolOnlineLesson";
import Loader from "./utils/Loader";
import ProtectedRoute from "./Routes/ProtectedRoute";
import StudentFormCopyNew from "./pages/Enroll/schoollesson/StudentFormCopyNew";
import TermsAndCondition from "./pages/Enroll/schoollesson/TermsAndCondition";
import { ConfirmDialog } from "primereact/confirmdialog";
import ParentLandingPage from "./portals/parents-dashboard/parent/ParentLandingPage";
import Account from "./portals/parents-dashboard/parent/Account&Invoice/Account";
import SelectNewSchool from "./pages/NewEnrollment/school-lessons/SelectNewSchool";
import NewSchoolLessonForm from "./pages/NewEnrollment/school-lessons/NewSchoolLessonForm";
import NewParentInformation from "./pages/NewEnrollment/school-lessons/NewParentInformation";
import NewBillingInfo from "./pages/NewEnrollment/school-lessons/NewBillingInfo";
// import Enroll from "./pages/Enroll";

// import Enroll from "./Enrollment/Enroll";
import Enroll from "./Enrollment1/Enroll1.jsx";
// import StudentEnrollmentForm from "./Enrollment/StudentEnrollmentForm";
import NewOnlineLessonForm from "./pages/NewEnrollment/online-lessson/NewOnlineLessonForm";
import StudentEnrollmentForm1 from "./Enrollment1/StudentEnrollmentForm.jsx";
import EnrollmentForm from "./Enrollment1/EnrollmentForm.jsx";
import New from "./Enrollment1/New.jsx";
import NewParentInfo from "./Enrollment1/NewParentInfo.js";

// Old Route for school

// import Enroll from "./pages/Enroll";
// import SelectSchool from "./pages/Enroll/schoollesson/SelectSchool";

// import TermsAndCondition from "./pages/Enroll/schoollesson/TermsAndCondition";
// import SelectOnlineLesson from "./pages/Enroll/onlinelesson/SelectOnlineLesson";

// import SchoolLessons from "./pages/Enroll/schoollesson/SchoolLesson";

// import SchoolOnlineLesson from "./pages/Enroll/onlinelesson/SchoolOnlineLesson";
// import StudentFormCopyNew from "./pages/Enroll/schoollesson/StudentFormCopyNew";
// import ParentInformation from "./pages/Enroll/schoollesson/ParentInformation";
// import BillingInformation from "./pages/Enroll/schoollesson/BillingInformation";
// import PaymentInfo from "./pages/Enroll/schoollesson/PaymentInfo";
// import ParentLandingPage from "./portals/parents-dashboard/parent/ParentLandingPage";

function App() {
    return (
        <>
            <HashRouter>
                <Loader />
                <ConfirmDialog />
                <Switch>
                    <Route path="/" exact={true} component={Home} />
                    <Route path="/parent/account-invoice" component={Account} />
                    <Route path="/student-login" exact={true} component={StudentLogin} />
                    <Route path="/parent-login" exact={true} component={ParentLogin} />
                    <Route path="/teacher-login" exact={true} component={TeacherLogin} />
                    <Route path="/enroll" exact={true} component={Enroll} />
                    <Route path="/student-forgot-password" exact={true} component={ForgotPassword} />
                    <Route path="/parent-forgot-password" exact={true} component={ForgotPassword} />
                    <Route path="/teacher-forgot-password" exact={true} component={ForgotPassword} />
                    <Route path="/student-verify-otp/:encodedEmail" exact={true} component={SendCode} />
                    <Route path="/parent-verify-otp/:encodedEmail" exact={true} component={SendCode} />
                    <Route path="/teacher-verify-otp/:encodedEmail" exact={true} component={SendCode} />
                    <Route path="/student-reset-password/:encodedEmail" exact={true} component={CreatePassword} />
                    <Route path="/parent-reset-password/:encodedEmail" exact={true} component={CreatePassword} />
                    <Route path="/teacher-reset-password/:encodedEmail" exact={true} component={CreatePassword} />
                    <Route path="/select-school/:type" exact={true} component={SelectSchool} />
                    <Route path="/terms-and-condition" exact={true} component={TermsAndCondition} />
                    <Route path="/select-online-lesson/:type" exact={true} component={SelectOnlineLesson} />
                    <Route path="/school-lesson/:type/:id" exact={true} component={SchoolLessons} />
                    <Route path="/school-online-lesson/:type/:id" exact={true} component={SchoolOnlineLesson} />
                    <Route path="/student-form2/:type/:id" exact={true} component={StudentFormCopyNew} />
                    {/* <Route path="/parent-information" exact={true} component={ParentInformation} /> */}
                    <Route path="/parent-information" exact={true} component={NewParentInfo} />

                    <Route path="/billing-information" exact={true} component={BillingInformation} />
                    <Route path="/payment-information" exact={true} component={PaymentInfo} />
                    {/* <Route path="/online-music-classes/:type/:id" exact={true} component={StudentFormCopyNew} /> */}
                    <Route path="/landing/" component={ParentLandingPage} />
                    <Route path="/select/:type" component={EnrollmentForm} />
                    {/* <Route path="/student-form/:type/:id/" exact={true} component={StudentEnrollmentForm1} /> */}
                    <Route path="/student-form/:type/:id/" exact={true} component={New} />

                    {/* new enrollment form */}
                    {/* <Route path="/select-new-school" exact={true} component={SelectNewSchool} />
                    <Route path="/new-school-lesson-form" exact={true} component={NewSchoolLessonForm} />
                    <Route path="/new-parent-information" exact={true} component={NewParentInformation} />
                    <Route path="/new-billing-information" exact={true} component={NewBillingInfo} />
                    <Route path="/new-online-lesson-form" exact={true} component={NewOnlineLessonForm} /> */}
                    <ProtectedRoute />

                    {/*  old school enroll routing  
                    <Route path="/enroll" exact={true} component={Enroll} />
                        <Route path="/select-school/:type" exact={true} component={SelectSchool} />
                        <Route path="/terms-and-condition" exact={true} component={TermsAndCondition} />
                        <Route path="/select-online-lesson/:type" exact={true} component={SelectOnlineLesson} />
                        <Route path="/school-lesson/:type/:id" exact={true} component={SchoolLessons} />
                        <Route path="/school-online-lesson/:type/:id" exact={true} component={SchoolOnlineLesson} />
                        <Route path="/student-form2/:type/:id" exact={true} component={StudentFormCopyNew} />
                        <Route path="/parent-information" exact={true} component={ParentInformation} />
                        <Route path="/billing-information" exact={true} component={BillingInformation} />
                        <Route path="/payment-information" exact={true} component={PaymentInfo} />
                        <Route path="/online-music-classes/:type/:id" exact={true} component={StudentFormCopyNew} />
                        <Route path="/landing/" component={ParentLandingPage} />
                    */}
                </Switch>
            </HashRouter>
            <ToastContainer
                // position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}

export default App;
