import types from "../../types";
import Constants from "../../../services/constant";
import { showLoaderAction, hideLoaderAction } from "../loadAction";
import { api } from "../../../services/api";
import { getInvoices } from "./parentAction";
import { showToast } from "../../../utils/showToast";
import moment from "moment";

export const getParentAccountsAction = (date) => async (dispatch) => {
    dispatch(showLoaderAction());
    const params = {
        date: moment(date).format("DD-MM-YYYY")
    }
    const res = await api("get", Constants.END_POINT.GET_ACCOUNTS, {}, {}, params);

    if (res.success) {
        dispatch({
            type: types.GET_ACCOUNTS,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const makePaymentAction = (makePayment, cardId, setMakePayment) => async (dispatch) => {
    dispatch(showLoaderAction());

    const payload = {
        invoice_id: makePayment,
        card_id: cardId
    };
    const res = await api("post", Constants.END_POINT.MAKE_PAYMENT, payload);
    if (res.success) {
        showToast([res.message], "success");
        setMakePayment(null);
        dispatch(getInvoices())
    } else {
        showToast([res.message], "error");
    }

    dispatch(hideLoaderAction());
}