import { OverlayPanel } from "primereact/overlaypanel";
import React, { useEffect, useState } from "react";
import AttandanceIcon from "../../../../../assets/icons/attandanceicon.png";
import EmailIcon from "../../../../../assets/icons/emailicon.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ViewEventAction } from "../../../../../redux/actions/TeacherPortal/attendanceAction";
import absent from "../../../../../assets/icons/absent.png";
import present from "../../../../../assets/icons/present.png";
import EditIcon from "../../../../../assets/icons/editicon.png";

const CalendarModalPopup = ({ overlayRef, id, isMenuOpen }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (id) {
            dispatch(ViewEventAction(id));
        }
    }, [id, dispatch]);

    const events = useSelector((state) => state?.events?.ViewEvent);

    const type = "single";
    return (
        <div>
            <OverlayPanel className="p-0 eventshow-panel" ref={overlayRef} dismissable={true} style={{ width: "20rem" }}>
                <div className="p-3 overflow-scroll scrollbar-show" style={{ height: "20rem" }}>
                    <div className="flex realtive ">
                        <p className="font-semibold text-sm mb-2">
                            {`${events?.attendees_list ?? ""} (${events?.category_name} ${events?.school_id ? "(DS)" : ""} with ${events?.teacher_name}) ${
                                events?.public_description ?? ""
                            }`}
                        </p>
                        <div className="text-2xl px-2 cursor-pointer absolute top-0 right-0" onClick={() => overlayRef?.current.toggle(null)}>
                            &times;
                        </div>
                    </div>
                    <div className="flex mb-1">
                        <p className="text-sm font-semibold">Time:</p>&nbsp;
                        <span className="text-sm">
                            {events?.school_id && events?.class_type == "during_school" ? events?.start_time_format : events?.start_time_format}
                            {/* {events.school_id && events?.class_type == "day_time_program"
                            ? "Rotating Schedule"
                            : events.start_time_format + " - " + events.end_time_format} */}
                        </span>
                    </div>
                    <div className="flex mb-1">
                        <p className="text-sm font-semibold">Teacher:</p>&nbsp;
                        <span className="text-sm">{events?.teacher_name}</span>
                    </div>
                    <div className="flex mb-1">
                        <p className="text-sm font-semibold">Location:</p>&nbsp;
                        <span className="text-sm text-main">{events?.school_id ? events?.school_name : "Online Lesson"}</span>
                    </div>
                    <hr />
                    <p className="text-sm font-semibold">Attendees:</p>
                    <ul className="ml-4">
                        {events?.attendees?.map((attendee) => (
                            <li key={attendee.id}>
                                <span>
                                    {attendee?.status === "absent" ? (
                                        <img src={absent} alt="" width="16" />
                                    ) : attendee?.status === "present" ? (
                                        <img src={present} width="16" />
                                    ) : (
                                        ""
                                    )}
                                </span>
                                <span className="text-sm"> {attendee?.name}</span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="footer-modal border-top  flex ml-2 m-0 p-2 bg-white w-full">
                    {events?.is_edit ? (
                        <Link to={`/teacher/calendar/edit-calendar-event/${id}`} className="no-underline text-dark">
                            <div className="text-center text-dark">
                                <img src={EditIcon} alt="" width={20} className="mb-1" />
                                <p className="text-sm">Edit</p>
                            </div>
                        </Link>
                    ) : null}
                    {events?.attendees?.length === 1 ? (
                        <Link
                            style={{ pointerEvents: events?.attendees?.length ? "" : "none" }}
                            to={`/teacher-dashboard/single-attandance/${id}/${events?.attendees?.[0]?.id}`}
                            className="no-underline text-dark"
                        >
                            <div className="text-center text-dark cursor-pointer ml-4">
                                <img src={AttandanceIcon} width={20} alt="" className="mb-1" />
                                <p className="text-sm">Attendance</p>
                            </div>
                        </Link>
                    ) : (
                        <Link
                            style={{ pointerEvents: events?.attendees?.length ? "" : "none" }}
                            to={`/teacher-dashboard/teacher-attendence/${id}`}
                            className="no-underline text-dark"
                        >
                            <div className="text-center text-dark cursor-pointer ml-4">
                                <img src={AttandanceIcon} width={20} alt="" className="mb-1" />
                                <p className="text-sm">Attendance</p>
                            </div>
                        </Link>
                    )}
                    <Link to={`/teacher/calendar/new-email/${id}`} className="no-underline text-dark">
                        <div className="text-center text-dark cursor-pointer ml-4">
                            <img src={EmailIcon} width={20} alt="" className="mb-1" />
                            <p className="text-sm">Email</p>
                        </div>
                    </Link>
                </div>
            </OverlayPanel>
        </div>
    );
};

export default CalendarModalPopup;
