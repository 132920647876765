import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addNewStudentAction, addStudentAction } from "../redux/actions/studentAction";
import states1 from "states-us";
import useUnload from "../components/useUnload";
import { confirmNext, showFormErrors } from "../utils/commonFunctions";
import { CheckboxComponent, DropDownComponent, InputComponent1, PhoneNumberComponent } from "./AllInputs";
import ButtonComponent from "../components/Fields/Button/CustomButton";
import ParentBgImg from "../assets/images/parentbg.png";
import { allValidations } from "../utils/formValidations";
import { updateParentForm } from "../redux/actions/newEnrollmentAction";
import { api } from "../services/api";
import Constants from "../services/constant";

export default function NewParentInfo() {
    const history = useHistory();

    const schoolForms = useSelector((state) => state?.enroll?.students);

    console.log("schoolForms==>", schoolForms);
    // useEffect(() => {
    //     const disableBackButton = () => {
    //         window.history.pushState(null, document.title, window.location.href);
    //     };

    //     disableBackButton();

    //     window.addEventListener("popstate", disableBackButton);

    //     return () => {
    //         window.removeEventListener("popstate", disableBackButton);
    //     };
    // }, []);

    const [extraFields, setExtraFields] = useState([]);

    useEffect(() => {
        if (schoolForms?.[0].school_id) {
            ViewSchoolAdmissionForm(schoolForms?.[0].school_id);
        }
    }, [schoolForms?.[0]?.school_id]);

    const ViewSchoolAdmissionForm = async (id) => {
        const res = await api("get", Constants.END_POINT.VIEW_SCHOOL_ADMISSION_FORM + id);
        if (res.success) {
            let data = res.data?.admission_form?.parent_extra_fields;
            setExtraFields(data);
        }
    };

    useEffect(() => {
        if (!schoolForms?.[0].enroll_type) {
            history.push("/enroll");
        }
    }, [schoolForms?.[0]?.enroll_type]);
    useUnload((e) => {
        e.preventDefault();
        e.returnValue = "";
    });
    useEffect(() => {
        getUpdatedStates();
    }, []);
    const getUpdatedStates = () => {
        let res = states1.filter((x) => x.contiguous);
        setStates(res);
    };
    const parentInfo = useSelector((state) => state?.enroll?.parent);

    const [data, setData] = useState({});
    useEffect(() => {
        setData(parentInfo);
    }, [parentInfo]);

    const [policies, setPolicies] = useState(false);
    const [states, setStates] = useState([]);

    const handleExtraFields = (e, item) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            parent_extra_fields: { ...prev?.parent_extra_fields, [name]: { value, ...item } },
        }));
    };

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };
    const dispatch = useDispatch();
    const handleSave = (e) => {
        e.preventDefault();
        dispatch(updateParentForm(data));
        if (showFormErrors(data, setData)) {
            confirmNext(
                () => {
                    dispatch(addNewStudentAction(data, policies, history));
                },
                "Would you like to proceed? Please review your information carefully, as once you advance, there's no turning back!",
                "center"
            );
        }
    };

    // const parentExtra = useSelector((state) => state?.studentInfo?.addFormInfo);

    return (
        <div>
            <div
                className="h-20rem md:h-26rem flex justify-content-center align-items-center background-overlay relative"
                style={{ backgroundImage: `url('${ParentBgImg}')`, backgroundSize: "cover" }}
            >
                <h1 className="text-4xl md:text-6xl text-center relative text-white">Parent Information</h1>
            </div>
            <div className=" p-4">
                <div className="p-4">
                    <div className="bg-main p-3 border-round-lg mt-3 md:my-4">
                        <p className="text-xl font-semibold text-black-alpha-90	">Parent Information</p>
                    </div>
                    <form action="" className="mb-3">
                        <div className="grid flex-wrap mb-2">
                            <InputComponent1
                                extraClassName="col-12 md:col-6"
                                label="First Name"
                                name="first_name"
                                labelClassName="relative left-0 text-dark text-base"
                                required
                                value={data?.first_name}
                                onChange={handleChange}
                                errorMessage={data?.formErrors?.first_name}
                            />
                            <InputComponent1
                                extraClassName="col-12 md:col-6"
                                label="Last Name"
                                name="last_name"
                                labelClassName="relative left-0 text-dark text-base"
                                required
                                value={data?.last_name}
                                onChange={handleChange}
                                errorMessage={data?.formErrors?.last_name}
                            />
                            <InputComponent1
                                label="Email"
                                extraClassName="col-12 md:col-6"
                                name="email"
                                labelClassName="relative left-0 text-dark text-base"
                                required
                                value={data?.email}
                                onChange={handleChange}
                                errorMessage={data?.formErrors?.email}
                            />
                            <PhoneNumberComponent
                                label="Mobile number"
                                size="col-12 md:col-6"
                                labelClassName="relative text-dark text-base"
                                placeholder="Enter phone number"
                                inputClass="py-0 h-full phone-input-number"
                                name="phone_number"
                                country="us"
                                onChange={handleChange}
                                value={data?.phone_number}
                                // inputClassName="h-full"
                                required
                                errorMessage={data?.formErrors?.phone_number}
                            />

                            <InputComponent1
                                extraClassName="col-12 md:col-6"
                                label="Billing Address Street"
                                labelClassName="relative left-0 text-dark text-base"
                                required
                                name="address"
                                onChange={handleChange}
                                value={data?.address}
                                errorMessage={data?.formErrors?.address}
                            />
                            <InputComponent1
                                extraClassName="col-12 md:col-6"
                                label="City"
                                labelClassName="relative left-0 text-dark text-base"
                                required
                                name="city"
                                onChange={handleChange}
                                value={data?.city}
                                errorMessage={data?.formErrors?.city}
                            />
                            <DropDownComponent
                                name="state"
                                extraClassName="col-12 md:col-6"
                                labelClassName="relative left-0 text-dark text-base"
                                label="State"
                                value={data?.state}
                                options={states}
                                optionLabel="name"
                                required
                                onChange={handleChange}
                                errorMessage={data?.formErrors?.state}
                            />
                            <InputComponent1
                                label="Zipcode"
                                extraClassName="col-12 md:col-6"
                                labelClassName="relative left-0 text-dark text-base"
                                name="zip_code"
                                value={data?.zip_code}
                                useGrouping={false}
                                onChange={handleChange}
                                errorMessage={data?.formErrors?.zip_code}
                                keyfilter="int"
                                required
                            />
                            {/* {parentExtra?.[0]?.student_detail?.parent_extra?.extraFields?.map((item) => (
                                <InputComponent1
                                    label={item?.label}
                                    id={item.label.toLowerCase()}
                                    name={item.label.toLowerCase()}
                                    extraClassName="col-12 md:col-6"
                                    placeholder={item?.placeholder}
                                    value={data?.parent_extra_fields?.label?.toLowerCase()}
                                    onChange={(e) => handleExtraFields(e, item)}
                                />
                            ))} */}

                            {extraFields.map((item) => (
                                <InputComponent1
                                    label={item?.label}
                                    id={item?.label?.toLowerCase()}
                                    name={item?.label?.toLowerCase()}
                                    extraClassName="col-12 md:col-6"
                                    placeholder={item?.placeholder}
                                    value={data?.parent_extra_fields?.label?.toLowerCase()}
                                    onChange={(e) => handleExtraFields(e, item)}
                                />
                            ))}
                        </div>
                        <h1 className="text-xl text-900 mb-3 font-bold">Policies</h1>
                        <div className="flex align-items-center mb-3">
                            <CheckboxComponent
                                label="I have read and agree to these policies"
                                labelClassName="text-lg"
                                onChange={(e) => setPolicies(e.checked)}
                                checked={policies}
                            />
                            <Link to="/terms-and-condition" target="_blank">
                                <div className="text-main underline text-lg ml-2 open-sans font-bold cursor-pointer">Click to View</div>
                            </Link>
                        </div>
                        <div className="flex justify-content-center">
                            <ButtonComponent
                                label="Next >>"
                                className="btn no-underline surface-800 text-white p-2 open-sans ml-2 px-5 border-none border-round-lg"
                                disabled={policies ? false : true}
                                onClick={handleSave}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
