import React from "react";
import { Password } from "primereact/password";

export default function CustomPassword({
  label,
  name,
  errorMessage,
  extraClassName,
  required,
  inputClass,
  labelClassName,

  ...props
}) {
  return (
    <div className={`${extraClassName}`}>
      {label ? (
        <label htmlFor={name} className={labelClassName}>
          {label} {required && <span className="p-error">*</span>}
        </label>
      ) : null}
      <Password
        id={name}
        name={name}
        className={`w-full ${inputClass} ${errorMessage ? "p-invalid" : null}`}
        {...props}
      />
      {errorMessage ? (
        <small className="p-error open-sans text-sm">{errorMessage}</small>
      ) : null}
    </div>
  );
}
