import React, { useEffect, useState } from "react";
import ChevronLeft from "../../../../assets/icons/chevronleft.png";
import EmailIcon from "../../../../assets/icons/newemail.png";
import { useHistory, Link } from "react-router-dom/cjs/react-router-dom.min";
import Index from "../../layout";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { getMesageHistoryAction } from "../../../../redux/actions/TeacherPortal/studentAction";
import moment from "moment";
import groupmessage from "../../../../assets/icons/groupmessage.png";
import viewIcon from "../../../../assets/icons/view.png";

import send from "../../../../assets/icons/send.png";
import InputComponent from "../../../../components/Fields/InputField";
import { getSearchedData } from "../../../../utils/commonFunctions";
import { Inplace, InplaceDisplay, InplaceContent } from "primereact/inplace";
import ButtonComponent from "../../../../components/Fields/Button/CustomButton";
import { getTeacherEmailViewAction, getTeacherEmailsAction } from "../../../../redux/actions/TeacherPortal/emailAction";
import { Dialog } from "primereact/dialog";
import { ViewTemplateAction } from "../../../../redux/actions/TeacherPortal/emailTempalteAction";

const AllMessageHistory = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [keyword, setKeyword] = useState("");
    const [view, setView] = useState("SENT");
    const [openPreview, setOpenPreview] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        dispatch(getMesageHistoryAction());
    }, []);

    const messageData = useSelector((state) => state?.teacherStudent?.getEmailHistory);

    const toTemplate = (rowData) => {
        return (
            <>
                {rowData?.receiver_emails?.[0]?.name}
                {`<${rowData?.receiver_emails?.length ? rowData?.receiver_emails?.[0]?.email : "------------"}>`}
                {rowData?.receiver_emails?.length > 1 ? (
                    <Inplace>
                        <InplaceDisplay>View more</InplaceDisplay>
                        <InplaceContent>
                            {rowData?.receiver_emails?.slice(1).map((emailData, index) => (
                                <p className="m-0" key={index}>
                                    {`<${emailData.email}>`}
                                </p>
                            ))}
                        </InplaceContent>
                    </Inplace>
                ) : null}
            </>
        );
    };
    const fromTemplate = (rowData) => {
        return (
            <>
                {rowData?.admin_name}
                {`<${rowData?.from}>`}
            </>
        );
    };
    const TypeTemplate = (rowData) => {
        return (
            <>
                <img src={groupmessage} width="19"></img>
            </>
        );
    };
    const StatusTemplate = (rowData) => {
        return (
            <>
                <img src={send} width="19"></img>
            </>
        );
    };

    const dateTemplate = (rowData) => {
        return (
            <>
                {rowData?.scheduled_date
                    ? moment(rowData?.scheduled_date).format("MM/DD/YYYY") + " " + rowData?.send_time_formatted
                    : moment(rowData?.created_at).format("MM/DD/YYYY") + "  " + rowData?.send_time_formatted}
            </>
        );
    };
    const keys = ["from", "scheduled_date", "receiver_emails", "subject", "scheduled_date", "created_at"];

    useEffect(() => {
        dispatch(getTeacherEmailsAction());
    }, []);

    const SentmessageData = useSelector((state) => state?.teacherEmail?.getteacherEmails);

    const SenttoTemplate = (rowData) => {
        return (
            <>
                {rowData?.receiver_emails?.[0]?.name}
                {`<${rowData?.receiver_emails?.length ? rowData?.receiver_emails?.[0]?.email : "------------"}>`}
                {rowData?.receiver_emails?.length > 1 ? (
                    <Inplace>
                        <InplaceDisplay>View more</InplaceDisplay>
                        <InplaceContent>
                            {rowData?.receiver_emails?.slice(1).map((emailData, index) => (
                                <p className="" key={index}>
                                    {`<${emailData.email}>`}
                                </p>
                            ))}
                        </InplaceContent>
                    </Inplace>
                ) : null}
            </>
        );
    };
    const SentfromTemplate = (rowData) => {
        return (
            <>
                {rowData?.admin_name}
                {`<${rowData?.from}>`}
            </>
        );
    };
    const SentTypeTemplate = (rowData) => {
        return (
            <>
                <img src={groupmessage} width="19"></img>
            </>
        );
    };
    const SentStatusTemplate = (rowData) => {
        return (
            <>
                <img src={send} width="19"></img>
            </>
        );
    };

    const SentdateTemplate = (rowData) => {
        return (
            <>
                {rowData?.scheduled_date
                    ? moment(rowData?.scheduled_date).format("MM/DD/YYYY") + " " + rowData?.send_time_formatted
                    : moment(rowData?.created_at).format("MM/DD/YYYY") + "  " + rowData?.send_time_formatted}
            </>
        );
    };
    const Sentkeys = ["from", "scheduled_date", "receiver_emails", "subject", "scheduled_date", "created_at"];

    const ResendTemplate = (r) => {
        return (
            <>
                <img src={viewIcon} className="ml-5 cursor-pointer" width="25px" alt="" onClick={() => history.push(`/teacher/send-emails/resend/${r.id}`)} />
            </>
        );
    };

    const actionTemplate = (r) => {
        return (
            <>
                <img
                    src={viewIcon}
                    className="ml-5 cursor-pointer"
                    width="25px"
                    alt=""
                    onClick={() => {
                        setOpenPreview(true);
                        dispatch(getTeacherEmailViewAction(r.id));
                    }}
                />
            </>
        );
    };
    const emailView = useSelector((state) => state?.teacherEmail?.emailView);

    return (
        <Index>
            <div className="p-5">
                <h2 className="flex align-items-center mb-3">
                    <img src={ChevronLeft} className="cursor-pointer" alt="" width={10} onClick={() => history.goBack()} />
                    <img src={EmailIcon} alt="" width={30} height={30} className="ml-2" />
                    &nbsp; &nbsp;
                    <span className=" flex text-main text-2xl open-sans font-semibold">Message History</span>
                </h2>
                <div className="flex my-2 relative">
                    <ButtonComponent
                        icon="pi pi-envelope"
                        iconPos="left"
                        label="Sent Emails"
                        onClick={() => setView("SENT")}
                        className={`mr-2 border-none text-dark ${view === "SENT" ? "" : "bg-whitesmoke"}`}
                    />
                    <ButtonComponent
                        icon="pi pi-envelope"
                        iconPos="left"
                        label="Received Emails"
                        onClick={() => setView("RECEIVED")}
                        className={`mr-2 border-none  text-dark ${view === "RECEIVED" ? "" : "bg-whitesmoke"}`}
                    />
                </div>
                <div className=" mt-3 mb-3">
                    <div className="table-top-header border-round-top-xl  bg-gray-light p-2 flex justify-content-between">
                        <div className="flex">
                            <Link
                                to={{
                                    pathname: "/teacher/message",
                                }}
                                className="text-decoration"
                            >
                                <div className="flex align-items-center text-center p-1 h-100">
                                    <img src={EmailIcon} alt="" width={35} />
                                    <p className="text-sm ml-2 "> New Email</p>
                                </div>
                            </Link>
                        </div>
                        {setKeyword ? (
                            <div className="p-input-icon-left mx-2">
                                <i className="pi pi-search z-3" />
                                <InputComponent value={keyword} inputClass="pl-5" onChange={(e) => setKeyword(e.target.value)} placeholder="Search" />
                            </div>
                        ) : null}
                    </div>
                    {view === "RECEIVED" ? (
                        <DataTable
                            showGridlines
                            className="text-dark online-lesson"
                            tableStyle={{ minWidth: "100%" }}
                            paginator
                            stripedRows
                            responsiveLayout="scroll"
                            paginatorTemplate=" FirstPageLink  PageLinks  LastPageLink RowsPerPageDropdown "
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            style={{ borderRadius: "10px" }}
                            emptyMessage="No Student found."
                            filterDisplay="menu"
                            value={getSearchedData(messageData, keyword, keys)}
                        >
                            <Column field="type" body={TypeTemplate} header="Type"></Column>
                            <Column field="code" body={StatusTemplate} header="Status"></Column>
                            <Column field="code" body={toTemplate} header="To"></Column>
                            <Column field="scheduled_date" body={fromTemplate} header="From"></Column>
                            <Column field="subject" header="Subject"></Column>
                            <Column field={dateTemplate} header="Date"></Column>
                            <Column field={actionTemplate} header="Date"></Column>
                        </DataTable>
                    ) : (
                        <DataTable
                            showGridlines
                            className="text-dark online-lesson"
                            tableStyle={{ minWidth: "100%" }}
                            paginator
                            stripedRows
                            responsiveLayout="scroll"
                            paginatorTemplate=" FirstPageLink  PageLinks  LastPageLink RowsPerPageDropdown "
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            style={{ borderRadius: "10px" }}
                            emptyMessage="No Email found."
                            filterDisplay="menu"
                            value={getSearchedData(SentmessageData, keyword, Sentkeys)}
                            stateStorage="local"
                            stateKey="emailDetail"
                        >
                            <Column field="type" body={SentTypeTemplate} header="Type"></Column>
                            <Column field="code" body={SentStatusTemplate} header="Status"></Column>
                            <Column field="code" body={SenttoTemplate} header="To"></Column>
                            {/* <Column field="scheduled_date" body={fromTemplate} header="From"></Column> */}
                            <Column field="subject" header="Subject"></Column>
                            <Column field={SentdateTemplate} header="Date"></Column>
                            <Column body={ResendTemplate} header="Action"></Column>
                        </DataTable>
                    )}
                </div>
                <Dialog
                    header={"Email Preview"}
                    className="assign-student border-round-lg overflow-hidden "
                    visible={openPreview}
                    onHide={() => {
                        setOpenPreview(false);
                    }}
                    style={{ width: "48vw" }}
                    draggable={false}
                    resizable={false}
                >
                    <form className="p-3">
                        <div className="grid mb-3 ">
                            <p className="font-bold  lg:col-2">Subject</p>
                            <div className="lg:col-9">
                                <p>{emailView?.subject}</p>
                            </div>
                        </div>
                        <div className="grid">
                            <p className="font-bold lg:col-2">Message</p>

                            <div className="lg:col-9">
                                <div
                                    className="border-1 border-400 p-3 "
                                    dangerouslySetInnerHTML={{ __html: emailView?.message }}
                                    style={{ minHeight: "150px" }}
                                ></div>
                            </div>
                        </div>
                        <div className="flex justify-content-end mt-2">
                            <ButtonComponent
                                label="Close"
                                className="bg-gray-light ml-2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpenPreview(false);
                                }}
                            ></ButtonComponent>
                        </div>
                    </form>
                </Dialog>
            </div>
        </Index>
    );
};

export default AllMessageHistory;
