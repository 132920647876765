import React from "react";
import { Editor } from "primereact/editor";
import "./style.scss";

export default function EditorComponent({
  label,
  name,
  errorMessage,
  extraClassName,
  required,
  inputClass,
  labelClassName,

  ...props
}) {
  return (
    <div className={`${extraClassName}`}>
      {label ? (
        <label htmlFor={name} className={labelClassName}>
          {label} {required && <span className="p-error">*</span>}
        </label>
      ) : null}
      <Editor
        id={name}
        name={name}
        className={`w-full ${inputClass} ${errorMessage ? "p-invalid" : null}`}
        {...props}
      />
      {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
    </div>
  );
}
