import React, { useEffect } from "react";
import Index from "../../layout";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import NewsIcon from "../../../../assets/icons/newspaper.png";
import { getStudentNewsAction } from "../../../../redux/actions/studentAction";
import CardHeader from "../../../../pages/CardHeader";

const StudentNews = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getStudentNewsAction());
    }, [dispatch]);

    const newsInfo = useSelector((state) => state?.studentInfo?.studentNews);

    const DateTemplate = (rowData) => {
        return <>{moment(rowData.created_at).format("MM_DD-YYYY")}</>;
    };
    const DescriptionTemplate = (rowData) => {
        return <div dangerouslySetInnerHTML={{ __html: rowData?.description }} />;
    };
    return (
        <>
            <Index>
                <div className="p-4">
                    <CardHeader icon={NewsIcon} heading="News" />
                    <div className="shadow-sm border-round-lg overflow-hidden">
                        <DataTable
                            className="student-info-table"
                            value={newsInfo?.length && newsInfo}
                            stripedRows
                            tableStyle={{ minWidth: "50rem" }}
                        >
                            <Column field="created_at" body={DateTemplate} header="Date"></Column>
                            <Column field="description" body={DescriptionTemplate} header="Description"></Column>
                        </DataTable>
                    </div>
                </div>
            </Index>
        </>
    );
};
export default StudentNews;
