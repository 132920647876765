import types from "../../types";
import Constants from "../../../services/constant";
import { showLoaderAction, hideLoaderAction } from "../loadAction";
import { api } from "../../../services/api";
import moment from "moment";

export const getParentNotesAction = (date) => async (dispatch) => {
    dispatch(showLoaderAction());
    const params = {
        date: moment(date).format("DD-MM-YYYY"),
    };

    const res = await api("get", Constants.END_POINT.GET_NOTES, {}, {}, params);

    if (res.success) {
        dispatch({
            type: types.GET_NOTES,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
