import React, { useEffect, useState } from "react";
import { ViewOnlineSchoolDetail, ViewOnlineSchoolGrades, getOnlineLessonProgramAction } from "../redux/actions/schoolAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { CustomDropdownComponent, InputComponent1 } from "./AllInputs";
import TopBar from "../shared/Navbar/TopBar";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ButtonComponent from "../components/Fields/Button/CustomButton";
import MoreOnlineInfo from "./MoreOnlineInfo";
import { updateStudentForm } from "../redux/actions/newEnrollmentAction";
import { getInstrumentsAction } from "../redux/actions/studentAction";
import { getImageURL } from "../utils/javascript";

const EnrollmentOnlineLessons = ({ initialState, index, error }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getOnlineLessonProgramAction());
        dispatch(getInstrumentsAction());
    }, [dispatch]);
    const [data, setData] = useState({});

    useEffect(() => {
        setData(initialState);
    }, [initialState]);
    const { getOnlineLessons, getOnlineGrades, viewOnlineLesson } = useSelector((state) => state?.school);
    const [gradeInstruments, setGradeInstruments] = useState([]);

    let { getAllInstruments } = useSelector((state) => state?.studentInfo);

    getAllInstruments = getAllInstruments?.map((item) => ({ name: item.name, value: item.id, icon: item.icon }));

    let gradeIntruments = getOnlineGrades;

    useEffect(() => {
        let newGradeInstruments = gradeIntruments
            ?.filter((item) => item.grade === data.grade_id)
            ?.flatMap((item) => item.grade_instruments)
            ?.map((item) => ({ name: item.name, value: item.id, icon: item.icon }));

        setGradeInstruments(newGradeInstruments);
    }, [data.grade_id]);

    const handleChange = ({ name, value }) => {
        setData((prev) => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        if (data?.program_id) {
            dispatch(ViewOnlineSchoolGrades(data?.program_id));
            dispatch(ViewOnlineSchoolDetail(data?.program_id));
        }
    }, [data?.program_id]);

    const handleInstrumentClick = (value) => {
        setData((prev) => ({ ...prev, instrument: value }));
    };
    const [visible, setVisible] = useState("");

    const handleEnroll = () => {
        if (index === 0) {
            if (data?.enroll_type === "online_lessons") {
                dispatch(updateStudentForm(index, data));
                history.push(`/student-form/online_lessons/${data.program_id}`);
            }
        } else {
            setVisible(false);
            // dispatch(updateStudentForm(index, { ...data, ...emptyState, enrollNow: true }));
        }
    };

    const ActionTemplate = (r) => {
        return (
            <div className="flex gap-3">
                <ButtonComponent label="More Info" className="bg-dark border-none primary-btn" onClick={() => setVisible(data?.program_id)} />
                <ButtonComponent
                    label={index === 0 ? "Enroll Now" : "Continue Enrollment"}
                    className="bg-dark border-none primary-btn"
                    onClick={handleEnroll}
                />
            </div>
        );
    };

    return (
        <>
            {index === 0 && <TopBar />}
            {index === 0 ? (
                <div className="p-2 md:p-7 enroll-main-div">
                    <Card
                        className="border-1 shadow-1 border-300 p-2 md:p-7 md:w-10 m-auto"
                        title={
                            <h1 className="text-4xl font-bold lora">
                                Enrollment Info for Private or <span className="text-main">Online Lessons</span>
                            </h1>
                        }
                    >
                        <div className="grid my-3 align-items-end">
                            <CustomDropdownComponent
                                label="Lesson Programs"
                                name="program_id"
                                extraClassName="col-12 md:col-6"
                                placeholder="Select program"
                                value={data?.program_id}
                                options={getOnlineLessons}
                                onChange={handleChange}
                                optionLabel="name"
                            />
                            {data?.program_id && (
                                <CustomDropdownComponent
                                    label="Grade(If Student)"
                                    extraClassName="col-12 md:col-6"
                                    placeholder="Select grade"
                                    name="grade_id"
                                    value={data?.grade_id}
                                    options={getOnlineGrades?.map((item) => {
                                        return { name: item.grade, value: item.grade };
                                    })}
                                    onChange={handleChange}
                                    optionLabel="name"
                                />
                            )}
                            {data.program_id && (
                                <InputComponent1
                                    name="age"
                                    data={data}
                                    onChange={handleChange}
                                    label="Age"
                                    required
                                    extraClassName="col-12 md:col-6"
                                    keyfilter="int"
                                />
                            )}
                        </div>
                        {data?.grade_id ? (
                            <>
                                <div className="my-3">
                                    <h2 className="text-dark font-bold text-2xl">Choose Instrument</h2>
                                    <div className="flex mt-3 flex-wrap gap-3">
                                        {gradeInstruments?.map((item, i) => {
                                            return (
                                                <>
                                                    <div
                                                        className={`bg-skinlight-color flex flex-column align-items-center justify-content-center instrument-box p-3 cursor-pointer ${data?.instrument == item.value && "programme-selected"
                                                            }`}
                                                        onClick={() => {
                                                            handleInstrumentClick(item.value);
                                                        }}
                                                        key={item.value}
                                                    >
                                                        {item.icon ?
                                                            <img src={item.icon ? getImageURL(item.icon) : ""} alt="" className="mb-2" />
                                                            : null
                                                        }
                                                        <p className="text-sm text-dark ">{item.name}</p>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                            </>
                        ) : (
                            data.program_id &&
                            data.age && (
                                <div className="my-3">
                                    <h2 className="text-dark font-bold text-2xl">Choose Instrument</h2>
                                    <div className="flex mt-3 flex-wrap gap-3">
                                        {getAllInstruments?.map((item, i) => {
                                            return (
                                                <>
                                                    <div
                                                        className={`bg-skinlight-color flex flex-column align-items-center justify-content-center instrument-box p-3 cursor-pointer ${data?.instrument == item.value && "programme-selected"
                                                            }`}
                                                        onClick={() => {
                                                            handleInstrumentClick(item.value);
                                                        }}
                                                        key={item.value}
                                                    >
                                                        {item.icon ?
                                                            <img src={item.icon ? getImageURL(item.icon) : ""} alt="" className="mb-2" />
                                                            : null}
                                                        <p className="text-sm text-dark ">{item.name}</p>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                            )
                        )}
                        {data?.instrument && (
                            <div className="">
                                <h2 className="text-dark font-bold text-2xl mb-3">Program Types</h2>
                                <DataTable value={viewOnlineLesson?.tution_per_class} className="border-round-lg overflow-hidden text-dark">
                                    <Column field="payment_per_class" body={(r) => `$${r.payment_per_class}`} header="Payment Per Class"></Column>
                                    <Column field="class_duration" body={(r) => `${r.class_duration} Minutes`} header="Class Duration"></Column>
                                    <Column body={ActionTemplate} header="Action" style={{ width: "280px" }}></Column>
                                </DataTable>
                            </div>
                        )}
                        <MoreOnlineInfo
                            visible={visible}
                            setVisible={setVisible}
                            instrumentId={data?.instrument}
                            handleEnroll={handleEnroll}
                            btnText="Enroll now"
                        />
                    </Card>
                </div>
            ) : null}
        </>
    );
};

export default EnrollmentOnlineLessons;
