import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  TeacherloginAction,
} from "../../redux/actions/loginAction";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../services/auth";
var base64 = require("base-64");

const initialState = {
  email: "",
  password: "",
};

const TeacherLogin = () => {
  const history = useHistory();
  useEffect(() => {
    document.documentElement.style.fontSize = 14 + "px";
    if (isAuthenticated()) {
      history.push("/teacher-dashboard");
    }
  }, [history]);

  const dispatch = useDispatch();
  const [teacherData, setTeacherData] = useState(initialState);

  useEffect(() => {
    const rememberMe = localStorage.getItem("teacherrememberMe") === "true";
    const email = rememberMe ? localStorage.getItem("teacherEmail") : "";
    const password = rememberMe ? localStorage.getItem("teacherDetail") : "";
    if (localStorage.getItem("rememberMe") === "false") {
      localStorage.removeItem("teacherEmail");
      localStorage.removeItem("teacherDetail");
      setTeacherData({
        email: "",
        password: "",
        teacherrememberMe: false,
      });
    }

    setTeacherData({
      email: email,
      password: base64.decode(password),
      teacherrememberMe: rememberMe,
    });
  }, []);
  const [err, setErr] = useState({});
  const validateForm = () => {
    let errors = {};
    if (!teacherData?.email) {
      errors.email = "Email is required ";
    }
    if (!teacherData?.password) {
      errors.password = "Password is required ";
    }

    setErr(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = validateForm();
    if (form) {
      dispatch(TeacherloginAction(teacherData, history, "TEACHER"));
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "teacherrememberMe") {
      setTeacherData({ ...teacherData, [name]: checked });
    } else {
      setTeacherData({ ...teacherData, [name]: value });
      setErr({ ...err, [name]: "" });
    }
  };

  return {
    teacherData,
    handleSubmit,
    handleChange,
    err,
  };
};

export default TeacherLogin;
