import types from "../types";
const initialBoard = {
  addFormInfo: {},
  getSchools: {},
  student_billing_info: {},
  addBillingInfo: {},
  dashboard: {},
  studentNews: [],
  studentMessageHistory: [],
  attendenceNotes: [],
  getAllInstruments: []
};

const studentReducer = (state = initialBoard, action) => {
  switch (action.type) {
    case types.FETCH_SCHOOLS:
      return { ...state, getSchools: action.payload };
    case types.ADD_FORM_INFO:
      return { ...state, addFormInfo: action.payload };
    case types.ADD_STUDENT_INFO:
      return { ...state, student_billing_info: action.payload };
    case types.ADD_BILLING_INFO:
      return { ...state, addBillingInfo: action.payload };
    case types.GET_STUDENT_DASHBOARD:
      return { ...state, dashboard: action.payload };
    case types.GET_STUDENT_NEWS:
      return { ...state, studentNews: action.payload };
    case types.STUDENT_MESSAGE_HISTORY:
      return { ...state, studentMessageHistory: action.payload };
    case types.STUDENT_ATTENDENCE_NOTES:
      return { ...state, attendenceNotes: action.payload };
    case types.GET_ALL_INSTRUMENTS:
      return { ...state, getAllInstruments: action.payload };

    default:
      return { ...state };
  }
};

export default studentReducer;
