import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import ButtonComponent from "../../components/Fields/Button/CustomButton";
import InputComponent from "../../components/Fields/InputField";
import EditorComponent from "../../components/Fields/CustomEditor";
import { addEmailTemplate } from "../../redux/actions/TeacherPortal/emailTempalteAction";

export default function NewCustomTemplate({ openEmailCustomTemplate, setopenEmailCustomTemplate, edit, setEdit, viewTemplate }) {
    const [preview, setPreview] = useState(false);
    const dispatch = useDispatch();
    const [templateData, setTemplateData] = useState({
        title: "",
        message: "",
    });
    useEffect(() => {
        if (edit) {
            setTemplateData({
                title: viewTemplate?.title,
                message: viewTemplate?.message,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewTemplate]);

    const [err, setErr] = useState({});
    const handleSave = () => {
        edit ? editTemplate() : addTemplate();
    };

    const addTemplate = () => {
        const form = validateForm();
        if (form) {
            dispatch(addEmailTemplate(templateData, "custom", setopenEmailCustomTemplate));
        }
    };

    const editTemplate = () => {
        const form = validateForm();
        if (form) {
            // dispatch(editEmailTemplate(edit, templateData, "custom", setopenEmailCustomTemplate, setEdit));
        }
    };
    const validateForm = () => {
        let errors = {};
        if (!templateData.title) {
            errors.title = "Title  is required ";
        }

        if (!templateData?.message) {
            errors.message = "Message is required";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleChange = (e, name) => {
        if (name === "message") {
            setTemplateData((prev) => ({ ...prev, message: e.htmlValue }));
        } else {
            setTemplateData((prev) => ({ ...prev, [name]: e.target.value }));
        }
        setErr({ ...err, [name]: "" });
    };

    return (
        <>
            <Dialog
                header={edit ? "Edit Custom Email Template" : "Add Custom Email Tempalte"}
                className="assign-student border-round-lg overflow-hidden "
                visible={openEmailCustomTemplate}
                onHide={() => {
                    setopenEmailCustomTemplate(false);
                    setEdit(null);
                }}
                style={{ width: "48vw" }}
                draggable={false}
                resizable={false}
            >
                {preview ? (
                    <>
                        <form className="p-3">
                            <div className="grid mb-3">
                                <p className="font-bold  lg:col-2">Title</p>
                                <div className="lg:col-9">
                                    <p>{templateData?.title}</p>
                                </div>
                            </div>
                            <div className="grid">
                                <p className="font-bold lg:col-2">Message</p>
                                <div className="lg:col-9">
                                    <div
                                        className="border-1 border-400 p-3 "
                                        dangerouslySetInnerHTML={{ __html: templateData?.message }}
                                        style={{ minHeight: "150px" }}
                                    ></div>
                                </div>
                            </div>

                            <div className="flex justify-content-end mt-2">
                                <ButtonComponent
                                    label="Close"
                                    className="bg-gray-light ml-2"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setPreview(false);
                                    }}
                                ></ButtonComponent>
                            </div>
                        </form>
                    </>
                ) : (
                    <>
                        <div className=" mb-3">
                            <InputComponent
                                label="Title"
                                size="mt-3 w-full mb-3 font-bold"
                                name="title"
                                onChange={(e) => handleChange(e, "title")}
                                value={templateData?.title}
                                errorMessage={err?.title}
                            />
                            <p className="text-lg font-semibold mb-2">Message</p>
                            <EditorComponent
                                style={{ height: "250px" }}
                                onTextChange={(e) => handleChange(e, "message")}
                                value={templateData?.message}
                                errorMessage={err?.message}
                            />
                        </div>

                        <div className=" p-5 flex justify-content-end">
                            <ButtonComponent
                                label="Cancel"
                                className="bg-gray-light ml-2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setopenEmailCustomTemplate(false);
                                    setEdit(null);
                                }}
                            ></ButtonComponent>
                            <ButtonComponent className="ml-3" onClick={() => setPreview(true)}>
                                <i className="pi pi-eye text-sm mr-2 "></i> Preview
                            </ButtonComponent>
                            <ButtonComponent label="Save" className="ml-3" onClick={handleSave} />
                        </div>
                    </>
                )}
            </Dialog>
        </>
    );
}
