import AdultMusic from "../../assets/images/Adult-Music-Lessons-Online.jpeg";
import DashboardImg from "../../assets/images/dashboardImg2.png";
import cardImage from "../../assets/images/cardimage.png";
import musicInnst from "../../assets/images/musicalinstruments.gif";

const ProgrameCardContent = () => {
    let ProgramCardData = [
        {
            redirect: "https://fundamentalmusicinstruction.com/services/private-classes-online/",
            image: AdultMusic,
            title: "On-Line Private Classes",
            description: "",
            path: "",
        },

        {
            redirect: "https://fundamentalmusicinstruction.com/services/how-the-at-school-program-works/",
            image: cardImage,
            title: "How the 'At-School' Program Works",
            description: "",
            path: "",
        },
        {
            redirect: "https://fundamentalmusicinstruction.com/services/jr-jam/",
            image: DashboardImg,
            title: "Introducing Jr. Jam for K-2",
            description: "",
            path: "",
        },
        {
            redirect: "https://fundamentalmusicinstruction.com/services/getting-and-instrument/",
            image: musicInnst,
            title: "Getting an Instrument",
            description: "",
            path: "",
        },
    ];
    return { ProgramCardData };
};
export default ProgrameCardContent;
