import types from "../types";

const intitalState = {
  loginDetail: {
    email: "",
    token: "",
    rememberMe: "false",
  },
  getStudentProfile: {},
  getTeacherProfile: {},
  getParentProfile: {},
};

const loginReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.USER_DETAIL:
      return {
        ...state,
        loginDetail: action.payload,
      };
    case types.GET_STUDENT_PROFILE:
      return {
        ...state,
        getStudentProfile: action.payload,
      };
    case types.GET_TEACHER_PROFILE:
      return {
        ...state,
        getTeacherProfile: action.payload,
      };
    case types.GET_PARENT_PROFILE:
      return {
        ...state,
        getParentProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export default loginReducer;
