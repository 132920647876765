import React, { useState, useEffect } from "react";
import Index from "../../../layout";
import {
    ChangeCalendarDateAction,
    editEventAction,
    getCategoryAction,
} from "../../../../../redux/actions/TeacherPortal/eventsAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import RadioButtonComponent from "../../../../../components/Fields/Radio";
import DropDownComponent from "../../../../../components/Fields/CustomDropdown";
import InputGroup from "../../../../../components/Fields/InputGroup";
import NumberComponent from "../../../../../components/Fields/NumberField";
import MultiSelectComponent from "../../../../../components/Fields/CustomMultiSelect";
import ButtonComponent from "../../../../../components/Fields/Button/CustomButton";
import ChevronLeft from "../../../../../assets/icons/chevronleft.png";
import EventIcon from "../../../../../assets/icons/eventicon.png";
import { TeacherStudentsListAction, getTeacherSchoolsAction } from "../../../../../redux/actions/TeacherPortal/teacherAction";
import CustomCalendar from "../../../../../components/Fields/CustomCalendar";
import CheckboxComponent from "../../../../../components/Fields/Checkbox";
import { optionsFrequency } from "../../../../../utils/constants";
import moment from "moment";
import InputTextAreaComponent from "../../../../../components/Fields/CustomInputTextArea";
import { ViewEventAction } from "../../../../../redux/actions/TeacherPortal/attendanceAction";
import types from "../../../../../redux/types";
import { getSchoolTeachers, viewSchoolAction } from "../../../../../redux/actions/schoolAction";
import { getTeachersTabListing } from "../../../../../redux/actions/TeacherPortal/teachersTabAction";

const EditEvent = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { id } = useParams();

    const [eventId, setEventId] = useState(id);

    useEffect(() => {
        setEvents({
            ...events,
            date: location?.state ? location?.state : new Date(),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        dispatch(getTeacherSchoolsAction());
        // dispatch(getSchoolsAction());
        // dispatch(getStudentAction());
        dispatch(getCategoryAction());
    }, [dispatch, eventId]);

    const [events, setEvents] = useState({
        teacher_id: "",
        subsititute: "",
        visibility: "private",
        student_portal: "no_registration",
        select_location: "school",
        school_id: "",
        class_type: "",
        school_program: "",
        date: new Date(),
        start_time: "",
        school_duration: null,
        others_duration: null,
        all_day: false,
        current_attendees: [],
        calendar_category_id: [],
        event_repeat: null,
        frequency: "weekly",
        every_years: null,
        every_weeks: null,
        every_months: null,
        repeat_until: "",
        price_per_student: "default_price",
        event_price: null,
        public_description: "",
        private_description: "",
        show_public_description: null,
    });

    useEffect(() => {
        if (events?.select_location === "others") {
            dispatch(getTeachersTabListing());
        }
        if (events?.teacher_id) {
            dispatch(getTeachersTabListing());
        }
    }, [events?.select_location, events?.teacher_id]);
    const schoolDropdown = useSelector((state) => state?.teacher?.getSchools);
    const category = useSelector((state) => state?.teacherEvents?.getCategories);
    const teachersList = useSelector((state) => state.teachers.getTeachersList);

    useEffect(() => {
        if (events.school_id) {
            dispatch(getSchoolTeachers(events.school_id));
        }
    }, [events.school_id, dispatch]);

    useEffect(() => {
        if (events?.school_id) {
            dispatch(viewSchoolAction(events.school_id));
            return () => {
                dispatch({
                    type: types.VIEW_SCHOOL,
                    payload: {},
                });
            };
        }
    }, [events.school_id, dispatch]);

    const schoolTeachers = useSelector((state) => state?.school?.viewSchoolTeachers);
    const school_detail = useSelector((state) => state?.school?.viewSchool);

    useEffect(() => {
        if (id) {
            dispatch(ViewEventAction(id));
        }
    }, [id, dispatch]);

    const events1 = useSelector((state) => state?.events?.ViewEvent);

    useEffect(() => {
        if (eventId) {
            let date = events1.date;

            if (date) {
                let offset = moment(new Date(date)).utcOffset();
                date = moment(date).subtract(offset, "m");
                date = new Date(date);
            }
            let repeat_until = events1.repeat_until;

            if (repeat_until) {
                let offset = moment(new Date(repeat_until)).utcOffset();
                repeat_until = moment(repeat_until).subtract(offset, "m");
                repeat_until = new Date(repeat_until);
            }

            setEvents({
                teacher_id: events1.teacher_id,
                subsititute: events1.subsititute,
                visibility: events1.visibility,
                student_portal: events1.student_portal,
                select_location: events1.select_location,
                school_id: events1.school_id,
                class_type: events1.class_type,
                school_program: school_detail?.school_program_type,
                date: date,
                start_time: events1?.class_type !== "during_school" ? new Date(events1.start_time) : "",
                school_duration: events1.school_duration,
                others_duration: events1.others_duration,
                all_day: events1.all_day === 0 ? false : true,
                current_attendees: events1.attendees?.map((item) => ({
                    id: item.id,
                    name: item.name,
                })),
                day: new Date(),
                calendar_category_id: events1.calendar_category_id,
                event_repeat: events1.event_repeat === 0 ? false : true,
                frequency: events1.frequency,
                every_years: events1.every_years,
                every_weeks: events1.every_weeks,
                every_months: events1.every_months,
                repeat_until: events1.repeat_until ? repeat_until : "",
                price_per_student: events1.price_per_student,
                event_price: events1.event_price,
                public_description: events1.public_description,
                private_description: events1.private_description,
                show_public_description: events1.show_public_description === 0 ? false : true,
            });
        }
        setCheckedDays(events1?.days);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events1, events1.start_time, events1.date]);

    const handleChange = (e) => {
        const { name } = e.target;

        if (id) {
            if (name === "select_location") {
                if (events?.select_location === "school") {
                    setEvents((prev) => ({
                        ...prev,
                        select_location: e.value,
                        current_attendees: [],
                    }));
                }
                if (events?.select_location === "others") {
                    setEvents((prev) => ({
                        ...prev,
                        select_location: e.value,
                        current_attendees: [],
                    }));
                }
                setEvents((prev) => ({
                    ...prev,
                    [name]: e.value,
                }));
            } else {
                setEvents((prev) => ({
                    ...prev,
                    [name]: e.value,
                }));
            }
        } else {
            if (name === "select_location") {
                if (events?.select_location === "school") {
                    setEvents((prev) => ({
                        ...prev,
                        select_location: e.value,
                        start_time: "",
                        teacher_id: "",
                        others_duration: "",
                        subsititute: "",
                        others_frequency: null,
                    }));
                }
                if (events?.select_location === "others") {
                    setEvents((prev) => ({
                        ...prev,
                        select_location: e.value,
                        school_id: "",
                        class_type: "",
                        teacher_id: "",
                        subsititute: "",
                        school_duration: null,
                        current_attendees: [],
                    }));
                }
                setEvents((prev) => ({
                    ...prev,
                    current_attendees: [],
                }));
            } else if (name === "frequency") {
                setEvents((prev) => ({
                    ...prev,
                    frequency: e.value,
                    every_months: null,
                    every_weeks: null,
                }));
            } else if (name === "school_id") {
                setEvents((prev) => ({
                    ...prev,
                    school_id: e.value,
                    teacher_id: "",
                    subsititute: "",
                    class_type: "",
                }));
            } else if (name === "start_time") {
                let currentMin = e.value.getMinutes();
                let startTime = e.value;
                if (currentMin % 5 !== 0) {
                    startTime = new Date(e.value.getTime() + (5 - (currentMin % 5)) * 60000);
                }
                setEvents((prev) => ({
                    ...prev,
                    [name]: startTime,
                }));
            } else {
                setEvents((prev) => ({
                    ...prev,
                    [name]: e.value,
                }));
            }
        }

        setErr({ ...err, [name]: "" });
    };

    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setEvents((prev) => ({ ...prev, [name]: value }));
    };
    const handleCheck = (name, e) => {
        setEvents({ ...events, [name]: !events[name] });
    };
    var [checkedDays, setCheckedDays] = useState([0, 1, 2, 3, 4, 5, 6]);
    const handleCheckboxChange = (event, i) => {
        let newArr = [...checkedDays];
        if (event.target.checked) {
            newArr.push(i);
            setErr({ ...err, checkedDays: "" });
        } else {
            let indx = newArr.findIndex((item) => item === i);
            newArr.splice(indx, 1);
        }
        setCheckedDays(newArr);
    };
    const [err, setErr] = useState({});

    const validateForm = () => {
        let errors = {};
        if (!events?.teacher_id) {
            errors.teacher_id = "Teacher is required";
        }
        if (events?.select_location === "school" && !events?.school_id) {
            errors.school_id = "School is required";
        }
        if (
            (events?.select_location === "others" && !events.start_time) ||
            (events?.class_type === "after_school" && !events.start_time) ||
            (events?.class_type === "semi_private" && !events.start_time)
        ) {
            errors.start_time = "Start Time is required";
        }
        if (events?.select_location === "school" && events?.school_duration === null) {
            errors.school_duration = "Duration is required";
        }
        if (events?.select_location === "school" && events?.school_id && !events?.class_type) {
            errors.class_type = "Class Type is required";
        }
        if (events?.select_location === "others" && events?.others_duration === null) {
            errors.others_duration = "Duration is required";
        }
        if (events?.calendar_category_id?.length === 0) {
            errors.calendar_category_id = "Category is required";
        }

        if (events.event_repeat && events.frequency === "weekly" && !events.every_weeks) {
            errors.every_weeks = "Week is required";
        }
        if (events.event_repeat && events.frequency === "monthly" && !events.every_months) {
            errors.every_months = "Month  is required";
        }
        if (events.event_repeat && events.frequency === "yearly" && !events.every_years) {
            errors.every_years = "Year  is required";
        }

        if (events.event_repeat && !events.repeat_until) {
            errors.repeat_until = "Repeat Until  is required";
        }

        if (events.price_per_student === "specify_price" && !events.event_price) {
            errors.event_price = "Price  is required";
        }
        if (events.event_repeat && events.frequency === "daily" && !checkedDays.length) {
            errors.checkedDays = "Please Select Atleast a Day";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };
    const handleEditEvent = (e) => {
        const form = validateForm();
        if (form) {
            dispatch(editEventAction(events, checkedDays, eventId, history, setEventId, e, events?.date, location.state));
        }
    };
    const handleSave = (e, event) => {
        e.preventDefault();
        handleEditEvent(event);
    };
    useEffect(() => {
        if (events?.teacher_id) {
            dispatch(TeacherStudentsListAction(events?.teacher_id, events?.select_location));
            return () => {
                dispatch({
                    type: types.TEACHER_STUDENTS,
                    payload: [],
                });
            };
            // dispatch(TeacherSchoolsListAction(events?.teacher_id));
        }
        return () => {
            dispatch({
                type: types.TEACHER_STUDENTS,
                payload: [],
            });
        };
    }, [dispatch, events?.teacher_id, events?.select_location]);
    const teacherStudents = useSelector((state) => state?.teacher?.viewStudent);

    const AsssignedAttendee =
        (teacherStudents?.length &&
            teacherStudents?.map((item) => {
                return { id: item.user_id, name: item.name };
            })) ||
        [];

    useEffect(() => {
        return () => {
            dispatch({
                type: types.VIEW_SCHOOL,
                payload: [],
            });
        };
    }, [dispatch]);

    return (
        <>
            <Index>
                <div className="p-5">
                    <div className="grid">
                        <div className="md:col-10">
                            <h3 className="text-main open-sans mb-5 flex align-items-center text-2xl font-semibold cursor-pointer">
                                <img
                                    src={ChevronLeft}
                                    alt=""
                                    width={10}
                                    onClick={() => {
                                        dispatch(ChangeCalendarDateAction(events.date));
                                        history.goBack();
                                    }}
                                />
                                <img src={EventIcon} alt="" width={30} className="ml-2" />
                                &nbsp; Event Details
                            </h3>
                            <div className="grid mb-3">
                                <div className="md:col-2">
                                    <p className="font-semibold">Select Location</p>
                                </div>
                                <div className="md:col-10 flex">
                                    <RadioButtonComponent
                                        label="School"
                                        labelClassName="font-semibold"
                                        name="select_location"
                                        value="school"
                                        onChange={handleChange}
                                        checked={events?.select_location === "school"}
                                    />
                                    <RadioButtonComponent
                                        label="Others"
                                        labelClassName="font-semibold"
                                        name="select_location"
                                        value="others"
                                        onChange={handleChange}
                                        checked={events?.select_location === "others"}
                                    />
                                </div>
                            </div>
                            {events?.select_location === "school" ? (
                                <div className="school-grade">
                                    <div className="grid mb-2">
                                        <DropDownComponent
                                            extraClassName="md:col-4"
                                            label="Select School"
                                            placeholder="Select School"
                                            name="school_id"
                                            value={events?.school_id}
                                            options={schoolDropdown?.map((item, index) => {
                                                return { label: item?.name, value: item?.id };
                                            })}
                                            emptyMessage={
                                                events?.teacher_id ? "No School Assigned" : "Please Select Teacher First"
                                            }
                                            onChange={handleChange}
                                            optionLabel="label"
                                            errorMessage={err?.school_id}
                                            required
                                        />
                                        <DropDownComponent
                                            extraClassName="md:col-4"
                                            label="Class Type"
                                            placeholder="Select Class Type"
                                            name="class_type"
                                            value={events?.class_type}
                                            options={school_detail?.class_types}
                                            onChange={handleChange}
                                            optionLabel="name"
                                            emptyMessage={events?.school_id ? "No record found" : "Please Select School First"}
                                            errorMessage={err?.class_type}
                                            required
                                        />
                                    </div>
                                    <div className="grid">
                                        <DropDownComponent
                                            extraClassName="md:col-8 mb-1"
                                            label="Teacher"
                                            name="teacher_id"
                                            value={events?.teacher_id}
                                            required
                                            options={schoolTeachers
                                                ?.filter((item) => item.id !== events.subsititute)
                                                ?.map((item, index) => {
                                                    return { label: item?.name, value: item?.id };
                                                })}
                                            emptyMessage={
                                                !events?.school_id
                                                    ? "Please Select School First"
                                                    : schoolTeachers?.length === 0
                                                    ? "This School has no teachers"
                                                    : "Please Select School First"
                                            }
                                            onChange={handleChange}
                                            optionLabel="label"
                                            errorMessage={err?.teacher_id}
                                        />
                                        <DropDownComponent
                                            extraClassName="md:col-8 mb-1"
                                            label="Substitute"
                                            name="subsititute"
                                            value={events?.subsititute}
                                            placeholder="None"
                                            options={teachersList
                                                ?.filter((item) => item.id !== events.teacher_id)
                                                .map((item, index) => {
                                                    return { label: item?.name, value: item?.id };
                                                })}
                                            onChange={handleChange}
                                            emptyMessage={
                                                events?.school_id ? "Please Select Teacher" : "Please Select School First"
                                            }
                                            optionLabel="label"
                                        />
                                    </div>
                                    <div className="grid mb-2">
                                        <div className="md:col-2">
                                            <p className="font-semibold">Visibility</p>
                                        </div>
                                        <div className="md:col-10">
                                            <RadioButtonComponent
                                                extraClassName="mb-3"
                                                label="Private Only Current Attendees can see this event, other students will not see this in the Student Portal"
                                                name="visibility"
                                                value="private"
                                                onChange={handleChange}
                                                checked={events?.visibility === "private"}
                                            />
                                            <RadioButtonComponent
                                                extraClassName="mb-3"
                                                label="Public  All students can see this event in the Student Portal"
                                                name="visibility"
                                                value="public"
                                                onChange={handleChange}
                                                checked={events?.visibility === "public"}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid mb-2">
                                        <div className="md:col-2">
                                            <p className="font-semibold">Student Portal</p>
                                        </div>
                                        <div className="md:col-10">
                                            <RadioButtonComponent
                                                extraClassName="mb-3"
                                                label="No Registration For recitals, master class, etc."
                                                name="student_portal"
                                                value="no_registration"
                                                onChange={handleChange}
                                                checked={events?.student_portal === "no_registration"}
                                            />
                                            <RadioButtonComponent
                                                label="Allow Registration For setting up open lesson slots, or events with limited space."
                                                name="student_portal"
                                                value="allow_registration"
                                                onChange={handleChange}
                                                checked={events?.student_portal === "allow_registration"}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid mb-3">
                                        <CustomCalendar
                                            onChange={handleChange}
                                            name="date"
                                            label="Date"
                                            size="md:col-2"
                                            minDate={new Date()}
                                            value={events.date}
                                        />
                                        <div className="md:col-2">
                                            <InputGroup min="min">
                                                <NumberComponent
                                                    label="Duration"
                                                    placeholder=""
                                                    required
                                                    name="school_duration"
                                                    min="min"
                                                    maxLength={3}
                                                    onValueChange={handleChange}
                                                    value={events.school_duration}
                                                />
                                            </InputGroup>
                                            {err && <div className="p-error text-sm">{err.school_duration}</div>}
                                        </div>
                                        {(events?.class_type.includes("after_school_program") ||
                                            events?.class_type.includes("after_school") ||
                                            events?.class_type.includes("semi_private")) && (
                                            <>
                                                <CustomCalendar
                                                    label="Start Time"
                                                    size="md:col-2"
                                                    name="start_time"
                                                    value={events.start_time}
                                                    onChange={handleChange}
                                                    icon="pi pi-clock"
                                                    showIcon
                                                    required
                                                    timeOnly
                                                    hourFormat="12"
                                                    stepMinute={5}
                                                    errorMessage={err.start_time}
                                                />
                                            </>
                                        )}

                                        {events?.class_type.includes("day_time_program") ||
                                            (events?.class_type.includes("during_school") && (
                                                <div className="md:col-2 flex align-items-center mb-2">
                                                    <CheckboxComponent
                                                        label="Rotating Schedule"
                                                        name="all_day"
                                                        checked={events?.all_day}
                                                        onChange={(event) => handleCheck("all_day", event)}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                    <div className="grid mb-2">
                                        <MultiSelectComponent
                                            extraClassName="md:col-5 pr-0 py-0 mb-3"
                                            label="Current Attendees"
                                            inputClass="border-noround-right current-attendance"
                                            name="current_attendees"
                                            value={AsssignedAttendee.length > 0 ? events?.current_attendees : []}
                                            options={AsssignedAttendee}
                                            placeholder={
                                                // events?.teacher_id ? AsssignedAttendee : AttendeeMessage
                                                !events?.teacher_id
                                                    ? "Please Select Teacher First"
                                                    : AsssignedAttendee?.length === 0
                                                    ? "This teacher has no Attendee"
                                                    : ""
                                            }
                                            onChange={handleChange}
                                            optionDisabled={(option) =>
                                                option.name === "Please Select Teacher First" ||
                                                option.name === "This teacher has no Attendee"
                                            }
                                            display="chip"
                                            optionLabel="name"
                                            errorMessage={err?.current_attendees}
                                        />

                                        <DropDownComponent
                                            extraClassName="p-0 mb-3"
                                            inputClass="bg-main bg-green1 text-dark1 border-noround-left group-dropdown"
                                            placeholder="Groups"
                                        />
                                    </div>
                                    <div className="grid">
                                        <DropDownComponent
                                            extraClassName="md:col-7 mb-3"
                                            label="Category"
                                            placeholder="Select Category"
                                            name="calendar_category_id"
                                            value={events?.calendar_category_id}
                                            options={category?.map((item, index) => {
                                                return { label: item?.name, value: item?.id };
                                            })}
                                            onChange={handleChange}
                                            required
                                            optionLabel="label"
                                            errorMessage={err?.calendar_category_id}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="other-grade">
                                    <div className="grid">
                                        <DropDownComponent
                                            extraClassName="md:col-7 mb-1"
                                            label="Teacher"
                                            name="teacher_id"
                                            value={events?.teacher_id}
                                            required
                                            options={teachersList
                                                ?.filter((item) => item.id !== events.subsititute)
                                                ?.map((item, index) => {
                                                    return { label: item?.name, value: item?.id };
                                                })}
                                            disabled
                                            onChange={handleChange}
                                            optionLabel="label"
                                            errorMessage={err?.teacher_id}
                                        />
                                        <DropDownComponent
                                            extraClassName="md:col-7 mb-1"
                                            label="Substitute"
                                            name="subsititute"
                                            value={events?.subsititute}
                                            options={teachersList
                                                ?.filter((item) => item.id !== events.teacher_id)
                                                .map((item, index) => {
                                                    return { label: item?.name, value: item?.id };
                                                })}
                                            onChange={handleChange}
                                            optionLabel="label"
                                        />
                                    </div>
                                    <div className="grid mb-2">
                                        <div className="md:col-2">
                                            <p className="font-semibold">Visibility</p>
                                        </div>
                                        <div className="md:col-10">
                                            <RadioButtonComponent
                                                extraClassName="mb-3"
                                                label="Private Only Current Attendees can see this event, other students will not see this in the Student Portal"
                                                name="visibility"
                                                value="private"
                                                onChange={handleChange}
                                                checked={events?.visibility === "private"}
                                            />
                                            <RadioButtonComponent
                                                extraClassName="mb-3"
                                                label="Public  All students can see this event in the Student Portal"
                                                name="visibility"
                                                value="public"
                                                onChange={handleChange}
                                                checked={events?.visibility === "public"}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid mb-2">
                                        <div className="md:col-2">
                                            <p className="font-semibold">Student Portal</p>
                                        </div>
                                        <div className="md:col-10">
                                            <RadioButtonComponent
                                                extraClassName="mb-3"
                                                label="No Registration For recitals, master class, etc."
                                                name="student_portal"
                                                value="no_registration"
                                                onChange={handleChange}
                                                checked={events?.student_portal === "no_registration"}
                                            />
                                            <RadioButtonComponent
                                                label="Allow Registration For setting up open lesson slots, or events with limited space."
                                                name="student_portal"
                                                value="allow_registration"
                                                onChange={handleChange}
                                                checked={events?.student_portal === "allow_registration"}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid mb-2">
                                        <DropDownComponent
                                            extraClassName="md:col-4"
                                            label="Select Category"
                                            placeholder="Select Category"
                                            name="calendar_category_id"
                                            value={events?.calendar_category_id}
                                            options={category?.map((item, index) => {
                                                return { label: item?.name, value: item?.id };
                                            })}
                                            onChange={handleChange}
                                            optionLabel="label"
                                            errorMessage={err?.calendar_category_id}
                                        />
                                        <CustomCalendar
                                            onChange={handleChange}
                                            name="date"
                                            label="Date"
                                            size="md:col-4"
                                            value={events.date}
                                        />
                                    </div>
                                    <div className="grid align-items-center mb-3">
                                        <CustomCalendar
                                            label="Start Time"
                                            size="md:col-2"
                                            name="start_time"
                                            value={events.start_time}
                                            onChange={handleChange}
                                            icon="pi pi-clock"
                                            showIcon
                                            required
                                            timeOnly
                                            stepMinute={5}
                                            hourFormat="12"
                                            errorMessage={err.start_time}
                                        />

                                        <InputGroup min="min" extraClassName="md:col-3">
                                            <NumberComponent
                                                label="Duration"
                                                placeholder=""
                                                required
                                                name="others_duration"
                                                min="min"
                                                maxLength={3}
                                                onValueChange={handleChange}
                                                value={events.others_duration}
                                            />
                                        </InputGroup>
                                    </div>
                                    {err && <div className="p-error text-sm">{err.others_duration}</div>}
                                    <div className="grid mb-2 align-items-center">
                                        <MultiSelectComponent
                                            extraClassName="md:col-6 pr-0"
                                            label="Current Attendees"
                                            placeholder="No attendees. Click here to add some"
                                            name="current_attendees"
                                            value={AsssignedAttendee.length > 0 ? events?.current_attendees : []}
                                            options={AsssignedAttendee}
                                            onChange={handleChange}
                                            optionLabel="name"
                                            display="chip"
                                            required
                                            errorMessage={err?.current_attendees}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="grid mb-4">
                                <CheckboxComponent
                                    label="This event repeats on a regular basis."
                                    name="event_repeat"
                                    checked={events?.event_repeat}
                                    onChange={(event) => handleCheck("event_repeat", event)}
                                />
                            </div>
                            {events.event_repeat && (
                                <>
                                    <div className="grid">
                                        <DropDownComponent
                                            label="Frequency"
                                            placeholder="Weekly"
                                            extraClassName="md:col-4"
                                            name="frequency"
                                            value={events?.frequency}
                                            options={optionsFrequency}
                                            onChange={handleChange}
                                            optionLabel="label"
                                        />
                                        {events.frequency === "weekly" && (
                                            <NumberComponent
                                                label="Every Week"
                                                placeholder=""
                                                extraClassName="md:col-4"
                                                name="every_weeks"
                                                onValueChange={handleChange}
                                                value={events.every_weeks}
                                                errorMessage={err?.every_weeks}
                                            />
                                        )}

                                        {events.frequency === "monthly" && (
                                            <NumberComponent
                                                label="No. of months"
                                                placeholder=""
                                                extraClassName="md:col-4"
                                                name="every_months"
                                                onValueChange={handleChange}
                                                value={events.every_months}
                                                errorMessage={err?.every_months}
                                            />
                                        )}
                                        {events.frequency === "yearly" && (
                                            <NumberComponent
                                                label="Years"
                                                placeholder=""
                                                extraClassName="md:col-4"
                                                name="every_years"
                                                onValueChange={handleChange}
                                                value={events.every_years}
                                                errorMessage={err?.every_years}
                                            />
                                        )}
                                        <CustomCalendar
                                            label="Repeat Until"
                                            size="md:col-4"
                                            name="repeat_until"
                                            value={events.repeat_until}
                                            onChange={handleChange}
                                            icon="pi pi-clock"
                                            showIcon
                                            required
                                            errorMessage={err?.repeat_until}
                                        />
                                    </div>
                                    {events.frequency === "daily" && (
                                        <div className="grid">
                                            <div className="col-2">
                                                <p className="font-semibold">Days</p>
                                            </div>
                                            <div className="md:col-8 flex align-items-center">
                                                {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day, i) => (
                                                    <div key={day}>
                                                        <CheckboxComponent
                                                            label={day}
                                                            checked={checkedDays?.includes(i)}
                                                            onChange={(e) => handleCheckboxChange(e, i)}
                                                        />
                                                    </div>
                                                ))}
                                                {err && <div className="p-error text-sm ml-3">{err.checkedDays}</div>}
                                            </div>
                                        </div>
                                    )}
                                    {events.frequency === "monthly" && (
                                        <div className="grid">
                                            <div className="col-2">
                                                <p className="font-semibold text-sm">Day</p>
                                            </div>
                                            <div className="md:col-8">
                                                <RadioButtonComponent
                                                    label={`The  ${moment(events.date).format("DD")}  of the month`}
                                                    checked={true}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            <p className="font-semibold mb-4">Description</p>
                            <div className="grid">
                                <InputTextAreaComponent
                                    extraClassName="md:col-6"
                                    label="Public"
                                    rows={5}
                                    cols={30}
                                    name="public_description"
                                    onChange={handleChange1}
                                    value={events.public_description}
                                />
                                <InputTextAreaComponent
                                    extraClassName="md:col-6"
                                    label="Private"
                                    rows={5}
                                    cols={30}
                                    name="private_description"
                                    onChange={handleChange1}
                                    value={events.private_description}
                                />
                            </div>
                            <CheckboxComponent
                                label="Show public description directly on calendar"
                                extraClassName="mb-3"
                                name="show_public_description"
                                checked={events?.show_public_description}
                                onChange={(event) => handleCheck("show_public_description", event)}
                            />
                            {eventId && events1?.event_repeat === 1 ? (
                                <div className="bg-gray-light text-center p-4">
                                    <p className="mb-3">This is a repeating event.Apply Changes to which events?</p>
                                    <ButtonComponent
                                        icon="pi pi-check"
                                        iconPos="left"
                                        label="This Event Only"
                                        className="ml-2"
                                        onClick={handleSave}
                                    />
                                    <ButtonComponent
                                        icon="pi pi-check"
                                        iconPos="left"
                                        className="ml-2"
                                        label="Future Events"
                                        onClick={(e) => handleSave(e, "future_events")}
                                    />
                                    <ButtonComponent
                                        label="Cancel"
                                        className="bg-gray-light ml-2 shadow "
                                        onClick={() => {
                                            dispatch(ChangeCalendarDateAction(events.date));
                                            history.goBack();
                                        }}
                                    />
                                </div>
                            ) : (
                                <>
                                    <div>
                                        <ButtonComponent label="Save" onClick={handleSave} />
                                        <ButtonComponent
                                            label="Cancel"
                                            className="bg-gray-light ml-2"
                                            onClick={() => {
                                                dispatch(ChangeCalendarDateAction(events.date));
                                                history.goBack();
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Index>
        </>
    );
};

export default EditEvent;
