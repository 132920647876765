import React, { useState, useEffect, useRef } from "react";
import Index from "../../layout";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import CalendarIcon from "../../../../assets/icons/calendar.png";
import { Menu } from "primereact/menu";
import Dropblack from "../../../../assets/icons/dropblack.png";
import viewIcon from "../../../../assets/icons/viewIcon.png";
import { getStudentsEventsAction } from "../../../../redux/actions/calendarAction";
import { useDispatch, useSelector } from "react-redux";
import CardHeader from "../../../../pages/CardHeader";

const StudentCalendar = () => {
    const menu = useRef(null);
    const viewAgenda = useRef();
    const calendarRef = useRef(null);
    const [date, onDateChange] = useState(new Date());
    const now = new Date();
    const dispatch = useDispatch();

    const [view, setView] = useState("dayGridMonth");

    useEffect(() => {
        calendarRef.current.getApi().gotoDate(new Date());
    }, []);
    const CalendarMenu = [
        {
            label: "students",
            // url: `/#/calendar/calendar-event`,
        },
    ];
    const items = [
        {
            label: "Month",
            key: 1,
            style: view === "dayGridMonth" ? { backgroundColor: "#59c6bc" } : null,
            command: () => {
                const { current: calendarDom } = calendarRef;
                const API = calendarDom ? calendarDom.getApi() : null;
                API && API.changeView("dayGridMonth");
                setView("dayGridMonth");
            },
        },
        {
            label: "Day",
            key: 2,
            style: view === "dayGridDay" ? { backgroundColor: "#59c6bc" } : null,
            command: () => {
                const { current: calendarDom } = calendarRef;
                const API = calendarDom ? calendarDom.getApi() : null;
                API && API.changeView("dayGridDay");
                setView("dayGridDay");
            },
        },
        {
            label: "Week",
            key: 3,
            style: view === "dayGridWeek" ? { backgroundColor: "#59c6bc" } : null,
            command: () => {
                const { current: calendarDom } = calendarRef;
                const API = calendarDom ? calendarDom.getApi() : null;
                API && API.changeView("dayGridWeek");
                setView("dayGridWeek");
            },
        },
    ];

    useEffect(() => {
        dispatch(getStudentsEventsAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [now.getFullYear() !== date.getFullYear()]);

    const events1 = useSelector((state) => state?.calendar?.getStudentEvents);

    function getEvents() {
        const events = [];
        events1?.forEach((item, index) => {
            if (item?.student_events?.length) {
                item.student_events.forEach((event, i) => {
                    events.push({
                        id: event.id,
                        title: `${event?.class_type === "after_school_program" || event?.select_location === "others" ? event.start_time_format : ""} ${
                            event.attendees_list ?? ""
                        } (${event?.category_name}  ${event.class_type === "during_school" ? "(DS)" : ""} with ${event.teacher}) ${
                            event.public_description && event.show_public_description ? event.public_description : ""
                        }`,
                        backgroundColor: `#${event.calendar_colour}`,
                        color: "#fff",
                        start: item.date,
                        end: item.date,
                    });
                });
            }
        });
        return events;
    }

    return (
        <>
            <Index>
                <div className="p-5">
                    <CardHeader icon={CalendarIcon} heading="Calendar" />
                    <div className="table-top-header border-round-top-xl bg-main p-3 mb-3">
                        <div className="flex justify-content-between">
                            <div className="flex justify-content-between">
                                <Menu model={CalendarMenu} popup ref={menu} className="" />
                            </div>
                            <div className="flex justify-content-between">
                                <div
                                    className={`flex align-items-center open-sans font-semibold text-xs ml-5 cursor-pointer${
                                        viewAgenda.current ? "" : `icon-rotate`
                                    }`}
                                    onClick={(e) => {
                                        viewAgenda.current.toggle(e);
                                    }}
                                >
                                    <img src={viewIcon} alt="" className="bg-white p-2 border-round-lg " width={30} />
                                    &nbsp; View &nbsp;
                                    <img src={Dropblack} alt="" className="" width={15} />
                                </div>
                                <Menu model={items} popup ref={viewAgenda} className={"view-menu "} />
                                {/* <div className="flex align-items-center open-sans font-semibold text-xs ml-5">
                            <img src={SyncIcon} alt="" className="bg-white p-2 border-round-lg" width={30} />
                            &nbsp; Set Up Sync &nbsp;
                            <img src={Dropblack} alt="" className="" width={15} />
                        </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="custom-calander">
                        <FullCalendar
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            headerToolbar={{
                                left: "title",
                                center: "",
                                right: "prev today next",
                            }}
                            initialView={view}
                            ref={calendarRef}
                            views={view}
                            // select={handleSelect}
                            // dateClick={(e) => EventPopover(e)}
                            hiddenDays="0"
                            weekends={true}
                            events={getEvents()}
                            eventTextColor="#111"
                            // eventClick={handleEventRender}
                            // eventDidMount={EventPopover}
                            // validRange={{
                            //   start: new Date(),
                            //   end: null,
                            // }}
                            customButtons={{
                                prev: {
                                    text: "Previous",
                                    click: function () {
                                        let calendarApi = calendarRef.current.getApi();
                                        calendarApi.prev();
                                        onDateChange(calendarApi.getDate());
                                    },
                                },
                                next: {
                                    text: "Next",
                                    click: function () {
                                        let calendarApi = calendarRef.current.getApi();
                                        calendarApi.next();
                                        onDateChange(calendarApi.getDate());
                                    },
                                },
                            }}
                        />
                    </div>
                </div>
            </Index>
        </>
    );
};
export default StudentCalendar;
