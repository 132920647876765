export const GradeOptions = [
    { name: "Kindergarten (K)", value: "k" },
    { name: "Grade 1", value: 1 },
    { name: "Grade 2", value: 2 },
    { name: "Grade 3", value: 3 },
    { name: "Grade 4", value: 4 },
    { name: "Grade 5", value: 5 },
    { name: "Grade 6", value: 6 },
    { name: "Grade 7", value: 7 },
    { name: "Grade 8", value: 8 },
    { name: "Grade 9", value: 9 },
    { name: "Grade 10", value: 10 },
    { name: "Grade 11", value: 11 },
    { name: "Grade 12", value: 12 },
];

export const SkillLevel = [
    { name: "Beginner", value: "Beginner" },
    { name: "Intermediate", value: "Intermediate" },
    { name: "Advanced", value: "Advanced" },
];
export const InstrumentsPurchaseType = [
    { name: "Rent", value: "rent" },
    { name: "Purchase", value: "purchase" },
    { name: "Nothing Needed", value: "nothing_needed" },
];

export const emptyState = {
    student_type: "child",
    first_name: "",
    last_name: "",
    // email: "",
    skill_level: {},
    current_school: "",
    aftercare_picked_up_time: "",
    prefer_drop_picked_up_for_class: "",
    class_type: "",
    order_book: true,
    is_waiting: false,
    cost_per_book: 30,
    monthly_installment: "",
    program_id: "",
    one_time_payment: "",
    instrument_id: "",
    type: null,
    amount: null,
    instrument_needed: "yes",
};

export const emptyState1 = {
    school_id: "",
    grade1: "",
    enrollInstrument: "",
};

export const onlineEmptyState = {
    student_type: "child",
    first_name: "",
    last_name: "",
    email: "",
    skill_level: "",
    current_school: "",
    grade_id: "",
    instrument_id: "",
    instrument_on_purchase_or_rent: "",
    monthly_installment: "",
    one_time_payment: "",
    order_book: true,
    cost_per_book: 30,
    type: "purchase",
    day: [],
    time: "",
    phone_number: "",
    instrument_needed: "yes",
};
export const onlineEmptyState1 = {
    student_type: "child",
    program_id: "",
    age: "",
    enrollInstrument: "",
};
