import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EnrollmentLessons1 from "./EnrollmentLessons1";
import useUnload from "../components/useUnload";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EnrollmentOnlineLessons from "./EnrollmentOnlineLessons";

const EnrollmentForm = () => {
    const schoolForms = useSelector((state) => state?.enroll?.students);
    const [validateForm, setValidationForm] = useState(true);
    const history = useHistory();
    const error = true;
    useUnload((e) => {
        e.preventDefault();
        e.returnValue = "";
    });

    useEffect(() => {
        if (!schoolForms?.[0].enroll_type) {
            history.push("/enroll");
        }
    }, [schoolForms?.[0]?.enroll_type]);
    return (
        <>
            {schoolForms?.[0]?.enroll_type === "school_lessons" ? (
                <EnrollmentLessons1 initialState={schoolForms[0]} index={0} error={setValidationForm} />
            ) : (
                <EnrollmentOnlineLessons initialState={schoolForms[0]} index={0} error={setValidationForm} />
            )}
        </>
    );
};

export default EnrollmentForm;
