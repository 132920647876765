import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo/logo.png";
import { LoginDialog } from "../../pages/Home/loginDialog";

export default function TopBar() {
    const [visible, setVisible] = useState(false);
    const [selectUserType, setSelectUserType] = useState("STUDENT");
    return (
        <>
            <div className="px-2 md:px-7 shadow-2 ">
                <div className="p-2 flex justify-content-between align-items-center">
                    <div className="logo">
                        <Link to="/">
                            <img src={Logo} alt="" width="200" className="mr-2"></img>
                        </Link>
                    </div>
                    <div className="p-menuitem-link" onClick={() => setVisible(true)}>
                        <button
                            className="p-2 px-3 ml-2 bg-dark text-white border-none text-sm border-round-lg cursor-pointer open-sans flex align-items-center flex-column justify-content-center"
                            style={{ width: "8rem", height: "40px" }}
                        >
                            Login
                        </button>
                    </div>
                </div>
            </div>
            <LoginDialog
                selectUserType={selectUserType}
                setSelectUserType={setSelectUserType}
                visible={visible}
                setVisible={setVisible}
            />
        </>
    );
}
