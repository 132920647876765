import types from "../types";

const initialState = {
    students: [
        {
            enroll_type: "",
            school_id: "",
            grade1: "",
            instrument: "",
            school_name: "",
        },
    ],
    parent: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        address: "",
        city: "",
        state: "",
        state_code: "",
        zip_code: "",
    },
};

const EnrollmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.UPDATE_STUDENT_FORM:
            const { index, data } = action.payload;
            const updatedForms = [...state.students];
            updatedForms[index] = { ...updatedForms[index], ...data };
            return {
                ...state,
                students: updatedForms,
                parent: { ...state.parent },
            };
        case types.UPDATE_PARENT_FORM:
            return {
                ...state,
                parent: action.payload,
            };

        case types.ADD_ANOTHER_SCHOOL_FORM:
            return {
                ...state,
                students: [...state.students, { enroll_type: "", school_id: null, grade: "", instrument: "", school_name: "" }],
            };
        case types.RESET_STUDENT_FIELDS:
            return {
                students: [{ enroll_type: "", school_id: "", grade1: "", instrument: "", school_name: "" }],
            };

        case types.REPLACE_KEYS:
            const { index: index2, keys } = action.payload;
            const updatedStudents1 = [...state.students];
            updatedStudents1[index2] = { ...keys };

            return {
                ...state,
                students: updatedStudents1,
            };

        case types.INITIALIZE_SCHOOL_FIELDS:
            const { index: index1, schooldata } = action.payload;
            const updatedForms1 = [...state.students];
            updatedForms1[index1] = { ...updatedForms1[index1], ...schooldata };
            return {
                ...state,
                students: updatedForms1,
            };

        case types.REMOVE_STUDENT:
            const indexToRemove = action.payload;
            const updatedStudents = [...state.students];
            updatedStudents.splice(indexToRemove, 1);
            return {
                ...state,
                students: updatedStudents,
            };
        case types.UPDATE_SCHOOL_FORMS:
            return {
                ...state,
                schoolForms: action.payload.schoolForms,
            };
        case types.UPDATE_ENROLLMENT_DATA:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            };

        case types.SET_ADMISSION_FIELDS:
            return {
                ...state,
                admissionFields: action.paylaod,
            };

        case types.UPDATE_ADMISSION_FIELD:
            const { fieldName, value, formErrors } = action.payload;
            return {
                ...state,
                admissionFields: {
                    ...state.admissionFields,
                    [fieldName]: value,
                },
                // ...state,
                // admissionFields: formErrors
                //     ? { ...state.admissionFields, [fieldName]: value, formErrors }
                //     : { ...state.admissionFields, [fieldName]: value },
            };

        case types.UPDATE_EXTRA_FIELDS:
            const { name, value: fieldValue, item, extraFields } = action.payload;
            return {
                ...state,
                admissionFields: {
                    ...state.admissionFields,
                    student_extra_fields: {
                        ...state.admissionFields.student_extra_fields,
                        [name]: { value: fieldValue, ...item },
                    },
                    parent_extra: { extraFields },
                },
            };
        case types.SET_EXTRA_FIELDS:
            return { extraFields: action.payload };

        // other cases
        default:
            return state;
    }
};
export default EnrollmentReducer;
