import React, { useEffect, useRef } from "react";
import Index from "../../layout";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getParentAssignmentAction } from "../../../../redux/actions/ParentPortal/assignmentAction";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import view from "../../../../assets/icons/eyegreen.png";
import StudentIcon from "../../../../assets/icons/student.png";
import ButtonComponent from "../../../../components/Fields/Button/CustomButton";
import Dropblack from "../../../../assets/icons/dropblack.png";
import { Menu } from "primereact/menu";
import { getParentStudentsAction } from "../../../../redux/actions/ParentPortal/parentAction";
import CardHeader from "../../../../pages/CardHeader";
import AssignmentsIcon from "../../../../assets/icons/Assignments.png";

const Assignments = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getParentAssignmentAction());
        dispatch(getParentStudentsAction());
    }, [dispatch]);

    const studentFilter = useRef(null);

    const assignmentList = useSelector((state) => state?.assignment?.getParentAssignment);
    const students = useSelector((state) => state?.parent?.getParentStudents);

    const ActionTemplate = (r) => {
        return (
            <>
                <img src={view} width="25px" alt="" onClick={() => history.push(`/parent/view-attendence/${r.id}`)} />
            </>
        );
    };

    const studentList = students?.map((item, i) => ({
        label: item.name,
        kay: i + 1,
        command: () => {
            dispatch(getParentAssignmentAction(item.id));
        },
    }));
    const allLabel = { label: "All", key: 0, command: () => dispatch(getParentAssignmentAction()) };
    studentList.unshift(allLabel);

    return (
        <Index>
            <div className="grid mb-3 border-1 border-round-lg border-300 p-5">
                <CardHeader icon={AssignmentsIcon} heading="Assignments" />
                <div className="md:col-12">
                    <div className="table-top-header border-round-top-xl bg-main p-3 mb-3">
                        <div className="flex justify-content-between">
                            <div className="flex justify-content-between">
                                <ButtonComponent
                                    className="flex align-items-center font-semibold bg-transparent border-none p-0 text-xs relative"
                                    onClick={(e) => studentFilter.current.toggle(e)}
                                >
                                    <img src={StudentIcon} alt="" className="bg-white p-2 border-round-lg" width={30} />
                                    &nbsp; Student &nbsp;
                                    <img src={Dropblack} alt="" className="" width={15} />
                                </ButtonComponent>
                                <Menu model={studentList} popup ref={studentFilter} className="" />
                            </div>
                        </div>
                    </div>

                    <DataTable showGridlines className="text-dark online-lesson" value={assignmentList?.length && assignmentList}>
                        <Column field="user_name" header="Students" style={{ width: "30%" }}></Column>
                        <Column field="event_category_name" header="Event Category" style={{ width: "30%" }}></Column>
                        <Column field="date" header="Lesson Date" style={{ width: "30%" }}></Column>
                        <Column body={ActionTemplate} header="Action" style={{ width: "30%" }} />
                    </DataTable>
                </div>
            </div>
        </Index>
    );
};

export default Assignments;
