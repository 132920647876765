import React, { useState, useEffect, useRef } from "react";
import EmailIcon from "../../assets/icons/newemail.png";
import ChevronLeft from "../../assets/icons/chevronleft.png";
import HistoryIcon from "../../assets/icons/history.png";
import RecipientIcon from "../../assets/icons/recipents.png";
import DropDownComponent from "../../components/Fields/CustomDropdown";
import MultiSelectComponent from "../../components/Fields/CustomMultiSelect";
import InputComponent from "../../components/Fields/InputField";
import EditorComponent from "../../components/Fields/CustomEditor";
import CheckboxComponent from "../../components/Fields/Checkbox";
import ButtonComponent from "../../components/Fields/Button/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Eyeblack from "../../assets/icons/blackEye.png";
import DeleteIcon from "../../assets/icons/delete.png";
// import { AllEmailDropDownAction, sendCalendarEventEmail } from "../../../redux/actions/emailAction";
import { Dialog } from "primereact/dialog";
import { adjustTime } from "../../utils/commonFunctions";
import RadioButtonComponent from "../../components/Fields/Radio";
import { AllEmailDropDownAction, sendCalendarEventEmail } from "../../redux/actions/TeacherPortal/studentAction";
import CustomFilesInput from "../../components/Fields/CustomFilesInput";
import CustomCalendar from "../../components/Fields/CustomCalendar";
import Index from "../../portals/Teacher-dashboard/layout";
import DropBlackIcon from "../../assets/icons/dropblack.png";
import { Menu } from "primereact/menu";
import { ViewTemplateAction, getEmailTemplateAction } from "../../redux/actions/TeacherPortal/emailTempalteAction";
import types from "../../redux/types";
import NewCustomTemplate from "./NewCustomTemplate";

const EmailComponent = ({ emailData, setEmailData, selected, fromOption, selectedMessage, id }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const templateRef = useRef(null);
    const [addRecipient, setAddRecipient] = useState(false);

    useEffect(() => {
        setActive(false);
        dispatch(AllEmailDropDownAction());
    }, []);
    useEffect(() => {
        if (!addRecipient) {
            setEmailData({
                ...emailData,
                extra_recipients: [],
            });
        }
    }, [addRecipient]);

    const allEmails = useSelector((state) => state?.teacherStudent?.getEmails);

    const toOption =
        allEmails
            ?.filter((item) => item.email)
            ?.map((item) => {
                return { label: `${item?.name}<${item?.email}>`, email: item?.email };
            }) || [];

    useEffect(() => {
        if (selected) {
            setEmailData({
                ...emailData,
                to: selected,
            });
        }
    }, []);

    const [message, setMessage] = useState("");

    useEffect(() => {
        if (selectedMessage) {
            setMessage(selectedMessage);
        }
    }, [selectedMessage]);

    const [attachments, setAttachments] = useState([]);

    const [err, setErr] = useState({});
    const validateForm = () => {
        let errors = {};
        if (!emailData.from || Object.keys(emailData.from).length === 0) {
            errors.from = "From  is required ";
        }
        if (emailData?.to?.length === 0) {
            errors.to = "To  is required ";
        }
        if (!emailData?.subject) {
            errors.subject = "Please Provide a subject for this message";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const [openPreview, setOpenPreview] = useState(false);
    const handleChange = (e) => {
        const { name } = e.target;
        if (name == "scheduled_time") {
            const time = adjustTime(e);
            setEmailData({
                ...emailData,
                [name]: time,
            });
        } else {
            setEmailData({
                ...emailData,
                [name]: e.value,
            });
        }
        setErr({ ...err, [name]: "" });
    };
    const handleChange1 = (e) => {
        const { name, value } = e.target;

        if (name == "extra_recipients" && addRecipient) {
            const splitStrings = value.split(",");
            setEmailData({
                ...emailData,
                [name]: splitStrings,
            });
        } else {
            setEmailData({
                ...emailData,
                [name]: value,
            });
        }
        setErr({ ...err, [name]: "" });
    };
    const handleCheck = (name, e) => {
        setEmailData({ ...emailData, [name]: !emailData[name] });
    };

    const handlefileChange = (files) => {
        files.some((file) => {
            if (attachments.findIndex((f) => f.name === file.name) === -1) {
                attachments.push(file);
            }
        });
        setAttachments([...attachments]);
    };
    const handleFiles = (e) => {
        const chossenFile = e.target.files;
        handlefileChange(Object.values(chossenFile));
    };
    const onDelete = (id) => {
        setAttachments((prevState) => prevState.filter((item, index) => index !== id));
    };

    const handleSend = (e) => {
        const form = validateForm();
        e.preventDefault();
        if (form) {
            dispatch(sendCalendarEventEmail(emailData, message, attachments, history));
        }
    };

    const handleCancel = () => {
        history.goBack();
    };

    const [inactive, setInActive] = useState(false);

    const handleRecipient = () => {
        setAddRecipient(!addRecipient);
        setActive(!active);
    };

    const handleActive = () => {
        setInActive(!inactive);
    };

    const [active, setActive] = useState(true);

    const [openEmailCustomTemplate, setopenEmailCustomTemplate] = useState();
    const [edit, setEdit] = useState(null);

    useEffect(() => {
        dispatch(getEmailTemplateAction(null, "custom"));
        return () => {
            dispatch({
                type: types.VIEW_TEMPLATE,
                payload: {},
            });
        };
    }, [dispatch]);

    const CustomEmailTemplateData = useSelector((state) => state?.emailTemplate?.getEmailTemplates);

    const templatesList = CustomEmailTemplateData?.map((item, i) => ({
        label: item.title,
        key: i + 1,
        command: () => {
            dispatch(ViewTemplateAction(item.id));
        },
    }));
    const viewTemplate = useSelector((state) => state?.emailTemplate?.ViewTemplate);

    useEffect(() => {
        if (Object.keys(viewTemplate)?.length) {
            setMessage(viewTemplate?.message);
        }
    }, [message, viewTemplate]);

    const item1 = {
        label: "Save New Template",
        icon: "pi pi-plus",
        command: () => {
            setopenEmailCustomTemplate(true);
        },
    };
    const item2 = { separator: true };
    templatesList.unshift(item1, item2);

    return (
        <>
            <Index>
                <div className="p-5">
                    <h2 className="flex align-items-center mb-3">
                        <img src={ChevronLeft} className="cursor-pointer" alt="" width={10} onClick={handleCancel} />
                        <img src={EmailIcon} alt="" width={30} height={30} className="ml-2" />
                        &nbsp; &nbsp;
                        <span className=" flex text-main text-2xl open-sans font-semibold">
                            {id ? "Resend Email" : "New Email"}
                        </span>
                    </h2>
                    <div className="flex justify-content-between mb-3">
                        <div className="flex">
                            <div
                                className={`text-center border border-round-lg p-3 `}
                                onClick={() => history.push("/teacher/message-history")}
                            >
                                <img src={HistoryIcon} alt="" width={30} />
                                <p className="text-graylight-color">Message History</p>
                            </div>
                            <div
                                className={`text-center border border-round-lg p-3 ml-3 cursor-pointer ${
                                    active ? "bg-gray-light text-black" : "text-graylight-color"
                                }`}
                                onClick={handleRecipient}
                            >
                                <img src={RecipientIcon} alt="" width={25} />
                                <p>Extra Recipients</p>
                            </div>
                        </div>
                        {/* <div
                            className={`text-center border border-round-lg p-3  ml-3 ${
                                inactive ? "show-inactive active text-900" : "text-graylight-color"
                            }`}
                            onClick={handleActive}
                        >
                            <i className={`pi pi-ban text-lg  ${inactive ? "text-red-500" : "text-400"}`}></i>
                            <p className="">Show inactive</p>
                        </div> */}
                    </div>
                    <div className="border border-round-lg">
                        <div className="p-5">
                            <DropDownComponent
                                label="From"
                                extraClassName="mb-3"
                                required
                                labelClassName=""
                                name="from"
                                optionLabel="item"
                                options={fromOption}
                                onChange={handleChange}
                                value={emailData?.from}
                                errorMessage={err?.from}
                            />
                            <div className="flex ">
                                <MultiSelectComponent
                                    extraClassName="w-11 pr-0 py-0"
                                    label="To"
                                    inputClass="border-noround-right  current-attendance"
                                    display="chip"
                                    name="to"
                                    required
                                    optionLabel="label"
                                    filter
                                    value={toOption?.length ? emailData?.to && emailData?.to : []}
                                    options={toOption}
                                    onChange={handleChange}
                                    errorMessage={err?.to}
                                />

                                <DropDownComponent
                                    extraclassname="p-0"
                                    inputClass="bg-main bg-green1 text-dark1 border-noround-left group-dropdown"
                                    placeholder="Select"
                                />
                            </div>
                            {addRecipient && (
                                <>
                                    <InputComponent
                                        label="Extra Recipients"
                                        extraClassName=" w-full mt-3 "
                                        name="extra_recipients"
                                        onChange={handleChange1}
                                        value={emailData?.extra_recipients}
                                    />
                                    <small className="">Extra recipients are automatically BCCed.</small>
                                </>
                            )}
                            <InputComponent
                                label="Subject"
                                extraClassName="mt-3 mb-3"
                                name="subject"
                                onChange={handleChange1}
                                value={emailData?.subject}
                                errorMessage={err?.subject}
                            />
                            <div className="flex justify-content-between ">
                                <p className="text-lg font-semibold mb-2">Message</p>
                                <div
                                    className=""
                                    onClick={(event) => templateRef.current.toggle(event)}
                                    aria-controls="popup_menu_left"
                                    aria-haspopup
                                >
                                    <span className="font-bold cursor-pointer ">Templates</span>&nbsp;&nbsp;
                                    <img src={DropBlackIcon} alt="" className="" />
                                </div>
                                <div className="absolute">
                                    <Menu
                                        model={templatesList}
                                        popup
                                        ref={templateRef}
                                        popupAlignment="left"
                                        // id="popup_menu_left"
                                        // className={"template-dropdown view-menu absolute "}
                                    />
                                </div>
                            </div>
                            <EditorComponent
                                style={{ height: "200px" }}
                                onTextChange={(e) => setMessage(e.htmlValue)}
                                value={message}
                            />
                            <CheckboxComponent
                                label="Graphical Mode"
                                extraClassName="mb-3 mt-3"
                                name="graphical_mode"
                                checked={emailData?.graphical_mode}
                                onChange={(event) => handleCheck("graphical_mode", event)}
                            />
                            <div className="flex mb-2">
                                <p className="font-semibold w-1">Options</p>
                                <CheckboxComponent
                                    label="CC Me- Send me a copy of this email"
                                    name="cc_me"
                                    checked={emailData?.cc_me}
                                    onChange={(event) => handleCheck("cc_me", event)}
                                />
                            </div>
                            {/* <div className="flex">
                                <p className="font-semibold w-1">Send At</p>
                                <div>
                                    <RadioButtonComponent
                                        extraClassName="mb-2"
                                        label="Immediately - Send this email as soon as possible"
                                        name="send_at"
                                        value="immediately"
                                        onChange={handleChange}
                                        checked={emailData?.send_at === "immediately"}
                                    />
                                    <RadioButtonComponent
                                        extraClassName="mb-2"
                                        label="Scheduled - Send this email at a further date/time"
                                        name="send_at"
                                        value="scheduled"
                                        onChange={handleChange}
                                        checked={emailData?.send_at === "scheduled"}
                                    />
                                </div>
                            </div> */}
                            {emailData?.send_at == "scheduled" && (
                                <div className="grid mt-2">
                                    <CustomCalendar
                                        onChange={handleChange}
                                        name="scheduled_date"
                                        label="Date"
                                        extraclassname="md:col-3"
                                        value={emailData.scheduled_date}
                                    />
                                    <CustomCalendar
                                        label="Time"
                                        extraclassname="md:col-3"
                                        name="scheduled_time"
                                        value={emailData?.scheduled_time}
                                        onChange={handleChange}
                                        icon="pi pi-clock"
                                        showIcon
                                        required
                                        timeOnly
                                        stepMinute={5}
                                        hourFormat="12"
                                    />
                                </div>
                            )}
                            <p className="font-semibold mb-2">Attachments</p>
                            <div className="flex">
                                <CustomFilesInput
                                    label="+ Add File"
                                    name="file"
                                    extraClassName="bg-light-green ml-2 mb-2"
                                    multiple
                                    onChange={handleFiles}
                                />
                            </div>
                            <div className="mb-3 mt-3 md:col-12">
                                {attachments?.length ? (
                                    <div className="p-2 mb-3 border-round-lg overflow-hidden">
                                        <div className="grid bg-main align-items-center p-2 border-bottom-1 border-300 ">
                                            <div className="md:col-3 text-sm open-sans font-semibold">Attached File</div>

                                            <div className="md:col-3 text-sm open-sans font-semibold">File size</div>
                                            <div className="md:col-3 text-sm open-sans font-semibold">Actions</div>
                                        </div>
                                        {Object.values(attachments).map((file, i) => (
                                            <div className="grid bg-whitesmoke align-items-center p-2">
                                                <div className="md:col-3 text-main-color  ">
                                                    <img src={File} alt="" width="15" />
                                                    {file?.name}
                                                </div>

                                                <div className="md:col-3 text-main-color ">{parseInt(file?.size / 1024)}kb</div>

                                                <div className="md:col-3 text-main-color ">
                                                    <img
                                                        src={Eyeblack}
                                                        alt=""
                                                        width="19px"
                                                        height="19px"
                                                        className="product-image m-1"
                                                        onClick={() => window.open(URL.createObjectURL(file))}
                                                    />
                                                    <img
                                                        className="pointer ml-2"
                                                        onClick={() => onDelete(i)}
                                                        src={DeleteIcon}
                                                        alt=""
                                                        width="15"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="bg-light-dark p-5 flex justify-content-between">
                            <div>
                                <ButtonComponent label="Send" className="ml-3" onClick={handleSend} />
                                <ButtonComponent label="Cancel" className="bg-gray-light ml-2" onClick={handleCancel} />
                            </div>
                            <ButtonComponent className="bg-purple text-white" onClick={() => setOpenPreview(true)}>
                                <i className="pi pi-eye text-sm mr-2"></i> Preview
                            </ButtonComponent>
                        </div>
                    </div>
                </div>
                {openEmailCustomTemplate && (
                    <NewCustomTemplate
                        openEmailCustomTemplate={openEmailCustomTemplate}
                        setopenEmailCustomTemplate={setopenEmailCustomTemplate}
                        edit={edit}
                        setEdit={setEdit}
                        viewTemplate={viewTemplate}
                    />
                )}
                <Dialog
                    header={"Email Preview"}
                    className="assign-student border-round-lg overflow-hidden "
                    visible={openPreview}
                    onHide={() => {
                        setOpenPreview(false);
                    }}
                    style={{ width: "48vw" }}
                    draggable={false}
                    resizable={false}
                >
                    <form className="p-3">
                        <div className="grid mb-3 ">
                            <p className="font-bold  lg:col-2">Subject</p>
                            <div className="lg:col-9">
                                <p>{emailData?.subject}</p>
                            </div>
                        </div>
                        <div className="grid">
                            <p className="font-bold lg:col-2">Message</p>

                            <div className="lg:col-9">
                                <div
                                    className="border-1 border-400 p-3 "
                                    dangerouslySetInnerHTML={{ __html: message }}
                                    style={{ minHeight: "150px" }}
                                ></div>
                            </div>
                        </div>
                        <div className="flex justify-content-end mt-2">
                            <ButtonComponent
                                label="Close"
                                className="bg-gray-light ml-2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpenPreview(false);
                                }}
                            ></ButtonComponent>
                        </div>
                    </form>
                </Dialog>
            </Index>
        </>
    );
};

export default EmailComponent;
