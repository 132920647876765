import React from "react";
import CustomCalendar, {
    CheckboxComponent,
    CustomCalendarTime,
    DropDownComponent,
    InputComponent1,
    MultiSelectComponent,
    NumberComponent,
    PhoneNumberComponent,
    RadioButtonComponent,
} from "./AllInputs";
import InputGroup from "../components/Fields/InputGroup";
import { SkillLevel } from "./constants1";
import { Days, InstrumentPurchaseType } from "../utils/constants";

export default function Form2({ data, handleChange, instrument }) {
    return (
        <>
            <div className="p-3 w-full">
                <div className="grid w-full">
                    <InputComponent1
                        label="First Name"
                        extraClassName="col-12 md:col-6"
                        labelClassName="relative left-0 text-dark text-base"
                        placeholder="First name"
                        required
                        name="first_name"
                        value={data?.first_name}
                        onChange={handleChange}
                        errorMessage={data?.formErrors?.first_name}
                    />
                    <InputComponent1
                        label="Last Name"
                        extraClassName="col-12 md:col-6"
                        labelClassName="relative left-0 text-dark text-base"
                        placeholder="Last name"
                        required
                        name="last_name"
                        value={data?.last_name}
                        onChange={handleChange}
                        errorMessage={data?.formErrors?.last_name}
                    />

                    <DropDownComponent
                        label="Skill level"
                        extraClassName="col-12 md:col-6"
                        labelClassName="relative left-0 text-dark text-base mb-0"
                        placeholder=""
                        required
                        options={SkillLevel}
                        name="skill_level"
                        optionLabel="name"
                        value={data?.skill_level}
                        onChange={handleChange}
                        errorMessage={data?.formErrors?.skill_level}
                    />
                    <InputComponent1
                        label="Email"
                        extraClassName="col-12 md:col-6"
                        labelClassName="relative left-0 text-dark text-base"
                        placeholder="Enter email"
                        required
                        name="email"
                        value={data?.email}
                        onChange={handleChange}
                        errorMessage={data?.formErrors?.email}
                    />
                    <NumberComponent
                        label="Age"
                        extraClassName="col-12 md:col-6"
                        labelClassName="relative left-0 text-dark text-base"
                        placeholder="Enter age"
                        required
                        value={data?.age}
                        readOnly
                        errorMessage={data?.formErrors?.age}
                    />
                    <PhoneNumberComponent
                        label="Phone number"
                        size="col-12 md:col-6"
                        labelClassName="relative text-dark text-base"
                        placeholder="Enter phone number"
                        inputClass="py-0 h-full phone-input-number"
                        country={"us"}
                        name="phone_number"
                        onChange={handleChange}
                        value={data?.phone_number}
                        errorMessage={data?.formErrors?.phone_number}
                        // inputClassName="h-full"
                        required
                    />
                    <div className="col-12 grid m-0 p-0">
                        <label className={`text-base m-0 font-semibold p-0 text-dark open-sans form-label col-12 p-0`}>
                            Select Preferred Day & Time <span className="p-error">*</span>
                        </label>
                        <MultiSelectComponent
                            label=""
                            extraClassName="col-12 md:col-6 py-0"
                            placeholder="Select Preferred Day*"
                            name="day"
                            options={Days}
                            optionLabel="name"
                            value={data?.day}
                            onChange={handleChange}
                            errorMessage={data?.formErrors?.day}
                        />

                        <CustomCalendarTime
                            size="col-12 md:col-6 py-0"
                            className="w-full"
                            showIcon
                            icon={() => <i className="pi pi-clock" />}
                            name="time"
                            placeholder="Select Preferred Time*"
                            value={data?.time}
                            onChange={handleChange}
                            errorMessage={data?.formErrors?.time}
                        />
                    </div>
                </div>

                <div className="bg-main p-3 border-round-lg my-4">
                    <p className="font-semibold text-black-alpha-90	">Instrument Purchase Program</p>
                </div>
                <div className="grid w-full">
                    <div className="col-12 md:col-6">
                        <div className="flex gap-3">
                            <p className="font-semibold">Do you need Instrument?</p>
                            <RadioButtonComponent
                                label="Yes"
                                name="instrument_needed"
                                value="yes"
                                checked={data?.instrument_needed === "yes"}
                                onChange={handleChange}
                            />
                            <RadioButtonComponent
                                label="No"
                                name="instrument_needed"
                                value="no"
                                checked={data?.instrument_needed === "no"}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                {data?.instrument_needed === "yes" && (
                    <>
                        <div className="grid mt-2">
                            <>
                                <InputComponent1
                                    label="Select Instrument"
                                    labelClassName="relative left-0 text-dark text-base mb-0 font-semibold"
                                    extraClassName="col-12 md:col-4 relative z-3"
                                    optionLabel="name"
                                    value={instrument?.name}
                                    readOnly
                                />
                                <DropDownComponent
                                    name="type"
                                    label="Purchase type"
                                    labelClassName="relative left-0 text-dark text-base mb-0 font-semibold"
                                    extraClassName="col-12 md:col-4 relative z-3"
                                    optionLabel="name"
                                    options={InstrumentPurchaseType}
                                    value={data?.type}
                                    onChange={handleChange}
                                    errorMessage={data?.formErrors?.type}
                                />
                                <div className="col-12 md:col-4">
                                    <label className="relative left-0 text-dark text-base mb-0 font-semibold">Amount</label>
                                    <InputGroup dollar="dollar" size="">
                                        <NumberComponent
                                            extraClassName="w-full"
                                            name="amount"
                                            dollar="dollar"
                                            disabled
                                            value={
                                                data?.type === "rent"
                                                    ? instrument?.monthly_installment
                                                    : data?.type === "purchase"
                                                    ? instrument?.purchase_amount
                                                    : 0
                                            }
                                            readOnly
                                        />
                                    </InputGroup>
                                </div>
                            </>
                        </div>

                        <p className="text-xs font-bold text-900 mb-3 open-sans">*If you need to rent an instrument, a link will be sent to you on your enrollment confirmation email. </p>
                        <p className="font-bold text-900 mb-3">$30.00 Registration Fee (Which also covers lesson materials needed)</p>
                    </>
                )}
            </div>
        </>
    );
}
