import StudentDashboard from "../portals/Student-dashboard/Student/Home/Home.jsx";
import StudentCalendar from "../portals/Student-dashboard/Student/Calendar/Calendar.jsx";
import StudentNews from "../portals/Student-dashboard/Student/News/News.jsx";
import StudentAttendenceAndNotes from "../portals/Student-dashboard/Student/Attendence&Notes/AttendenceAndNotes.jsx";
import StudentPracticeLog from "../portals/Student-dashboard/Student/PracticeLog/PracticeLog.jsx";
import StudentMessageHistory from "../portals/Student-dashboard/Student/MessageHistory/MessageHistory.jsx";
import ProfileSettings from "../portals/Student-dashboard/Student/Settings/Settings.jsx";
import TeacherDashboard from "../portals/Teacher-dashboard/Teacher/Home/Home.jsx";
import ChangePassword from "../portals/parents-dashboard/parent/ChangePassword.js";
import Account from "../portals/parents-dashboard/parent/Account&Invoice/Account.jsx";
import ParentDashboard from "../portals/parents-dashboard/parent/Home/Home.jsx";
import StudentList from "../portals/parents-dashboard/parent/Student/StudentList.jsx";
import Attendance from "../portals/parents-dashboard/parent/attandance&notes/index.jsx";
import Calendar from "../portals/parents-dashboard/parent/Calendar/Calendar.jsx";
import ContactList from "../portals/parents-dashboard/parent/ContactInfo/ContactList.jsx";
import News from "../portals/parents-dashboard/parent/News/News.jsx";
import MessageHistory from "../portals/parents-dashboard/parent/MessageHistory/MessageHistory.jsx";
import GroupAttendance from "../portals/Teacher-dashboard/Teacher/Home/Attendance/GroupAttendance.jsx";
import SingleAttendance from "../portals/Teacher-dashboard/Teacher/Home/Attendance/SingleAttendance.jsx";
import TeacherList from "../portals/Teacher-dashboard/Teacher/Home/Teacher/TeacherList.jsx";
import Assignments from "../portals/parents-dashboard/parent/Assignments/Assignments.jsx";
import StudentDetail from "../portals/parents-dashboard/parent/Student/StudentsDetail.js";
import RecordPracticeLog from "../portals/Student-dashboard/Student/PracticeLog/RecordPracticeLog.jsx";
import TeacherCalendar from "../portals/Teacher-dashboard/Teacher/Home/Calendar/Calendar.js";
import TeacherNews from "../portals/Teacher-dashboard/Teacher/Home/News/News.js";
import TeacherSettings from "../portals/Teacher-dashboard/Teacher/Settings/Settings.jsx";
import TeacherAssignments from "../portals/Teacher-dashboard/Teacher/Home/Assignments/Assignments.jsx";
import TeacherCompensation from "../portals/Teacher-dashboard/Teacher/Home/Compensation/Compensation.jsx";
import ParentPracticeLog from "../portals/parents-dashboard/parent/PracticeLog/ParentPracticeLog.jsx";
import TeacherAttendenceAndNotes from "../portals/Teacher-dashboard/Teacher/Home/AttendenceAndNotes/AttendenceContentList.jsx";
import TeacherStudentContentList from "../portals/Teacher-dashboard/Teacher/Home/Students/StudentContentList.jsx";
import ViewAssignment from "../portals/parents-dashboard/parent/Assignments/ViewAssignment.jsx";
import ViewTeacherAssignment from "../portals/Teacher-dashboard/Teacher/Home/Assignments/ViewTeacherAssignment.jsx";
import StudentView from "../portals/Teacher-dashboard/Teacher/Home/Students/StudentView.jsx";
import AddStudent from "../portals/parents-dashboard/parent/Student/addStudent.jsx";
import ViewStudent from "../portals/parents-dashboard/parent/Student/ViewStudent.jsx";
import ParentSetting from "../portals/parents-dashboard/parent/Settings/Settings.jsx";
import StudentMessaging from "../portals/Teacher-dashboard/Teacher/Messaging/StudentMessaging.js";
import AllMessageHistory from "../portals/Teacher-dashboard/Teacher/Messaging/AllMessageHistory.js";
import CalendarEmail from "../portals/Teacher-dashboard/Teacher/Home/Calendar/CalendarEmail.js";
import EditEvent from "../portals/Teacher-dashboard/Teacher/Home/Calendar/EditEvent.js";
import ResendMessaging from "../portals/Teacher-dashboard/Teacher/Home/SendEmails/ResendMessaging.js";
import TeacherMessaging from "../portals/Teacher-dashboard/Teacher/Home/Teacher/TeaherMessing.js";
import Schedule from "../portals/Teacher-dashboard/Teacher/Home/Schedule/Schedule.jsx";
import AddSchedule from "../portals/Teacher-dashboard/Teacher/Home/Schedule/AddSchedule.jsx";
import SetSchedules from "../portals/Teacher-dashboard/Teacher/Home/Schedule/SetSchedules.jsx";
import CalanderSchedule from "../portals/Teacher-dashboard/Teacher/Home/Schedule/CalanderSchedule.jsx";
import ViewSchedules from "../portals/Teacher-dashboard/Teacher/Home/Schedule/ViewSchedules.jsx";

export const StudentRoutes = [
    {
        path: "/student/dashboard",
        name: "Dashboard1",
        component: StudentDashboard,
        exact: true,
    },
    {
        path: "/student/calendar",
        name: "Student Calendar",
        component: StudentCalendar,
        exact: true,
    },
    {
        path: "/student/news",
        name: "Student News",
        component: StudentNews,
        exact: true,
    },
    {
        path: "/student/attendence-notes",
        name: "Student Attendence & Notes",
        component: StudentAttendenceAndNotes,
        exact: true,
    },
    {
        path: "/student/practice-logs",
        name: "Practice Log",
        component: StudentPracticeLog,
        exact: true,
    },
    {
        path: "/student/practice-logs/record",
        name: "Practice Log",
        component: RecordPracticeLog,
        exact: true,
    },
    {
        path: "/student/notifications",
        name: "Practice Message Hostory",
        component: StudentMessageHistory,
        exact: true,
    },
    {
        path: "/student/profile-settings",
        name: "Practice Message Hostory",
        component: ProfileSettings,
        exact: true,
    },
];

export const TeacherRoutes = [
    {
        path: "/teacher-dashboard/",
        name: "TeacherDashboard",
        component: TeacherDashboard,
        exact: true,
    },
    {
        path: "/teacher/calendar",
        name: "TeacherCalendar",
        component: TeacherCalendar,
        exact: true,
    },
    {
        path: "/teacher/schedule",
        name: "Teacher Schedule",
        component: Schedule,
        exact: true,
    },
    {
        path: "/teacher/schedule/add",
        name: "Teacher Schedule",
        component: AddSchedule,
        exact: true,
    },
    {
        path: "/teacher/schedule/edit/:id",
        name: "Teacher Schedule",
        component: AddSchedule,
        exact: true,
    },
    // {
    //     path: "/teacher/schedule/setup/:id",
    //     name: "Teacher Schedule",
    //     component: SetSchedules,
    //     exact: true,
    // },
    {
        path: "/teacher/schedule/setup/:id",
        name: "Teacher Schedule",
        component: CalanderSchedule,
        exact: true,
    },
    {
        path: "/teacher/schedule/view/:id",
        name: "Teacher Schedule",
        component: ViewSchedules,
        exact: true,
    },
    {
        path: "/teacher/assignments",
        name: "TeacherAssignments",
        component: TeacherAssignments,
        exact: true,
    },
    {
        path: "/teacher/attandance-notes",
        name: "TeacherAttendence",
        component: TeacherAttendenceAndNotes,
        exact: true,
    },
    {
        path: "/teacher/compensation",
        name: "TeacherCompensation",
        component: TeacherCompensation,
        exact: true,
    },
    {
        path: "/teacher/view-attendence/:id",
        exact: true,
        component: ViewTeacherAssignment,
    },
    {
        path: "/teacher/news",
        name: "TeacherNews",
        component: TeacherNews,
        exact: true,
    },
    {
        path: "/teacher/settings",
        name: "TeacherSettings",
        component: TeacherSettings,
        exact: true,
    },
    {
        path: "/teacher/students",
        name: "TeacherStudents",
        component: TeacherStudentContentList,
        exact: true,
    },
    {
        path: "/teacher/students/student-detail/:id",
        name: "StudentDetail",
        component: StudentView,
        exact: true,
    },

    {
        path: "/teacher-dashboard/teacher-attendence/:id",
        name: "TeacherAttendence",
        component: GroupAttendance,
        exact: true,
    },

    {
        path: "/teacher-dashboard/single-attandance/:eventId/:studentId",
        name: "SingleAttandance",
        component: SingleAttendance,
        exact: true,
    },
    {
        path: "/teachers/teacher",
        name: "TeacherTab",
        component: TeacherList,
        exact: true,
    },
    {
        path: "/teacher/message",
        name: "StudentEmail",
        component: StudentMessaging,
        exact: true,
    },
    {
        path: "/teacher/teacher/message",
        name: "StudentEmail",
        component: TeacherMessaging,
        exact: true,
    },
    {
        path: "/teacher/message-history",
        name: "Message History",
        component: AllMessageHistory,
        exact: true,
    },
    {
        path: "/teacher/calendar/new-email/:id",
        name: "Email",
        component: CalendarEmail,
        exact: true,
    },
    {
        path: "/teacher/calendar/edit-calendar-event/:id",
        name: "EditEvent",
        component: EditEvent,
        exact: true,
    },
    {
        path: "/teacher/send-emails/resend/:id",
        name: "ResendEmail",
        component: ResendMessaging,
        exact: true,
    },
];
export const ParentRoutes = [
    {
        path: "/change-password",
        exact: true,
        component: ChangePassword,
    },

    {
        path: "/parent/dashboard",
        component: ParentDashboard,
        exact: true,
    },
    {
        path: "/parent/attandance-notes",
        component: Attendance,
        exact: true,
    },
    {
        path: "/parent/view-attendence/:id",
        exact: true,
        component: ViewAssignment,
    },
    {
        path: "/parent/practice-logs",
        component: ParentPracticeLog,
        exact: true,
    },
    {
        path: "/parent/students/add-student",
        exact: true,
        component: AddStudent,
    },
    {
        path: "/parent/students/view/:id",
        exact: true,
        component: ViewStudent,
    },
    {
        path: "/parent/students",
        exact: true,
        component: StudentList,
    },
    {
        path: "/parent/students/edit/:id",
        exact: true,
        component: StudentDetail,
    },

    // {
    //     path: "/parent/account-invoice/",
    //     exact: true,
    //     component: Account,
    // },
    {
        path: "/parent/assignments",
        exact: true,
        component: Assignments,
    },
    {
        path: "/parent/calendar",
        exact: true,
        component: Calendar,
    },
    {
        path: "/parent/contact",
        exact: true,
        component: ContactList,
    },
    {
        path: "/parent/news",
        exact: true,
        component: News,
    },
    {
        path: "/parent/message-history",
        exact: true,
        component: MessageHistory,
    },
    {
        path: "/parent/profile-settings",
        exact: true,
        component: ParentSetting,
    },
];
