import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ParentloginAction } from "../../redux/actions/loginAction";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../services/auth";
var base64 = require("base-64");

const initialState = {
    email: "",
    password: "",
};

const ParentLogin = (location) => {
    const history = useHistory();
    useEffect(() => {
        document.documentElement.style.fontSize = 14 + "px";
        if (isAuthenticated()) {
            history.push("parent/dashboard");
        }
    }, [history]);

    const dispatch = useDispatch();
    const [parentData, setParentData] = useState(initialState);

    useEffect(() => {
        const rememberMe = localStorage.getItem("parentrememberMe") === "true";
        const email = rememberMe ? localStorage.getItem("parentEmail") : "";
        const password = rememberMe ? localStorage.getItem("parentDetail") : "";
        if (localStorage.getItem("parentrememberMe") === "false") {
            localStorage.removeItem("parentEmail");
            localStorage.removeItem("parentDetail");
            setParentData({
                email: "",
                password: "",
                parentrememberMe: false,
            });
        }

        setParentData({
            email: email,
            password: base64.decode(password),
            parentrememberMe: rememberMe,
        });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            dispatch(ParentloginAction(parentData, history, "PARENT", location));
        }
    };
    const [err, setErr] = useState({});
    const validateForm = () => {
        let errors = {};
        if (!parentData?.email) {
            errors.email = "Email is required ";
        }
        if (!parentData?.password) {
            errors.password = "Password is required ";
        }

        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        if (name === "parentrememberMe") {
            setParentData({ ...parentData, [name]: checked });
        } else {
            setParentData({ ...parentData, [name]: value });
            setErr({ ...err, [name]: "" });
        }
    };

    return {
        parentData,
        handleSubmit,
        handleChange,
        err,
    };
};

export default ParentLogin;
