import React from "react";

export default function CustomFilesInput({ label, name, files, onChange, onDelete, inputClass, extraClassName, ...props }) {
    return (
        <>
            <div className={`bg-main text-center p-2 px-3 border-round-lg cursor-pointer ${extraClassName}`}>
                <label htmlFor="files" className="m-0 open-sans text-black font-semibold cursor-pointer">
                    {label}
                </label>
                <input
                    name={name}
                    onClick={(e) => (e.target.value = "")}
                    type="file"
                    id="files"
                    className={`w-full ${inputClass} `}
                    onChange={onChange}
                    hidden
                    {...props}
                />
            </div>
        </>
    );
}
