import React from "react";

export default function InputGroup({ size, children, errorMessage, min, dollar }) {
    return (
        <div className={size}>
            <div className="p-inputgroup relative w-full">
                {dollar && <span className="p-inputgroup-addon p-0 border-400 bg-white-color open-sans">$</span>}
                {children}
                {min && <span className="p-inputgroup-addon border-left-none border-400 p-1 bg-white-color open-sans">Min</span>}
                {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
            </div>
        </div>
    );
}
