import React,{useEffect} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Socialgroup from "../../../../assets/icons/group.png";
import DropDownComponent from "../../../../components/Fields/CustomDropdown";
import ButtonComponent from "../../../../components/Fields/Button/CustomButton";
import { useDispatch,useSelector } from "react-redux";
import { getTeacherOverDues } from "../../../../redux/actions/TeacherPortal/teacherAction";
import Edit from "../../../../assets/icons/edit.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SelectName } from "../../../../utils/constants";
import { useState } from "react";
import { SetAllUnrecordedAction } from "../../../../redux/actions/TeacherPortal/attendanceAction";


const OverdueAttandence = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedRow,setSelectedRow] = useState([])
    const[record,setSelectedRecord] = useState("")
    
    useEffect(() => {
      dispatch(getTeacherOverDues());
    }, [dispatch]);

    const DateTemplate=(r)=>{
        return<>{r.date}  &nbsp;{r.format_start_time
        }</>
    }

    const ActionBodyTemplate=(rowData)=>{
        return<><img src ={Edit} alt="edit" width="20px" onClick={() => history.push(`/teacher-dashboard/teacher-attendence/${rowData?.id}`)} /></>
    }
  
    const overdueAttandence = useSelector((state) => state.teacher.overdueAttandence);

    const handleSave = () => {
        dispatch(SetAllUnrecordedAction(selectedRow, record,setSelectedRecord));
    };

    return <><div className="grid">
        <div className="md:col-12 border-1 border-round-lg border-300 p-3 mb-3 ">
            <h3 className="open-sans p-3 py-2 font-semibold m-0 text-lg">
                <img src={Socialgroup} alt="" width={30} /> Overdue Attendance
            </h3>
            <p className="text-lg text-dark-gray p-3 py-2 open-sans">
                These are events that have passed in the last 30 days and don’t
                have attendance assigned
            </p>
            <DataTable
                value={overdueAttandence}
                paginator
                className="bg-light-main"
                stripedRows
                rows={5}
                scrollable
                scrollHeight="400px"
                selection={selectedRow}
                onSelectionChange={(e) => setSelectedRow(e.value)}
                style={{ minWidth: "50rem" }}
            >
                <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3rem" }}
                ></Column>
                <Column field="current_attendees" header="Student Name "></Column>
                <Column field="date"  body ={DateTemplate}header="Date & Time"></Column>
                <Column field="category_name" header="Event Category"></Column>
                <Column
                    field="action"
                    header="Action"
                    body={ActionBodyTemplate}
                ></Column>
            </DataTable>
            <div className="grid p-3">
                <div className="md:col-5">
                    <p className="text-lg open-sans font-semibold mb-2">
                        Set All Unrecorded
                    </p>
                    <div className="flex justify-content-between">
                        <DropDownComponent
                            inputClass="border-round-lg "
                            extraClassName="w-10"
                            value={record}
                            onChange={(e) => setSelectedRecord(e.value)}
                            options={SelectName}
                            optionLabel="name"
                            placeholder="Please Select Status"
                        />
                        <ButtonComponent
                            label="Save"
                            className="bg-main open-sans font-semibold border-0 px-4 ml-2"
                            onClick={handleSave}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div></>
}
export default OverdueAttandence