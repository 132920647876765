import types from "../../types";
import Constants from "../../../services/constant";
import { showLoaderAction, hideLoaderAction } from "../loadAction";
import { api } from "../../../services/api";
import { showToast } from "../../../utils/showToast";

export const getTeacherAssignmentAction = (id) => async (dispatch) => {

  dispatch(showLoaderAction());
  const url = id ? Constants.END_POINT.TEACHER_ASSIGNMENT + "/" + id : Constants.END_POINT.TEACHER_ASSIGNMENT;
  const res = await api("get", url);

  if (res.success) {
    dispatch({
      type: types.TEACHER_ASSIGNMENT,
      payload: res.data,
    });
  }

  dispatch(hideLoaderAction());
};
export const getTeacherStudentDropDownAction = (id) => async (dispatch) => {

  dispatch(showLoaderAction());

  const res = await api("get", Constants.END_POINT.STUDENT_DROPDOWN);

  if (res.success) {
    dispatch({
      type: types.STUDENT_DROPDOWN,
      payload: res.data,
    });
  }

  dispatch(hideLoaderAction());
};

export const ViewTeacherAssignmentAction = (id) => async (dispatch) => {

  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.VIEW_TEACHER_ASSIGNMENTS + id);

  if (res.success) {
    dispatch({
      type: types.VIEW_TEACHER_ASSIGNMENTS,
      payload: res.data,
    });
  }

  dispatch(hideLoaderAction());
}
export const TeacherFeedbackAction = (data, history) => async (dispatch) => {

  const payload = {
    ...data,
    is_parent: data?.is_parent ? 1 : 0,
    is_student: data?.is_student ? 1 : 0
  }

  dispatch(showLoaderAction());
  const res = await api("post", Constants.END_POINT.TEACHER_FEEDBACK, payload);

  if (res.success) {
    showToast([res.message], "success");
    dispatch(ViewTeacherAssignmentAction(data?.assignment_id));
    history.goBack()
  }

  dispatch(hideLoaderAction());
}
