import React, { useState, useEffect } from "react";
import Index from "../../../layout";
import { TabPanel, TabView } from "primereact/tabview";
import RadioButtonComponent from "../../../../../components/Fields/Radio";
import CheckboxComponent from "../../../../../components/Fields/Checkbox";
import { Accordion, AccordionTab } from "primereact/accordion";
import EditorComponent from "../../../../../components/Fields/CustomEditor";
import InputComponent from "../../../../../components/Fields/InputField";
import ButtonComponent from "../../../../../components/Fields/Button/CustomButton";
import MultiSelectComponent from "../../../../../components/Fields/CustomMultiSelect";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import types from "../../../../../redux/types";
import { addAttendenceAction, viewAttendenceAction } from "../../../../../redux/actions/TeacherPortal/attendanceAction";
import userlogoOne from "../../../../../assets/icons/userlogoOne.png";
import DeleteIcon from "../../../../../assets/icons/delete.png";
import CustomFilesInput from "../../../../../components/Fields/CustomFilesInput";
import Eyeblack from "../../../../../assets/icons/blackEye.png";

const SingleAttendance = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const { eventId, studentId: studId } = useParams();
    const [studentId, setStudentId] = useState(studId);
    const type = "single";

    useEffect(() => {
        dispatch(viewAttendenceAction(eventId, studentId, type));

        return () => {
            dispatch({
                type: types.VIEW_ATTENDENCE,
                payload: {},
            });
        };
    }, [studentId, dispatch]);

    const viewAttendence = useSelector((state) => state?.events?.viewAttandence);

    const handleChangeTabView = (e, id) => {
        setStudentId(id);
    };
    const [editAttandence, setEditAttandence] = useState(false);
    const [notes, setNotes] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [limit, setLimit] = useState(3);
    const [err, setErr] = useState({});

    const [singleAttendenceData, setSingleAttendenceData] = useState({
        event_id: "",
        type: type,
        attendance: "",
        online_resource: "",
        is_billable: false,
        parents: false,
        students: false,
        teachers: false,
        parents_ids: [],
        students_ids: [],
        teachers_ids: [],
    });

    useEffect(() => {
        setActiveIndex(tabIndex);

        setSingleAttendenceData({
            ...singleAttendenceData,
            ["attendance"]: viewAttendence?.attendance && viewAttendence?.attendance?.attendance[0]?.status,
            ["is_billable"]: viewAttendence?.attendance?.is_billable == 1 ? true : false,
            ["event_id"]: eventId,
            ["students_ids"]: [{ id: studentId }],
        });
        setUrls(viewAttendence?.online_resource);
        setAssignments(viewAttendence?.notes_assignments?.[0]?.assignment);
        setNotes(viewAttendence?.notes_assignments?.[0]?.notes);
    }, [viewAttendence?.attendance, studentId, eventId]);

    useEffect(() => {
        if (viewAttendence?.attendance_attachments) {
            setSelectedFiles(viewAttendence?.attendance_attachments);
        } else {
            setSelectedFiles([]);
        }
    }, [viewAttendence?.attendance_attachments]);

    const handleChange = (e) => {
        const { name } = e.target;
        setSingleAttendenceData((prev) => ({ ...prev, [name]: e.value }));
    };
    const handleNotes = (e) => {
        setNotes(e.htmlValue);
    };
    const handlefileChange = (files) => {
        files.some((file) => {
            if (selectedFiles.findIndex((f) => f.name === file.name) === -1) {
                selectedFiles.push(file);
            }
        });
        setSelectedFiles([...selectedFiles]);
    };

    const handleFiles = (e) => {
        const chossenFile = e.target.files;
        handlefileChange(Object.values(chossenFile));
    };
    const [deletedFileIDs, setDeletedFileIDs] = useState([]);
    const [urlErr, setUrlErr] = useState("");

    const onDelete = (id, type) => {
        if (type === "URL") {
            setUrls((prevState) => prevState.filter((item, index) => index !== id));
        } else {
            setSelectedFiles((prevState) => {
                const filteredFiles = prevState.filter((item, index) => index !== id);
                let deletedID = prevState[id].id;
                if (deletedID !== undefined) {
                    setDeletedFileIDs((prevDeletedIDs) => [...prevDeletedIDs, deletedID]);
                }
                return filteredFiles;
            });
        }
    };

    const handleCheck = (name, e) => {
        if (name === "students") {
            setSingleAttendenceData({
                ...singleAttendenceData,
                [name]: !singleAttendenceData[name],
            });
        }
        setSingleAttendenceData({
            ...singleAttendenceData,
            [name]: !singleAttendenceData[name],
        });
    };
    const handleChange1 = (e) => {
        const { name, value } = e.target;
        if (name === "online_resource") {
            setSingleAttendenceData({ ...singleAttendenceData, [name]: value });
            setUrlErr("");
        }
        setSingleAttendenceData({ ...singleAttendenceData, [name]: value });
    };

    const attendeeOptions = [];

    let obj = {
        id: studentId,
        status: singleAttendenceData?.attendance,
    };
    attendeeOptions?.push(obj);

    const handleSaveAndExit = (e) => {
        e.preventDefault();
        dispatch(
            addAttendenceAction(
                viewAttendence,
                singleAttendenceData,
                selectedFiles,
                attendeeOptions,
                type,
                notes,
                history,
                studentId,
                assignments,
                url,
                deletedFileIDs
            )
        );
    };

    const handleSave = (e) => {
        e.preventDefault();
        dispatch(
            addAttendenceAction(
                viewAttendence,
                singleAttendenceData,
                selectedFiles,
                attendeeOptions,
                type,
                notes,
                "",
                studentId,
                assignments,
                url,
                deletedFileIDs
            )
        );
    };
    const [assignments, setAssignments] = useState("");
    const tabIndex = viewAttendence?.attendees?.findIndex((item) => item.id == studentId);
    const [activeIndex, setActiveIndex] = useState(tabIndex);
    const [url, setUrls] = useState([]);
    const handleAddUrl = (url) => {
        setSingleAttendenceData({ ...singleAttendenceData, online_resource: "" });
        if (!url) {
            setUrlErr("Url is required");
        } else {
            setUrls((prev) => [...prev, url]);
        }
    };

    return (
        <Index>
            <div>
                <div className="p-5">
                    <div className="border p-3 border-400 border-round-lg flex text-lg mb-3">
                        <p className="font-semibold">
                            {viewAttendence?.all_day == 1 ? "All Day -" : ""}&nbsp;
                            {viewAttendence?.category_name}
                        </p>
                        <p className="text-dark-gray ml-2">{viewAttendence?.school_name}</p>
                    </div>
                    <div className="border  border-400 border-round-lg mb-3">
                        <TabView
                            activeIndex={activeIndex}
                            onTabChange={(e) => {
                                setActiveIndex(e.index);
                                let id = viewAttendence?.attendees[e.index].id;
                                handleChangeTabView(e, id);
                            }}
                            className="user-tab"
                        >
                            {viewAttendence?.attendees?.length &&
                                viewAttendence?.attendees?.map((item, index) => (
                                    <TabPanel
                                        header={
                                            <div onClick={(e) => handleChangeTabView(e, item?.id)}>
                                                <p className="text-lg m-0">
                                                    <img src={userlogoOne} width={15} /> &nbsp;
                                                    {item.name}
                                                </p>
                                            </div>
                                        }
                                    ></TabPanel>
                                ))}
                        </TabView>
                        {!editAttandence && (
                            <div className="flex justify-content-between bg-greenshade p-3 rounded mb-3">
                                <div>
                                    {viewAttendence?.attendance?.attendance?.[0]?.status && (
                                        <>
                                            <p className="text-lg font-bold text-greendark">
                                                {viewAttendence?.attendees
                                                    ?.filter((item, i) => item.id == studentId)
                                                    .map((item, i) => item.name)}{" "}
                                                &nbsp; was marked {viewAttendence?.attendance?.attendance?.[0]?.status}
                                            </p>
                                            {singleAttendenceData?.is_billable ? <p>This attendance is billable</p> : ""}
                                        </>
                                    )}
                                </div>
                                <div className="text-greendark font-bold" onClick={() => setEditAttandence(true)}>
                                    Edit
                                </div>
                            </div>
                        )}
                        {editAttandence && (
                            <div className="border border-round-lg p-3 mb-2">
                                <p className="ml-3 text-black font-semibold mb-2">Attendance Status</p>
                                <div className="flex align-items-center mb-2">
                                    <RadioButtonComponent
                                        label="Unrecorded"
                                        extraClassName="ml-3"
                                        labelClassName="text-sm"
                                        name="attendance"
                                        value="unrecorded"
                                        onChange={handleChange}
                                        checked={singleAttendenceData?.attendance == "unrecorded"}
                                    />
                                    <RadioButtonComponent
                                        label="Present"
                                        extraClassName="ml-3"
                                        labelClassName="text-sm"
                                        value="present"
                                        name="attendance"
                                        onChange={handleChange}
                                        checked={singleAttendenceData?.attendance == "present"}
                                    />
                                    <RadioButtonComponent
                                        label="Absent"
                                        extraClassName="ml-3"
                                        labelClassName="text-sm"
                                        value="absent"
                                        name="attendance"
                                        onChange={handleChange}
                                        checked={singleAttendenceData?.attendance == "absent"}
                                    />
                                    {/* <RadioButtonComponent
                                        label="Teacher Absent"
                                        extraClassName="ml-3"
                                        labelClassName="text-sm"
                                        value="teacher_absent"
                                        name="attendance"
                                        onChange={handleChange}
                                        checked={singleAttendenceData?.attendance == "teacher_absent"}
                                    /> */}
                                </div>
                            </div>
                        )}
                        <Accordion className="history-accordion">
                            <AccordionTab
                                header={
                                    <div>
                                        <p className="text-lg font-bold mb-2">Lesson Details</p>
                                        <p className="text-gray font-normal">Add notes, assignments and link online resources</p>
                                    </div>
                                }
                            >
                                <div className=" p-3  mb-3">
                                    <p className="font-semibold">Mark Attendance</p>
                                    <p className="text-sm mb-3">Add everyone’s notes & assignments for this Group Lesson (DS)</p>
                                    <p className="font-semibold mb-3"> Add Notes</p>
                                    <EditorComponent style={{ height: "200px" }} onTextChange={handleNotes} value={notes} />

                                    <h6 className="mt-2 open-sans font-semibold">Add Assignment</h6>
                                    <EditorComponent
                                        style={{ height: "200px" }}
                                        onTextChange={(e) => setAssignments(e.htmlValue)}
                                        value={assignments}
                                    />

                                    <div className="col-12 md:col-12 py-2 ">
                                        <h6 className="open-sans text-lg font-semibold">Add Linked Online Resources</h6>
                                        <div className="grid align-items-center">
                                            <InputComponent
                                                extraClassName="mb-0 ml-2 md:col-6"
                                                placeholder=""
                                                inputClass="p-2"
                                                name="online_resource"
                                                onChange={handleChange1}
                                                value={singleAttendenceData?.online_resource}
                                            />
                                            <ButtonComponent
                                                className="bg-main col-2 p-2"
                                                label="Add URL"
                                                onClick={() => handleAddUrl(singleAttendenceData?.online_resource)}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-danger text-sm open-sans ml-4">{urlErr}</div>

                                    <div className="mb-3 mt-3 md:col-12">
                                        {url?.length ? (
                                            <div className="p-2 mb-3 border-round-lg overflow-hidden">
                                                <div className="grid bg-main align-items-center p-2 border-bottom-1 border-300 justify-content-between">
                                                    <div className="md:col-3 text-sm open-sans font-semibold">Links</div>
                                                    <div className="md:col-3 text-sm open-sans font-semibold">Actions</div>
                                                </div>
                                                {url.map((u, i) => (
                                                    <div className="grid bg-whitesmoke justify-content-between align-items-center p-2">
                                                        <div className="md:col-3 text-main-color  ">{u}</div>

                                                        <div className="md:col-3 text-main-color ">
                                                            <img
                                                                className="pointer ml-2"
                                                                onClick={() => onDelete(i, "URL")}
                                                                src={DeleteIcon}
                                                                alt=""
                                                                width="15"
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="col-12 md:col-12 ">
                                        <CustomFilesInput
                                            label="+ Add File"
                                            name="file"
                                            accept=".pdf"
                                            multiple
                                            extraClassName="bg-light-green ml-2 mb-2 md:col-1"
                                            onChange={handleFiles}
                                        />
                                    </div>
                                    <div className="mb-3 mt-3 md:col-12">
                                        {selectedFiles?.length ? (
                                            <div className="p-2 mb-3 border-round-lg overflow-hidden">
                                                <div className="grid bg-main align-items-center p-2 border-bottom-1 border-300 ">
                                                    <div className="md:col-3 text-sm open-sans font-semibold">File Name</div>

                                                    <div className="md:col-3 text-sm open-sans font-semibold">File size</div>
                                                    <div className="md:col-3 text-sm open-sans font-semibold">Actions</div>
                                                </div>
                                                {Object.values(selectedFiles).map((file, i) => (
                                                    <div className="grid bg-whitesmoke align-items-center p-2">
                                                        <div className="md:col-3 text-main-color  ">
                                                            <img src={File} alt="" width="15" />
                                                            {file?.name || file?.file_name}
                                                        </div>

                                                        <div className="md:col-3 text-main-color ">{parseInt(file?.size)}kb</div>

                                                        <div className="md:col-3 text-main-color ">
                                                            <img
                                                                src={Eyeblack}
                                                                alt=""
                                                                width="19px"
                                                                height="19px"
                                                                className="product-image m-1"
                                                                onClick={() => window.open(URL.createObjectURL(file))}
                                                            />
                                                            <img
                                                                className="pointer ml-2"
                                                                onClick={() => onDelete(i, "FILES")}
                                                                src={DeleteIcon}
                                                                alt=""
                                                                width="15"
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>

                                    <p className="font-semibold mb-3">Email Notes</p>
                                    <div className="flex mb-3">
                                        <CheckboxComponent
                                            label="Parents"
                                            extraClassName=""
                                            labelClassName="text-sm"
                                            name="parents"
                                            checked={singleAttendenceData?.parents}
                                            onChange={(event) => handleCheck("parents", event)}
                                        />
                                        <CheckboxComponent
                                            label="Students"
                                            extraClassName="ml-3"
                                            labelClassName="text-sm"
                                            name="students"
                                            checked={singleAttendenceData?.students}
                                            onChange={(event) => handleCheck("students", event)}
                                        />
                                        <CheckboxComponent
                                            label="Teacher"
                                            extraClassName="ml-3"
                                            labelClassName="text-sm"
                                            name="teachers"
                                            checked={singleAttendenceData?.teachers}
                                            onChange={(event) => handleCheck("teachers", event)}
                                        />
                                    </div>

                                    <div className="grid">
                                        {singleAttendenceData?.parents && (
                                            <MultiSelectComponent
                                                name="parents_ids"
                                                size="md:col-4"
                                                optionLabel="name"
                                                options={viewAttendence?.student_parent}
                                                placeholder="Select Parent"
                                                onChange={handleChange}
                                                value={singleAttendenceData?.parents_ids}
                                            />
                                        )}
                                    </div>
                                </div>
                            </AccordionTab>
                            <AccordionTab
                                header={
                                    <div>
                                        <p className="text-lg font-bold mb-2">Communication History</p>
                                        <p className="text-gray font-normal">Look Back at previous notes & assignments</p>
                                    </div>
                                }
                            >
                                {viewAttendence?.communication_notes?.length > 0 &&
                                    viewAttendence?.communication_notes?.slice(0, limit)?.map((item) => (
                                        <div className="flex mb-3">
                                            <div className="">{/* <img src={CommunicationICon} alt="" width={40} /> */}</div>
                                            <div className="w-10 ml-5">
                                                <div className="border rounded p-2 chatting_msg position-relative">
                                                    <p className="font-bold mb-1">
                                                        {item?.type == "single" ? "Notes to Student" : "Notes to Group"}
                                                    </p>
                                                    <small className="text-gray font-normal">
                                                        {moment.utc(item.created_at).local().startOf("seconds").fromNow()} -{" "}
                                                        {moment(item?.created_at).format("MM-DD-YYYY")}
                                                    </small>
                                                    <div dangerouslySetInnerHTML={{ __html: item?.notes }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                {viewAttendence?.communication_notes?.length > 0 && (
                                    <div div className="flex align-items-center justify-content-between">
                                        <p className="text-sm font-bold text-graylight-color">
                                            Viewing history from the last 60 days
                                        </p>
                                        {limit < viewAttendence?.communication_notes?.length && (
                                            <ButtonComponent label="View More" onClick={() => setLimit(limit + 3)} />
                                        )}
                                    </div>
                                )}
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div>
                        <ButtonComponent label="Save" className="bg-gray-light" onClick={handleSave} />
                        <ButtonComponent label="Save & Exit" className="ml-3" onClick={handleSaveAndExit} />
                    </div>
                </div>
            </div>
        </Index>
    );
};

export default SingleAttendance;
