import Index from "../../layout";
import StudentEvents from "./StudentEvents";
import { useSelector } from "react-redux";
import pdfIcon from "../../../../assets/icons/pdf.png";
import { Tag } from 'primereact/tag';
import { Charts } from "../../../../pages/Chart";
import CardHeader from "../../../../pages/CardHeader";
import StudentIcon from "../../../../assets/icons/student.png";

export default function Home() {
  // const { upcominEventData, ClassSchedule } = ScheduleData();

  const studentDashboard = useSelector(
    (state) => state?.studentInfo?.dashboard
  );
  
  return (
    <Index>
      <div className="p-4">
        <CardHeader icon ={StudentIcon} heading="Student Dashboard"/>
        <div className="grid mb-3">
          <div className="md:col-6">
            <div className="bg-light-main border-round-lg p-3 h-100">
              <h4 className="open-sans font-bold text-main">Attendance</h4>
              <p className="open-sans">In the last 90 days</p>
              <div className="text-center">
               <Charts present={studentDashboard?.total_presents} absent ={studentDashboard?.total_absences}/>
              </div>
            </div>
          </div>
        </div>
        <StudentEvents />
        <div className="grid mb-3">
          {studentDashboard?.school !== "" ||
          studentDashboard?.school !== null ? (
            <div className="md:col-12 px-0 m-0 p-0 border-1 border-round-lg border-200">
              <h3 className="open-sans p-3 text-main font-semibold m-0">
                View Schedule of {studentDashboard?.school}
              </h3>
              <div className="border-1 border-round-lg shadow-sm border-200 mb-3 overflow-hidden open-sans">
                <div className="bg-dark text-white p-3 font-bold">
                  {studentDashboard?.schedule?.schedule_title}
                </div>
                <div className="shadow-sm flex">
                  {studentDashboard?.schedule?.attachments?.map(
                    (attachment, index) => {
                      return (
                        <div className="col-3 p-3" key={index}>
                          <a  href={attachment?.file} target="_blank" rel="noreferrer">
                            <img
                              src={pdfIcon}
                              alt="PDF FILE ICON"
                              style={{ width: "22px" }}
                            ></img>
                            <span className="text-dark">View File</span>
                          </a>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="grid">
          <div className="md:col-12 px-0">
            <div className="border-1 border-round-lg border-200 p-3">
              <h3 className="open-sans text-main font-semibold mb-2">
                Recent Assignments
              </h3>
              {studentDashboard?.recent_assignments?.length ? (
                studentDashboard?.recent_assignments?.map(
                  (assignment, index) => {
                    return <div key={index} className="border border-300 border-round-lg mb-3 p-3">
                      <h5 className="open-sans font-semibold  m-0 mb-2">
                        {assignment?.category_name} By {assignment?.teacher_name}
                        <Tag severity="success" value={assignment?.date} className="ml-5 px-3 text-dark" style={{background: "var(--surface-300)"}} rounded></Tag>
                      </h5>
                      <p dangerouslySetInnerHTML={{ __html: assignment?.assignment }}></p>
                      {
                        assignment?.online_resources?.map((res, index)=>{
                          return <p key={index}><a href={res.url} target="_blank" rel="noreferrer">{res.url}</a></p>
                        })
                      }
                    </div>;
                  }
                )
              ) : (
                <div className="border border-300 border-round-lg h-8rem flex flex-column justify-content-center align-item-center mb-3">
                  <h5 className="text-400 open-sans font-semibold text-center m-0">
                    No Assignments
                  </h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Index>
  );
}
