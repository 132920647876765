import React, { useEffect, useRef } from "react";
import Index from "../../../layout";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getTeachersTabListing } from "../../../../../redux/actions/TeacherPortal/teachersTabAction";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import TeacherIcon from "../../../../../assets/icons/teacher-icon.png";
import CardHeader from "../../../../../pages/CardHeader";
import { useState } from "react";
import InputComponent from "../../../../../components/Fields/InputField";
import { getSearchedData } from "../../../../../utils/commonFunctions";
import { showToast } from "../../../../../utils/showToast";
import MessageIcon from "../../../../../assets/icons/mail 1.png";
import Dropblack from "../../../../../assets/icons/dropblack.png";
import { Menu } from "primereact/menu";

const TeacherList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedRow, setSelectedRow] = useState([]);

    useEffect(() => {
        dispatch(getTeachersTabListing());
    }, [dispatch]);

    const teachersList = useSelector((state) => state.teachers.getTeachersList);

    const schoolTemplate = (r) => {
        return (
            <>
                {r.school[0]?.name ? (
                    <Dropdown
                        className="border-none dropdown-view"
                        options={r.school}
                        value={r.school[0]?.name ? r.school[0] : "-"}
                        optionLabel="name"
                    />
                ) : (
                    ""
                )}
            </>
        );
    };

    const InstrumentsTemplate = (r) => {
        return (
            <>
                {" "}
                {r.instruments_name[0] ? (
                    <Dropdown
                        className="border-none dropdown-view"
                        options={r.instruments_name}
                        value={r.instruments_name[0] ?? "--"}
                        optionLabel="name"
                    />
                ) : (
                    <></>
                )}
            </>
        );
    };
    const [keyword, setKeyword] = useState("");
    const keys = ["name", "email", "phone_number"];
    const ViewMessaging = useRef();
    const messageItems = [
        {
            label: "New Email",
            key: 1,
            command: () => {
                history.push("/teacher/teacher/message");
            },
        },
        {
            label: "Email Selected Teachers",
            key: 3,
            command: () => {
                if (selectedRow?.length) {
                    history.push({
                        pathname: "/teacher/teacher/message",
                        state: { role: "teacher", items: selectedRow },
                    });
                } else {
                    showToast(["Please Select Atleast One Teacher"], "error");
                }
            },
        },
        // {
        //     label: "Message History",
        //     key: 1,
        //     command: () => {
        //         history.push("/teacher/message-history");
        //     },
        // },
    ];

    return (
        <Index>
            <div className="grid mb-3 border-1 border-round-lg border-300 p-5">
                <CardHeader icon={TeacherIcon} heading="Teachers" />

                <div className="md:col-12">
                    <div className=" table-top-header border-round-top-xl  bg-gray-light p-3">
                        <div className="flex justify-content-between">
                            <div className="flex justify-content-between">
                                <div
                                    className="flex align-items-center open-sans font-semibold text-xs ml-5 cursor-pointer"
                                    onClick={(event) => ViewMessaging.current.toggle(event)}
                                >
                                    <img src={MessageIcon} alt="" className="bg-white p-2 border-round-lg" width={30} />
                                    &nbsp; Messaging &nbsp; <img src={Dropblack} alt="" className="" width={15} />
                                </div>
                                <Menu model={messageItems} popup ref={ViewMessaging} className={"view-menu "} />
                            </div>
                            {setKeyword ? (
                                <div className="p-input-icon-left mx-2">
                                    <i className="pi pi-search z-3" />
                                    <InputComponent
                                        value={keyword}
                                        inputClass="pl-5"
                                        onChange={(e) => setKeyword(e.target.value)}
                                        placeholder="Search"
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <DataTable
                        showGridlines
                        className="text-dark online-lesson"
                        value={getSearchedData(teachersList, keyword, keys)}
                        selection={selectedRow}
                        onSelectionChange={(e) => setSelectedRow(e.value)}
                        paginator
                        stripedRows
                        responsiveLayout="scroll"
                        paginatorTemplate=" FirstPageLink  PageLinks  LastPageLink RowsPerPageDropdown "
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50]}
                        style={{ borderRadius: "10px" }}
                        emptyMessage="No Teacher found."
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                        <Column
                            field="name"
                            header="Name"
                            // style={{ width: "50%" }}
                        ></Column>
                        <Column
                            field="email"
                            header="Email"
                            // style={{ width: "50%" }}
                        ></Column>
                        <Column
                            field="phone_number"
                            header="Phone"
                            // style={{ width: "50%" }}
                        ></Column>

                        <Column
                            field=""
                            header="Schools"
                            body={schoolTemplate}
                            // style={{ width: "50%" }}
                        ></Column>
                        <Column
                            field=""
                            header="Instruments"
                            body={InstrumentsTemplate}
                            // style={{ width: "50%" }}
                        ></Column>
                    </DataTable>
                </div>
            </div>
        </Index>
    );
};

export default TeacherList;
