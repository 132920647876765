import React from 'react';
import Chart from "react-apexcharts";

export const Charts = ({ present = 0, absent = 0 }) => {
    const doughnutChartdata = {
        series: [
            present,
            absent],
        options: {
            labels: ["Present", "Absent"],
            colors: ["#59C6BC", "#f00"],
            chart: {
                type: "donut",
            },
            fill: {
                type: "gradient",
                gradient: {
                    shade: "dark",
                    type: "diagonal1",
                    shadeIntensity: 1,
                    gradientToColors: "#000000", // optional, if not defined - uses the shades of same color in series
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100],
                },
            },
            plotOptions: {
                pie: {
                    offsetY: 30,
                    startAngle: 90,
                    endAngle: 420,
                    donut: {
                        size: "80%",
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                showAlways: false,
                                label: "Total",
                                fontSize: "22px",
                                fontFamily: "Helvetica, Arial, sans-serif",
                                fontWeight: 600,
                                color: "#373d3f",
                                formatter: function (w) {
                                    return w.globals.seriesTotals.reduce((a, b) => {
                                        return a + b;
                                    }, 0);
                                },
                            },
                        },
                    },
                },
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: undefined,
                formatter: function (val, opts) {
                    return Math.round(val) + "%";
                },
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: "bottom",
                        },
                    },
                },
            ],
        },
    };
    return (
        <div>
            {present !== 0 || absent !== 0 ? <Chart
                options={doughnutChartdata.options}
                series={doughnutChartdata.series}
                type="donut"
                width={400}
                height={400}
            /> : "No record Found"}
        </div>
    )
}

export default Chart
