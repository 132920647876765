import React, { useState, useEffect } from "react";
import BrundCrumb from "../../../assets/icons/brundcrumb.png";
import Logo from "../../../assets/logo/logo.png";
import QuestionMark from "../../../assets/icons/questionmark.png";
import { useHistory } from "react-router-dom";
import Signout from "../../../assets/icons/signout.png";
import {
  getTeacherProfileApi,
  logoutAction,
} from "../../../redux/actions/loginAction";
import { useDispatch, useSelector } from "react-redux";
import { removeStateFn } from "../../../utils/localStorage";
import Notification from "../../../assets/icons/notification.png";

export default function TopBar({ toggleSidebar }) {
  const [isActiveSubMenu, setIsActiveSubMenu] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeSubMenu, setActiveSubMenu] = useState();
  useEffect(() => {
    dispatch(getTeacherProfileApi());
  }, [dispatch]);

  const toggleSubMenu = () => {
    setIsActiveSubMenu((prev) => !prev);
    setActiveSubMenu("subMenu");
  };
  const handleLogout = () => {
    dispatch(logoutAction());
    removeStateFn("token");
    removeStateFn("Role");
    history.push("/");
  };

  const profile = useSelector((state) => state.login.getTeacherProfile);

  return (
    <>
      <div className="bg-main px-4 p-2 flex justify-content-between border-round-bottom-2xl">
        <div className="flex align-items-center">
          <button className="border-0 bg-transparent p-2">
            <img
              src={BrundCrumb}
              onClick={() => toggleSidebar((prev) => !prev)}
              className="w-5"
              alt=""
            />
          </button>
          <div className="flex align-items-center">
            <img src={Logo} className="w-3" alt="" />
          </div>
        </div>
        <div className="flex">
          <div className="flex align-items-center">
            <img src={Notification} alt="" className="w-2" />
            <p className=" ml-2 open-sans font-normal m-0">Notification</p>
          </div>
          <div className="flex align-items-center ml-2">
            <img src={QuestionMark} alt="" className="w-3" />
            <p className=" ml-2 open-sans font-normal m-0">Help</p>
          </div>
          <div
            className="relative cursor-pointer"
            onClick={() => toggleSubMenu()}
          >
            <div className="flex align-items-center">
              <div className="avtar border bg-white p-2">
                <p className="open-sans font-semibold">
                  {profile?.name?.substring(0, 2)}
                </p>
              </div>
              <div className="ml-2">
                <p className="open-sans font-semibold m-0">{profile.name}</p>
                <small className="font-normal open-sans">{profile.email}</small>
              </div>
              <span>
                <i
                  className={
                    isActiveSubMenu
                      ? "pi pi-chevron-up cursor-pointer"
                      : "pi pi-chevron-down cursor-pointer"
                  }
                ></i>
              </span>
            </div>
            <ul
              className="absolute border-round-lg shadow-1 z-2 list-unstyled bg-white w-full p-3"
              style={{
                display:
                  isActiveSubMenu && activeSubMenu === "subMenu"
                    ? "block"
                    : "none",
              }}
            >
              <li>
                <div
                  to=""
                  className="text-sm text-900 inter font-semibold"
                  onClick={handleLogout}
                >
                  <img src={Signout} alt="" width={15} />
                  &nbsp;Sign Out
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className="overlay-set absolute w-full h-full top-0 left-0 z-1"
        style={{
          display: isActiveSubMenu && activeSubMenu === "subMenu" ? "block" : "none",
        }}
        onClick={() => toggleSubMenu()}
      ></div>
    </>

  );
}
