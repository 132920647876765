import React, { useEffect, useState } from "react";
import Index from "../../../layout";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import NewsIcon from "../../../../../assets/icons/newspaper.png";
import { getTeacherNewsAction } from "../../../../../redux/actions/TeacherPortal/teacherAction";
import CardHeader from "../../../../../pages/CardHeader";

const TeacherNews = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getTeacherNewsAction());
    }, [dispatch]);

    const newsInfo = useSelector((state) => state?.teacher?.teacherNews);

    const DateTemplate = (rowData) => {
        return <>{moment(rowData.created_at).format("MM-DD-YYYY")}</>;
    };
    const DescriptionTemplate = (rowData) => {
        return <div dangerouslySetInnerHTML={{ __html: rowData?.description }} />;
    };
    return (
        <>
            <Index>
                <div className="p-4">
                    <CardHeader icon={NewsIcon} heading="News" />
                    <div className="shadow-sm border-round-lg overflow-hidden">
                        <DataTable
                            className="student-info-table"
                            value={newsInfo?.length && newsInfo}
                            stripedRows
                            tableStyle={{ minWidth: "50rem" }}
                        >
                            <Column field="created_at" body={DateTemplate} header="Date"></Column>
                            <Column field="description" body={DescriptionTemplate} header="Description"></Column>
                        </DataTable>
                    </div>
                </div>
            </Index>
        </>
    );
};
export default TeacherNews;
