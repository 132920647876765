import studentIcon from "../../assets/icons/student-select.png";
import parentIcon from "../../assets/icons/joint-family.png";
import teacherIcon from "../../assets/icons/teachericon.png";
import NewsImg from "../../assets/images/news.png";
import NewsImg1 from "../../assets/images/news1.png";
import NewsImg2 from "../../assets/images/news2.png";

const MainData = () => {
  const userData = [
    {
      img: studentIcon,
      heading: "Student",
      type: "STUDENT",
    },
    {
      img: parentIcon,
      heading: "Parent",
      type: "PARENT",
    },
    {
      img: teacherIcon,
      heading: "Teacher",
      type: "TEACHER",
    },
  ];

  const newsData = [
    {
      img: NewsImg,
      heading:
        "How to Play Trumpet – Things you will learn in your first few lessons",
      description:
        "All trumpet sounds happen through vibration. The source of this vibration for all brass instruments is your lips. The goal will be a full, rich...",
      date: "April 21, 2021",
    },
    {
      img: NewsImg1,
      heading: "Trumpet Lessons for Beginners – Starting Right",
      description:
        "Set the Tone When deciding to take Trumpet lessons for beginners – getting started right is the most important consideration. Find the best...",
      date: "March 29, 2021",
    },
    {
      img: NewsImg2,
      heading: "Instruments are for any age",
      description:
        "Instruments are for every age Are you looking for virtual music lessons? Learning to play an instrument is for any age. One of the most frequent...",
      date: "March 26, 2021",
    },
  ];

  return { userData, newsData };
};
export default MainData;
