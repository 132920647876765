import React, { useEffect, useState } from "react";
import Index from "../../../layout";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { firstLetterToUppercase } from "../../../../../utils/regex";
import Teacher from "../../../../../assets/icons/teacher1.png";
import CalendarIcon from "../../../../../assets/icons/calendarIcon.png";
import Manual from "../../../../../assets/icons/manual (1).png";
import Substitute1 from "../../../../../assets/icons/substitute1.png";
import Substitute2 from "../../../../../assets/icons/substitute2.png";
import { useDispatch, useSelector } from "react-redux";
import CompensationIcon from "../../../../../assets/icons/compensationIcon.png";
import { getCompensationListAction } from "../../../../../redux/actions/TeacherPortal/teacherAction";
import CardHeader from "../../../../../pages/CardHeader";
import InputComponent from "../../../../../components/Fields/InputField";
import { getSearchedData } from "../../../../../utils/commonFunctions";
import { Calendar } from "primereact/calendar";
import CustomCalendar from "../../../../../components/Fields/CustomCalendar";

const TeacherCompensation = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCompensationListAction());
    }, [dispatch]);

    const compensation = useSelector((state) => state.teacher.getCompensation);
    const dateTemplate = (rowData) => {
        return <>{moment(rowData.date).format("MM-DD-YYYY")}</>;
    };
    const attendenceStatus = (rowData) => {
        return <>{rowData?.attendance_status ? firstLetterToUppercase(rowData?.attendance_status) : "-"}</>;
    };

    const earnedIncome = (rowData) => {
        return <>$ {rowData?.earned_income}</>;
    };
    const payment = (rowData) => {
        return <> $ {rowData?.payment}</>;
    };

    const balance = (rowData) => {
        const formattedValue = Math.abs(rowData?.balance);

        return <> ${rowData?.balance < 0 ? `(${formattedValue})` : formattedValue}</>;
    };

    const source = (rowData) => {
        return (
            <>
                {rowData?.source_type === "teacher" ? (
                    <img src={Teacher} alt="teacher" width="15px" />
                ) : rowData?.source_type === "subtitute" ? (
                    <div className="flex">
                        <img src={Substitute1} width="15px" />
                        <img src={Substitute2} width="15px" className="ml-1" />
                    </div>
                ) : rowData?.source_type === "calendar" ? (
                    <img src={CalendarIcon} alt="Calendar" />
                ) : (
                    <img src={Manual} alt="manual" />
                )}
            </>
        );
    };

    const [keyword, setKeyword] = useState("");
    const [date, setDate] = useState(new Date());

    const keys = ["students_name", "attendance_status", "description", "date"];
    const dateFilterTemplate = (options) => {
        return (
            <Calendar
                value={options.value}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                dateFormat="mm/dd/yy"
                placeholder="mm/dd/yyyy"
                mask="99/99/9999"
            />
        );
    };

    let data = compensation?.map((item) => ({ ...item, date: item.date.substring(0, 10) }));
    data = compensation?.map((item) => ({ ...item, date: new Date(item.date) }));

    const formatDate = (value) => {
        return value.toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    };
    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    };
    return (
        <>
            <Index>
                <div className="grid mb-3 border-1 border-round-lg border-300 p-5">
                    <CardHeader icon={CompensationIcon} heading="Compensation" />

                    <div className="md:col-12">
                        <div className="table-top-header border-round-top-xl  bg-gray-light p-3">
                            <div className="flex justify-content-between">
                                <div className="flex align-items-center open-sans font-semibold text-xs ml-5">
                                    <CustomCalendar
                                        // size="md:col-6"
                                        showWeek
                                        value={date}
                                        onChange={(e) => {
                                            setDate(e.value);
                                            dispatch(getCompensationListAction(e.value));
                                        }}
                                    />
                                </div>
                                {setKeyword ? (
                                    <div className="p-input-icon-left mx-2">
                                        <i className="pi pi-search z-3" />
                                        <InputComponent
                                            value={keyword}
                                            inputClass="pl-5"
                                            onChange={(e) => setKeyword(e.target.value)}
                                            placeholder="Search"
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <DataTable
                            showGridlines
                            className="text-dark online-lesson"
                            value={getSearchedData(data, keyword, keys)}
                            paginator
                            stripedRows
                            responsiveLayout="scroll"
                            paginatorTemplate=" FirstPageLink  PageLinks  LastPageLink RowsPerPageDropdown "
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            style={{ borderRadius: "10px" }}
                            emptyMessage="No Compensation found."
                        >
                            <Column
                                field="date"
                                header="Date"
                                filterField="date"
                                dataType="date"
                                body={dateBodyTemplate}
                                filter
                                filterElement={dateFilterTemplate}
                                showFilterOperator={false}
                            ></Column>
                            <Column field="time_format" header="Time" body={(rowData) => rowData.time_format}></Column>
                            <Column field="duration" header="Duration"></Column>

                            <Column field="description" header="Description"></Column>
                            <Column field="students_name" header="Students"></Column>
                            <Column field="attendance_status" header="Attendence" body={attendenceStatus}></Column>
                            <Column field="source" body={source} header="Sources"></Column>
                            <Column body={earnedIncome} header="Earned Income"></Column>
                            <Column body={payment} header="Payment"></Column>
                            <Column body={balance} header="Balance"></Column>
                        </DataTable>
                    </div>
                </div>
            </Index>
        </>
    );
};
export default TeacherCompensation;
