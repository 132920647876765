import types from "../../types";
const initialBoard = {
    getParentAssignment: {},
    viewAssignment: {}

};

const assignmentReducer = (state = initialBoard, action) => {
    switch (action.type) {
        case types.GET_ASSIGNMENTS:
            return { ...state, getParentAssignment: action.payload };
        case types.VIEW_PARENT_ASSIGNMENTS:
            return { ...state, viewAssignment: action.payload }
        default:
            return { ...state };
    }
};

export default assignmentReducer;