import React from "react";
import Index from "../../layout";
import VideoCard from "./VideoCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getParentPracticeLogs } from "../../../../redux/actions/StudentPortal/practiceLog";
import CardHeader from "../../../../pages/CardHeader";
import PracticeLogIcon from "../../../../assets/icons/practicelog.png";

export default function ParentPracticeLog() {
  const { practicelog } = useSelector((state) => state.practice);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getParentPracticeLogs());
  }, [dispatch]);

  return (
    <>
      <Index>
        <div className="p-4">
          <CardHeader icon ={PracticeLogIcon} heading="Practice Log"/>
          <div className="shadow-sm border-round-lg overflow-hidden">
            <div className="table-top-header border-round-top-xl bg-main p-3">
              <div className="flex justify-content-between">
                <div className="flex justify-content-between">
                  <div className="">
                    {/* <div className="flex align-items-center open-sans font-semibold text-xs ml-4 no-underline text-900">
                      <img
                        src={AddIcon}
                        alt=""
                        className="bg-white p-2 border-round-lg"
                        width={30}
                        height={32}
                      />
                      &nbsp;Student
                    </div> */}
                  </div>
                </div>
                {/* <div className="flex align-items-center open-sans font-semibold text-xs ml-5">
                  <img
                    src={ColumnsImg}
                    alt=""
                    className="bg-white p-2 border-round-lg"
                    width={30}
                  />
                  &nbsp; Columns &nbsp;
                  <img src={Dropblack} alt="" className="" width={15} />
                </div> */}
              </div>
            </div>
            <div className="grid p-3">
              {practicelog?.practice_logs?.length > 0 ?
                practicelog?.practice_logs.map((item) => (
                  <VideoCard
                    baseUrl={practicelog?.base_url}
                    item={item}
                    videoUrl={practicelog?.base_url + item?.file}
                  />
                )):"No practice log found"}
            </div>
          </div>
        </div>
      </Index>
    </>
  );
}
