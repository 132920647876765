import React, { useCallback, useEffect, useMemo, useState } from "react";
import RadioButtonComponent from "../../../components/Fields/Radio";
import InputComponent from "../../../components/Fields/InputField";
import NumberComponent from "../../../components/Fields/NumberField";
import DropDownComponent from "../../../components/Fields/CustomDropdown";
import CheckboxComponent from "../../../components/Fields/Checkbox";
import { api } from "../../../services/api";
import Constants from "../../../services/constant";
import { allValidations } from "../../../utils/formValidations";
import ButtonComponent from "../../../components/Fields/Button/CustomButton";
import { Dialog } from "primereact/dialog";
import InputGroup from "../../../components/Fields/InputGroup";
import Loader from "../../../utils/Loader";
import Loader1 from "../../../utils/Loader1";

const AtSchoolNewAdmissionForm = ({ schoolId, setFormState, index, data }) => {
    const [selectedProgramme, setSelectedProgramme] = useState(false);
    const [loading, setIsLoading] = useState(false);

    const [admissionForm, setAdmissionForm] = useState();
    const [instrument, setInstrument] = useState();
    const [programType, setProgramType] = useState();
    const [ProgramList, setProgramList] = useState([]);
    const [admissionFields, setAdmissionFields] = useState({
        student_type: "child",
        first_name: "",
        last_name: "",
        // email: "",
        phone_no: null,
        skill_level: {},
        current_school: "",
        aftercare_picked_up_time: "",
        grade: "",
        prefer_drop_picked_up_for_class: "",
        class_type: "",
        order_book: true,
        is_waiting: false,
        // instrument_on_purchase_or_rent: "",
        cost_per_book: 30,
        monthly_installment: "",
        program_id: "",
        one_time_payment: "",
        instrument_id: "",
        type: null,
        amount: null,
    });
    useEffect(() => {
        ViewSchoolAdmissionForm(schoolId);
    }, [schoolId]);

    useEffect(() => {
        if (admissionFields?.class_type) {
            ViewSchoolTypeProgramsList(schoolId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [admissionFields?.class_type]);

    useEffect(() => {
        if (admissionFields?.grade) {
            ViewSchoolPrograms(schoolId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [admissionFields?.grade, schoolId]);

    const [extraFields, setExtraFields] = useState([]);
    const ViewSchoolAdmissionForm = async (id) => {
        const res = await api("get", Constants.END_POINT.VIEW_SCHOOL_ADMISSION_FORM + id);
        if (res.success) {
            setAdmissionForm(res.data);
            setExtraFields(res?.data?.admission_form?.parent_extra_fields);
        }
    };

    const ViewInstrument = async (id) => {
        setIsLoading(true);
        const res = await api("get", Constants.END_POINT.PROGRAM_INSTRUMENTS + id);
        if (res.success) {
            setInstrument(res.data);
        }
        setIsLoading(false);
    };

    const ViewSchoolPrograms = async (id) => {
        setIsLoading(true);
        const res = await api("post", Constants.END_POINT.SCHOOL_PROGRAMS, {
            school_id: id,
            grade: admissionFields?.grade,
        });
        if (res.success) {
            setProgramType(res.data);
        }
        setIsLoading(false);
    };

    const ViewSchoolTypeProgramsList = async (id) => {
        setIsLoading(true);
        const res = await api("post", Constants.END_POINT.CLASS_TYPE_PROGRAMS, {
            school_id: id,
            class_type: admissionFields?.class_type,
            grade: admissionFields?.grade,
        });
        if (res.success) {
            setProgramList(res.data);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        setFormState(admissionFields, index);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [admissionFields, schoolId]);

    useEffect(() => {
        if (admissionFields?.program_id) {
            ViewInstrument(admissionFields?.program_id);
        }
    }, [admissionFields?.program_id]);
    useEffect(() => {
        if (admissionFields?.class_type) {
            selectJrJamFun(admissionFields?.class_type, admissionFields?.program_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [admissionFields?.program_id]);

    useEffect(() => {
        if (admissionFields?.class_type === "during_school") {
            delete admissionFields["aftercare_picked_up_time"];
            delete admissionFields["prefer_drop_picked_up_for_class"];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [admissionFields?.class_type]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const formErrors = allValidations(name, value, data);
        if (name === "phone_no") {
            setAdmissionFields((prev) => ({ ...prev, phone_no: value }));
        } else {
            setAdmissionFields((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const handleExtraFields = (e, item) => {
        const { name, value } = e.target;
        setAdmissionFields((prev) => ({
            ...prev,
            student_extra_fields: { ...prev?.student_extra_fields, [name]: { value, ...item } },
            parent_extra: { extraFields },
        }));
    };

    useEffect(() => {
        if (instrument) {
            setAdmissionFields({ ...admissionFields, instruments: instrument });
        }
    }, [instrument]);

    const handleChange1 = (e) => {
        const { name, id } = e.target;
        const formErrors = allValidations(name, e.value, data);
        if (name === "grade") {
            setAdmissionFields((prev) => ({
                ...prev,
                grade: e.value,
                class_type: "",
                prefer_drop_picked_up_for_class: "",
                aftercare_picked_up_time: "",
                program_id: "",
                instruments: [],
                // ["instrument_id"]: "",
            }));
            setInstrument({});
        }
        if (name === "class_type") {
            setAdmissionFields((prev) => ({
                ...prev,
                class_type: e.value,
                prefer_drop_picked_up_for_class: "",
                aftercare_picked_up_time: "",
                program_id: "",
                instruments: [],
                // ["instrument_id"]: "",
            }));
            setInstrument({});
        }
        if (name === "after_school") {
            setAdmissionFields((prev) => ({
                ...prev,
                during_school: e.value,
                aftercare_picked_up_time: "",
                prefer_drop_picked_up_for_class: "",
            }));
        }
        if (id === "rent") {
            setAdmissionFields((prev) => ({
                ...prev,
                monthly_installment: instrument?.monthly_installment,
                one_time_payment: "",
            }));
        }
        if (id === "purchase") {
            setAdmissionFields((prev) => ({
                ...prev,
                one_time_payment: instrument?.purchase_amount,
                monthly_installment: "",
            }));
        }
        setAdmissionFields((prev) => ({ ...prev, [name]: e.value, formErrors }));
        if (name === "class_type") {
            const formErrors = allValidations("program_id", admissionFields?.program_id, data);
            setAdmissionFields((prev) => ({
                ...prev,
                [name]: e.value,
                formErrors,
            }));
            // ViewInstrument(e.value)
        }

        if (name === "instrument_id" || name === "type") {
            setAdmissionFields((prevState) => ({
                ...prevState,
                // instruments: {
                //   ...prevState.instruments,
                [name]: e.value,
                // },
            }));
        }
    };

    useEffect(() => {
        let instr = instrument?.instruments?.filter((item, i) => item.id === admissionFields?.instrument_id);
        setAdmissionFields((prevState) => ({
            ...prevState,
            amount: admissionFields?.type === "rent" ? instr[0]?.monthly_installment : admissionFields?.type === "purchase" ? instr?.[0]?.purchase_amount : 0,
        }));
    }, [admissionFields?.type, admissionFields?.instrument_id]);

    const selectJrJamFun = (classType, programId) => {
        if (classType.includes("Jr. Jam")) {
            let _instrument = [...instrument.instruments];
            _instrument[0].selected = "nothing_needed";
            setInstrument((prev) => ({ ...prev, instruments: _instrument }));
            // setAdmissionFields({ ...admissionFields, instruments: instrument });
        } else {
            setAdmissionFields({
                ...admissionFields,
                program_id: programId,
            });
        }
    };
    const SkillLevel = [
        { name: "Beginner", value: "Beginner" },
        { name: "Intermediate", value: "Intermediate" },
        { name: "Advanced", value: "Advanced" },
    ];
    const InstrumentsPurchaseType = [
        { name: "Rent", value: "rent" },
        { name: "Purchase", value: "purchase" },
        { name: "Nothing Needed", value: "nothing_needed" },
    ];

    const filteredInstrumentsPurchaseType = [];

    for (const instrumentPurchaseType of InstrumentsPurchaseType) {
        if (instrument?.mode_of_purchasing?.includes(instrumentPurchaseType.value)) {
            filteredInstrumentsPurchaseType.push(instrumentPurchaseType);
        }
    }
    const GradeOptions = [
        { name: "Kindergarten (K)", value: "k" },
        { name: "Grade 1", value: 1 },
        { name: "Grade 2", value: 2 },
        { name: "Grade 3", value: 3 },
        { name: "Grade 4", value: 4 },
        { name: "Grade 5", value: 5 },
        { name: "Grade 6", value: 6 },
        { name: "Grade 7", value: 7 },
        { name: "Grade 8", value: 8 },
        { name: "Grade 9", value: 9 },
        { name: "Grade 10", value: 10 },
        { name: "Grade 11", value: 11 },
        { name: "Grade 12", value: 12 },
    ];

    const handlePrograms = (program) => {
        const formErrors = allValidations("program_id", program.id, data);
        setAdmissionFields((prev) => ({ ...prev, program_id: program.id, formErrors }));
    };

    useEffect(() => {
        if (admissionFields?.program_id) {
            setAdmissionFields((prev) => ({
                ...prev,
                type: instrument?.instruments?.[0]?.name.includes("Jr. Jam") ? "nothing_needed" : "",
                instrument_id: instrument?.instruments?.[0]?.id,
                formErrors: {},
            }));
        }
    }, [admissionFields?.program_id, instrument]);

    return (
        <div>
            {loading ? <Loader1 isLoading={loading} /> : null}
            <form className="">
                <div className="mb-3 shadow-1 p-5 border-round-lg">
                    <div className="grid flex-wrap mb-2">
                        <InputComponent
                            extraClassName="col-12 md:col-6"
                            placeholder="Student First Name*"
                            name="first_name"
                            value={admissionFields?.first_name}
                            onChange={handleChange}
                            required
                            errorMessage={data?.formErrors?.first_name}
                        />
                        <InputComponent
                            name="last_name"
                            extraClassName="col-12 md:col-6"
                            placeholder="Student Last Name*"
                            value={admissionFields?.last_name}
                            onChange={handleChange}
                            required
                            errorMessage={data?.formErrors?.last_name}
                        />
                        <InputComponent name="school" extraClassName="col-12 md:col-6" placeholder="" value={admissionForm?.name} readOnly />
                        <DropDownComponent
                            name="skill_level"
                            extraClassName="col-12 md:col-6"
                            placeholder=" Select Skill Level"
                            value={admissionFields?.skill_level}
                            options={SkillLevel}
                            onChange={handleChange1}
                            optionLabel="name"
                            errorMessage={data?.formErrors?.skill_level}
                        />
                        {admissionForm?.admission_form?.student_extra_fields?.map((item) => (
                            <div className="col-12 md:col-6">
                                <InputComponent
                                    labelClassName=""
                                    label={item?.label}
                                    id={item?.label?.toLowerCase()}
                                    name={item?.label?.toLowerCase()}
                                    // extraClassName="col-6"
                                    placeholder={item?.placeholder}
                                    value={admissionFields?.student_extra_fields?.label?.toLowerCase()}
                                    onChange={(e) => handleExtraFields(e, item)}
                                />
                                <label className="">{item?.text}</label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="shadow-1 p-5 border-round-lg mb-3">
                    <div className="grid flex-wrap  align-items-end mb-4">
                        <DropDownComponent
                            name="grade"
                            extraClassName="col-12 md:col-6"
                            placeholder=" Select Grade"
                            value={admissionFields?.grade}
                            options={GradeOptions?.map((item, index) => {
                                return { name: item?.name, value: item?.value };
                            })}
                            onChange={handleChange1}
                            optionLabel="name"
                            errorMessage={data?.formErrors?.grade}
                        />
                        <DropDownComponent
                            name="class_type"
                            extraClassName="col-12 md:col-6"
                            label="Choose your class type"
                            labelClassName="font-bold"
                            placeholder="Select Program Type"
                            options={programType}
                            value={admissionFields?.class_type}
                            onChange={handleChange1}
                            optionLabel="name"
                            errorMessage={data?.formErrors?.class_type}
                        />
                    </div>
                    {(admissionFields?.class_type === "after_school" || admissionFields?.class_type === "semi_private") && (
                        <div className="relative mb-3">
                            <h6 className="text-900 font-bold flex align-items-center justify-content-between">Dismissal Programs</h6>
                            <div className="grid align-items-center w-full md:w-auto m-0">
                                <div className="md:col-5 mb-2">
                                    <p>Does your child attend aftercare?</p>
                                </div>
                                <div className="md:col-3">
                                    <div className="flex align-items-center">
                                        <RadioButtonComponent
                                            name="prefer_drop_picked_up_for_class"
                                            label="Yes"
                                            value="yes"
                                            checked={admissionFields?.prefer_drop_picked_up_for_class === "yes"}
                                            onChange={handleChange1}
                                        />
                                        <RadioButtonComponent
                                            name="prefer_drop_picked_up_for_class"
                                            label="No"
                                            value="no"
                                            checked={admissionFields?.prefer_drop_picked_up_for_class === "no"}
                                            onChange={handleChange1}
                                        />
                                    </div>
                                    {data?.formErrors?.prefer_drop_picked_up_for_class ? (
                                        <small className="p-error open-sans text-sm">{data?.formErrors?.prefer_drop_picked_up_for_class}</small>
                                    ) : null}
                                </div>
                            </div>
                            <div className="grid w-full md:w-auto m-0  align-items-center">
                                <div className="md:col-5 pl-0">
                                    <p>If so, what time are they picked up?</p>
                                </div>
                                <div className="col-12 md:col-3">
                                    <InputComponent
                                        type="time"
                                        inputClass="select_time_zone"
                                        name="aftercare_picked_up_time"
                                        onChange={handleChange}
                                        value={admissionFields?.aftercare_picked_up_time}
                                        errorMessage={data?.formErrors?.aftercare_picked_up_time}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {ProgramList?.length > 0 && admissionFields?.class_type ? (
                        <h6 className="text-900 font-bold flex mb-3 mt-4 align-items-center justify-content-between">Next, click on your class</h6>
                    ) : (
                        ""
                    )}

                    <div className="grid">
                        {/*  */}

                        {ProgramList?.length > 0 &&
                            ProgramList?.map((program, i) =>
                                admissionFields?.class_type === "during_school" ? (
                                    <div className="col-12 md:col-4 ">
                                        <div
                                            className={`grid h-full m-0 border-round-lg overflow-hidden bg-whitesmoke ${
                                                admissionFields?.program_id === program.id ? "programme-selected" : ""
                                            }`}
                                            onClick={() => handlePrograms(program)}

                                            // onClick={(e) => {
                                            //   setAdmissionFields({
                                            //     ...admissionFields,
                                            //     program_id: program.id,
                                            //   });
                                            // }
                                        >
                                            <div className="col-4 p-0 bg-main flex justify-content-center align-items-center">
                                                <small className="font-bold" style={{ fontSize: "14px" }}>
                                                    Flexible Time
                                                </small>
                                            </div>
                                            <div className="col-8 p-3">
                                                <h6 className="text-sm font-semibold text-900 mb-0">{program.school_lesson.name}</h6>
                                                <p className="text-main small">
                                                    <div className="text-danger font-semibold">{program.is_full ? " Seats are full " : ""} </div>
                                                </p>
                                                <small>
                                                    <span className="text-main">Instrument:</span> {program?.instruments}
                                                    <br />
                                                    {program.is_full ? (
                                                        <div className="float-right">
                                                            <span
                                                                onClick={() => setSelectedProgramme(true)}
                                                                className="bg-main justify-content-end border-round-sm cursor-pointer p-1 m-auto"
                                                            >
                                                                Join Waitlist
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                ) : admissionFields?.class_type === "after_school" || admissionFields?.class_type === "semi_private" ? (
                                    <div className="col-12 md:col-4 ">
                                        <div
                                            className={`grid h-full m-0 border-round-lg overflow-hidden bg-whitesmoke ${
                                                admissionFields?.program_id === program.id ? "programme-selected" : ""
                                            }`}
                                            onClick={(e) => {
                                                handlePrograms(program);
                                            }}
                                        >
                                            <div className="col-4 p-0 bg-main flex justify-content-center align-items-center">
                                                <small className="font-bold" style={{ fontSize: "14px" }}>
                                                    {" "}
                                                    {program?.school_program_session?.session_start_time}
                                                </small>
                                            </div>
                                            <div className="col-8 p-3">
                                                <h6 className="text-sm font-semibold text-900 mb-0">{program.school_lesson.name}</h6>
                                                <p className="text-main small">
                                                    <div className="text-danger font-semibold">{program.is_full ? " Seats are full " : ""} </div>
                                                </p>
                                                <small>
                                                    <span className="text-main">Instrument:</span> {program?.instruments}
                                                    <br />
                                                    {program.is_full ? (
                                                        <div className="float-right">
                                                            <span
                                                                onClick={() => setSelectedProgramme(true)}
                                                                className="bg-main justify-content-end border-round-sm cursor-pointer p-1 m-auto"
                                                            >
                                                                Join Waitlist
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <p className="text-main">Open to {program?.school_lesson?.no_of_seats} Student</p>
                                                        </div>
                                                    )}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )
                            )}
                    </div>
                    {ProgramList?.length > 0 && admissionFields?.class_type && (
                        <div className="text-danger text-sm open-sans">{data?.formErrors?.program_id}</div>
                    )}
                </div>
                <div className="shadow-1 p-5 border-round-lg mb-3">
                    <h6 className=" text-900 font-semibold mb-3">Instrument Purchase Program</h6>
                    {/* {instrument?.instruments?.length > 0 &&
            instrument?.instruments?.map((inst, i) => ( */}
                    <div className="grid mt-2">
                        <>
                            <DropDownComponent
                                label="Select Instrument"
                                labelClassName="absolute text-xs z-3 text-500 open-sans bg-white form-label m-0"
                                extraClassName="col-12 md:col-4 relative z-3"
                                optionLabel="name"
                                onChange={handleChange1}
                                name="instrument_id"
                                value={admissionFields?.instrument_id}
                                options={instrument?.instruments?.map((item) => {
                                    return { name: item.name, value: item.id };
                                })}
                                errorMessage={data?.formErrors?.instrument_id}
                            />
                            <DropDownComponent
                                name="type"
                                label="Purchase type"
                                labelClassName="absolute text-xs z-3 text-500 open-sans bg-white form-label m-0"
                                extraClassName="col-12 md:col-4 relative z-3"
                                optionLabel="name"
                                options={filteredInstrumentsPurchaseType}
                                value={admissionFields?.type}
                                onChange={handleChange1}
                                errorMessage={data?.formErrors?.type}
                            />
                            <InputGroup dollar="dollar" size="col-12 md:col-4">
                                <NumberComponent
                                    label="Amount"
                                    placeholder="0"
                                    extraClassName="w-full"
                                    name="amount"
                                    value={
                                        admissionFields?.amount
                                        // admissionFields?.instruments?.type === "rent"
                                        //   ? inst.monthly_installment
                                        //   : admissionFields?.instruments?.type === "purchase"
                                        //     ? inst.purchase_amount
                                        //     : 0
                                    }
                                    dollar="dollar"
                                    disabled
                                />
                            </InputGroup>
                        </>
                    </div>
                    {/* { <div className="text-danger text-sm">{data?.formErrors?.instruments}</div>} */}
                    {/* ))} */}

                    {/*<div className="flex flex-wrap gap-3 mb-3">
            {admissionForm?.admission_form?.form?.rent_instrument && (
              <RadioButtonComponent
                name="instrument_on_purchase_or_rent"
                label="Installments"
                id="rent"
                value="rent"
                checked={
                  admissionFields?.instrument_on_purchase_or_rent === "rent"
                }
                onChange={handleChange1}
              />
            )}
            {admissionForm?.admission_form?.form?.purchase_instrument && (
              <RadioButtonComponent
                name="instrument_on_purchase_or_rent"
                label="Purchase"
                id="purchase"
                value="purchase"
                checked={
                  admissionFields?.instrument_on_purchase_or_rent === "purchase"
                }
                onChange={handleChange1}
              />
            )}
            <RadioButtonComponent
              name="instrument_on_purchase_or_rent"
              label="Nothing Needed - we have an instrument"
              value="nothing_needed"
              checked={
                admissionFields?.instrument_on_purchase_or_rent ===
                "nothing_needed"
              }
              onChange={handleChange1}
            />
          </div>
          {admissionFields?.instrument_on_purchase_or_rent !==
            "nothing_needed" && (
              <div className="grid align-items-center">
                <div className="col-3">
                  <NumberComponent
                    placeholder="$0"
                    value={
                      admissionFields?.instrument_on_purchase_or_rent === "rent"
                        ? instrument?.monthly_installment
                        : admissionFields?.instrument_on_purchase_or_rent ===
                          "purchase"
                          ? instrument?.purchase_amount
                          : null
                    }
                    readOnly
                  />
                </div>
                <div className="col-3">
                  <p className="font-bold open-sans text-gray-color m-0">
                    {admissionFields?.instrument_on_purchase_or_rent === "rent"
                      ? "Monthly Installment"
                      : admissionFields?.instrument_on_purchase_or_rent ===
                        "purchase"
                        ? "One Time Payment"
                        : null}{" "}
                  </p>
                </div>
              </div>
            )} */}
                    <p className="text-xs font-normal text-900 mb-3 open-sans">*If you need to rent an instrument, a link will be sent to you on your enrollment confirmation email. </p>
                    <p className="font-bold text-900 mb-3">$30.00 Registration Fee (Which also covers lesson materials needed)</p>
                </div>
            </form>
            <Dialog className="header-none text-center border-round-lg overflow-hidden" visible={selectedProgramme} style={{ width: "35vw" }}>
                <div className="px-3">
                    <p className="mb-3">
                        We're in the process of creating your class,which may take 1-2 weeks. To be on the waitlist, please make a deposit of{" "}
                        {ProgramList?.length > 0 && ProgramList?.map((item) => item.school_lesson.deposit_amount)} dollars
                        {/* Seats are full. if you want to be in waitlist you need to deposit
            $50 */}
                    </p>
                    <div className="">
                        <ButtonComponent
                            label="Cancel"
                            className="bg-whitesmoke text-dark border-0"
                            onClick={() => {
                                setSelectedProgramme(false);
                                setAdmissionFields({
                                    ...admissionFields,
                                    is_waiting: false,
                                });
                            }}
                        />
                        <ButtonComponent
                            label="OK"
                            className="ripple border-none bg-main font-semibold border-round-lg ml-2"
                            onClick={() => {
                                setSelectedProgramme(false);
                                setAdmissionFields({
                                    ...admissionFields,
                                    is_waiting: true,
                                });
                            }}
                        />
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default AtSchoolNewAdmissionForm;
