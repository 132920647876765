import React from "react";
import { InputText } from "primereact/inputtext";

export default function InputComponent({
    label,
    name,
    errorMessage,
    extraClassName,
    required,
    inputClass,
    labelClassName,

    ...props
}) {
    return (
        <div className={`relative ${extraClassName}`}>
            {label ? (
                <label
                    htmlFor={name}
                    className={`absolute text-xs text-500 m-0 top-0 bg-white open-sans  bg-white-color form-label z-1 ${labelClassName}`}
                >
                    {label} {required && <span className="p-error">*</span>}
                </label>
            ) : null}
            <InputText
                id={name}
                name={name}
                className={`w-full border-1 border-round-lg border-400 overflow-hidden ${inputClass} ${
                    errorMessage ? "p-invalid" : null
                }`}
                {...props}
            />
            {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
        </div>
    );
}
