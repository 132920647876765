import types from "../types";
import Constants from "../../services/constant";
import { showLoaderAction, hideLoaderAction } from "./loadAction";
import { api } from "../../services/api";
import { loadStateFn } from "../../utils/localStorage";
import moment from "moment";

export const getParentsEventsAction = (filter) => async (dispatch) => {
    const payload = {
        // date: date,
        // students: filter?.students,
        // teachers: filter?.teachers,
        // locations: filter?.schools,
        // categories: filter?.category,
    };
    dispatch(showLoaderAction());

    const res = await api("post", Constants.END_POINT.GET_EVENTS, payload, loadStateFn("token"));
    if (res.success) {
        dispatch({
            type: types.FETCH_EVENTS,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
export const getTeachersEventsAction = (filter, date, setLoading) => async (dispatch) => {
    const payload = {
        date: moment(date).format("DD-MM-YYYY"),
        students: filter?.students,
        teachers: filter?.teachers,
        locations: filter?.schools,
        categories: filter?.category,
    };
    if (setLoading) {
        setLoading(true);
    }

    const res = await api("post", Constants.END_POINT.GET_TEACHER_EVENTS, payload, loadStateFn("token"));
    if (res.success) {
        dispatch({
            type: types.GET_TEACHER_EVENTS,
            payload: res.data,
        });
    }
    if (setLoading) {
        setLoading(false);
    }
};
export const getStudentsEventsAction = (filter) => async (dispatch) => {
    const payload = {
        // date: date,
        // students: filter?.students,
        // teachers: filter?.teachers,
        // locations: filter?.schools,
        // categories: filter?.category,
    };
    dispatch(showLoaderAction());

    const res = await api("post", Constants.END_POINT.GET_STUDENT_EVENTS, payload, loadStateFn("token"));
    if (res.success) {
        dispatch({
            type: types.GET_STUDENT_EVENTS,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
