import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ViewEventAction } from "../../../../../redux/actions/TeacherPortal/attendanceAction";
import EmailComponent from "../../../../../pages/Email/EmailComponent";
import { getTeacherProfileAction } from "../../../../../redux/actions/TeacherPortal/teacherAction";

export default function CalendarEmail() {
    const { id } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(ViewEventAction(id));
    }, [dispatch, id]);
    const eventsStudent = useSelector((state) => state?.events?.ViewEvent);

    useEffect(() => {
        dispatch(getTeacherProfileAction);
    }, []);

    const teacherDetail = useSelector((state) => state?.login?.getTeacherProfile);
    const fromOption = [
        {
            name: `${teacherDetail?.name}<${teacherDetail?.email}>`,
            item: `${teacherDetail?.name}<${teacherDetail?.email}>`,
            email: teacherDetail?.email,
        },
    ];

    const [emailData, setEmailData] = useState({
        from: {},
        to: [],
        subject: "",
        graphical_mode: true,
        cc_me: false,
        send_at: "",
        scheduled_date: "",
        scheduled_time: "",
    });
    const selectedOptions =
        eventsStudent?.attendees
            ?.filter((item) => item?.email)
            ?.map((item) => {
                return {
                    label: `${item?.name}<${item?.email}>`,
                    email: item?.email,
                };
            }) || [];

    return (
        <EmailComponent emailData={emailData} setEmailData={setEmailData} selected={selectedOptions} fromOption={fromOption} />
    );
}
