import React, { useEffect } from "react";
import { Avatar } from "primereact/avatar";
import AccountIcon from "../../../../assets/icons/avtar.png";
import InvoiceIcon from "../../../../assets/icons/invoices.png";
import { useDispatch, useSelector } from "react-redux";
import { getInvoices } from "../../../../redux/actions/ParentPortal/parentAction";
import moment from "moment";
import { getParentAccountsAction } from "../../../../redux/actions/ParentPortal/accountsandInvoicesAction";
import downloadIcon from "../../../../assets/icons/downloadgreen.png";
import { useState } from "react";
import ViewIcon from "../../../../assets/icons/view.png";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Column } from "primereact/column";
import CustomCalendar from "../../../../components/Fields/CustomCalendar";
import { DataTable } from "primereact/datatable";
import ButtonComponent from "../../../../components/Fields/Button/CustomButton";
import { isAuthenticated } from "../../../../services/auth";

const AccountContent = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useLocation();

    const [open, setOpen] = useState(false);

    const location = {
        state: { referrer: { pathname: "/parent/account-invoice/", search: "?tab=invoices" } },
    };
    const authenticated = isAuthenticated();

    useEffect(() => {
        if (params.pathname.includes("/parent/dashboard")) {
            history.push("/parent/account-invoice/");
        }
    }, [params]);

    useEffect(() => {
        if (authenticated) {
            setOpen(false);
            history.push("/parent/account-invoice/?tab=invoices");
        } else {
            setOpen(true);
        }
    }, [authenticated]);
    useEffect(() => {
        if (authenticated) {
            dispatch(getInvoices());
            dispatch(getParentAccountsAction());
        }
    }, [dispatch, authenticated]);
    const [invoiceView, setInvoiceView] = useState(false);
    const invoices = useSelector((state) => state.parent.getInvoices);
    const accounts = useSelector((state) => state?.accounts?.getParentAccounts);
    const [makePayment, setMakePayment] = useState(null);
    const [openCard, setOpenCard] = useState(false);
    const [amount, setAmount] = useState(0);
    const [selectedInvoice, setSelectedInvoice] = useState({});
    const [activeIndex, setActiveIndex] = useState(0);
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const tab = searchParams.get("tab");
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        let idx = 0;
        if (tab) {
            idx = tabs.findIndex((obj) => obj.title === tab);
        }
        setActiveIndex(idx);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);
    const statusTemplate = (rowData) => {
        return (
            <>
                {rowData?.status === "paid" ? (
                    <i class="pi pi-money-bill text-green-600"></i>
                ) : rowData?.status === "declined" ? (
                    <i class="pi pi-money-bill text-red-500"></i>
                ) : (
                    <i class="pi pi-money-bill"></i>
                )}
            </>
        );
    };

    const dateRange = (rowData) => {
        return (
            <>
                {rowData?.start_date
                    ? `${moment(rowData?.start_date).format("MMMM-DD-YYYY")} - ${moment(rowData?.end_date).format(
                          "MMMM-DD-YYYY"
                      )}`
                    : "----------------------"}
            </>
        );
    };

    const paymentTemp = (r) => {
        return (
            <>
                {r.status === "pending" || r.status === "declined" ? (
                    <div className="text-green cursor-pointer">
                        <ButtonComponent
                            label={r?.status === "pending" ? "Make Payment" : "Repay"}
                            onClick={() => {
                                setMakePayment(r.id);
                                setAmount(r.amount);
                            }}
                            style={{ padding: "8px 15px", fontSize: "12px" }}
                        />
                    </div>
                ) : (
                    "Paid"
                )}
            </>
        );
    };
    const dateTemplate = (rowData) => {
        return <>{moment(rowData.date).format("MMMM/DD/YYYY")}</>;
    };
    const dateRangeTemplate = (rowData) => {
        return <>{"------"}</>;
    };
    const invoiceAmountTemplate = (rowData) => {
        return <div className="text-green">${rowData?.amount}</div>;
    };
    const charges = (rowData) => {
        return <>{rowData?.type === "charge" ? <div className="text-red">${rowData?.amount}</div> : null}</>;
    };
    const discount = (rowData) => {
        return <>{rowData?.type === "discount" ? <div className="text-red">${rowData?.amount}</div> : null}</>;
    };
    const payment = (rowData) => {
        return <>{rowData?.type === "payment" ? <div className="text-green">${rowData?.amount}</div> : null}</>;
    };
    const refund = (rowData) => {
        return <>{rowData?.type === "refund" ? <div className="text-green">${rowData?.amount}</div> : null}</>;
    };
    const balance = (rowData) => {
        return (
            <>
                {rowData?.balance >= 0 ? (
                    <div className="text-green">${rowData?.balance}</div>
                ) : (
                    <div className="text-red">(${Math.abs(rowData?.balance)})</div>
                )}
            </>
        );
    };
    const downloadInvoice = (col) => {
        setSelectedInvoice({
            invoiceId: col.id,
            type: "download",
        });
    };

    const viewInvoice = (col) => {
        setSelectedInvoice({
            invoiceId: col.id,
            type: "view",
        });
        setInvoiceView(true);
    };
    const InvoiceAction = (col) => {
        return (
            <div className="align-items-center px-3 p-2 open-sans font-semibold flex " style={{ cursor: "pointer" }}>
                <img
                    src={ViewIcon}
                    alt=""
                    width="auto"
                    height="10.5px"
                    className="product-image cursor-pointer"
                    onClick={() => viewInvoice(col)}
                />

                <img
                    src={downloadIcon}
                    alt=""
                    width="auto"
                    height="18.5px"
                    className="product-image ml-3 cursor-pointer"
                    onClick={() => downloadInvoice(col)}
                />
            </div>
        );
    };

    const Invoices = () => {
        return (
            <>
                <DataTable
                    className="border-1 border-round-lg overflow-hidden border-400"
                    value={invoices}
                    stripedRows
                    tableStyle={{ minWidth: "50rem" }}
                >
                    <Column field="status" body={statusTemplate} header="Status"></Column>
                    <Column field="quickbook_invoice_id" header="Invoice #"></Column>
                    <Column
                        body={(rowData) =>
                            rowData.due_date ? moment(rowData.date).format("MMMM/DD/YYYY") : "-----------------------"
                        }
                        header="Invoice Date"
                    ></Column>
                    <Column
                        body={(rowData) =>
                            rowData.due_date ? moment(rowData.due_date).format("MMMM/DD/YYYY") : "-----------------------"
                        }
                        header="Due Date"
                    ></Column>
                    <Column body={dateRange} header="Date Range"></Column>
                    <Column field="amount" body={(r) => `$ ${r.amount}`} header="Invoice Amount"></Column>
                    <Column body={paymentTemp} header="Payment"></Column>
                    <Column body={InvoiceAction} header="Action"></Column>
                </DataTable>
            </>
        );
    };
    const Account = () => {
        return (
            <>
                <div className="shadow-sm border-round-lg overflow-hidden">
                    <div className="table-top-header border-round-top-xl bg-main p-3">
                        <div className="flex justify-content-between">
                            <div className="flex justify-content-between">
                                <div className="flex align-items-center open-sans font-semibold text-xs ml-5">
                                    <CustomCalendar
                                        size="md:col-16"
                                        showWeek
                                        value={date}
                                        onChange={(e) => {
                                            setDate(e.value);
                                            dispatch(getParentAccountsAction(e.value));
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        className="student-info-table"
                        value={accounts?.transactions}
                        stripedRows
                        tableStyle={{ minWidth: "50rem" }}
                    >
                        <Column field="student" header="Student"></Column>
                        <Column body={dateTemplate} header="Date"></Column>
                        <Column field="description" header="Description"></Column>
                        <Column field="discount" body={discount} header="Discounts"></Column>
                        <Column field="payment" body={payment} header="Payments"></Column>
                        <Column body={charges} header="Charges"></Column>
                        <Column body={refund} header="Refunds"></Column>
                        <Column body={balance} header="Balance"></Column>
                    </DataTable>
                </div>
            </>
        );
    };
    const TabViewTemplate = (options) => {
        return (
            <div
                className="flex align-items-center px-3 p-2 open-sans font-semibold"
                style={{ cursor: "pointer" }}
                onClick={options.onClick}
            >
                <Avatar image={AccountIcon} className="mx-2 w-3 h-1rem" />
                Account
            </div>
        );
    };
    const TabViewTemplate1 = (options) => {
        return (
            <div
                className="flex align-items-center px-3 p-2 open-sans font-semibold"
                style={{ cursor: "pointer" }}
                onClick={options.onClick}
            >
                <Avatar image={InvoiceIcon} className="mx-2 w-3 h-1rem" />
                Invoices
            </div>
        );
    };

    const tabs = [
        {
            title: "account",
            content: Account(),
            headerTemplate: TabViewTemplate,
        },
        {
            title: "invoices",
            content: Invoices(),
            headerTemplate: TabViewTemplate1,
        },
    ];
    const handleTabChange = ({ index }) => {
        let tab = tabs[index].title;
        history.replace({
            search: "?tab=" + tab,
        });
    };

    return {
        accounts,
        makePayment,
        setMakePayment,
        openCard,
        setOpenCard,
        amount,
        selectedInvoice,
        invoiceView,
        setInvoiceView,
        activeIndex,
        handleTabChange,
        tabs,
        location,
        open,
        setOpen,
    };
};
export default AccountContent;
