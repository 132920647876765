import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import New2 from "./New2";
import SchoolBgImg from "../assets/images/formbg.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useUnload from "../components/useUnload";

export default function New() {
    const schoolForms = useSelector((state) => state?.enroll?.students);
    const [activeIndex, setActiveIndex] = useState(0);
    const history = useHistory();

    useUnload((e) => {
        e.preventDefault();
        e.returnValue = "";
    });

    useEffect(() => {
        if (!schoolForms?.[0].enroll_type) {
            history.push("/enroll");
        }
    }, [schoolForms?.[0]?.enroll_type]);

    return (
        <div>
            <div
                className="h-20rem md:h-27rem flex justify-content-center align-items-center background-overlay relative"
                style={{ backgroundImage: `url('${SchoolBgImg}')`, backgroundSize: "cover" }}
            >
                <h1 className="text-4xl md:text-6xl text-center relative text-white">Start Your Music Journey with Us</h1>
            </div>
            {schoolForms.map((item, i) => (
                <New2
                    initialState={item}
                    index={i}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    isLast={schoolForms.length === i + 1}
                    length={schoolForms.length}
                    isRemove={schoolForms.length - 1}
                />
            ))}
        </div>
    );
}
