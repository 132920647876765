import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { ViewSchoolLessonsAction } from "../../../redux/actions/schoolAction";

export default function SchoolDetail({ viewSchoolDetail }) {
  const [programDetail, setProgramDetails] = useState(false)
  const dispatch = useDispatch();

  const viewSchoolLessons = useSelector((state) => state?.school?.viewSchoolLessons);
  const viewProgramDetails = (id) => {
    dispatch(ViewSchoolLessonsAction(id));
    setProgramDetails(true)
  }

  const purchaseAmount = (rowData) => {
    return <div>${rowData?.purchase_amount}</div>;
  };
  const MonthlyInstallment = (rowData) => {
    return <div>${rowData?.monthly_installment}</div>;
  };

  const depositAmount = (rowData) => {
    return <div>${rowData?.deposit_amount}</div>;
  };

  const ActionTemplate = (col) => {
    return (
      <div className="text-main small cursor-pointer" closeIcon onClick={() => viewProgramDetails(col.school_lesson_id)}>
        <h6>View</h6>
      </div>
    );
  };

  return (
    <div className="w-full mobile-view">
      <Dialog
        header={viewSchoolLessons?.name}
        headerClassName="program-detail-model"
        visible={programDetail}
        style={{ width: "50vw" }}
        onHide={() => setProgramDetails(false)}
      >
        <div className="program-detail-table">
          <DataTable
            value={viewSchoolLessons?.school_lesson_group}
            showGridlines
            className="border-round-lg overflow-hidden text-dark"
          >
            <Column
              field="number_of_student"
              header="No. of Student"
            ></Column>
            <Column field="discount" header="Discount" body={(r) => r.discount + "%"}></Column>
            <Column field="installment" header="Installment" body={(r) => "$" + r.installment}></Column>
            <Column field="number_of_installment" header="No. of Installment"></Column>
            <Column field="prepayment_discount" header="Prepayment Discount" body={(r) => "$" + r.prepayment_discount}></Column>
          </DataTable></div>
        <div className="p-3"
          dangerouslySetInnerHTML={{
            __html:
              viewSchoolLessons?.description
          }}
        ></div>
      </Dialog>
      <Accordion className="enrollform">
        <AccordionTab header="Programs" className="border-none">
          <div className="card mb-3 border-none">
            <DataTable
              value={viewSchoolDetail?.grade}
              showGridlines
              className="border-round-lg overflow-hidden text-dark"
            >
              <Column
                field="school_type"
                body={(r) =>
                  r.school_type === "during_school"
                    ? "During School"
                    : r.school_type === "after_school"
                      ? "After School"
                      : r.school_type === "semi_private_during_school"
                        ? "Semi-Private (During School)"
                        : "Semi-Private(After School)"

                }
                header="Program Type"
                style={{ width: "2rem" }}
              ></Column>
              <Column field="program_name" header="Program Name"></Column>
              <Column field="instruments_name" header="Instrument"></Column>
              <Column
                field="grades"
                body={(r) =>
                  r?.grades
                    ?.sort((a, b) => a - b)
                    ?.map((item, index) =>
                      index === r?.grades?.length - 1 ? item : item + ","
                    )
                }
                header="Grade"
              ></Column>
              <Column body={ActionTemplate} header="Action"></Column>
            </DataTable>
          </div>
        </AccordionTab>
        <AccordionTab header="Class Schedule">
          <div className="dropdown mb-3">
            <p className="font-bold text-red mb-2 open-sans">
              <b>{viewSchoolDetail?.school?.schedule_title}</b>
            </p>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: viewSchoolDetail?.school?.class_schedule_description,
            }}
            className="text-xs open-sans class-schedule"
          ></div>
        </AccordionTab>
        <AccordionTab header="Instrument Purchase Plan-*If Needed*">
          <p className="text-900 font-bold text-lg mb-2">Instrument Included</p>
          <div className="card mb-3">
            <DataTable value={viewSchoolDetail?.instruments} showGridlines>
              <Column field="name" header="Instrument"></Column>
              <Column body={purchaseAmount} header="Purchase Amount"></Column>
              <Column
                field={MonthlyInstallment}
                header="Monthly Installment"
              ></Column>
              <Column
                field="number_of_installment"
                header="No of Installment"
              ></Column>
              <Column body={depositAmount} header="Deposit Amount"></Column>
            </DataTable>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html:
                viewSchoolDetail?.instrument_prurchase_plan?.purchase_plan,
            }}
          ></div>
        </AccordionTab>
      </Accordion>
    </div>
  );
}
