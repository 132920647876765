import React from "react";
import { RadioButton } from "primereact/radiobutton";

export default function RadioButtonComponent({ label, name, errorMessage, extraClassName, required, labelClassName, ...props }) {
    return (
        <div className={`flex align-items-center justify-content-left ml-2 ${extraClassName}`}>
            <RadioButton id={name} name={name} {...props} />
            {errorMessage ? <small className="p-error">{errorMessage}</small> : null}
            &nbsp;&nbsp;
            <label htmlFor={name} className={`m-0 ${labelClassName}`}>
                {label} {required && <span className="p-error">*</span>}
            </label>
        </div>
    );
}
