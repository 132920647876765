import types from "../../types";
import Constants from "../../../services/constant";
import { showLoaderAction, hideLoaderAction } from "../loadAction";
import { api } from "../../../services/api";

export const getParentNewsAction = () => async (dispatch) => {
  dispatch(showLoaderAction());

  const res = await api("get", Constants.END_POINT.NEWS);


  if (res.success) {
    dispatch({
      type: types.GET_PARENT_NEWS,
      payload: res.data,
    });
  }

  dispatch(hideLoaderAction());
};