import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStudentEvents } from "../../../../redux/actions/studentAction";
import { Accordion, AccordionTab } from "primereact/accordion";
import WomenIcon from "../../../../assets/icons/womenicon.png";
import VideoCam from "../../../../assets/icons/videocamicon.png";
import CalendarIcon from "../../../../assets/icons/calendar-white.png";
import moment from "moment";

const StudentEvents = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStudentEvents());
  }, [dispatch]);

  const studentDashboard = useSelector(
    (state) => state?.studentInfo?.dashboard
  );

  return (
    <>
      <h3 className="open-sans text-main font-semibold mb-3">
        Upcoming Events
      </h3>
      <div className="grid mb-3">
        {studentDashboard?.upcoming_events?.length > 0 ?
          studentDashboard?.upcoming_events?.map((item, i) => {
            return (
              <div className="md:col-4 p-2" key={i}>
                <div className="bg-light-main p-0 relative border-round-lg overflow-hidden w-full">
                  <Accordion className="m-0 upcoming-event teacher-upcoming">
                    <AccordionTab
                      header={
                        <div className="h-full w-100 grid m-0">
                          <div className="md:col-4 bg-main">
                            <p className="text-xs text-white justify-content-center font-semibold open-sans p-3 text-center flex flex-column align-items-center h-100">
                              <img
                                src={CalendarIcon}
                                width={20}
                                alt=""
                                className="mb-2"
                              />
                              {moment(item.date).format("DD MMMM")}
                            </p>
                          </div>
                          <div className="md:col-8 p-0">
                            <div className="p-3 w-11">
                              <p className="vertical-align-middle open-sans text-dark font-semibold mb-2  headingsize">
                              {item.category_name}
                              </p>
                              <small className="open-sans text-900 font-semibold  datasize">
                              {item.start_time} - {item.end_time}
                              </small>
                            </div>
                          </div>
                        </div>
                      }
                      className="m-0 border-none "
                    >
                      <div className="grid m-0 h-full">
                        <div className="md:col-4 p-1 bg-main">
                          <hr className="m-0 border-white" />
                          
                        </div>
                        <div className="md:col-8 p-1">
                          <hr className="m-0 border-white" />
                          <div className="p-2 mb-5">
                            <div className="flex align-items-end justify-content-between mb-2">
                              <div className="flex">
                              <img src={WomenIcon} alt="" width={20} />
                            <p className="font-semibold open-sans ml-2">
                              {item.teacher}
                            </p>
                              </div>
                            </div>
                            <div className="flex align-items-end justify-content-between mb-2">
                              <div className="flex">
                              <img src={VideoCam} alt ="" width={20} />
                            <p className="font-semibold open-sans ml-2">
                              {item.subsititute_teacher}
                            </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </AccordionTab>
                  </Accordion>
                  {/* <ButtonComponent
                    label="Cancel Lesson"
                    className="absolute bottom-0 right-0 bg-light-red text-red-500 border-0 open-sans font-semibold text-sm radius-max p-2"
                  /> */}
                  {/* <div className="md:col-3 bg-main flex align-items-center justify-content-center">
                    <p className="text-xs flex flex-column text-white align-items-center justify-content-center font-semibold open-sans p-2 h-full text-center">
                      <img src={CalendarIcon} width={20} className="mb-2" />
                      {moment(item.date).format("DD MMMM")}
                    </p>
                  </div>
                  <div className="md:col-9 bg-light-main p-0 relative">
                    <Accordion className="m-0 upcoming-event ">
                      <AccordionTab
                        header={
                          <div className="h-full">
                            <p className="vertical-align-middle open-sans text-dark font-semibold mb-2 text-lg">
                              {item.category_name}
                            </p>
                            <small className="open-sans text-900 text-sm">
                              {item.start_time} - {item.end_time}
                            </small>
                          </div>
                        }
                        className="m-0 border-none h-full "
                      >
                        <div className="">
                          <div className="flex mb-2">
                            <img src={WomenIcon} width={20} />
                            <p className="font-semibold open-sans ml-2">
                              {item.teacher}
                            </p>
                          </div>
                          <div className="flex mb-2">
                            <img src={VideoCam} width={20} />
                            <p className="font-semibold open-sans ml-2">
                              {item.subsititute_teacher}
                            </p>
                          </div>
                        </div>
                        <ButtonComponent
                          label="Cancel Lesson"
                          className="absolute bottom-0 right-0 bg-light-red text-red-500 border-0 open-sans font-semibold text-sm radius-max p-2"
                        />
                      </AccordionTab>
                    </Accordion>
                  </div> */}
                </div>
              </div>
            );
          }):"No Upcoming Events"}
      </div>
    </>
  );
};

export default StudentEvents;
