import React, { useState, useEffect } from "react";
import Navbar from "../../shared/Navbar";
import bannerRight from "../../assets/images/banner.png";
import fmiimage from "../../assets/images/FMIWhite.png";
import CurveArrow from "../../assets/icons/arrow.png";
import Music from "../../assets/icons/music.png";
import ProgrameCardContent from "../../components/card/ProgramCardContent";
import ProgramCard from "../../components/card/ProgramCard";
import Benifit from "../../assets/images/Rectangle23067.png";
import InstrumentMusic from "../../components/card/InstrumentMusic.js";
import Client from "../../assets/images/ed.jpeg";
import Client1 from "../../assets/images/team-211.png";
import Client2 from "../../assets/images/team-2b-11.png";
import { Link } from "react-router-dom";
import facebook from "../../assets/icons/Vector.png";
import Dott from "../../assets/icons/dot.png";
import Polygon from "../../assets/icons/Polygon.png";
import Ellipse from "../../assets/icons/Ellipse.png";
import Calendar from "../../assets/icons/calendar.png";
import ReviewCarousel from "../../components/carousel/ReviewCarousel";
import Logo from "../../assets/logo/logo.png";
import Locationimg from "../../assets/icons/location.png";
import Phone from "../../assets/icons/phone.png";
import Message from "../../assets/icons/message.png";
import DataValue from "./main";
import { LoginDialog } from "./loginDialog";
import Footer from "../../shared/Footer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isAuthenticated } from "../../services/auth";
import { useDispatch } from "react-redux";
import { resetFields } from "../../redux/actions/newEnrollmentAction.js";

var base64 = require("base-64");

export default function Home(props) {
    const { newsData } = DataValue();
    const history = useHistory();
    const dispatch = useDispatch();

    const { ProgramCardData } = ProgrameCardContent();
    const { InstrumentData } = InstrumentMusic();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        document.documentElement.style.fontSize = 14 + "px";
        if (isAuthenticated()) {
            const role = localStorage.getItem("Role");
            const role1 = base64.decode(role);
            if (role1 === "STUDENT") {
                history.push("/student/dashboard");
            } else if (role1 === "TEACHER") {
                history.push("/teacher-dashboard/");
            } else if (role1 === "PARENT") {
                history.push("parent/dashboard");
            }
        }
    }, [history]);

    const handleEnroll = () => {
        dispatch(resetFields());
        history.push("/enroll");
    };

    const [selectUserType, setSelectUserType] = useState("STUDENT");
    return (
        <>
            <Navbar visible={visible} setVisible={setVisible} handleEnroll={handleEnroll} />
            <LoginDialog selectUserType={selectUserType} setSelectUserType={setSelectUserType} visible={visible} setVisible={setVisible} />

            {/* banner */}
            <marquee className="sliding-text block lg:hidden" width="100%" direction="left">
                Thank you for visiting our website! While it currently shines brightest on desktop, we're diligently crafting a responsive design to ensure a
                seamless experience across all devices. Your patience is highly appreciated. Thankyou. If you face any issues during enrollment, Feel free to
                reach us at - 1. Contact info - [908-244-4943] 2. Email - ebrassman@gmail.com Or info@fundamentalmusicinstruction.com
            </marquee>
            <div className="banner">
                <div className="grid  m-0">
                    <div className="left-banner lg:col-7 md:col-12 sm:col-12 relative bg-main  h-100 vertical-center ">
                        <img src={fmiimage} className="absolute w-5 top-10" alt="" />
                        <div className="spacer d-none d-xxl-block "></div>
                        <h1 className="heading mb-3 font-semibold text-dark">
                            <span className="">Music </span>
                            <br />
                            is Life Changing
                            <br />
                            <span className=""> Music Speaks</span>
                        </h1>
                        <div className="flex justify-content-between align-items-center">
                            <p className="text-lg open-sans">
                                Music lessons available either At-School or Online
                                <br />
                                We offer music lessons for individuals of just about every age and skill level. Classes are available at select schools or
                                online.
                            </p>
                            <img src={CurveArrow} className="w-2" alt="" />
                        </div>
                        <div className="flex">
                            <button
                                className="p-3 px-5 bg-yellow border-none text-sm border-round-lg relative text-lg cursor-pointer open-sans"
                                onClick={handleEnroll}
                            >
                                Enroll Now or Learn More
                            </button>
                        </div>
                        <img src={Music} className="w-4 absolute music-img opacity-20" alt="" />
                    </div>
                    <div className="right-banner lg:col-5 md:col-12 p-0 bg-light-green h-100 relative">
                        <img src={bannerRight} className="w-full h-full cover-img" alt="" />
                    </div>
                </div>
            </div>

            {/* banner-end */}

            {/* program-information */}
            <div className="program-info p-8 padding-none">
                <div className="text-center mb-5">
                    <h2 className="text-main font-bold mb-3">Program Information</h2>
                    <p className="text-dark font-normal line-height-4 open-sans mb-3">
                        Music is fundamental to a well-rounded education. FMI has been providing instrumental music programs to individuals and to schools of
                        all types for more than 40 years. We tailor our program to each community we serve. In today's diverse societal needs, FMI dedicates
                        itself to providing the highest quality education in every setting.
                    </p>
                    <p className="text-dark font-normal line-height-4 open-sans">
                        The FMI faculty consists of professional music educators. Our talented staff all have degrees from top music schools. Many of our
                        teachers have Masters Degrees and several have Doctoral Degrees.
                    </p>
                </div>
                <div className="grid">
                    {ProgramCardData.map((data, key) => {
                        return (
                            <div key={key} className="lg:col-3 program-info-card  md:col-2">
                                <ProgramCard to={data.redirect} image={data.image} title={data.title} description={data.description} path={data.path} />
                            </div>
                        );
                    })}
                </div>
            </div>

            {/* program-end */}

            {/* benifits */}
            <div className="p-8 padding-none">
                <div className="grid align-items-center justify-content-between">
                    <div className="lg:col-5">
                        <div className="benifit-img relative">
                            <img src={Benifit} className="w-full h-full" alt="" />
                        </div>
                    </div>
                    <div className="lg:col-6">
                        <div className="">
                            <h2 className="text-main mb-3 font-bold">The Benefits of Playing Music</h2>
                            <p className="font-normal text-dark open-sans mb-3 line-height-3">
                                No matter how old you are, music lessons reap tremendous benefits! For every age level, scientists have discovered that playing
                                music creates happiness. Really! The brain releases dopamine, a feel good chemical. It can push back depression, frustration,
                                and anxiety. Who doesn’t want that? Playing music can reduce the risks of dementia, and improve cognitive functioning in older
                                adults.
                            </p>
                            <p className="font-normal text-dark open-sans line-height-3 mb-3">
                                For children, scientific research illustrates how music lessons increase children’s IQ’s! Wondering what instruments you can
                                learn through Fundamental Music Instruction programs? There is something for everyone to love! We offer flute, clarinet,
                                saxophone, trumpet, trombone, guitar, ukulele, bass guitar, piano, drums, cello and violin.
                            </p>
                            <p className="font-normal text-dark open-sans line-height-3 mb-3">
                                Not sure what instrument to start with? Why not schedule your first lesson at school and learn more about each instrument? Not a
                                student at one of our schools or prefer lessons off school time? Schedule an online lesson to to get started.
                            </p>
                            <p className="font-normal text-dark open-sans line-height-3 mb-3">
                                *Note: If Online, parents may wish to learn along with young students*
                            </p>
                            <Link to="/enroll" className="no-underline">
                                <button className="bg-dark text-white text-base py-3 px-5 border-round-lg border-none open-sans">Schedule a Lesson</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* benifit end */}

            {/* instrument */}
            <div className="p-8 padding-none">
                <div className="text-center mb-3">
                    <h2 className="text-main font-bold mb-3">Musical Instruments</h2>
                    <p className="text-dark open-sans">
                        Click on an instrument for some general information and sample Lesson Support Video, (We also teach Ukulele, Cello, Voice and Music
                        Technology)
                        <br /> (Below are instruments available for grades 3 + Older)
                    </p>
                </div>
                <div className="flex justify-content-center flex-wrap">
                    {InstrumentData.map((data, key) => {
                        return (
                            <a href={data.redirect} className="no-underline">
                                <div
                                    key={key}
                                    className="instrument-card bg-light-main cursor-pointer ml-2 mt-3 text-center p-3 border-0 h-100 border-round-lg"
                                    style={{ width: "13rem" }}
                                >
                                    <img src={data.image} className="mb-3" width="80" height="90" alt="" />
                                    <h4 className="open-sans text-dark">{data.title}</h4>
                                </div>
                            </a>
                        );
                    })}
                </div>
            </div>

            {/* instrument-end */}

            {/* instrument and accessories */}
            <div className="p-8 padding-none accessories">
                <div className="text-center relative">
                    <h2 className="text-white font-bold mb-3">Instruments and Accessories</h2>
                    <p className="text-white mb-3">
                        Introducing our new Instrument Purchase Plan and own a quality instrument in 9-18 payments! Our Online Shop Page is your 1 stop store
                        for all your musical needs
                    </p>
                    <div className="grid justify-content-center">
                        <div className="xxl:col-4 mb-2">
                            <a
                                href="https://fundamentalmusicinstruction.com/2020/11/shopping-cyber-monday-give-music-lesson-merriest-gift/"
                                className="no-underline text-dark"
                                target="_blank"
                            >
                                <div className="bg-yellow p-4 px-5 border-round-xl">Explore our Store to Purchase an instrument in One Payment</div>
                            </a>
                        </div>
                        <div className="xxl:col-4 ml-2">
                            <a
                                href="https://fundamentalmusicinstruction.mymusicstaff.com/Instrument-Purchase-Plan-and-Accessories"
                                className="no-underline text-dark"
                                target="_blank"
                            >
                                <div className="bg-yellow p-4 px-5 border-round-xl">Need an instrument? Order an Instrument in 6 -12 Payments</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* instrument & accesseries end */}

            {/* benifit of instrumental */}
            <div className="p-8 padding-none">
                <div className="text-center mb-3">
                    <h2 className="text-main font-bold mb-3">The Benefits of Instrumental Music for Brain Development</h2>
                    <p className="text-dark open-sans mb-4">
                        When you listen to music, multiple areas of your brain become engaged and active. But when you actually play an instrument, that
                        activity becomes more like a full-body brain workout. What’s going on? Anita Collins explains the fireworks that go off in musicians’
                        brains when they play, and examines some of the long-term positive effects of this mental workout.
                    </p>
                    <div className="grid justify-content-center">
                        <div className="xl:col-7 lg:col-10">
                            <iframe
                                className="w-full"
                                style={{ height: "30rem" }}
                                src="https://www.youtube.com/embed/R0JKCYZ8hng"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            {/* benifit of instrumental end */}

            {/* teachers */}
            <div className="p-8 padding-none relative">
                <img src={Dott} className="absolute right-0 top-0 w-1" alt="" />
                <img src={Dott} className="absolute left-0 bottom-0 w-1" alt="" />
                <img src={Ellipse} className="absolute left-0 w-1 ellipse" alt="" />
                <div className="text-center mb-3 relative">
                    <img src={Polygon} alt="" className="absolute left-0 top-0 " width="74" />

                    <h2 className="text-main font-bold mb-3">Our Music Teachers</h2>
                    <p className="text-dark open-sans mb-4">Talented, Experienced, Top Notch</p>
                    <div className="grid justify-content-center">
                        <div className="xl:col-3 lg:col-3 col-12">
                            <div className="">
                                <div className="bg-goldan flex mb-3 justify-content-center align-items-end text-center client-img">
                                    <img src={Client} style={{ width: "100%", height: "22rem", objectFit: "cover" }} alt="" />
                                </div>
                                <div className="flex justify-content-between">
                                    <div className="client-info">
                                        <h4 className="open-sans font-semibold text-lg mb-2">Mr. Ed Kelly</h4>
                                        <p className="text-xs text-dark open-sans">Director of Bands</p>
                                    </div>
                                    <div className="icons">
                                        <p className="mb-2">
                                            <Link to="/">
                                                <i className="pi pi-twitter text-main" style={{ fontSize: "1.2rem" }}></i>
                                            </Link>
                                        </p>
                                        <p>
                                            <Link to="/">
                                                <img src={facebook} width="10" alt="" />
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-3 lg:col-3 col-12">
                            <div className="">
                                <div className="bg-goldan flex mb-3 justify-content-center align-items-end text-center client-img">
                                    <img src={Client1} alt="" style={{ width: "85%", height: "21rem" }} />
                                </div>
                                <div className="flex justify-content-between">
                                    <div className="client-info">
                                        <h4 className="open-sans font-semibold text-lg mb-2">Mr. Brian Franca</h4>
                                        <p className="text-xs text-dark open-sans text-left">Saxophonist</p>
                                    </div>
                                    <div className="icons">
                                        <p className="mb-2">
                                            <Link to="/">
                                                <i className="pi pi-twitter text-main" style={{ fontSize: "1.2rem" }}></i>
                                            </Link>
                                        </p>
                                        <p>
                                            <Link to="/">
                                                <img src={facebook} width="10" alt="" />
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-3 lg:col-3 col-12">
                            <div className="">
                                <div className="bg-goldan flex mb-3 justify-content-center align-items-end text-center client-img">
                                    <img src={Client2} alt="" style={{ width: "85%", height: "21rem" }} />
                                </div>
                                <div className="flex justify-content-between text-left">
                                    <div className="client-info">
                                        <h4 className="open-sans font-semibold text-lg mb-2">Mr. Della Pello</h4>
                                        <p className="text-xs text-dark open-sans">Guitarist, Singer & Songwriter</p>
                                    </div>
                                    <div className="icons">
                                        <p className="mb-2">
                                            <Link to="/">
                                                <i className="pi pi-twitter text-main" style={{ fontSize: "1.2rem" }}></i>
                                            </Link>
                                        </p>
                                        <p>
                                            <Link to="/">
                                                <img src={facebook} width="10" alt="" />
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* teachers end */}

            {/* Latest News */}
            <div className="p-8 padding-none">
                <div className="text-center mb-3 relative">
                    <h2 className="text-main font-bold mb-3">Latest News</h2>
                    <div className="grid justify-content-center">
                        {newsData.map((data, i) => {
                            return (
                                <div className="xl:col-4 lg:col-4" key={i}>
                                    <div className="flex flex-column  h-full news-description">
                                        <div className="news-img relative overflow-hidden cursor-pointer">
                                            <img src={data.img} className="w-full h-full cover-img" alt="" />
                                            <div className="news-overlay absolute w-100 h-100 top-0">
                                                <p className="text-white flex font-bold text-lg open-sans justify-content-center align-items-center h-full">
                                                    Read more &nbsp;
                                                    <i className="pi pi-arrow-right" style={{ fontSize: "1rem" }}></i>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="text-left p-2 ">
                                            <h3 className="open-sans text-xl font-bold mb-3">{data.heading}</h3>
                                            <p className="text-lg open-sans font-normal mb-3 news-para">{data.description}</p>
                                            <p className="flex align-items-center text-lg open-sans font-normal mb-3">
                                                <img src={Calendar} alt="" width="20" />
                                                &nbsp;{data.date}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        {/* <div className="xl:col-4 lg:col-4">
              <div className="">
                <div className="text-left p-2 news-description">
                  <h3 className="open-sans text-xl font-bold mb-3">
                    Trumpet Lessons for Beginners – Starting Right
                  </h3>
                  <p className="text-lg open-sans font-normal mb-3 news-para">
                    Set the Tone When deciding to take Trumpet lessons for
                    beginners – getting started right is the most important
                    consideration. Find the best...
                  </p>
                  <p className="flex align-items-center text-lg open-sans font-normal mb-3">
                    <img src={Calendar} alt="" width="20" />
                    &nbsp;March 29, 2021
                  </p>
                </div>
                <div className="news-img relative overflow-hidden cursor-pointer">
                  <img src={News1} className="w-full h-full cover-img" alt="" />
                  <div className="news-overlay absolute w-100 h-100  top-0">
                    <p className="text-white flex font-bold text-lg open-sans justify-content-center align-items-center h-full">
                      Read more &nbsp;
                      <i
                        className="pi pi-arrow-right"
                        style={{ fontSize: "1rem" }}
                      ></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:col-4 lg:col-4">
              <div className="">
                <div className="news-img relative overflow-hidden cursor-pointer">
                  <img src={News2} className="w-full h-full cover-img" alt="" />
                  <div className="news-overlay absolute w-100 h-100 top-0">
                    <p className="text-white flex font-bold text-lg open-sans justify-content-center align-items-center h-full">
                      Read more &nbsp;
                      <i
                        className="pi pi-arrow-right"
                        style={{ fontSize: "1rem" }}
                      ></i>
                    </p>
                  </div>
                </div>
                <div className="text-left p-2 news-description">
                  <h3 className="open-sans text-xl font-bold mb-3">
                    Instruments are for any age
                  </h3>
                  <p className="text-lg open-sans font-normal mb-3 news-para">
                    Instruments are for every age Are you looking for virtual
                    music lessons? Learning to play an instrument is for any
                    age. One of the most frequent...
                  </p>
                  <p className="flex align-items-center text-lg open-sans font-normal mb-3">
                    <img src={Calendar} alt="" width="20" />
                    &nbsp;March 26, 2021
                  </p>
                </div>
              </div>
            </div> */}
                    </div>
                </div>
            </div>

            {/* Latest News end */}

            {/* touch stripe */}
            <div className="p-5 padding-none bg-main">
                <div className="flex align-items-center px-8 justify-content-between flex-wrap">
                    <p className="text-lg text-900 open-sans">Reach out to us if you have more enquiries</p>
                    <Link
                        to="/"
                        className="no-underline"
                        onClick={(e) => {
                            window.location.href = "mailto:info@fundamentalmusicinstruction.com";
                        }}
                    >
                        <button className="bg-dark p-3 text-white px-6 text-sm border-none border-round-lg">Get in Touch</button>
                    </Link>
                </div>
            </div>

            {/* testimonial */}
            <ReviewCarousel />
            {/* testimonial */}

            {/* footer */}
            <div className="p-8 padding-none bg-main">
                <div className="grid">
                    <div className="xl:col-3 lg:col-4">
                        <img src={Logo} alt="" className="w-7 mb-3" />
                        <p className="text-white text-sm open-sans font-normal line-height-3">
                            Hello, we are fundamental trying to make an effort to put the right people for you to get the best results. Just insight
                        </p>
                    </div>
                    <div className="xl:col-1 "></div>
                    <div className="xl:col-4 lg:col-4">
                        <h4 className="text-900 text-lg font-bold mb-3">Useful Links</h4>
                        <div className="flex align-items-center text-white mb-3">
                            <i className="pi pi-chevron-right" style={{ fontSize: ".75rem" }}></i>
                            &nbsp;
                            <p className="text-sm open-sans">Student Resources</p>
                        </div>
                        <div className="flex align-items-center text-white mb-3">
                            <i className="pi pi-chevron-right" style={{ fontSize: ".75rem" }}></i>
                            &nbsp;
                            <p className="text-sm open-sans">Lesson Books</p>
                        </div>
                        <div className="flex align-items-center text-white mb-3">
                            <i className="pi pi-chevron-right" style={{ fontSize: ".75rem" }}></i>
                            &nbsp;
                            <p className="text-sm open-sans">Teacher Login</p>
                        </div>
                    </div>
                    <div className="xl:col-4 lg:col-4">
                        <h4 className="text-900 text-lg font-bold mb-3">Contact Info</h4>
                        <div className="flex align-items-center text-white mb-3">
                            <img src={Locationimg} alt="" width="15" />
                            &nbsp;
                            <p className="ml-2 open-sans">Fundamental Music Instruction Director Ed Kelly</p>
                        </div>
                        <div className="flex align-items-center text-white mb-3">
                            <img src={Phone} alt="" width="15" />
                            &nbsp;
                            <p className="ml-2 open-sans">908-244-4943</p>
                        </div>
                        <div className="flex align-items-center text-white mb-3">
                            <img src={Message} alt="" width="15" />
                            &nbsp;
                            <p className="ml-2 open-sans">ebrassman@gmail.com/info</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
