import React from "react";
import { ParentRoutes, PrivateRoutes, StudentRoutes, TeacherRoutes } from "./AllRoutes";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "../services/auth";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
var base64 = require("base-64");

export default function ProtectedRoute() {
    const location = useLocation();
    const history = useHistory();

    const role = localStorage.getItem("Role");

    const userRole = base64.decode(role);

    const getAuthRoutes = (routes) => {
        return routes.map((prop, key) => {
            return isAuthenticated() ? (
                <Route path={prop.path} key={prop.path} component={prop.component} exact={prop?.exact} />
            ) : (
                //  location.pathname.includes("parent") ? (
                //     <Redirect
                //         to={{
                //             pathname: "/parent/account-invoice",
                //             state: { referrer: location },
                //         }}
                //     />
                // )
                //  :
                <Redirect to="/" />
            );
        });
    };

    return (
        <div>
            {userRole == "PARENT" ? (
                <div>{getAuthRoutes(ParentRoutes)}</div>
            ) : userRole == "TEACHER" ? (
                <div>{getAuthRoutes(TeacherRoutes)}</div>
            ) : (
                <div>{getAuthRoutes(StudentRoutes)}</div>
            )}
        </div>
    );
}
