import React, { useState, useEffect } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import SelectNewSchoolType from "./SelectNewSchoolType";
import CustomButton from "../../../components/Fields/Button/CustomButton";
import AtSchoolNewAdmissionForm from "../StudentForm/AtSchoolNewAdmissionForm";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addStudentAction1 } from "../../../redux/actions/studentAction";
import OnlineAdmissionForm from "../StudentForm/OnlineAdmissionForm";
import ButtonComponent from "../../../components/Fields/Button/CustomButton";
import RemoveStudentDialog from "../onlinelesson/RemoveStudentDialog";
import { confirmNext, showFormErrors1 } from "../../../utils/commonFunctions";
import useUnload from "../../../components/useUnload";

export default function StudentFormCopyNew() {
    useUnload((e) => {
        e.preventDefault();
        e.returnValue = "";
    });
    useEffect(() => {
        const disableBackButton = () => {
            window.history.pushState(null, document.title, window.location.href);
        };

        disableBackButton();

        window.addEventListener("popstate", disableBackButton);

        return () => {
            window.removeEventListener("popstate", disableBackButton);
        };
    }, []);

    const [schoolForms, setSchoolForms] = useState([]);
    const [program_id, setProgramId] = useState();
    const [removeStudent, setRemoveStudent] = useState(false);
    const [validationForm, setValidateForm] = useState(true);
    const { id, type } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        setSchoolForms([
            ...schoolForms,
            {
                school_id: type === "school_lessons" ? id : null,
                student_detail: {},
                lesson_type: type,
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeState = (state, i) => {
        let newState = [...schoolForms];
        newState[i].student_detail = state;
        setSchoolForms([...newState]);
    };

    const selectSchool = (school_id, lesson_type, i) => {
        let newState = [...schoolForms];
        newState[i].school_id = school_id;
        newState[i].lesson_type = lesson_type;
        setSchoolForms([...newState]);
    };
    const selectOnlineSchool = (programId, lesson_type, i) => {
        let newState = [...schoolForms];
        newState[i].school_id = null;
        newState[i].lesson_type = lesson_type;
        setProgramId(programId);
        setSchoolForms([...newState]);
    };

    const setSchoolForms1 = (item, i) => {
        let newState = [...schoolForms];
        if (newState[i].student_detail.class_type === "during_school") {
            delete newState[i].student_detail["aftercare_picked_up_time"];
            delete newState[i].student_detail["prefer_drop_picked_up_for_class"];
            item.formErrors["aftercare_picked_up_time"] = "";
            item.formErrors["prefer_drop_picked_up_for_class"] = "";
            newState[i].student_detail = item;
            setSchoolForms([...newState]);
        } else {
            newState[i].student_detail = item;
            setSchoolForms([...newState]);
        }
    };
    // const setSchoolForm2 = (school_id, lesson_type, i) => {
    //   let newState = [...schoolForms];
    //   newState[i].school_id = school_id;
    //   newState[i].lesson_type = lesson_type;
    //   setSchoolForms([...newState]);
    // };
    const handleNext = (i) => {
        for (let j = 0; j < schoolForms.length; j++) {
            showFormErrors1(schoolForms, j, setSchoolForms1);
        }

        if (showFormErrors1(schoolForms, i, setSchoolForms1)) {
            confirmNext(
                () => {
                    history.push("/parent-information");
                    dispatch(addStudentAction1(schoolForms));
                },
                "Would you like to proceed? Please review your information carefully, as once you advance, there's no turning back!",
                "center"
            );
        }
    };

    const handleAnother = (i) => {
        for (let j = 0; j < i; j++) {
            showFormErrors1(schoolForms, j, setSchoolForms1);
        }

        if (showFormErrors1(schoolForms, i, setSchoolForms1)) {
            setSchoolForms((p) => [
                ...p,
                {
                    school_id: null,
                    student_detail: {},
                },
            ]);
        }
    };

    const error = (validateForm) => {
        setValidateForm(validateForm);
    };

    return (
        <div className=" md:p-0">
            <div className="student_banner relative mb-3">
                <h1 className="text-white relative text-center">Enrollment in Classes for the 2022-23 School Year</h1>
            </div>
            <p className="text-900 font-semibold mb-3 text-center inter">
                If you begin the program with us and decide to discontinue, tuition billing will stop once 5 payments are made. To
                discontinue,
                <span className="text-main">you must notify Mr. Kelly</span> (Program Administrator)
            </p>
            <div className="grid justify-content-center p-3">
                <div className="md:col-9">
                    <p className="text-red inter font-semibold mb-3 text-center">
                        Please read Lesson Policies and Terms prior to enrollment.
                    </p>

                    {schoolForms?.length &&
                        schoolForms?.map((item, i) => (
                            <Accordion activeIndex={0} className="form-accordion border-none enrollform" key={i}>
                                <AccordionTab header="Student Information" className="text-white border-none">
                                    <div className="App">
                                        {i ? (
                                            <SelectNewSchoolType
                                                setSchoolId={selectSchool}
                                                setOnlineSchoolType={selectOnlineSchool}
                                                setFormState={changeState}
                                                index={i}
                                                data={schoolForms[i]?.lesson_type}
                                                error={error}
                                            />
                                        ) : null}

                                        {item.lesson_type === "online_lessons" ? (
                                            <OnlineAdmissionForm
                                                schoolId={null}
                                                index={i}
                                                setFormState={changeState}
                                                programId={program_id}
                                                data={schoolForms[i]?.student_detail}
                                            />
                                        ) : item.school_id ? (
                                            <AtSchoolNewAdmissionForm
                                                schoolId={item.school_id}
                                                index={i}
                                                setFormState={changeState}
                                                data={schoolForms[i]?.student_detail}
                                            />
                                        ) : null}
                                    </div>

                                    {schoolForms.length === i + 1 ? (
                                        <div className="flex align-items-center justify-content-center">
                                            {schoolForms.length === i + 1 && i < 3 && (
                                                <CustomButton
                                                    className="border-none bg-dark text-white"
                                                    onClick={
                                                        () => handleAnother(i)
                                                        // setSchoolForms((p) => [
                                                        //   ...p,
                                                        //   {
                                                        //     school_id: null,
                                                        //     student_detail: {},
                                                        //   },
                                                        // ])
                                                    }
                                                    label="Add Another"
                                                />
                                            )}
                                            <CustomButton
                                                onClick={() => handleNext(i)}
                                                className="btn p-button p-component border-none bg-dark text-white ml-2"
                                            >
                                                Next {">>"}
                                            </CustomButton>
                                            {i >= 1 && i < 4 && (
                                                <ButtonComponent
                                                    label="Remove Student"
                                                    className="btn no-underline bg-main text-white ml-2 border-none border-round-lg open-sans"
                                                    onClick={() => setRemoveStudent(i)}
                                                />
                                            )}
                                            {removeStudent && (
                                                <RemoveStudentDialog
                                                    removeStudent={removeStudent}
                                                    setRemoveStudent={setRemoveStudent}
                                                    schoolForms={schoolForms}
                                                    setSchoolForms={setSchoolForms}
                                                    index={i}
                                                />
                                            )}
                                        </div>
                                    ) : null}
                                </AccordionTab>
                            </Accordion>
                        ))}
                </div>
            </div>
        </div>
    );
}
