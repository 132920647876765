import types from "../../types";
import Constants from "../../../services/constant";
import { showLoaderAction, hideLoaderAction } from "../loadAction";
import { api, multipartApi } from "../../../services/api";

const getPracticeLogs = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.STUDENT_PRACTICE_LOGS);
  if (res.success) {
    dispatch({
      type: types.STUDENT_PRACTICE_LOGS,
      payload: res.data,
    });
  }

  dispatch(hideLoaderAction());
};

const getParentPracticeLogs = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.PARENT_PRACTICE_LOGS);
  if (res.success) {
    dispatch({
      type: types.STUDENT_PRACTICE_LOGS,
      payload: res.data,
    });
  }
  dispatch(hideLoaderAction());
};

const addPracticeLogs = (file, next) => async (dispatch) => {
  dispatch(showLoaderAction());

  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_type", "recorded");
  const res = await multipartApi(
    "post",
    Constants.END_POINT.STUDENT_ADD_PRACTICE_LOGS,
    formData
  );
  if (res.success) {
    dispatch(hideLoaderAction());
    next();
  }

  dispatch(hideLoaderAction());
};

const deletePracticeLogs = (id) => async (dispatch) => {
  const res = await api(
    "get",
    Constants.END_POINT.STUDENT_DELETE_PRACTICE_LOGS + id
  );
  if (res.success) {
    dispatch(getPracticeLogs());
  }
};

export {
  getPracticeLogs,
  addPracticeLogs,
  deletePracticeLogs,
  getParentPracticeLogs,
};
