import types from "../../types";
const initialState = {
    getEmailTemplates: [],
    ViewTemplate: {},
};
const EmailTemplateReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_EMAIL_TEMPLATE:
            return { ...state, getEmailTemplates: action.payload };
        case types.VIEW_TEMPLATE:
            return { ...state, ViewTemplate: action.payload };
        default:
            return { ...state };
    }
};

export default EmailTemplateReducer;
