import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import DropDownComponent from "../../../components/Fields/CustomDropdown";
import OnlineSchoolDetail from "../onlinelesson/OnlineSchoolDetail";
import { ViewOnlineSchoolDetail } from "../../../redux/actions/schoolAction";
import { getSchoolAction, getOnlineLessonProgramAction } from "../../../redux/actions/schoolAction";
import SchoolDetail from "./SchoolDetail";
import { ViewSchoolAction } from "../../../redux/actions/schoolAction";

const SelectNewSchoolType = ({ setSchoolId, setOnlineSchoolType, index, data, error }) => {
    const school = useSelector((state) => state?.school?.getSchools);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [selectedOnlineLessons, setSelectedOnlineLessons] = useState(null);

    const [addSchoolType, setAddSchoolType] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSchoolAction());
        dispatch(getOnlineLessonProgramAction());
    }, [dispatch]);

    const [err, setErr] = useState();

    const validateForm = useCallback(() => {
        let errors = {};
        if (!data) {
            errors.selectSchool = "Choose Enroll Type ";
        }
        if (data === "school_lessons" && !selectedSchool?.school) {
            errors.school = "Please Select School ";
        }
        if (data === "online_lessons" && !selectedOnlineLessons?.online_school) {
            errors.school = "Please Select Online Lessons ";
        }
        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectedSchool]);

    useEffect(() => {
        const errors = validateForm();
        error(errors);
        // do something with errors
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectedSchool, selectedOnlineLessons, validateForm]);

    useEffect(() => {
        setSchoolId(selectedSchool?.school, addSchoolType, index);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSchool?.school, selectedOnlineLessons, addSchoolType]);
    useEffect(() => {
        setOnlineSchoolType(selectedOnlineLessons?.online_school, addSchoolType, index);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOnlineLessons, addSchoolType]);

    const handleChange = (e) => {
        const { name } = e.target;
        if (name === "school") {
            setSelectedSchool((prev) => ({ ...prev, [name]: e.value }));

            setErr({ ...err, [name]: "" });
        }
        if (name === "online_school") {
            setSelectedOnlineLessons((prev) => ({ ...prev, [name]: e.value }));
        }
        setErr({ ...err, school: "", online_school: "" });
    };
    const onlineLessonProgram = useSelector((state) => state?.school?.getOnlineLessons);

    useEffect(() => {
        dispatch(ViewOnlineSchoolDetail(selectedOnlineLessons?.online_school));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOnlineLessons?.online_school]);

    const viewOnlineSchoolDetail = useSelector((state) => state?.school?.viewOnlineLesson);
    useEffect(() => {
        dispatch(ViewSchoolAction(selectedSchool?.school));
    }, [selectedSchool?.school, dispatch]);

    const viewSchoolDetail = useSelector((state) => state?.school?.viewSchool);

    return (
        <>
            <div className=" relative mb-3">
                <h5 className="text-dark font-bold mb-2">
                    Enroll Now in at - <span className="text-main">School Lesson</span>
                </h5>

                <div className="grid">
                    <div className="col-12 md:col-5 lg:col-4">
                        <p
                            className={`text-center open-sans font-semibold border-set border-round-lg p-2 text-base w-100 enrollBtn cursor-pointer ${
                                addSchoolType === "school_lessons" ? "active" : ""
                            }`}
                            onClick={() => {
                                setAddSchoolType("school_lessons");
                            }}
                        >
                            Enroll Now in at -School Lessons
                        </p>
                    </div>
                    <div className="col-12 md:col-5 lg:col-4">
                        <p
                            className={`text-center open-sans font-semibold border-set border-round-lg p-2 text-base w-100 enrollBtn cursor-pointer ${
                                addSchoolType === "online_lessons" ? "active" : ""
                            }`}
                            onClick={() => {
                                setAddSchoolType("online_lessons");
                            }}
                        >
                            Enroll in Private lessons
                        </p>
                    </div>
                </div>
                {err && <div className="p-error">{err?.selectSchool}</div>}
                {addSchoolType === "school_lessons" && (
                    <>
                        <div className="border-round-lg ms-3 relative z-3 bg-white">
                            <form action="">
                                <p className="text-dark-purple text-xs mb-2 font-normal open-sans">Select School</p>
                                <DropDownComponent
                                    name="school"
                                    placeholder="Select School"
                                    value={selectedSchool?.school}
                                    options={school?.map((item, index) => {
                                        return {
                                            label: item?.name,
                                            value: item?.id,
                                        };
                                    })}
                                    onChange={handleChange}
                                    className="mb-2 w-11"
                                />
                            </form>
                        </div>

                        {err && <div className="p-error">{err.school}</div>}
                        {selectedSchool?.school && <SchoolDetail viewSchoolDetail={viewSchoolDetail} />}
                    </>
                )}
                {addSchoolType === "online_lessons" && (
                    <>
                        <div className="border-round-lg ms-3 relative z-3 bg-white">
                            <form action="">
                                <p className="text-dark-purple text-xs mb-2 font-normal open-sans">Select School</p>
                                <DropDownComponent
                                    name="online_school"
                                    className="w-full mb-2"
                                    placeholder="Select Lesson Programs"
                                    value={selectedOnlineLessons?.online_school}
                                    options={onlineLessonProgram?.map((item, index) => {
                                        return {
                                            label: item?.name,
                                            value: item?.id,
                                        };
                                    })}
                                    onChange={handleChange}
                                />
                            </form>
                        </div>
                        {err && <div className="p-error">{err.school}</div>}
                        {selectedOnlineLessons?.online_school && (
                            <OnlineSchoolDetail viewOnlineSchoolDetail={viewOnlineSchoolDetail} />
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default SelectNewSchoolType;
