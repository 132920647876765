import Pianomusic from "../../assets/icons/vecteezy_music-instrument-grand-piano_1206488.png";
import Guitarmusic from "../../assets/icons/vecteezy_music-instrument-electric-guitar_1206670.png";
import Saxophonemusic from "../../assets/icons/vecteezy_saxophone_1207897.png";
import Clarinetmusic from "../../assets/icons/vecteezy_clarinet_1207931.png";
import Violinmusic from "../../assets/icons/vecteezy_music-violin_1208315.png";
import Trombonemusic from "../../assets/icons/vecteezy_trombone_1207895.png";
import Flutemusic from "../../assets/icons/image.png";
import Trumpetmusic from "../../assets/icons/vecteezy_music-trumpet_1208284.png";
import Drumsmusic from "../../assets/icons/vecteezy_percussion-instrument-drum-set_1207549.png";

const InstrumentMusic = () => {
  let InstrumentData = [
    {
      redirect: "https://fundamentalmusicinstruction.com/services/piano/",
      image: Pianomusic,
      title: "Piano",
    },

    {
      redirect: "https://fundamentalmusicinstruction.com/services/guitar/",
      image: Guitarmusic,
      title: "Guitar",
    },
    {
      redirect: "https://fundamentalmusicinstruction.com/services/saxophone/",
      image: Saxophonemusic,
      title: "Saxophone",
    },
    {
      redirect: "https://fundamentalmusicinstruction.com/services/clarinet/",
      image: Clarinetmusic,
      title: "Clarinet",
    },
    {
      redirect: "https://fundamentalmusicinstruction.com/services/violin/",
      image: Violinmusic,
      title: "Violin",
    },

    {
      redirect: "https://fundamentalmusicinstruction.com/services/trombone/",
      image: Trombonemusic,
      title: "Trombone",
    },
    {
      redirect: "https://fundamentalmusicinstruction.com/services/flute/",
      image: Flutemusic,
      title: "Flute",
    },
    {
      redirect: "https://fundamentalmusicinstruction.com/services/trumpet/",
      image: Trumpetmusic,
      title: "Trumpet",
    },
    {
      redirect: "https://fundamentalmusicinstruction.com/services/drums/",
      image: Drumsmusic,
      title: "Drums",
    },
  ];
  return { InstrumentData };

}
export default InstrumentMusic;