import types from "../../types";
const initialBoard = {
    getTeachersList: [],
    getPriviledgeteachers:[]
};

const teachersReducer = (state = initialBoard, action) => {
    switch (action.type) {
        case types.TEACHERS_LIST:
            return { ...state, getTeachersList: action.payload };
            case types.PRIVELEGES_TEACHER:
                return { ...state, getPriviledgeteachers: action.payload };
        default:
            return { ...state };
    }
};

export default teachersReducer;
