import React, { useState, useEffect } from "react";
import ButtonComponent from "../../../../../components/Fields/Button/CustomButton";
import Userlogotwo from "../../../../../assets/icons/Userlogotwo.png";
import { Link } from "react-router-dom";
import InputComponent from "../../../../../components/Fields/InputField";
import RadioButtonComponent from "../../../../../components/Fields/Radio";
import { ViewEventAction, addAttendenceAction } from "../../../../../redux/actions/TeacherPortal/attendanceAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import userlogoOne from "../../../../../assets/icons/userlogoOne.png";
import CheckboxComponent from "../../../../../components/Fields/Checkbox";
import EditorComponent from "../../../../../components/Fields/CustomEditor";
import DeleteIcon from "../../../../../assets/icons/delete.png";
import MultiSelectComponent from "../../../../../components/Fields/CustomMultiSelect";
import Index from "../../../layout";
import { useHistory } from "react-router-dom";
import ChevronLeft from "../../../../../assets/icons/chevronleft.png";
import Eyeblack from "../../../../../assets/icons/blackEye.png";
import CustomFilesInput from "../../../../../components/Fields/CustomFilesInput";

const GroupAttendance = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const events = useSelector((state) => state?.events?.ViewEvent);

    useEffect(() => {
        dispatch(ViewEventAction(id));
        if (events?.id) {
            setAttandance({ ...attandence, event_id: events?.id });
            setNotes(events?.notes_assignments[0]?.notes);
            setAssignments(events?.notes_assignments[0]?.assignment);
            setUrls(events?.online_resource);
        }
    }, [id, dispatch, events.id]);

    const [files, setFiles] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [attendeeOptions, setAttendeeOptions] = useState([]);
    const [notes, setNotes] = useState("");
    const [assignments, setAssignments] = useState("");
    const [err, setErr] = useState({});
    const [active, setActive] = useState("");
    const [urlErr, setUrlErr] = useState("");

    const handleRadioButton = (event, i, item) => {
        const { value } = event.target;
        if (attendeeOptions[i]) {
            attendeeOptions[i] = { id: item.id, status: value };
            setAttendeeOptions([...attendeeOptions]);
        } else {
            setAttendeeOptions((prev) => [...prev, { id: item.id, status: value }]);
        }
        setErr({ ...err, ["attendee"]: "" });
    };

    const [attandence, setAttandance] = useState({
        event_id: "",
        online_resource: "",
        parents: false,
        students: false,
        teachers: false,
        is_billable: false,
        parents_ids: [],
        students_ids: [],
        teachers_ids: [],
    });
    const [status, setStatus] = useState("");

    const [url, setUrls] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "online_resource") {
            setAttandance({ ...attandence, [name]: value });
            setUrlErr("");
        }
        setAttandance({ ...attandence, [name]: value });
    };

    useEffect(() => {
        if (events && events.attendees) {
            const attendee = events?.attendees?.map((item) => ({
                id: item.id,
                status: item.status,
            }));
            setAttendeeOptions(attendee);
            setStatus(attendee.status);
        }
    }, [events.attendees]);

    useEffect(() => {
        if (events?.event_attachment) {
            setSelectedFiles(events?.event_attachment);
        } else {
            setSelectedFiles([]);
        }
    }, [events?.event_attachment]);

    const onSelectRadio = (type, e) => {
        const a = events.attendees.map((item) => ({
            id: item.id,
            status: type,
        }));
        setStatus(type);

        setAttendeeOptions(a);
        setErr({ ...err, attendee: "" });
    };

    const handleNotes = (e) => {
        setNotes(e.htmlValue);
    };
    const handlefileChange = (files) => {
        files.some((file) => {
            if (selectedFiles.findIndex((f) => f.name === file.name) === -1) {
                selectedFiles.push(file);
            }
        });
        setSelectedFiles([...selectedFiles]);
    };
    const handleFiles = (e) => {
        const chossenFile = e.target.files;
        handlefileChange(Object.values(chossenFile));
    };

    const [deletedFileIDs, setDeletedFileIDs] = useState([]);

    const onDelete = (id, type) => {
        if (type === "URL") {
            setUrls((prevState) => prevState.filter((item, index) => index !== id));
        } else {
            setSelectedFiles((prevState) => {
                const filteredFiles = prevState.filter((item, index) => index !== id);
                let deletedID = prevState[id].id;
                if (deletedID !== undefined) {
                    setDeletedFileIDs((prevDeletedIDs) => [...prevDeletedIDs, deletedID]);
                }
                return filteredFiles;
            });
        }
    };

    const handleCheck = (name, e) => {
        setAttandance({ ...attandence, [name]: !attandence[name] });
    };

    const handleChange1 = (e) => {
        const { name } = e.target;
        setAttandance({ ...attandence, [name]: e.value });
    };

    let type = "group";

    const handleSaveAndExit = () => {
        const form = validateForm();
        if (form) {
            dispatch(
                addAttendenceAction(events, attandence, selectedFiles, attendeeOptions, type, notes, history, "", assignments, url, deletedFileIDs, status)
            );
        } else {
            const element = document.getElementById("main-content");
            element.scroll({
                top: 100,
                left: 100,
                behavior: "smooth",
            });
        }
    };
    const handleSave = () => {
        const form = validateForm();
        if (form) {
            dispatch(addAttendenceAction(events, attandence, selectedFiles, attendeeOptions, type, notes, "", "", assignments, url, deletedFileIDs, status));
        } else {
            const element = document.getElementById("main-content");
            element.scroll({
                top: 100,
                left: 100,
                behavior: "smooth",
            });
        }
    };

    const validateForm = () => {
        let errors = {};
        attendeeOptions.forEach((attendee) => {
            if (attendee.status === null) {
                errors.attendee = "Please mark attendance of above students";
            }
        });
        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleAddUrl = (url) => {
        setAttandance({ ...attandence, online_resource: "" });
        if (!url) {
            setUrlErr("Url is required");
        } else {
            setUrls((prev) => [...prev, url]);
        }
    };

    return (
        <Index>
            <div className="p-5">
                <h3 className="text-main  open-sans mb-3 flex align-items-center font-semibold cursor-pointer">
                    <img
                        src={ChevronLeft}
                        alt=""
                        width={10}
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    &nbsp; Attendance
                </h3>

                <div className="font-semibold text-lg flex align-items-center mb-3">
                    <img src={Userlogotwo} alt="" width="20" />
                    &nbsp; All {events?.attendees?.length > 0 && events?.attendees?.length} Students
                </div>

                <div className="border p-3 border-400 border-round-lg flex text-lg mb-3">
                    <p className="font-semibold">
                        {events?.all_day == 1 ? "All Day -" : ""}&nbsp;
                        {events?.category_name}
                    </p>

                    <p className="text-dark-gray ml-2"> {events?.school_name}</p>
                </div>

                <div className="border border-400 border-round-lg mb-3">
                    <div className="flex align-items-center">
                        <p className="font-semibold mb-2 p-3">Mark Attendance</p>
                        <RadioButtonComponent
                            label="Teacher Absent"
                            extraClassName="ml-3"
                            labelClassName="text-sm"
                            checked={
                                attendeeOptions?.length &&
                                events?.attendees?.length &&
                                attendeeOptions.filter((item) => item.status === "teacher_absent").length === events?.attendees?.length
                                    ? true
                                    : false
                            }
                            onChange={() => onSelectRadio("teacher_absent")}
                        />
                    </div>

                    <div className="grid mb-2">
                        <div className="md:col-2">
                            <p className="text-main font-semibold text-lg lightest-green p-2 pl-6">Mark All</p>
                        </div>
                        <div className="md:col-8 flex align-items-center">
                            <RadioButtonComponent
                                label="Unrecorded"
                                extraClassName="ml-3"
                                labelClassName="text-sm"
                                checked={
                                    attendeeOptions?.length &&
                                    events?.attendees?.length &&
                                    attendeeOptions.filter((item) => item.status === "unrecorded").length === events?.attendees?.length
                                        ? true
                                        : false
                                }
                                onChange={(e) => onSelectRadio("unrecorded", e)}
                            />
                            <RadioButtonComponent
                                label="Present"
                                extraClassName="ml-3"
                                labelClassName="text-sm"
                                checked={
                                    attendeeOptions?.length &&
                                    events?.attendees?.length &&
                                    attendeeOptions.filter((item) => item.status === "present").length === events?.attendees?.length
                                        ? true
                                        : false
                                }
                                onChange={(e) => onSelectRadio("present", e)}
                            />
                            <RadioButtonComponent
                                label="Absent"
                                extraClassName="ml-3"
                                labelClassName="text-sm"
                                checked={
                                    attendeeOptions?.length &&
                                    events?.attendees?.length &&
                                    attendeeOptions.filter((item) => item.status === "absent").length === events?.attendees?.length
                                        ? true
                                        : false
                                }
                                onChange={() => onSelectRadio("absent")}
                            />
                        </div>
                    </div>
                    {events?.attendees?.length > 0 &&
                        events?.attendees?.map((item, index) => (
                            <div className="grid mb-2" key={index}>
                                <div className="md:col-2">
                                    <Link
                                        to={`/teacher-dashboard/single-attandance/${id}/${events?.attendees?.[index]?.id}`}
                                        className="text-black no-underline"
                                    >
                                        <p className="text-lg p-2 pl-4">
                                            <img src={userlogoOne} width={15} /> &nbsp; {item.name}
                                        </p>
                                    </Link>
                                </div>

                                <div className="md:col-8 flex align-items-center">
                                    <RadioButtonComponent
                                        label="Unrecorded"
                                        extraClassName="ml-3"
                                        labelClassName="text-sm"
                                        value="unrecorded"
                                        checked={attendeeOptions[index]?.status === "unrecorded"}
                                        onChange={(event) => handleRadioButton(event, index, item)}
                                    />
                                    <RadioButtonComponent
                                        label="Present"
                                        extraClassName="ml-3"
                                        labelClassName="text-sm"
                                        value="present"
                                        checked={attendeeOptions[index]?.status === "present"}
                                        onChange={(event) => handleRadioButton(event, index, item)}
                                    />
                                    <RadioButtonComponent
                                        label="Absent"
                                        extraClassName="ml-3"
                                        labelClassName="text-sm"
                                        value="absent"
                                        checked={attendeeOptions[index]?.status === "absent"}
                                        onChange={(event) => handleRadioButton(event, index, item)}
                                    />
                                    {/* <RadioButtonComponent
                                        label="Teacher Absent"
                                        extraClassName="ml-3"
                                        labelClassName="text-sm"
                                        value="teacher_absent"
                                        checked={attendeeOptions[index]?.status === "teacher_absent"}
                                        onChange={(event) => handleRadioButton(event, index, item)}
                                    /> */}
                                </div>
                            </div>
                        ))}
                    <div className="grid mb-2">
                        <div className="md:col-2"></div>
                        <div className="md:col-8"></div>
                    </div>
                    {err?.attendee && <div className="text-danger open-sans text-sm ml-4" key={id}>{`${err?.attendee}`}</div>}
                </div>
                <div className="border p-3 border-400 border-round-lg mb-3">
                    <p className="font-semibold">Mark Attendance</p>
                    <p className="text-sm mb-3">Add everyone’s notes & assignments for this Group Lesson (DS)</p>
                    <h6 className="mt-2 open-sans font-semibold">Add Notes</h6>
                    <EditorComponent style={{ height: "200px" }} onTextChange={handleNotes} value={notes} />
                    <h6 className="mt-2 open-sans font-semibold">Add Assignment</h6>
                    <EditorComponent extraClassName="mb-2" style={{ height: "200px" }} onTextChange={(e) => setAssignments(e.htmlValue)} value={assignments} />

                    <div className="col-12 md:col-12 py-2 ">
                        <h6 className="open-sans text-lg font-semibold">Add Linked Online Resources</h6>
                        <div className="grid align-items-center mt-3">
                            <InputComponent
                                extraClassName="mb-0 ml-2 md:col-6"
                                placeholder=""
                                name="online_resource"
                                onChange={handleChange}
                                value={attandence?.online_resource}
                            />
                            <ButtonComponent className="col-2 border-1 p-2" label="Add URL" onClick={() => handleAddUrl(attandence?.online_resource)} />
                        </div>
                    </div>
                    <div className="text-danger text-sm open-sans ml-4">{urlErr}</div>

                    <div className="mb-3 mt-3 md:col-12">
                        {url?.length ? (
                            <div className="p-2 mb-3 border-round-lg overflow-hidden">
                                <div className="grid bg-main align-items-center p-2 border-bottom-1 border-300 justify-content-between">
                                    <div className="md:col-3 text-sm open-sans font-semibold">Links</div>
                                    <div className="md:col-3 text-sm open-sans font-semibold">Actions</div>
                                </div>
                                {url.map((u, i) => (
                                    <div className="grid bg-whitesmoke justify-content-between align-items-center p-2">
                                        <div className="md:col-3 text-main-color  ">{u}</div>
                                        <div className="md:col-3 text-main-color ">
                                            <img className="pointer ml-2" onClick={() => onDelete(i, "URL")} src={DeleteIcon} alt="" width="15" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </div>

                    <div className="col-12 md:col-12 py-2">
                        <CustomFilesInput
                            label="+ Add File"
                            name="file"
                            accept=".pdf"
                            multiple
                            extraClassName="bg-light-green ml-2 mb-2 md:col-1"
                            onChange={handleFiles}
                        />
                    </div>
                    <div className="mb-3 mt-3 md:col-12">
                        {selectedFiles?.length ? (
                            <div className="p-2 mb-3 border-round-lg overflow-hidden">
                                <div className="grid bg-main align-items-center p-2 border-bottom-1 border-300 justify-content-between">
                                    <div className="md:col-3 text-sm open-sans font-semibold">File Name</div>

                                    <div className="md:col-3 text-sm open-sans font-semibold">File size</div>
                                    <div className="md:col-3 text-sm open-sans font-semibold">Actions</div>
                                </div>
                                {Object.values(selectedFiles).map((file, i) => (
                                    <div className="grid justify-content-between bg-whitesmoke align-items-center p-2">
                                        <div className="md:col-3 text-main-color  ">
                                            <img src={File} alt="" width="15" />
                                            {file?.name || file?.file_name}
                                        </div>

                                        <div className="md:col-3 text-main-color ">{parseInt(file.size)}kb</div>

                                        <div className="md:col-3 text-main-color ">
                                            <img
                                                src={Eyeblack}
                                                alt=""
                                                width="19px"
                                                height="19px"
                                                className="product-image m-1"
                                                onClick={() => window.open(URL.createObjectURL(file))}
                                            />
                                            <img className="pointer ml-2" onClick={() => onDelete(i, "FILES")} src={DeleteIcon} alt="" width="15" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </div>

                    <p className="font-semibold mb-3">Email Notes</p>
                    <div className="flex mb-3">
                        <CheckboxComponent
                            label="Parents"
                            extraClassName=""
                            labelClassName="text-sm"
                            name="parents"
                            checked={attandence?.parents}
                            onChange={(event) => handleCheck("parents", event)}
                        />
                        <CheckboxComponent
                            label="Students"
                            extraClassName="ml-3"
                            labelClassName="text-sm"
                            name="students"
                            checked={attandence?.students}
                            onChange={(event) => handleCheck("students", event)}
                        />
                        <CheckboxComponent
                            label="Teacher"
                            extraClassName="ml-3"
                            labelClassName="text-sm"
                            name="teachers"
                            checked={attandence?.teachers}
                            onChange={(event) => handleCheck("teachers", event)}
                        />
                    </div>

                    <div className="grid">
                        {attandence?.parents && (
                            <MultiSelectComponent
                                name="parents_ids"
                                size="md:col-4"
                                optionLabel="name"
                                options={events?.parent_arr}
                                placeholder="Select Parent"
                                onChange={handleChange1}
                                value={attandence?.parents_ids}
                            />
                        )}
                        {attandence?.students && (
                            <MultiSelectComponent
                                size="md:col-4"
                                name="students_ids"
                                optionLabel="name"
                                options={events?.attendees}
                                placeholder="Select Student"
                                onChange={handleChange1}
                                value={attandence?.students_ids}
                            />
                        )}
                    </div>
                </div>
                <div>
                    <ButtonComponent label="Save" className="bg-gray-light" onClick={handleSave} />
                    <ButtonComponent label="Save & Exit" className="ml-3" onClick={handleSaveAndExit} />
                </div>
            </div>
        </Index>
    );
};

export default GroupAttendance;
