import React, { useState ,useEffect} from "react";
import { Dialog } from "primereact/dialog";
import ForgotPassword from "../../../assets/images/Forgotassword-bro.png";
import playingImage from "../../../assets/images/Playing.png";
import {  useHistory } from "react-router-dom";
import InputComponent from "../../../components/Fields/InputField";
import DropDownComponent from "../../../components/Fields/CustomDropdown";
import ButtonComponent from "../../../components/Fields/Button/CustomButton";
import { useSelector, useDispatch } from "react-redux";
import { addBillingInfoAction } from "../../../redux/actions/studentAction";
import InputMaskComponent from "../../../components/Fields/InputMaskComponent";
import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import useUnload from "../../../components/useUnload";
import { confirmNext } from "../../../utils/commonFunctions";


export default function PaymentInfo() {
  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });
  const [visible, setVisible] = useState(false);
  const [thankVisible, setThankVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [openSuccessModal, setOpenSuccessModal] = useState();
  const {
    meta,
    getCardNumberProps,
    getExpiryDateProps,
    getCardImageProps,
    getCVCProps
  } = usePaymentInputs();
  // const { erroredInputs, touchedInputs } = meta;

  const month = [
    { name: "Jan", value: "01" },
    { name: "Feb", value: "02 " },
    { name: "Mar", value: "03" },
    { name: "Apr", value: "04" },
    { name: "May", value: "05" },
    { name: "Jun", value: "06" },
    { name: "Jul", value: "07" },
    { name: "Aug", value: "08" },
    { name: "Sep", value: "09" },
    { name: "Oct", value: "10" },
    { name: "Nov", value: "11" },
    { name: "Dec", value: "12" },
  ];

  const [data, setData] = useState({
    cardName: "",
    cardNum: null,
    month: "",
    year: "",
    cvcCode: null,
  });

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setErr({ ...err, [name]: "" });
  };
  useEffect(() => {
    const disableBackButton = () => {
      window.history.pushState(null, document.title, window.location.href);
    };

    disableBackButton();

    window.addEventListener('popstate', disableBackButton);

    return () => {
      window.removeEventListener('popstate', disableBackButton);
    };
  }, []);
  const getDropList = () => {
    const year = new Date().getFullYear();
    return Array.from(new Array(50), (v, i) => {
      return { label: year + i, value: year + i };
    });
  };
  const year = getDropList();
  const parent_info = useSelector(
    (state) => state?.studentInfo?.student_billing_info
  );
  const billingInfo = useSelector((state) => state.studentInfo.addBillingInfo);
  const handleClose = (e) => {
    e.preventDefault();
    setOpenSuccessModal(false);
    history.push("/");
  };
  const [err, setErr] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = validateForm();
    if (form) {
      dispatch(
        addBillingInfoAction(
          parent_info,
          billingInfo,
          data,
          history,
          setOpenSuccessModal
        )
      );
    }
  };

  const validateForm = () => {
    let errors = {};
    if (!data?.cardName) {
      errors.cardName = "Card Name is required ";
    }
    // if (!data?.cardNum) {
    //   errors.cardNum = "Card Number is required ";
    // }
    if (meta.erroredInputs.cardNumber) {
      errors.cardNum = meta.erroredInputs.cardNumber;
    }
    if (!data?.month) {
      errors.month = "Month is required ";
    }
    if (!data?.year) {
      errors.year = "Year is required ";
    }
    if (meta.erroredInputs.cvc) {
      errors.cvcCode = meta.erroredInputs.cvc
    }

    setErr(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleNumberChange =(e)=>{
setData({ ...data, cardNum: e.target.value });
  }

  const handleCsvChange =(e)=>{
setData({ ...data, cvcCode: e.target.value });

  }

  return (
    <div>
      <div className="student_banner relative">
        <h1 className="text-white relative text-center">Card Details</h1>
      </div>
      <div className="px-3 md:px-8 py-4">
        <p className="text-900 font-semibold mb-3 text-center inter">
        If you begin the program with us and decide to discontinue, tuition billing will stop once 5 payments are made. To discontinue,
          <span className="text-main">you must notify Mr. Kelly</span> (Program
          Administrator)
        </p>
        <div className="shadow-1 p-4 border-round-lg lg:w-9 m-auto">
          <p className="text-red inter font-semibold mb-3 text-center">
          Please read Lesson Policies and Terms prior to enrollment.
          </p>
          <p className="bg-main p-3 border-round-lg font-bold text-white mb-2">
            Card Details
          </p>
          <form action="">
            <div className="grid flex-wrap mb-2 mt-3">
                <InputComponent
                  placeholder="Cardholder Name"
                  extraClassName="col-12 md:col-6 mb-3"
                  name="cardName"
                  inputClass="w-full border-1 p-3 border-round-lg border-700 open-sans"
                  value={data?.cardName}
                  onChange={handleChange1}
                  errorMessage={err.cardName}
                />
                <div className="col-12 md:col-6 mb-3">
                  <div className="relative">
                <svg {...getCardImageProps({ images })} className="absolute card-svg" />
               <input className="w-full p-3 border-round-lg border-1 border-700" {...getCardNumberProps({onChange:handleNumberChange})} value={data?.cardNum}  />
               </div>
               <div className="text-danger text-sm">{err?.cardNum}</div>
               </div>
                
                {/* <InputMaskComponent
                  inputClass="w-full border-1 p-3 border-round-lg border-700 open-sans"
                  placeholder="Card Number *"
                  name="cardNum"
                  extraClassName="col-12 md:col-6 mb-3"
                  id="cardNum"
                  mask="9999 9999 9999 9999"
                  value={data?.cardNum}
                  onChange={handleChange1}
                  errorMessage={err.cardNum}
                /> */}
                {/* <NumberComponent
                  name="cardNum"
                  inputClass="w-full border-1 p-0 border-round-lg border-700"
                  placeholder="Card Number"
                  value={data?.cardNum}
                  onValueChange={handleChange}
                  useGrouping={false}
                  errorMessage={err.cardNum}
                /> */}
                <DropDownComponent
                  name="month"
                  inputClass="w-full border-1 p-1 border-round-lg border-700 open-sans"
                  placeholder="Month"
                  extraClassName="col-6 md:col-3 mb-3"
                  value={data?.month}
                  options={month?.map((item, index) => {
                    return { label: item?.name, value: item?.value };
                  })}
                  onChange={handleChange1}
                  errorMessage={err.month}
                />
                <DropDownComponent
                  name="year"
                  extraClassName="col-6 md:col-3 mb-3"
                  inputClass="w-full border-1 p-1 border-round-lg border-700 open-sans"
                  placeholder="Year"
                  value={data?.year}
                  options={year}
                  onChange={handleChange1}
                  errorMessage={err.year}
                />
                 <div className="col-12 md:col-6 mb-3">
                  <div className="relative">
               <input className="w-full p-3 border-round-lg border-1 border-700" {...getCVCProps({onChange:handleCsvChange})} value={data?.cvcCode} placeholder="CVC Code"  />
               </div>
               <div className="text-danger text-sm">{err?.cvcCode}</div>
               </div>
                {/* <InputMaskComponent
                  inputClass="w-full border-1  border-round-lg border-700 open-sans"
                  placeholder="CVC Code *"
                  name="cvcCode"
                  extraClassName="col-12 md:col-6 mb-3"
                  id="cvcCode"
                  mask="9999"
                  value={data.cvcCode}
                  onChange={handleChange1}
                  errorMessage={err.cvcCode}
                /> */}
            </div>
            <div className="flex justify-content-center">
              {/* <ButtonComponent
                label="Go Back"
                className="btn no-underline surface-800 text-white p-2 open-sans ml-2 px-5 border-none border-round-lg"
              /> */}
              <ButtonComponent
                label="Submit"
                className="btn no-underline surface-800 text-white p-2 open-sans ml-2 px-5 border-none border-round-lg"
                onClick={handleSubmit}
              />
            </div>
          </form>
        </div>
      </div>
      <Dialog
        className="text-center header-none"
        visible={openSuccessModal}
        style={{ width: "30vw" }}
      >
        <img src={playingImage} className="w-5" alt="" />
        <h5 className="text-sm text-900 open-sans mb-3">
          Thanks for registering with us.
        </h5>
        <ButtonComponent
          label="Close"
          className="btn no-underline surface-800 text-white p-2 open-sans ml-2 px-5 border-none border-round-lg"
          onClick={handleClose}
        />
      </Dialog>

      <Dialog
        className="text-center header-none"
        visible={visible}
        style={{ width: "30vw" }}
      >
        <img src={ForgotPassword} className="w-5" alt="" />
        <p className="text-sm text-900 open-sans mb-3">
          Your card was declined. Please go back and verify you’ve entered the
          correct name, card number, expiry date and CVC code. Ensure that you
          use the same address that your credit card statement is sent to. If
          the problem persists, please contact your credit card provider.
        </p>
        <ButtonComponent
          label="OK"
          className="btn no-underline surface-800 text-white p-2 open-sans ml-2 px-5 border-none border-round-lg"
          onClick={() => {
            setThankVisible(true);
            setVisible(false);
          }}
        />
      </Dialog>
      <Dialog
        className="text-center header-none"
        visible={thankVisible}
        style={{ width: "30vw" }}
      >
        <img src={playingImage} className="w-5" alt="" />
        <h5 className="text-sm text-900 open-sans mb-3">
          Thanks for registering with us.
        </h5>
        <ButtonComponent
          label="Close"
          className="btn no-underline surface-800 text-white p-2 open-sans ml-2 px-5 border-none border-round-lg"
          onClick={() => setThankVisible(false)}
        />
      </Dialog>
    </div>
  );
}
