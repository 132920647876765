import types from "../../types";
const initialBoard = {
    getParentNotes: {},

};

const parentattendenceAndNotesReducer = (state = initialBoard, action) => {
    switch (action.type) {
        case types.GET_NOTES:
            return { ...state, getParentNotes: action.payload };
        default:
            return { ...state };
    }
};

export default parentattendenceAndNotesReducer;