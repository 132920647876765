import { CustomDropdownComponent, InputComponent1 } from "./AllInputs";
import { getImageURL } from "../utils/javascript";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import MoreOnlineInfo from "./MoreOnlineInfo";

const SubEnrollmentOnlineLessons = ({
    data,
    handleChange,
    getOnlineGrades,
    getOnlineLessons,
    gradeIntruments,
    getAllInstruments,
    viewOnlineLesson,
    handleInstrumentClick,
    ActionTemplate,
    visible,
    setVisible,
    handleEnroll,
}) => {
    return (
        <div>
            <>
                <div className="grid my-3 align-items-end">
                    <CustomDropdownComponent
                        label="Lesson Programs"
                        name="program_id"
                        extraClassName="col-12 md:col-6"
                        placeholder="Select program"
                        value={data?.program_id}
                        options={getOnlineLessons}
                        onChange={handleChange}
                        optionLabel="name"
                        errorMessage={data?.formErrors?.program_id}
                    />
                    {data?.program_id && (
                        <CustomDropdownComponent
                            label="Grade(If Student)"
                            extraClassName="col-12 md:col-6"
                            placeholder="Select grade"
                            name="grade_id"
                            value={data?.grade_id}
                            options={getOnlineGrades?.map((item) => {
                                return { name: item.grade, value: item.grade };
                            })}
                            onChange={handleChange}
                            optionLabel="name"
                        />
                    )}
                    <InputComponent1
                        name="age"
                        data={data}
                        onChange={handleChange}
                        label="Age"
                        required
                        extraClassName="col-12 md:col-6"
                        keyfilter="int"
                        errorMessage={data?.formErrors?.age}
                    />
                </div>
                {data?.grade_id ? (
                    <>
                        <div className="my-3">
                            <h2 className="text-dark font-bold text-2xl">Choose Instrument</h2>
                            <div className="flex mt-3 flex-wrap gap-3">
                                {gradeIntruments?.map((item, i) => {
                                    return (
                                        <>
                                            <div
                                                className={`bg-skinlight-color flex flex-column align-items-center justify-content-center instrument-box p-3 cursor-pointer ${
                                                    data?.enrollInstrument == item.value && "programme-selected"
                                                }`}
                                                onClick={() => {
                                                    handleInstrumentClick(item.value);
                                                }}
                                                key={item.value}
                                            >
                                                {item.icon? <img src={item.icon ? getImageURL(item.icon) : ""} alt="" className="mb-2" /> 
                                                : null}
                                                <p className="text-sm text-dark ">{item.name}</p>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="my-3">
                        <h2 className="text-dark font-bold text-2xl">Choose Instrument</h2>
                        <div className="flex mt-3 flex-wrap gap-3">
                            {getAllInstruments?.map((item, i) => {
                                return (
                                    <>
                                        <div
                                            className={`bg-skinlight-color flex flex-column align-items-center justify-content-center instrument-box p-3 cursor-pointer ${
                                                data?.enrollInstrument == item.id && "programme-selected"
                                            }`}
                                            onClick={() => {
                                                handleInstrumentClick(item.id);
                                            }}
                                            key={item.id}
                                        >
                                            {item.icon? <img src={item.icon ? getImageURL(item.icon) : ""} alt="" className="mb-2" /> : null}
                                            <p className="text-sm text-dark ">{item.name}</p>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </div>
                )}

                <div className="text-sm p-error">{data?.formErrors?.enrollInstrument}</div>
                {data?.enrollInstrument && (
                    <div className="">
                        <h2 className="text-dark font-bold text-2xl mb-3">Program Types</h2>
                        <DataTable value={viewOnlineLesson?.tution_per_class} className="border-round-lg overflow-hidden text-dark">
                            <Column field="payment_per_class" body={(r) => `$${r.payment_per_class}`} header="Payment Per Class"></Column>
                            <Column field="class_duration" body={(r) => `${r.class_duration} Minutes`} header="Class Duration"></Column>
                            <Column body={ActionTemplate} header="Action" style={{ width: "280px" }}></Column>
                        </DataTable>
                    </div>
                )}
                <MoreOnlineInfo
                    visible={visible}
                    setVisible={setVisible}
                    instrumentId={data?.enrollInstrument}
                    handleEnroll={handleEnroll}
                    btnText="Enroll now"
                />
            </>
        </div>
    );
};

export default SubEnrollmentOnlineLessons;
