import React from "react";
import UserProfile from "../../assets/images/ProfilePictureViewer.png";
import EditPensilIcon from "../../assets/icons/editpensil.png";
import DeleteIcon from "../../assets/icons/delete.png";
import { getImageURL } from "../../utils/javascript";

export default function FormImage({
  name,
  image,
  extraclass,
  onChange,
  handleDeleteImage,
  view,
  ...props
}) {
  return (
    <div className={`lg:w-18rem lg:col-3 xl:col-3 p-1 ${extraclass}`}>
      <div className="user-profile relative border-round-lg overflow-hidden">
        <div className="w-full h-full">
          <img
            className="w-full h-full fit-cover"
            // image ? (typeof image === "string" ? getImageURL(image) : URL.createObjectURL(image[0])) : uploadfile
            src={
              image
                ? typeof image === "string"
                  ? getImageURL(image)
                  : URL.createObjectURL(image)
                : UserProfile
            }
            alt=""
          />
        </div>
        {!view ? <div className="flex absolute bottom-0 justify-content-center w-full mb-2">
          <button className="image-edit-btn border-circle bg-white-transparent  overflow-hidden cursor-pointer">
            <div className="relative cursor-pointer">
              <input
                name={name}
                onChange={onChange}
                onClick={(e) => (e.target.value = "")}
                id="formImage"
                type="file"
                accept="image/*"
                className="opacity-0 absolute top-0 left-0 cursor-pointer"
              />
              {<img src={EditPensilIcon} alt="" />}
            </div>
          </button>
          <button className="image-delete-btn border-circle bg-white-transparent ml-3 ">
            <img src={DeleteIcon} alt="" onClick={handleDeleteImage} />
          </button>
        </div> : <><div className="relative cursor-pointer">
          <input
            name={name}
            onChange={onChange}
            onClick={(e) => (e.target.value = "")}
            id="formImage"
            type="file"
            accept="image/*"
            className="opacity-0 absolute top-0 left-0 cursor-pointer"
          />
        </div></>}
      </div>
    </div>
  );
}
