import React, { useEffect } from "react";
import Index from "../../layout";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { getParentContactAction } from "../../../../redux/actions/ParentPortal/contactAction";
import emailIcon from "../../../../assets/icons/mail 1.png";
import telephone from "../../../../assets/icons/telephone 1.png";
import ContactIcon from "../../../../assets/icons/contact.png";
import CardHeader from "../../../../pages/CardHeader";

const ContactList = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getParentContactAction());
    }, [dispatch]);

    const constactInfo = useSelector((state) => state?.contact?.getParentContact);

    const ParentContactBodyTemplate = (rowData) => {
        return (
            <>
                <div className="flex align-items-center">
                    <img src={emailIcon} alt="" width={20} />
                    <h6 className="m-0">{rowData?.email}</h6>
                </div>
                <div className="flex align-items-center">
                    <img src={telephone} alt="" width={20} />
                    <h6 className="m-0">{rowData?.phone_number}</h6>
                </div>
            </>
        );
    };
    return (
        <>
            <Index>
                <div className="p-4">
                    <CardHeader icon={ContactIcon} heading=" Teacher Contact Info" />
                    <div className="shadow-sm border-round-lg overflow-hidden">
                        <div className="table-top-header border-round-top-xl bg-main p-3">
                            <div className="flex justify-content-between">
                                <div className="flex justify-content-between">
                                    <div className="">
                                        <div className="flex align-items-center open-sans font-semibold text-xs ml-4 no-underline text-900">
                                            &nbsp; Studio Information
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTable
                            className="student-info-table"
                            value={constactInfo?.studio_information?.length && constactInfo?.studio_information}
                            stripedRows
                            tableStyle={{ minWidth: "50rem" }}
                        >
                            <Column field="name" header="Name"></Column>
                            <Column field="address" header="Address"></Column>
                            <Column field="mobile" header="Telephone"></Column>
                            <Column field="email" header="Email"></Column>
                        </DataTable>
                    </div>
                </div>
                <div className="p-4">
                    <div className="shadow-sm border-round-lg overflow-hidden">
                        <div className="table-top-header border-round-top-xl bg-main p-3">
                            <div className="flex justify-content-between">
                                <div className="flex justify-content-between">
                                    <div className="">
                                        <div className="flex align-items-center open-sans font-semibold text-xs ml-4 no-underline text-900">
                                            &nbsp; My Teachers
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DataTable
                            className="student-info-table"
                            value={constactInfo?.teachers?.length && constactInfo?.teachers}
                            stripedRows
                            tableStyle={{ minWidth: "50rem" }}
                        >
                            <Column field="name" header="Name"></Column>
                            <Column field="email" body={ParentContactBodyTemplate} header="Email"></Column>
                        </DataTable>
                    </div>
                </div>
            </Index>
        </>
    );
};
export default ContactList;
