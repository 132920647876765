import React from "react";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo/logo.png";
import MusicLyric from "../../assets/icons/music-lyric.png";
import TeacherLogin from "../../containers/login/TeacherLogin";

export default function Login() {
  const { teacherData, handleSubmit, handleChange, err } = TeacherLogin();

  return (
    <>
      <div className="px-8 shadow-2 flex justify-content-between align-items-center p-2">
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="" width="200" className="mr-2"></img>
          </Link>
        </div>
        <Link
          to="/enroll"
          className="p-3 px-3 text-center text-dark font-normal open-sans no-underline bg-yellow border-none text-sm border-round-lg cursor-pointer flex align-items-center flex-column justify-content-center"
          style={{ width: "8rem", height: "40px" }}
        >
          Enroll Now
        </Link>
      </div>
      <div className="banner login-banner" style={{ height: "94vh" }}>
        <div className="grid  m-0 h-full align-items-center">
          <div className="left-banner login lg:col-6 md:col-12 sm:col-12 relative  h-full vertical-center ">
            <div className="relative">
              <div className="spacer sm-hidden md-block"></div>
              <h1 className="heading mb-3 z-2 font-semibold text-white">
                <span className="text-white-border">Music </span>
                <br />
                is life
                <br />
                <span className="text-white-border"> Changing</span>
              </h1>
            </div>
          </div>
          <div className=" lg:col-6 p-5 md:col-12 p-0 h-100 relative">
            <img
              src={MusicLyric}
              alt=""
              className="absolute opacity-20 w-5"
              style={{ right: "2rem", top: "-2rem", zIndex: "-1" }}
            />
            <div className=" relative flex flex-column align-items-center justify-content-center h-full">
              <h2 className="text-main mb-3 w-9 font-bold">Teacher Login</h2>
              <div className="shadow-1 p-3 py-6 border-round-lg w-9 relative z-3 bg-white">
                <h3 className="text-2xl mb-3 font-bold">
                  Welcome to <br></br>
                  Fundamental <span className="text-main">Music</span>
                </h3>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="open-sans text-sm mb-2 ">Email ID</label>
                    <div className="border-set p-2 flex  border-round-lg align-items-center">
                      <i className="pi pi-envelope text-dark-gray font-bold"></i>
                      <input
                        type="email"
                        className="border-none w-full ml-2 p-2"
                        placeholder="Your email"
                        name="email"
                        onChange={handleChange}
                        value={teacherData?.email}
                      />
                    </div>
                    <div className="text-danger open-sans text-sm">
                      {err && err?.email}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="open-sans text-sm mb-2 ">Password</label>
                    <div className="border-set p-2 flex  border-round-lg align-items-center">
                      <i className="pi pi-lock text-dark-gray font-bold"></i>
                      <Password
                        placeholder="Your password"
                        toggleMask
                        className="border-none w-full ml-2"
                        name="password"
                        onChange={handleChange}
                        value={teacherData?.password}
                      />
                    </div>
                    <div className="text-danger open-sans text-sm">
                      {err && err.password}
                    </div>
                  </div>
                  <div className="flex mb-4 justify-content-between align-items-center">
                    <div>
                      <Checkbox
                        inputId="ingredient1"
                        name="teacherrememberMe"
                        checked={teacherData?.teacherrememberMe}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="ingredient1"
                        className="ml-2 mb-0 text-500 text-sm open-sans"
                      >
                        Remember Me
                      </label>
                    </div>
                    <div>
                      <Link
                        to="/teacher-forgot-password"
                        className="text-dark text-sm no-underline open-sans"
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <button
                    className="bg-dark w-full p-3 border-none text-white font-semibold border-round-lg open-sans"
                    type="submit"
                  >
                    Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
