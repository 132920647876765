import types from "../types";

const initialState = {
    schoolForms: [{ student_detail: {} }],
    admissionFields: {
        student_type: "child",
        first_name: "",
        last_name: "",
        // email: "",
        phone_no: null,
        skill_level: {},
        current_school: "",
        aftercare_picked_up_time: "",
        grade: "",
        prefer_drop_picked_up_for_class: "",
        class_type: "",
        order_book: true,
        is_waiting: false,
        // instrument_on_purchase_or_rent: "",
        cost_per_book: 30,
        monthly_installment: "",
        program_id: "",
        one_time_payment: "",
        instrument_id: "",
        type: null,
        amount: null,
        instrument_needed: "no",
    },
    extraFields: [],
    admissionForm: [],
};

const EnrollmentReducer1 = (state = initialState, action) => {
    switch (action.type) {
        case types.INITIALIZE_SCHOOL_FORMS:
            return {
                ...state,
                schoolForms: [
                    {
                        school_id: "",
                        student_detail: {},
                        lesson_type: "",
                        instrument: "",
                        grade: "",
                    },
                ],
            };
        case types.ADD_ANOTHER_SCHOOL_FORM:
            return {
                ...state,
                schoolForms: [
                    ...state.schoolForms,
                    {
                        school_id: null,
                        student_detail: {},
                        lesson_type: action.payload,
                    },
                ],
            };

        case types.UPDATE_SCHOOL_FORMS:
            const { index, updatedData, studentDetailUpdates } = action.payload;
            const updatedSchoolForms = [...state.schoolForms];

            if (updatedSchoolForms[index]) {
                updatedSchoolForms[index] = {
                    ...updatedSchoolForms[index],
                    ...updatedData,
                };
            }
            return {
                ...state,
                schoolForms: updatedSchoolForms,
            };
        case types.UPDATE_ENROLLMENT_DATA:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            };

        case types.SET_ADMISSION_FIELDS:
            return {
                ...state,
                admissionFields: action.paylaod,
            };

        case types.UPDATE_ADMISSION_FIELD:
            const { fieldName, value, formErrors } = action.payload;
            return {
                ...state,
                admissionFields: {
                    ...state.admissionFields,
                    [fieldName]: value,
                },
            };

        case types.UPDATE_EXTRA_FIELDS:
            const { name, value: fieldValue, item, extraFields } = action.payload;
            return {
                ...state,
                admissionFields: {
                    ...state.admissionFields,
                    student_extra_fields: {
                        ...state.admissionFields.student_extra_fields,
                        [name]: { value: fieldValue, ...item },
                    },
                    parent_extra: { extraFields },
                },
            };
        case types.SET_EXTRA_FIELDS:
            return { ...state, extraFields: action.payload };
        case types.SET_ADMISSION_FORM:
            return { ...state, admissionForm: action.payload };

        // other cases
        default:
            return state;
    }
};
export default EnrollmentReducer1;
