import React, { useEffect, useState } from "react";
import Index from "../../../layout";
import IndexNotes from "../../../../../assets/icons/sticky-notes.png";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { viewTeacherAttendenceAction } from "../../../../../redux/actions/TeacherPortal/attendanceAction";
import { useDispatch, useSelector } from "react-redux";
import edit from "../../../../../assets/icons/edit.png";
import { useHistory } from "react-router-dom";
import Attendance from "../../../../../assets/icons/attandence.png";
import CardHeader from "../../../../../pages/CardHeader";
import InputComponent from "../../../../../components/Fields/InputField";
import { getSearchedData } from "../../../../../utils/commonFunctions";
import { firstLetterToUppercase } from "../../../../../utils/regex";

const TeacherAttendenceAndNotes = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(viewTeacherAttendenceAction());
    }, [dispatch]);
    const attendenceNotes = useSelector((state) => state?.events?.viewTeacherAttendence);
    const history = useHistory();

    const ActionTemplate = (r) => {
        return (
            <img
                src={edit}
                width="25px"
                alt=""
                onClick={() => history.push(`/teacher-dashboard/teacher-attendence/${r?.event_id}`)}
            />
        );
    };
    const [keyword, setKeyword] = useState("");
    const keys = ["category_name", "status", "user_name"];

    const StudentAttendanceAction = (rowData) => {
        return (
            <>
                {rowData?.user_name?.length > 0 ? (
                    rowData?.user_name?.length <= 2 ? (
                        rowData?.user_name.join(" ; ")
                    ) : (
                        <>
                            {rowData?.user_name.slice(0, 2).join(", ")} <b>+{rowData?.user_name.length - 2} more</b>
                        </>
                    )
                ) : (
                    "-"
                )}
            </>
        );
    };

    return (
        <>
            <Index>
                <div className="p-4">
                    <CardHeader icon={Attendance} heading="Attendance & Notes" />
                    <div className="table-top-header border-round-top-xl  bg-gray-light p-3">
                        <div className="flex justify-content-between">
                            <div className="flex justify-content-between"></div>
                            {setKeyword ? (
                                <div className="p-input-icon-left mx-2">
                                    <i className="pi pi-search z-3" />
                                    <InputComponent
                                        value={keyword}
                                        inputClass="pl-5"
                                        onChange={(e) => setKeyword(e.target.value)}
                                        placeholder="Search"
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="shadow-sm border-round-lg overflow-hidden">
                        <DataTable
                            className=""
                            value={getSearchedData(attendenceNotes, keyword, keys)}
                            stripedRows
                            tableStyle={{ minWidth: "50rem" }}
                            paginator
                            responsiveLayout="scroll"
                            paginatorTemplate=" FirstPageLink  PageLinks  LastPageLink RowsPerPageDropdown "
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            style={{ borderRadius: "10px" }}
                            selectionMode="checkbox"
                            emptyMessage="No Attendence found."
                            filterDisplay="menu"
                        >
                            <Column field="user_name" body={StudentAttendanceAction} header="Student"></Column>
                            <Column
                                field="event_date"
                                body={(r) => {
                                    return r.date + ` ${r.start_time}`;
                                }}
                                header="Date/Time"
                            ></Column>
                            <Column field="category_name" header="Event Category"></Column>
                            <Column field="status" body={(r) => firstLetterToUppercase(r.status)} header="Status"></Column>
                            <Column header="Actions" body={ActionTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </Index>
        </>
    );
};
export default TeacherAttendenceAndNotes;
