import { Dialog } from "primereact/dialog";
import React from "react";
import QuestionIcon from "../../assets/icons/question.png";
import ButtonComponent from "../Fields/Button/CustomButton";

export default function NextDialog({ visible, title, detail, lightbtnlabel, darkbtnlabel, closebtn, modalClass, okBtnClick }) {
    return (
        <Dialog
            className={`confirmation-next  ${modalClass}`}
            visible={visible}
            style={{ width: "500px" }}
            onHide={closebtn}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
            <div className="text-center">
                <img src={QuestionIcon} alt="" className="mb-2 w-1" />
                {title ? <p className="text-2xl text-dark font-bold">{title}</p> : ""}
                <p className="text-base text-dark">{detail}</p>
                <div className="flex w-10 m-auto gap-3 mt-5 justify-content-center">
                    <ButtonComponent label={lightbtnlabel} className="w-5 bg-gray-light text-dark border-none" onClick={closebtn} />
                    <ButtonComponent label={darkbtnlabel} className="bg-dark w-5 border-none" onClick={okBtnClick} />
                </div>
            </div>
        </Dialog>
    );
}
