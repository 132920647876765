import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo/logo.png";

function Navigation(visible, setVisible, handleEnroll) {
    // const [visible,setVisible] = useState(false)

    const enrollBtn = () => {
        return (
            <button className="p-3 px-3 bg-yellow border-none text-sm border-round-lg cursor-pointer" style={{ width: "8rem" }} onClick={handleEnroll}>
                Enroll Now
            </button>
        );
    };

    const logIn = () => {
        return (
            <div onClick={() => setVisible(true)}>
                <button className="p-3 px-3 ml-2 bg-dark text-white border-none text-sm border-round-lg cursor-pointer" style={{ width: "8rem" }}>
                    Login
                </button>
            </div>
        );
    };

    const studentResources = () => {
        return <span onClick={() => window.open("https://fundamentalmusicinstruction.com/student-portal-music-lesson/", "_blank")}>Student Resources</span>;
    };

    const lessonBooks = () => {
        return <span onClick={() => window.open("https://fundamentalmusicinstruction.com/music-lesson-books/", "_blank")}>Lesson Books</span>;
    };

    const items = [
        {
            label: studentResources(),
        },
        {
            label: lessonBooks(),
        },
        {
            label: enrollBtn(),
        },
        {
            label: logIn(),
        },
    ];
    const start = (
        <Link to="/">
            <img alt="logo" src={Logo} width="200" className="mr-2"></img>
        </Link>
    );
    const end = (
        <Link to="/enroll">
            <button className="p-3 px-3 bg-yellow border-none text-sm border-round-lg cursor-pointer" style={{ width: "8rem" }}>
                Enroll Now
            </button>
        </Link>
    );

    return { items, start, end };
}
export default Navigation;
