import types from "../types";
const initialBoard = {
    getSchools: [],
    getSchoolGrades: [],
    getSchoolInstruments: [],
    getSchoolInstrumentsPrograms: [],
    getMoreInfo: {},
    viewSchool: {},
    viewSchoolLessons: {},
    viewAdmissionForm: {},
    schoolGrades: {},
    viewInstrument: {},
    enrollType: {
        enrollType: "",
        school_id: "",
    },

    getOnlineLessons: [],
    viewOnlineLesson: {},
    viewSchoolTeachers: [],
    studentExtraField: [],
    getOnlineGrades: [],
    getAllSchoolClasses: [],
    viewOnlineEnrollmentLesson: [],
};

const schoolReducer = (state = initialBoard, action) => {
    switch (action.type) {
        case types.FETCH_SCHOOLS:
            return {
                ...state,
                getSchools: action.payload?.map((item) => ({ name: item.name, value: item.id })),
                getSchoolGrades: action.payload,
            };

        case types.FETCH_SCHOOLS_GRADE_INSTRUMENT:
            return {
                ...state,
                getSchoolInstruments: action.payload?.grade?.grade_instruments?.map((item) => ({
                    name: item.name,
                    value: item.id,
                    icon: item.icon,
                })),
            };
        case types.FETCH_SCHOOLS_GRADE_INSTRUMENT_PROGRAMS:
            return {
                ...state,
                getSchoolInstrumentsPrograms: action.payload,
            };
        case types.FETCH_ONLINE_LESSONS:
            return { ...state, getOnlineLessons: action.payload?.map((item) => ({ name: item.name, value: item.id })) };

        case types.VIEW_SCHOOL:
            return {
                ...state,
                viewSchool: action.payload,
            };
        case types.VIEW_ONLINE_LESSON:
            return {
                ...state,
                viewOnlineLesson: action.payload,
            };
        case types.VIEW_ONLINE_ENROLLMENT_LESSONS:
            return {
                ...state,
                viewOnlineEnrollmentLesson: action.payload,
            };
        case types.VIEW_ADMISSION_FORM:
            return {
                ...state,
                viewAdmissionForm: action.payload,
            };
        case types.VIEW_SCHOOL_GRADES:
            return {
                ...state,
                schoolGrades: action.payload,
            };
        case types.VIEW_SCHOOL_LESSONS:
            return {
                ...state,
                viewSchoolLessons: action.payload,
            };
        case types.VIEW_INSTRUMENT:
            return {
                ...state,
                viewInstrument: action.payload,
            };
        case "School_enroll":
            return {
                ...state,
                enrollType: { ...action.payload },
            };
        case types.VIEW_SCHOOL_TEACHERS:
            return { ...state, viewSchoolTeachers: action.payload };

        case types.VIEW_STUDENT_EXTRA_FIELD:
            return {
                ...state,
                studentExtraField: action.payload,
            };
        case types.FETCH_MORE_PROGRAM_INFO:
            return {
                ...state,
                getMoreInfo: action.payload,
            };
        case types.VIEW_ONLINE_GRADES:
            return {
                ...state,
                getOnlineGrades: action.payload.grade,
            };

        case types.FETCH_SCHOOLS_CLASSES:
            return {
                ...state,
                getAllSchoolClasses: action.payload,
            };
        default:
            return { ...state };
    }
};

export default schoolReducer;
