import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo/logo.png";
import MusicLyric from "../../assets/icons/music-lyric.png";
import SendOTP from "../../containers/SendOTP";

export default function ForgotPassword() {
    const { email, handleChange, handleSendOpt, match } = SendOTP();
    return (
        <>
            <div className="px-8 shadow-2 flex justify-content-between align-items-center">
                <div className="logo">
                    <Link to="/">
                        <img src={Logo} alt="" width="200" className="mr-2"></img>
                    </Link>
                </div>
            </div>
            <div className="banner login-banner" style={{ height: "94vh" }}>
                <div className="grid  m-0 h-full align-items-center">
                    <div className="left-banner login lg:col-6 md:col-12 sm:col-12 relative  h-full vertical-center ">
                        <div className="relative">
                            <div className="spacer sm-hidden md-block"></div>
                            <h1 className="heading mb-3 z-2 font-semibold text-white">
                                <span className="text-white-border">Music </span>
                                <br />
                                is life
                                <br />
                                <span className="text-white-border"> Changing</span>
                            </h1>
                        </div>
                    </div>
                    <div className=" flex align-items-center lg:col-6 p-5 md:col-12 p-0 h-100 relative">
                        <img
                            src={MusicLyric}
                            alt=""
                            className="absolute opacity-20 w-5"
                            style={{ right: "1rem", top: "3rem", zIndex: "-1" }}
                        />
                        <div className="relative w-full ml-5">
                            <div className="shadow-1 p-3 mt-5 py-5 border-round-lg w-9 relative z-3 bg-white">
                                <h3 className="text-2xl mb-3 font-bold">
                                    Forgot Your <span className="text-main">Password?</span>
                                </h3>
                                <form action="">
                                    <div className="mb-3">
                                        <label className="open-sans text-sm mb-2 ">Email</label>
                                        <div className="border-set p-2 flex  border-round-lg align-items-center">
                                            <i className="pi pi-envelope text-dark-gray font-bold"></i>
                                            {match.url === "/student-forgot-password" ? (
                                                <input
                                                    type="email"
                                                    className="border-none w-full ml-2 p-2"
                                                    placeholder="Your email"
                                                    name="studentEmail"
                                                    onChange={handleChange}
                                                    value={email?.studentEmail}
                                                />
                                            ) : match.url === "/parent-forgot-password" ? (
                                                <input
                                                    type="email"
                                                    className="border-none w-full ml-2 p-2"
                                                    placeholder="Your email"
                                                    name="parentEmail"
                                                    onChange={handleChange}
                                                    value={email?.parentEmail}
                                                />
                                            ) : (
                                                <input
                                                    type="email"
                                                    className="border-none w-full ml-2 p-2"
                                                    placeholder="Your email"
                                                    name="teacherEmail"
                                                    onChange={handleChange}
                                                    value={email?.teacherEmail}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    <button
                                        className="bg-dark w-full p-3 mb-3 border-none text-white font-semibold border-round-lg open-sans"
                                        onClick={(e) => handleSendOpt(e)}
                                    >
                                        Reset Password
                                    </button>

                                    <Link to="/login" className="no-underline">
                                        <div className="text-center text-main font-bold open-sans">
                                            <p> Back To Login</p>
                                        </div>
                                    </Link>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
