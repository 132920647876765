import React, { useEffect, useMemo, useRef } from "react";
import Filter from "../../../../../assets/icons/filter.png";
import viewIcon from "../../../../../assets/icons/viewIcon.png";
import Dropblack from "../../../../../assets/icons/dropblack.png";
import addIcon from "../../../../../assets/icons/add.png";
import Layout from "../../../layout";
import CardHeader from "../../../../../pages/CardHeader";
import ButtonComponent from "../../../../../components/Fields/Button/CustomButton";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import scheduledIcon from "../../../../../assets/icons/scheduled.png";
import { useDispatch, useSelector } from "react-redux";
import { onGetEventClassesAction, onGetEventsAction } from "../../../../../redux/actions/TeacherPortal/scheduleAction";
import { useState } from "react";
import { convertTo12HourFormat, getDatesForWeekday, getTimeSlots } from "../../../../../utils/dates";
import Logo from "../../../../../assets/logo/logo.png";

export default function ViewSchedules() {
    const profile = useSelector((state) => state.login.getTeacherProfile);
    const history = useHistory();
    const viewAgenda = useRef(null);
    const op = useRef(null);
    const { id } = useParams();
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [classes, setClasses] = useState([]);
    const [tableObj, setTableObj] = useState({});

    useEffect(() => {
        dispatch(
            onGetEventsAction(id, (e) => {
                setData(e);
            })
        );
        dispatch(
            onGetEventClassesAction(id, (e) => {
                setClasses(e);
            })
        );
    }, []);

    let dates = useMemo(() => {
        if (data?.class_schedule?.start_date && data?.class_schedule?.end_date && data?.class_schedule?.day) {
            return getDatesForWeekday(data?.class_schedule?.start_date, data?.class_schedule?.end_date, data?.class_schedule?.day);
        }
        return [];
    }, [data]);

    useEffect(() => {
        // let times = getTimeSlots(data?.class_schedule?.start_time, data?.class_schedule?.end_time, data?.class_schedule?.interval);
        let times = [];
        data?.class_event_raw?.forEach((item) => {
            if (!times.includes(item?.time)) {
                times.push(item.time);
            }
        });
        times.sort();
        let events = data.class_event;
        if (dates?.length && times.length) {
            let obj = {};
            times.forEach((time) => {
                if (!obj[time]) {
                    obj[time] = {};
                }
                dates.forEach((date) => {
                    if (events?.[time]?.[date]?.length) {
                        obj[time][date] = events?.[time]?.[date][0];
                    } else {
                        obj[time][date] = { time, date };
                    }
                });
            });
            setTableObj(obj);
        }
    }, [dates, data]);

    function printDiv() {
        var divContents = document.getElementById("GFG").innerHTML;
        var a = window.open("", "");
        a.document.write("<html>");
        a.document.write(divContents);
        a.document.write("</body></html>");
        a.document.close();
        setTimeout(() => {
            a.print();
        }, 200);
    }

    function getFormattedNames(names) {
        const list = names.split(",");
        return list.map((name, index) => (
            <span style={{ display: "inline-block", marginRight: "5px" }}>
                {name}
                {index !== list.length - 1 && ","}
            </span>
        ));
    }

    return (
        <Layout>
            <div className="p-5">
                <CardHeader icon={scheduledIcon} heading="View Schedule" />
                <div className="table-top-header border-round-top-xl bg-gray-light p-3">
                    <div className="flex justify-content-end">
                        <div className="flex justify-content-between">
                            <div className={`flex align-items-center open-sans font-semibold text-xs ml-5 cursor-pointer`} onClick={printDiv}>
                                <img src={viewIcon} alt="" className="bg-white p-2 border-round-lg " width={30} />
                                &nbsp; Print
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-content-between mt-2">
                    <span className=" flex text-main text-2xl open-sans font-semibold">
                        {data?.class_schedule?.school_name} {data?.class_schedule?.day && `(${data?.class_schedule?.day})`}
                    </span>
                </div>
                <div className="schedule-table-container">
                    <table className="schedule-table my-3" border="1" width="100%">
                        <tr>
                            <th className="text-color-secondary">Edit Time</th>
                            {dates?.map((date) => (
                                <th>{date}</th>
                            ))}
                        </tr>
                        {Object.entries(tableObj).map(([time, timeObj]) => (
                            <tr>
                                <th>{convertTo12HourFormat(time)}</th>
                                {Object.entries(timeObj).map(([date, dateObj]) => (
                                    <td className="dropdown-td">{dateObj?.class_title}</td>
                                ))}
                            </tr>
                        ))}
                    </table>
                </div>
            </div>
            <div id="GFG" hidden>
                <img src={Logo} alt="" width="300px" />
                <div style={{ width: "100%", padding: "0 50px", marginBottom: "20px", display: "flex", flexWrap: "wrap", fontSize: "22px" }}>
                    <div style={{ width: "50%", display: "flex", marginTop: "15px" }}>
                        <div style={{ color: "#59C6BC", fontWeight: "600", minWidth: "20%" }}>School</div>
                        <div style={{ marginLeft: "20px" }}>{data?.class_schedule?.school_name}</div>
                    </div>
                    <div style={{ width: "50%", display: "flex", marginTop: "15px" }}>
                        <div style={{ color: "#59C6BC", fontWeight: "600", minWidth: "20%" }}>Class Day</div>
                        <div style={{ marginLeft: "20px" }}>{data?.class_schedule?.day}</div>
                    </div>
                    <div style={{ width: "50%", display: "flex", marginTop: "15px" }}>
                        <div style={{ color: "#59C6BC", fontWeight: "600", minWidth: "20%" }}>Teacher</div>
                        <div style={{ marginLeft: "20px" }}>{profile?.name}</div>
                    </div>
                </div>
                <table border="1" width="100%" style={{ border: "1px solid #dddddd", textAlign: "center" }}>
                    <tr>
                        <th style={{ padding: "5px", textAlign: "center" }}></th>
                        {dates?.map((date) => (
                            <th style={{ padding: "5px", minWidth: "80px" }}>{date}</th>
                        ))}
                    </tr>
                    {Object.entries(tableObj).map(([time, timeObj]) => (
                        <tr>
                            <th style={{ padding: "5px" }}>{convertTo12HourFormat(time)}</th>
                            {Object.entries(timeObj).map(([date, dateObj]) => (
                                <td style={{ fontSize: "18px", color: "#59C6BC", fontWeight: "600" }} className="dropdown-td">
                                    {dateObj?.class_title}
                                </td>
                            ))}
                        </tr>
                    ))}
                </table>
                <div
                    style={{
                        width: "95%",
                        margin: "auto",
                        marginTop: "20px",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        fontSize: "16px",
                    }}
                >
                    {data?.class_schedule?.class_schedule_lists.map((item) => (
                        <div
                            style={{ width: "49%", display: "flex", marginTop: "15px", borderRadius: "10px", border: "1px solid #dddddd", overflow: "hidden" }}
                        >
                            <div
                                style={{
                                    minWidth: "15%",
                                    fontSize: "25px",
                                    textAlign: "center",
                                    display: "flex",
                                    color: "#59C6BC",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    borderRight: "1px solid #dddddd",
                                }}
                            >
                                {item?.class_title}
                            </div>
                            <div style={{ padding: "10px" }}>
                                {getFormattedNames(item?.current_attendees_name)}
                            </div>
                        </div>
                    ))}{" "}
                </div>
            </div>
        </Layout>
    );
}
