import Constants from "../../services/constant";
import { loadStateFn } from "../../utils/localStorage";
import { api, multipartApi } from "../../services/api";
import { showLoaderAction, hideLoaderAction } from "./loadAction";
import { showToast } from "../../utils/showToast";
import types from "../types";
import moment from "moment";
import { getStudentProfileApi } from "./loginAction";
import { removeEmpty } from "../../utils/commonFunctions";

export const addStudentAction1 = (student) => async (dispatch) => {
    dispatch({
        type: types.ADD_FORM_INFO,
        payload: student,
    });
};
export const addBillingAction = (billing, registrationAddress) => async (dispatch) => {
    const payload1 = { billing, registerAddress: registrationAddress };

    dispatch({
        type: types.ADD_BILLING_INFO,
        payload: payload1,
    });
};

export const getInstrumentsAction = () => async (dispatch) => {
    const res = await api("get", Constants.END_POINT.ALL_INSTRUMENTS, {});

    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.GET_ALL_INSTRUMENTS,
                payload: res.data,
            });
        }
    }
};

export const addNewStudentAction = (parentInfo, policies, history) => async (dispatch, getState) => {
    dispatch(showLoaderAction());

    const state = getState();
    // var student_contact = student?.admissionFields?.contact?.replace(/-/g, "");
    var parent_contact = parentInfo?.phone_number?.replace(/-/g, "");
    console.log(state.enroll.students);
    const studentArr = state.enroll.students?.map((item, i) => {
        const instrument = [
            {
                instrument_id: item.instrument || item.enrollInstrument,
                type: item?.type,
                amount: item?.enroll_type === "school_lessons" ? item?.amount : item.type === "purchase" ? item.one_time_payment : item.monthly_installment,
            },
        ];

        // Remove the instrument-related data from the item
        const { enroll_type, program_id, school_id, day, ...restItem } = item;

        console.log("restItem=>", restItem);

        const commonProps = {
            first_name: restItem.first_name,
            last_name: restItem.last_name,
            skill_level: restItem.skill_level,
            program_id: program_id,
            instrument_on_purchase_or_rent: restItem.type,
            one_time_payment: restItem.type === "purchase" ? restItem.one_time_payment : 0,
            monthly_installment: restItem.type === "rent" ? restItem.monthly_installment : 0,
            is_waiting: restItem?.is_waiting ? 1 : 0,
            cost_per_book: restItem.cost_per_book,
            order_book: restItem.order_book,
            student_type: "child",
            ...(restItem?.student_extra_fields && { student_extra_fields: Object.values(restItem?.student_extra_fields) }),
            ...(restItem?.instrument_needed === "yes" && { instruments: instrument }),
            instrument_id: restItem.instrument || restItem.enrollInstrument,
            phone_no: restItem.phone_number ? restItem.phone_number.replace(/-/g, "") : "",
        };

        const schoolProps = {
            grade: restItem?.grade1?.name,
            class_type: restItem?.class_type,
            ...(restItem?.aftercare_picked_up_time && { aftercare_picked_up_time: restItem?.aftercare_picked_up_time }),
            ...(restItem?.prefer_drop_picked_up_for_class && { prefer_drop_picked_up_for_class: restItem?.prefer_drop_picked_up_for_class }),
        };
        const nonSchoolProps = {
            email: restItem.email,
            day: day,
            grade: restItem.grade_id,
            age: restItem?.age,
            alternate_day: null,
            alternate_time: null,
            time: restItem.time ? new Date(restItem.time).getHours() + ":" + ("00" + new Date(restItem.time).getMinutes()).slice(-2) : "",
        };

        const studentDetail = {
            ...commonProps,
            ...(enroll_type === "school_lessons" ? schoolProps : nonSchoolProps),
        };

        // Return the remaining item data
        return {
            lesson_type: enroll_type,
            school_id: school_id ? school_id : null,
            student_detail: studentDetail,
        };
    });

    const payload = {
        student: studentArr,
        first_name: parentInfo?.first_name,
        last_name: parentInfo?.last_name,
        email: parentInfo?.email,
        phone_no: parent_contact,
        address: parentInfo?.address,
        policies_is_agree: policies ? 1 : 0,
        city: parentInfo?.city,
        state: parentInfo?.state?.name,
        state_code: parentInfo?.state?.abbreviation,
        zip_code: parentInfo?.zip_code,
        ...(parentInfo?.parent_extra_fields && { parent_extra_fields: Object.values(parentInfo?.parent_extra_fields) }),
    };

    const res = await api("post", Constants.END_POINT.ADD_STUDENT, payload, loadStateFn("token"));
    if (res.success) {
        history.push("/billing-information");

        dispatch({
            type: types.ADD_STUDENT_INFO,
            payload: res.data,
        });
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};

export const addStudentAction = (student, parentInfo, policies, history) => async (dispatch) => {
    dispatch(showLoaderAction());
    // var student_contact = student?.admissionFields?.contact?.replace(/-/g, "");
    var parent_contact = parentInfo?.parent_contact?.replace(/-/g, "");

    let weekdayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thrusday", "Friday", "Saturday"];
    const studentArr = student.map((item, i) => {
        const instrument = [
            {
                instrument_id: item.student_detail.instrument_id,
                type: item?.student_detail?.type,
                amount: item?.student_detail?.amount,
            },
        ];

        // Remove the instrument-related data from the item
        const { student_detail, ...restItem } = item;

        // Return the remaining item data
        return {
            ...restItem,
            student_detail: {
                ...student_detail,
                instruments: instrument,
                phone_no: student_detail.phone_no ? student_detail.phone_no.replace(/-/g, "") : "",
                day: student_detail?.day,
                is_waiting: student_detail?.is_waiting ? 1 : 0,
                ...(student_detail?.age && { age: student_detail?.age }),
                ...(student_detail?.student_extra_fields && { student_extra_fields: Object.values(student_detail?.student_extra_fields) }),
                time: student_detail.time ? new Date(student_detail.time).getHours() + ":" + ("00" + new Date(student_detail.time).getMinutes()).slice(-2) : "",
                alternate_day: student_detail.alternate_day ? weekdayNames[new Date(student_detail.alternate_day).getDay()] : "",
                alternate_time: student_detail.alternate_time
                    ? new Date(student_detail.alternate_time).getHours() + ":" + ("00" + new Date(student_detail.alternate_time).getMinutes()).slice(-2)
                    : "",
            },
        };
    });

    const payload = {
        student: studentArr,
        first_name: parentInfo?.parent_first_name,
        last_name: parentInfo?.parent_last_name,
        email: parentInfo?.parent_email,
        phone_no: parent_contact,
        address: parentInfo?.address,
        policies_is_agree: policies ? 1 : 0,
        city: parentInfo?.city,
        state: parentInfo?.state?.name,
        state_code: parentInfo?.state?.abbreviation,
        zip_code: parentInfo?.zip_code,
        ...(parentInfo?.parent_extra_fields && { parent_extra_fields: Object.values(parentInfo?.parent_extra_fields) }),
    };

    const res = await api("post", Constants.END_POINT.ADD_STUDENT, payload, loadStateFn("token"));
    if (res.success) {
        history.push("/billing-information");

        dispatch({
            type: types.ADD_STUDENT_INFO,
            payload: res.data,
        });
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
export const addBillingInfoAction = (parent_info, billingInfo, data, history, setOpenSuccessModal) => async (dispatch) => {
    dispatch(showLoaderAction());
    const cardnumber = data?.cardNum.replace(/ /g, "");
    let students = [];
    students.push(Object.values(parent_info?.student_ids));
    // parent_info.student_ids.map((item, i) => {
    //   students.push(item.id);
    // });

    const payload = {
        parent_id: parent_info?.parent?.id,
        payment_type: billingInfo?.billing?.payment_type,
        first_tution_installment:
            billingInfo?.billing?.payment_type === "monthly_payment" ? parent_info?.first_tution_installment : parent_info?.full_tution_payment,
        instrument_fees: parent_info?.instrument_fee,
        school_tax: parent_info?.school_tax,
        material_fees: parent_info?.material_fees,
        deposit_fee: parent_info?.deposit_fee,
        total_payment: billingInfo?.billing?.payment_type === "monthly_payment" ? parent_info?.total_payment : parent_info?.full_total_payment,
        same_as_registration: billingInfo?.registerAddress ? 1 : 0,
        address: billingInfo?.registerAddress ? parent_info?.parent?.address : billingInfo?.billing?.address,
        city: billingInfo?.registerAddress ? parent_info?.parent?.city : billingInfo?.billing?.city,
        state: billingInfo?.registerAddress ? parent_info?.parent?.state : billingInfo?.billing?.state,
        zipcode: billingInfo?.registerAddress ? parent_info?.parent?.zip_code : billingInfo?.billing?.zip_code,
        card_number: cardnumber,
        exp_month: data?.month,
        exp_year: data?.year,
        cvv: data?.cvcCode,
        students: students[0],
        group_no: parent_info?.group_no,
    };

    const res = await api("post", Constants.END_POINT.ADD_BILLING_INFO, payload);
    if (res.success) {
        showToast([res.message], "success");
        setOpenSuccessModal(true);
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};

export const getStudentEvents = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.STUDENT_DASHBOARD, {}, loadStateFn("token"));

    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.GET_STUDENT_DASHBOARD,
                payload: res.data,
            });
        }
    }

    dispatch(hideLoaderAction());
};

export const getStudentNewsAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.STUDENT_NEWS);

    if (res.success) {
        dispatch({
            type: types.GET_STUDENT_NEWS,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const getStudentMessageHistoryAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.STUDENT_MESSAGE_HISTORY);

    if (res.success) {
        dispatch({
            type: types.STUDENT_MESSAGE_HISTORY,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const getStudentNotesAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.STUDENT_ATTENDENCE_NOTES);

    if (res.success) {
        dispatch({
            type: types.STUDENT_ATTENDENCE_NOTES,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const uploadImages = async (images, type) => {
    const promises = images?.map(async (item) => {
        if (typeof item === "string") {
            return item;
        } else {
            const formData = new FormData();
            formData.append("file[0]", item);
            formData.append("type", type);

            const res = await multipartApi("post", Constants.END_POINT.UPLOAD_FILES, formData);
            if (res.success && res.data) {
                return res.data[0];
            }
        }
    });

    const urls = await Promise.all(promises);
    return urls;
};

export const StudentSettings = (profile, history) => async (dispatch) => {
    dispatch(showLoaderAction());

    let payload = {
        ...profile,
        ...(profile?.dob !== null && { dob: moment(profile?.dob).format("DD-MM-YYYY") }),
    };
    if (payload?.picture_url?.length) {
        payload.picture_url = await uploadImages(payload.picture_url, "students/pictures");
        payload.picture_url = payload.picture_url[0];
        payload.is_picture_delete = 0;

        if (payload.picture_url.includes("http")) {
            delete payload.picture_url;
        }
    } else {
        payload.picture_url = "";
        payload.is_picture_delete = 1;
    }

    const final = removeEmpty(payload);

    const res = await api("post", Constants.END_POINT.PROFILE_SETTINGS, final);
    if (res.success) {
        // history.goBack();
        showToast([res.message], "success");
        dispatch(getStudentProfileApi());
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
