import Index from "../../layout";
import Invoice from "../../../../assets/icons/invoice.png";
import { TabView, TabPanel } from "primereact/tabview";
import AccountContent from "./AccountInvoiceContent";
import CardHeader from "../../../../pages/CardHeader";
import MakePayment from "./MakePayment";
import { Dialog } from "primereact/dialog";
import { InvoiceGenerate } from "./InvoiceGenerate";
import MusicLyric from "../../../../assets/icons/music-lyric.png";
import ParentLogin from "../../../../containers/login/ParentLogin";
import { Password } from "primereact/password";
import { useState } from "react";
import { parentSendEmailApi, parentSendOtpApi, parentSendResetPasswordApi } from "../../../../redux/actions/loginAction";
import { useDispatch } from "react-redux";
import { InputText } from "primereact/inputtext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Account() {
    const {
        accounts,
        makePayment,
        setMakePayment,
        openCard,
        setOpenCard,
        amount,
        selectedInvoice,
        invoiceView,
        setInvoiceView,
        activeIndex,
        handleTabChange,
        tabs,
        location,
        open,
        setOpen,
    } = AccountContent();

    const { parentData, handleSubmit, handleChange, err } = ParentLogin(location);
    const dispatch = useDispatch();
    const history = useHistory();

    const [forgotPassword, setForgotPassword] = useState(false);
    const [verifyOTP, setVerifyOTP] = useState(false);
    const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
    const [parentEmail, setParentEmail] = useState("");

    const handleSendOpt = (e) => {
        e.preventDefault();
        // var encodedEmail = base64.encode(email?.parentEmail);
        dispatch(
            parentSendEmailApi(parentEmail, () => {
                setForgotPassword(false);
                setVerifyOTP(true);
                // history.push(`/parent-verify-otp/${encodedEmail}`);
            })
        );
    };
    const [otp, setOtp] = useState({
        parentOtp: "",
    });

    const handleOTPChange = (e) => {
        const { name, value } = e.target;
        setOtp({ ...otp, [name]: value });
    };

    const handleVerifyOpt = (e) => {
        e.preventDefault();
        dispatch(
            parentSendOtpApi(otp?.parentOtp, parentEmail, () => {
                setResetPasswordOpen(true);
                setVerifyOTP(false);
                // history.push(`/parent-reset-password/${encodedEmail}`);
            })
        );
    };
    const ResendOtp = () => {
        dispatch(parentSendEmailApi(parentEmail, () => {}));
    };

    const [resetPassword, setResetPassword] = useState({
        new_password: "",
        confirm_password: "",
    });

    const handleResetPasswordChange = (e) => {
        const { name, value } = e.target;
        setResetPassword({ ...resetPassword, [name]: value });
    };

    const handleResetPassword = (e) => {
        e.preventDefault();
        dispatch(
            parentSendResetPasswordApi(parentEmail, resetPassword?.new_password, () => {
                setResetPasswordOpen(false);
                setOpen(true);
            })
        );
    };
    return (
        <div>
            <Index>
                <div className="manveer">
                    <Dialog
                        maskClassName="login-custom-popup"
                        header="Set Login Details"
                        visible={open}
                        style={{ width: "30vw" }}
                        closable={false}
                    >
                        <div className="banner login-banner">
                            <div className="grid  m-0 h-full align-items-center">
                                <div className=" col-12 p-0 h-100 relative">
                                    <img
                                        src={MusicLyric}
                                        alt=""
                                        className="absolute opacity-20 w-5"
                                        style={{ right: "2rem", top: "-2rem", zIndex: "-1" }}
                                    />
                                    <div className=" relative flex flex-column h-full">
                                        <div className="p-3 py-6 border-round-lg w-12 relative z-3 bg-white">
                                            <h3 className="text-2xl mb-3 font-bold">
                                                Welcome to <br></br>
                                                Fundamental <span className="text-main">Music</span>
                                            </h3>
                                            <form onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                    <label className="open-sans text-sm mb-2 ">Email address</label>
                                                    <div className="border-set p-2 flex  border-round-lg align-items-center">
                                                        <i className="pi pi-envelope text-dark-gray font-bold"></i>
                                                        <input
                                                            type="email"
                                                            className="border-none w-full ml-2 p-2"
                                                            placeholder="Your email"
                                                            name="email"
                                                            onChange={handleChange}
                                                            value={parentData?.email}
                                                        />
                                                    </div>
                                                    <div className="text-danger open-sans text-sm">{err && err?.email}</div>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="open-sans text-sm mb-2 ">Password</label>
                                                    <div className="border-set p-2 flex  border-round-lg align-items-center">
                                                        <i className="pi pi-lock text-dark-gray font-bold"></i>
                                                        <Password
                                                            placeholder="Your password"
                                                            toggleMask
                                                            className="border-none w-full ml-2"
                                                            name="password"
                                                            onChange={handleChange}
                                                            value={parentData?.password}
                                                        />
                                                    </div>
                                                    <div className="text-danger open-sans text-sm">{err && err.password}</div>
                                                </div>
                                                <div className="flex mb-4 justify-content-end align-items-center cursor-pointer">
                                                    <div>
                                                        <div
                                                            onClick={() => {
                                                                setForgotPassword(true);
                                                                setOpen(false);
                                                            }}
                                                            className="text-dark text-sm no-underline open-sans"
                                                        >
                                                            Forgot Password?
                                                        </div>
                                                    </div>
                                                </div>

                                                <button
                                                    className="bg-dark w-full p-3 border-none text-white font-semibold border-round-lg open-sans"
                                                    type="submit"
                                                >
                                                    Login
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog
                        maskClassName="login-custom-popup"
                        header="Forgot Password"
                        visible={forgotPassword}
                        style={{ width: "30vw" }}
                        closable={false}
                    >
                        <div className="banner login-banner">
                            <div className="relative w-full">
                                <div className="">
                                    <h3 className="text-2xl mb-3 font-bold">
                                        Forgot Your <span className="text-main">Password?</span>
                                    </h3>
                                    <form action="">
                                        <div className="mb-3">
                                            <label className="open-sans text-sm mb-2 ">Email</label>
                                            <div className="border-set p-2 flex  border-round-lg align-items-center">
                                                <i className="pi pi-envelope text-dark-gray font-bold"></i>
                                                <input
                                                    type="email"
                                                    className="border-none w-full ml-2 p-2"
                                                    placeholder="Your email"
                                                    name="parentEmail"
                                                    onChange={(e) => setParentEmail(e.target.value)}
                                                    value={parentEmail}
                                                />
                                            </div>
                                        </div>

                                        <button
                                            className="bg-dark w-full p-3 mb-3 border-none text-white font-semibold border-round-lg open-sans"
                                            onClick={(e) => handleSendOpt(e)}
                                        >
                                            Reset Password
                                        </button>

                                        <div
                                            onClick={() => {
                                                setForgotPassword(false);
                                                setOpen(true);
                                            }}
                                            className="no-underline"
                                        >
                                            <div className="text-center text-main font-bold open-sans">
                                                <p> Back To Login</p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog
                        maskClassName="login-custom-popup"
                        header="Verify OTP"
                        visible={verifyOTP}
                        style={{ width: "30vw" }}
                        closable={false}
                    >
                        <div className="banner login-banner">
                            <div className="relative w-full">
                                <h3 className="text-2xl mb-3 font-bold">
                                    We send a code to
                                    <span className="text-main"> your email</span>
                                </h3>
                                <p className="font-medium mb-3 open-sans">
                                    Enter the 6-digit verification code sent to<br></br>
                                    <span className="text-main font-bold">{parentEmail}</span>{" "}
                                </p>

                                <form action="">
                                    <div className="mb-2">
                                        <InputText
                                            className="w-full"
                                            name="parentOtp"
                                            onChange={handleOTPChange}
                                            value={otp?.parentOtp}
                                        />
                                    </div>

                                    <p className="text-main font-semibold mb-3 open-sans" onClick={ResendOtp}>
                                        Resend code
                                    </p>

                                    <button
                                        className="bg-dark w-full p-3 mb-3 border-none text-white font-semibold border-round-lg open-sans"
                                        onClick={(e) => handleVerifyOpt(e)}
                                    >
                                        Verify OTP
                                    </button>

                                    <div className="">
                                        <p className="open-sans">
                                            If you don’t see the email in your inbox, check your spam folder.
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog
                        maskClassName="login-custom-popup"
                        header="Reset Password"
                        visible={resetPasswordOpen}
                        style={{ width: "30vw" }}
                        closable={false}
                    >
                        <div className="banner login-banner">
                            <div className=" relative w-full">
                                <h3 className="text-2xl mb-3 font-bold">
                                    Create New <span className="text-main">Password</span>
                                </h3>
                                <form action="">
                                    <div className="mb-3">
                                        <label className="open-sans text-xs mb-2 ">New Password</label>
                                        <div className="border-set p-2 flex  border-round-lg align-items-center">
                                            <i className="pi pi-lock text-dark-gray font-bold"></i>
                                            <Password
                                                placeholder="New Password"
                                                className="border-none w-full ml-2"
                                                name="new_password"
                                                onChange={handleResetPasswordChange}
                                                value={resetPassword?.new_password}
                                                toggleMask
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label className="open-sans text-xs mb-2 ">Confirm New Password</label>
                                        <div className="border-set p-2 flex  border-round-lg align-items-center">
                                            <i className="pi pi-lock text-dark-gray font-bold"></i>
                                            <Password
                                                placeholder="Confirm password"
                                                className="border-none w-full ml-2"
                                                name="confirm_password"
                                                onChange={handleResetPasswordChange}
                                                value={resetPassword?.confirm_password}
                                                toggleMask
                                            />
                                        </div>
                                    </div>

                                    <button
                                        className="bg-dark w-full p-3 border-none text-white font-semibold border-round-lg open-sans"
                                        onClick={(e) => handleResetPassword(e)}
                                    >
                                        Continue
                                    </button>
                                </form>
                            </div>
                        </div>
                    </Dialog>
                </div>

                <div className="p-4">
                    <CardHeader icon={Invoice} heading="Account & Invoices" />
                    <h4 className="open-sans font-semibold mb-4">
                        {accounts?.balance ? (
                            <h5 className="my-3">
                                Balance Owing:
                                <>
                                    {accounts?.balance >= 0 ? (
                                        <span className="text-green"> ${accounts?.balance}</span>
                                    ) : (
                                        <span className="text-red"> (${Math.abs(accounts?.balance)})</span>
                                    )}
                                </>{" "}
                                as of {accounts?.date}
                            </h5>
                        ) : null}
                        {/* Balance Owing: $0.00 as of 12/31/2022 */}
                    </h4>
                    <div className="detail_tabs">
                        <TabView className="account-invoice" activeIndex={activeIndex} onTabChange={handleTabChange}>
                            {tabs?.map((tab, i) => (
                                <TabPanel key={i} headerTemplate={tab.headerTemplate}>
                                    <div>{tab.content}</div>
                                </TabPanel>
                            ))}
                            {/* <TabPanel></TabPanel>
                            <TabPanel headerClassName="flex align-items-center"></TabPanel> */}
                        </TabView>
                        <Dialog
                            header={"Invoice"}
                            visible={invoiceView}
                            style={{ width: "48vw" }}
                            draggable={false}
                            resizable={false}
                            onHide={() => setInvoiceView(false)}
                        >
                            <div>
                                <InvoiceGenerate data={selectedInvoice ? selectedInvoice : null} />
                            </div>
                        </Dialog>
                        <div id="invoicePopUp">
                            <div id="pdfData">
                                <InvoiceGenerate data={selectedInvoice ? selectedInvoice : null} />
                            </div>
                        </div>
                        {makePayment && (
                            <MakePayment
                                makePayment={makePayment}
                                setMakePayment={setMakePayment}
                                openCard={openCard}
                                setOpenCard={setOpenCard}
                                amount={amount}
                            />
                        )}
                    </div>
                </div>
            </Index>
        </div>
    );
}
