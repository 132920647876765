import React, { useEffect } from "react";
import Assignments from "../../../../assets/icons/Assignments.png";
import Index from "../../layout";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ViewParentAssignmentAction } from "../../../../redux/actions/ParentPortal/assignmentAction";
import VideoCard from "../PracticeLog/VideoCard";

const ViewAssignment = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(ViewParentAssignmentAction(id));
  }, [dispatch,id]);

  const assignment = useSelector((state) => state?.assignment?.viewAssignment);
  return (
    <Index>
      <div className="grid mb-3 p-4 m-3">
        <div className="grid">
          <div className="flex align-items-center mb-3">
            <h2 className="flex align-items-center">
              <img src={Assignments} alt="" width={30} height={30} />
            </h2>
            &nbsp; &nbsp;
            <span className=" flex text-main text-2xl open-sans font-semibold">
              Assignment
            </span>
          </div>
        </div>

        <div className="md:col-12 px-0 ">
          <div className="border-1 border-round-lg border-300 p-3 m-3">
            <h6 className="open-sans  font-semibold mb-3">Assignment</h6>
            <div className="border-1 mb-3 border-round-lg border-200 p-3">
              {assignment?.practice_log_file && (
                <VideoCard
                  baseUrl={assignment?.base_url}
                  item={assignment}
                  videoUrl={
                    assignment?.base_url + assignment?.practice_log_file
                  }
                />
              )}
            </div>
            <h6 className="open-sans font-semibold mb-3 mt-2">Feedback</h6>
            <div className="border-1 border-round-lg border-200 p-3">{}</div>
          </div>
        </div>
      </div>
    </Index>
  );
};

export default ViewAssignment;
