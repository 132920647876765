import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import React, { useEffect } from "react";
import ButtonComponent from "../components/Fields/Button/CustomButton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { ViewOnlineEnrollmentSchoolDetail } from "../redux/actions/schoolAction";

const MoreOnlineInfo = ({ visible, setVisible, instrumentId, handleEnroll, btnText }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (visible) {
            dispatch(ViewOnlineEnrollmentSchoolDetail(visible, instrumentId));
        }
    }, [visible, instrumentId]);

    const { viewOnlineEnrollmentLesson } = useSelector((state) => state.school);
    const instrument = viewOnlineEnrollmentLesson?.instrument?.[0];
    const { name, purchase_amount, monthly_installment, number_of_installment, deposit_amount, description, purchase_plan } = instrument || {};
    return (
        <div>
            <Dialog
                visible={visible}
                className="more-info-dialog"
                style={{ width: "80vw" }}
                onHide={() => setVisible(null)}
                draggable={false}
                resizable={false}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <div className="px-3 md:px-6 ">
                    <Accordion className="instrument-view enrollform" activeIndex={[0]} multiple={true}>
                        <AccordionTab header={`Instrument : ${name}`} className="border-none">
                            <div className=" mb-4">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                ></div>
                                <h4 className="text-main mb-3 font-bold text-center mt-3">Getting started is easy!</h4>
                                <div className="text-center">
                                    <ButtonComponent label="Enroll Now" className="bg-dark w-4 border-none primary-btn" onClick={handleEnroll} />
                                </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header="Learn about Instrument Rental / Purchase">
                            {purchase_amount || monthly_installment || number_of_installment || deposit_amount ? (
                                <DataTable className="mb-3 border-round-lg overflow-hidden text-dark" value={viewOnlineEnrollmentLesson?.instrument}>
                                    <Column field="name" header="Instrument"></Column>
                                    <Column field="purchase_amount" header="Purchase Amt"></Column>
                                    <Column field="monthly_installment" header="Monthly Installment"></Column>
                                    <Column field="number_of_installment" header="No of Installment"></Column>
                                    <Column field="deposit_amount" header="Deposit Amt"></Column>
                                </DataTable>
                            ) : null}
                            {/* <p className="text-900 font-bold text-lg mb-2">{getMoreInfo?.learn_more_about_instrument_rentals_purchase?.title}</p> */}
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: purchase_plan,
                                }}
                            ></div>
                            <h4 className="text-main mb-3 font-bold text-center mt-3">Getting started is easy!</h4>
                            <div className="text-center">
                                <ButtonComponent label="Enroll Now" className="bg-dark w-4 border-none primary-btn" onClick={handleEnroll} />
                            </div>
                        </AccordionTab>
                        <AccordionTab header="Class Schedule Information" className="mt-3">
                            <div className="dropdown mb-3"></div>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: viewOnlineEnrollmentLesson?.online_lesson?.class_schedule,
                                }}
                                className="text-xs open-sans class-schedule"
                            ></div>
                            <h4 className="text-main mb-3  mt-4 font-bold text-center">Getting started is easy!</h4>
                            <div className="text-center mt-4 ">
                                <ButtonComponent label="Enroll Now" className="bg-dark w-4 border-none primary-btn" onClick={handleEnroll} />
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>
            </Dialog>
        </div>
    );
};

export default MoreOnlineInfo;
