import Constants from "../../../services/constant";
import { loadStateFn } from "../../../utils/localStorage";
import { api, multipartApi } from "../../../services/api";
import { showLoaderAction, hideLoaderAction } from "../loadAction";
import types from "../../types";
import { showToast } from "../../../utils/showToast";
import moment from "moment";
import { removeEmpty } from "../../../utils/commonFunctions";
import { getTeacherProfileApi } from "../loginAction";

export const getTeacherEvents = (date) => async (dispatch) => {
    dispatch(showLoaderAction());

    const payload = { date: moment(date).format("DD-MM-YYYY") };

    const res = await api("post", Constants.END_POINT.TEACHER_DASHBOARD, payload, loadStateFn("token"));

    if (res.success) {
        dispatch({
            type: types.GET_TEACHER_DASHBOARD,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
export const getTeacherOverDues = (date) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.TEACHER_OVERDUES, {}, loadStateFn("token"));

    if (res.success) {
        dispatch({
            type: types.GET_TEACHER_OVERDUE_ATTANDENCE,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
export const getTeacherNewsAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.TEACHER_NEWS);

    if (res.success) {
        dispatch({
            type: types.GET_TEACHER_NEWS,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const getCompensationListAction = (date) => async (dispatch) => {
    dispatch(showLoaderAction());
    const params = {
        date: moment(date).format("DD-MM-YYYY"),
    };

    const res = await api("get", Constants.END_POINT.GET_COMPENSATION, {}, loadStateFn("token"), params);
    if (res.success) {
        dispatch({
            type: types.GET_COMPENSATION,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
export const getTeacherSchoolsAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.GET_TEACHER_SCHOOL_DROPDOWN, {}, loadStateFn("token"));
    if (res.success) {
        dispatch({
            type: types.GET_TEACHER_SCHOOL_DROPDOWN,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
export const getTeacherClassTypesAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.TEACHER_CLASS_TYPES + id, {}, loadStateFn("token"));
    if (res.success) {
        dispatch({
            type: types.TEACHER_CLASS_TYPES,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
export const getTeacherProfileAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.TEACHER_PROFILE, {}, loadStateFn("token"));
    if (res.success) {
        dispatch({
            type: types.TEACHER_PROFILE,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const uploadImages = async (images, type) => {
    const promises = images?.map(async (item) => {
        if (typeof item === "string") {
            return item;
        } else {
            const formData = new FormData();
            formData.append("file[0]", item);
            formData.append("type", type);

            const res = await multipartApi("post", Constants.END_POINT.UPLOAD_TEACHER_FILES, formData);
            if (res.success && res.data) {
                return res.data[0];
            }
        }
    });

    const urls = await Promise.all(promises);
    return urls;
};

export const TeacherSettings = (profile, history) => async (dispatch) => {
    dispatch(showLoaderAction());

    let payload = {
        ...profile,
        phone_number: profile.phone_number.replace(/[-()]/g, ""),
        show_overdue: profile?.show_overdue ? 1 : 0,
    };
    if (payload?.picture_url?.length) {
        payload.picture_url = await uploadImages(payload.picture_url, "teacher/pictures");
        payload.picture_url = payload.picture_url[0];
        payload.is_picture_delete = 0;

        if (payload.picture_url.includes("http")) {
            delete payload.picture_url;
        }
    } else {
        payload.picture_url = "";
        payload.is_picture_delete = 1;
    }

    const final = removeEmpty(payload);

    const res = await api("post", Constants.END_POINT.TEACHER_PROFILE_SETTINGS, final);
    if (res.success) {
        // history.goBack();
        showToast([res.message], "success");
        dispatch(getTeacherProfileApi());
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
export const ChangeTeacherPassword = (data, setVisible) => async (dispatch) => {
    dispatch(showLoaderAction());
    const payload = { password: data?.current_password, new_password: data?.password };

    const res = await api("post", Constants.END_POINT.CHANGE_TEACHER_PASSWORD, payload);

    if (res.success) {
        showToast([res.message], "success");
        setVisible(false);
    } else {
        showToast([res.message], "error");
    }

    dispatch(hideLoaderAction());
};

export const GetTeacherAvailability = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.GET_AVAILIBILITY, {});

    if (res.success) {
        dispatch({
            type: types.GET_TEACHER_AVAILIBILITY,
            payload: res.data,
        });
    } else {
        dispatch({
            type: types.GET_TEACHER_AVAILIBILITY,
            payload: {},
        });
    }

    dispatch(hideLoaderAction());
};

export const addAvailibilityAction =
    (availabilty, checkedDays, setVisible, setAvailabilty, setCheckedDays) => async (dispatch) => {
        dispatch(showLoaderAction());

        const payload = {
            days: checkedDays,
            start_time:
                new Date(availabilty.start_time).getHours() +
                ":" +
                ("00" + new Date(availabilty.start_time).getMinutes()).slice(-2),
            end_time:
                new Date(availabilty.end_time).getHours() + ":" + ("00" + new Date(availabilty.end_time).getMinutes()).slice(-2),
            notes: availabilty?.notes,
        };
        if (availabilty?.start_date !== null) {
            payload.start_date = moment(availabilty.start_date).format("DD-MM-YYYY");
        }
        if (availabilty?.end_date !== null) {
            payload.end_date = moment(availabilty.end_date).format("DD-MM-YYYY");
        }
        const res = await api("post", Constants.END_POINT.ADD_TEACHER_AVAILABILITY, payload, loadStateFn("token"));
        if (res.success) {
            showToast([res.message], "success");
            dispatch(GetTeacherAvailability());
            setVisible(false);
            setAvailabilty({});
            setCheckedDays([0, 1, 2, 3, 4, 5, 6]);
        } else {
            showToast([res.message], "error");
        }
        dispatch(hideLoaderAction());
    };
export const TeacherStudentsListAction = (id, type) => async (dispatch) => {
    dispatch(showLoaderAction());

    if (type) {
        const res = await api("get", Constants.END_POINT.TEACHER_STUDENTS + id + "/" + type, {}, loadStateFn("jwt"));
        if (res.success) {
            dispatch({
                type: types.TEACHER_STUDENTS,
                payload: res.data,
            });
        }
    } else {
        const res = await api("get", Constants.END_POINT.TEACHER_STUDENTS + id, {}, loadStateFn("jwt"));
        if (res.success) {
            dispatch({
                type: types.TEACHER_STUDENTS,
                payload: res.data,
            });
        }
    }

    dispatch(hideLoaderAction());
};
