import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSchoolAction } from "../redux/actions/schoolAction";
import { getSchoolDetail } from "../redux/actions/schoolAction";

const SelectSchool = () => {
  const [selectedSchool, setSelectedSchool] = useState(null);
  const enroll_type = useSelector(
    (state) => state.school?.enrollType?.enroll_type
  );

  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSchoolAction());
  }, [dispatch]);

  const [err, setErr] = useState({});

  const school = useSelector((state) => state?.school?.getSchools);
  const validateForm = () => {
    let errors = {};
    if (!selectedSchool) {
      errors.selectedSchool = "Please Select School ";
    }

    setErr(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleContinue = (e) => {
    e.preventDefault();

    const form = validateForm();
    if (form) {
      if (selectedSchool) {
        dispatch(
          getSchoolDetail({
            enroll_type: enroll_type,
            school_id: selectedSchool?.school,
          })
        );
        history.push(`/school-lesson/school_lessons/${selectedSchool?.school}`);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedSchool({ ...selectedSchool, [name]: value });
    setErr({ selectedSchool: "" });
  };

  return {
    selectedSchool,
    school,
    handleChange,
    handleContinue,
    err,
  };
};

export default SelectSchool;
