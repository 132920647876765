import React, { useEffect, useRef, useState } from "react";
import Filter from "../../../../../assets/icons/filter.png";
import CalendarIcon from "../../../../../assets/icons/calendar.png";
import viewIcon from "../../../../../assets/icons/viewIcon.png";
import Dropblack from "../../../../../assets/icons/dropblack.png";
import addIcon from "../../../../../assets/icons/add.png";
import EmptyCalendar from "../../../../../assets/images/EmptyCalendar.png";
import Layout from "../../../layout";
import CardHeader from "../../../../../pages/CardHeader";
import { OverlayPanel } from "primereact/overlaypanel";
import { Menu } from "primereact/menu";
import ButtonComponent from "../../../../../components/Fields/Button/CustomButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { useDispatch } from "react-redux";
import { onGetSchedulesAction } from "../../../../../redux/actions/TeacherPortal/scheduleAction";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import editIcon from "../../../../../assets/icons/edit.png";
import printerIcon from "../../../../../assets/icons/printer.png";
import scheduledIcon from "../../../../../assets/icons/scheduled.png";
import unscheduledIcon from "../../../../../assets/icons/unscheduled.png";

export default function Schedule() {
    const dispatch = useDispatch();

    const [data, setData] = useState([]);

    const history = useHistory();
    const viewAgenda = useRef(null);
    const op = useRef(null);

    useEffect(() => {
        dispatch(
            onGetSchedulesAction((e) => {
                setData(e);
            })
        );
    }, [dispatch]);

    const ActionTemplate = (r) => {
        return (
            <>
                <img
                    src={editIcon}
                    width="25px"
                    className="mx-2 cursor-pointer"
                    onClick={() => {
                        history.push(`/teacher/schedule/edit/${r?.id}`);
                    }}
                />
                <img
                    src={r?.is_event_created ? scheduledIcon : unscheduledIcon}
                    width="25px"
                    className="mx-2 cursor-pointer"
                    onClick={() => {
                        history.push(`/teacher/schedule/setup/${r?.id}`);
                    }}
                />{" "}
                <img
                    src={printerIcon}
                    width="25px"
                    className="mx-2 cursor-pointer"
                    onClick={() => {
                        history.push(`/teacher/schedule/view/${r?.id}`);
                    }}
                />
            </>
        );
    };
    return (
        <>
            <Layout>
                <div className="p-5">
                    <CardHeader icon={CalendarIcon} heading="Schedule" />
                    <div className="table-top-header border-round-top-xl bg-gray-light p-3">
                        <div className="flex justify-content-end">
                            <div className="flex justify-content-between">
                                <div
                                    className={`flex align-items-center open-sans font-semibold text-xs ml-5 cursor-pointer`}
                                    onClick={() => history.push("/teacher/schedule/add")}
                                >
                                    <img src={addIcon} alt="" className="bg-white p-2 border-round-lg " width={30} />
                                    &nbsp; Add
                                </div>
                                <div
                                    className={`flex align-items-center open-sans font-semibold text-xs ml-5 cursor-pointer${
                                        viewAgenda.current ? "" : `icon-rotate`
                                    }`}
                                    onClick={(e) => {
                                        viewAgenda.current.toggle(e);
                                    }}
                                >
                                    <img src={viewIcon} alt="" className="bg-white p-2 border-round-lg " width={30} />
                                    &nbsp; Print &nbsp;
                                    <img src={Dropblack} alt="" className="" width={15} />
                                </div>
                                <div
                                    className="flex align-items-center open-sans font-semibold text-xs ml-5 cursor-pointer"
                                    onClick={(e) => op.current.toggle(e)}
                                >
                                    <img src={Filter} alt="" className="bg-white p-2 border-round-lg" width={30} />
                                    &nbsp; Filter
                                </div>

                                <div
                                    className={`flex align-items-center open-sans font-semibold text-xs ml-5 cursor-pointer${
                                        viewAgenda.current ? "" : `icon-rotate`
                                    }`}
                                    onClick={(e) => {
                                        viewAgenda.current.toggle(e);
                                    }}
                                >
                                    <img src={viewIcon} alt="" className="bg-white p-2 border-round-lg " width={30} />
                                    &nbsp; View &nbsp;
                                    <img src={Dropblack} alt="" className="" width={15} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {data.length ? (
                        <>
                            <DataTable
                                value={data}
                                tableStyle={{ minWidth: "100%" }}
                                paginator
                                stripedRows
                                className="text-center shadow-1 dynamicdata"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                            >
                                <Column field="school_name" header="School"></Column>
                                <Column field="day" header="Day"></Column>
                                <Column field="start_date" header="Start"></Column>
                                <Column field="end_date" header="End"></Column>
                                <Column header="Actions" body={ActionTemplate}></Column>
                            </DataTable>
                        </>
                    ) : (
                        <div className="text-center">
                            <img src={EmptyCalendar} alt="EmptyCalendar" />
                            <p>Oops! It looks like you haven't added any schedules yet. Start today by adding your first schedule!</p>
                            <ButtonComponent className="mt-4" label="Add Schedule" onClick={() => history.push("/teacher/schedule/add")} />
                        </div>
                    )}
                </div>
            </Layout>

            <OverlayPanel ref={op} className="p-0 p-0 search-intrument border-round-lg" style={{ width: "35rem" }}>
                <div className="overlay-header bg-main p-2 text-center">
                    <h5 className="text-black">Filter Calendar</h5>
                </div>
                <div className="py-4 px-3"></div>
            </OverlayPanel>
            <Menu model={[]} popup ref={viewAgenda} className={"view-menu "} />
        </>
    );
}
