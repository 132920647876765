let types = {
    SHOW_LOADER: "SHOW_LOADER",
    HIDE_LOADER: "HIDE_LOADER",
    USER_DETAIL: "USER_DETAIL",
    FETCH_SCHOOLS: "FETCH_SCHOOLS",
    VIEW_SCHOOL: "VIEW_SCHOOL",
    VIEW_ADMISSION_FORM: "VIEW_ADMISSION_FORM",
    VIEW_SCHOOL_GRADES: "VIEW_SCHOOL_GRADES",
    VIEW_SCHOOL_LESSONS: "VIEW_SCHOOL_LESSONS",
    VIEW_INSTRUMENT: "VIEW_INSTRUMENT",
    SCHOOL_DATA: "SCHOOL_DATA",

    ADD_STUDENT_INFO: "ADD_STUDENT_INFO",
    GET_STUDENT_NEWS: "GET_STUDENT_NEWS",
    STUDENT_MESSAGE_HISTORY: "STUDENT_MESSAGE_HISTORY",
    STUDENT_ATTENDENCE_NOTES: "STUDENT_ATTENDENCE_NOTES",
    STUDENT_PRACTICE_LOGS: "STUDENT_PRACTICE_LOGS",

    ADD_FORM_INFO: "ADD_FORM_INFO",
    FETCH_ONLINE_LESSONS: "FETCH_ONLINE_LESSONS",
    VIEW_ONLINE_LESSON: "VIEW_ONLINE_LESSON",
    ADD_BILLING_INFO: "ADD_BILLING_INFO",
    GET_STUDENT_PROFILE: "GET_STUDENT_PROFILE",
    GET_TEACHER_PROFILE: "GET_TEACHER_PROFILE",
    GET_PARENT_PROFILE: "GET_PARENT_PROFILE",
    GET_PARENT_STUDENTS: "GET_PARENT_STUDENTS",
    GET_PARENT_INVOICES: "GET_PARENT_INVOICES",
    GET_TEACHER_DASHBOARD: "GET_TEACHER_DASHBOARD",
    GET_STUDENT_DASHBOARD: "GET_STUDENT_DASHBOARD",
    GET_PARENT_DASHBOARD: "GET_PARENT_DASHBOARD",
    GET_PARENT_CONTACT: "GET_PARENT_CONTACT",
    GET_PARENT_NEWS: "GET_PARENT_NEWS",
    GET_TEACHER_OVERDUE_ATTANDENCE: "GET_TEACHER_OVERDUE_ATTANDENCE",
    GET_MESSAGE_HISTORY: "GET_MESSAGE_HISTORY",
    VIEW_EVENT: "VIEW_EVENT",
    VIEW_ATTENDENCE: "VIEW_ATTENDENCE",
    TEACHERS_LIST: "TEACHERS_LIST",
    GET_ASSIGNMENTS: "GET_ASSIGNMENTS",
    GET_NOTES: "GET_NOTES",
    GET_ACCOUNTS: "GET_ACCOUNTS",
    VIEW_STUDENT: "VIEW_STUDENT",
    FETCH_EVENTS: "FETCH_EVENTS",
    GET_TEACHER_EVENTS: "GET_TEACHER_EVENTS",
    GET_STUDENT_EVENTS: "GET_STUDENT_EVENTS",
    GET_TEACHER_NEWS: "GET_TEACHER_NEWS",
    TEACHER_ASSIGNMENT: "TEACHER_ASSIGNMENT",
    STUDENT_DROPDOWN: "STUDENT_DROPDOWN",
    GET_COMPENSATION: "GET_COMPENSATION",
    VIEW_TEACHER_ATTENDENCE: "VIEW_TEACHER_ATTENDENCE",
    GET_TEACHER_SCHOOL_DROPDOWN: "GET_TEACHER_SCHOOL_DROPDOWN",
    TEACHER_CLASS_TYPES: "TEACHER_CLASS_TYPES",
    TEACHER_PROFILE: "TEACHER_PROFILE",
    VIEW_TEACHER_STUDENTS: "VIEW_TEACHER_STUDENTS",
    VIEW_PARENT_ASSIGNMENTS: "VIEW_PARENT_ASSIGNMENTS",
    VIEW_TEACHER_ASSIGNMENTS: "VIEW_TEACHER_ASSIGNMENTS",
    TEACHER_FEEDBACK: "TEACHER_FEEDBACK",
    VIEW_TEACHER_STUDENTS_DETAILS: "VIEW_TEACHER_STUDENTS_DETAILS",
    EDIT_STUDENT: "EDIT_STUDENT",
    PARENT_SCHOOL_DROPDOWN: "PARENT_SCHOOL_DROPDOWN",
    VIEW_SCHOOL_GRADE_CLASS_TYPE: "VIEW_SCHOOL_GRADE_CLASS_TYPE",
    PARENT_CLASS_TYPE_PROGRAMS: "PARENT_CLASS_TYPE_PROGRAMS",
    GET_FILTER_LESSON: "GET_FILTER_LESSON",
    SCHOOL_INSTRUMENT: "SCHOOL_INSTRUMENT",
    ONLINE_INSTRUMENTS: "ONLINE_INSTRUMENTS",
    GET_PARENT_CARDS: "GET_PARENT_CARDS",
    GET_TEACHER_AVAILIBILITY: "GET_TEACHER_AVAILIBILITY",
    GET_ALL_INSTRUMENTS: "GET_ALL_INSTRUMENTS",
    EMAIL_DROPDOWN: "EMAIL_DROPDOWN",
    GET_TEACHER_MESSAGE_HISTORY: "GET_TEACHER_MESSAGE_HISTORY",
    CHANGE_CALENDAR_DATE: "CHANGE_CALENDAR_DATE",
    VIEW_SCHOOL_TEACHERS: "VIEW_SCHOOL_TEACHERS",
    TEACHER_STUDENTS: "TEACHER_STUDENTS",
    FETCH_CATEGORIES: "FETCH_CATEGORIES",
    GET_CALENDAR_FILTER: "GET_CALENDAR_FILTER",
    PRIVELEGES_TEACHER: "PRIVELEGES_TEACHER",
    GET_EMAIL_TEMPLATE: "GET_EMAIL_TEMPLATE",
    VIEW_TEMPLATE: "VIEW_TEMPLATE",
    SEND_EMAIL_LIST: "SEND_EMAIL_LIST",
    TEACHER_VIEW_EMAIL: "TEACHER_VIEW_EMAIL",
    UNPAID_INVOICES: "UNPAID_INVOICES",

    EVENT_DETAILS: "EVENT_DETAILS",

    UPDATE_ENROLLMENT_DATA: "UPDATE_ENROLLMENT_DATA",
    UPDATE_SCHOOL_FORMS: "UPDATE_SCHOOL_FORMS",
    ADD_ANOTHER_SCHOOL_FORM: "ADD_ANOTHER_SCHOOL_FORM",
    INITIALIZE_SCHOOL_FORMS: "INITIALIZE_SCHOOL_FORMS",
    SET_ADMISSION_FIELDS: "SET_ADMISSION_FIELDS",
    UPDATE_ADMISSION_FIELD: "UPDATE_ADMISSION_FIELD",
    UPDATE_EXTRA_FIELDS: "UPDATE_EXTRA_FIELDS",
    SET_EXTRA_FIELDS: "SET_EXTRA_FIELDS",
    SET_ADMISSION_FORM: "SET_ADMISSION_FORM",
    RESET_ADMISSION_FIELDS: "RESET_ADMISSION_FIELDS",
    REMOVE_STUDENT: "REMOVE_STUDENT",
    GET_CALENDAR_AGENDA: "GET_CALENDAR_AGENDA",

    UPDATE_STUDENT_FORM: "UPDATE_STUDENT_FORM",
    VIEW_STUDENT_EXTRA_FIELD: "VIEW_STUDENT_EXTRA_FIELD",
    FETCH_SCHOOLS_GRADE_INSTRUMENT: "FETCH_SCHOOLS_GRADE_INSTRUMENT",
    FETCH_SCHOOLS_GRADE_INSTRUMENT_PROGRAMS: "FETCH_SCHOOLS_GRADE_INSTRUMENT_PROGRAMS",
    FETCH_MORE_PROGRAM_INFO: "FETCH_MORE_PROGRAM_INFO",
    INITIALIZE_SCHOOL_FIELDS: "INITIALIZE_SCHOOL_FIELDS",
    REPLACE_KEYS: "REPLACE_KEYS",
    RESET_STUDENT_FIELDS: "RESET_STUDENT_FIELDS",
    VIEW_ONLINE_GRADES: "VIEW_ONLINE_GRADES",
    FETCH_SCHOOLS_CLASSES: "FETCH_SCHOOLS_CLASSES",
    VIEW_ONLINE_ENROLLMENT_LESSONS: "VIEW_ONLINE_ENROLLMENT_LESSONS",
    UPDATE_PARENT_FORM: "UPDATE_PARENT_FORM",
};
export default types;
