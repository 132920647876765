import React from "react";
import Fbwhite from "../../assets/icons/fb-white.png";
export default function Footer({ iconVisible = true }) {
    return (
        <>
            <div className="p-3 bg-dark">
                <div className="grid justify-content-center">
                    <div className={` ${iconVisible ? "xl:col-9" :"xl:col-12 text-center"}`}>
                        <p className="text-white text-sm open-sans font-normal">© 2024 Fundamental music. All rights reserved</p>
                    </div>
                    {iconVisible ? (
                        <div className="xl:col-3">
                            <div className="flex justify-content-end align-items-center">
                                <img src={Fbwhite} alt="" className="" width="9" />
                                <i className="pi pi-twitter text-white ml-4" style={{ fontSize: "1rem" }}></i>
                                <i className="pi pi-instagram text-white ml-4" style={{ fontSize: "1rem" }}></i>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </>
    );
}
