import Constants from "../../../services/constant";
import { loadStateFn } from "../../../utils/localStorage";
import { api, multipartApi } from "../../../services/api";
import { showLoaderAction, hideLoaderAction } from "../loadAction";
import { showToast } from "../../../utils/showToast";
import types from "../../types";
import moment from "moment";

export const viewTeacherStudentAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.VIEW_TEACHER_STUDENTS, {}, loadStateFn("token"));

    if (res.success) {
        dispatch({
            type: types.VIEW_TEACHER_STUDENTS,
            payload: res.data,
        });
    }
    dispatch(hideLoaderAction());
};
export const viewTeacherStudentDetailAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.VIEW_TEACHER_STUDENTS_DETAILS + id, {}, loadStateFn("token"));

    if (res.success) {
        dispatch({
            type: types.VIEW_TEACHER_STUDENTS_DETAILS,
            payload: res.data,
        });
    }
    dispatch(hideLoaderAction());
};
export const AllEmailDropDownAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.EMAIL_DROPDOWN);
    if (res.success) {
        dispatch({
            type: types.EMAIL_DROPDOWN,
            payload: res.data,
        });
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
export const sendCalendarEventEmail = (emailData, message, selectedFiles, history) => async (dispatch) => {
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
        formData.append(`attachment[${i}][file]`, selectedFiles[i]);
    }

    const sendto = [];
    emailData?.to?.map((item, i) => {
        let obj = {
            email: item?.email,
        };
        sendto.push(obj);
    });

    formData.append("from", emailData?.from?.email);
    formData.append("to", JSON.stringify(sendto));
    formData.append("subject", emailData?.subject);
    formData.append("message", message);
    formData.append("graphical_mode", emailData?.graphical_mode == true ? 1 : 0);
    formData.append("cc_me", emailData?.cc_me == true ? 1 : 0);
    formData.append("send_at", "immediately");
    formData.append("extra_recipients", emailData?.extra_recipients);

    if (emailData?.send_at == "scheduled") {
        formData.append("schduled_date", moment(emailData?.schduled_date).format("DD-MM-YYYY"));
        formData.append(
            "schduled_time",
            new Date(emailData?.scheduled_time).getHours() +
                ":" +
                ("00" + new Date(emailData?.scheduled_time).getMinutes()).slice(-2)
        );
    }

    dispatch(showLoaderAction());

    const res = await multipartApi("post", Constants.END_POINT.SEND_EMAIL, formData, loadStateFn("token"));
    if (res.success) {
        showToast([res.message], "success");
        history.goBack(-1);
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
export const getMesageHistoryAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.GET_TEACHER_MESSAGE_HISTORY);
    if (res.success) {
        dispatch({
            type: types.GET_TEACHER_MESSAGE_HISTORY,
            payload: res.data,
        });
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
