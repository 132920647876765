import React from "react";
import Logo from "../../../assets/logo/logo.png";
import LessonImg from "../../../assets/images/Select-amico1.png";
import MusicLyric from "../../../assets/icons/music-lyric.png";
import SelectSchool1 from "../../../containers/selectSchool";
import { Link, useHistory } from "react-router-dom";
import ArrowRight from "../../../assets/icons/arrow-right.png";
import DropDownComponent from "../../../components/Fields/CustomDropdown";

export default function SelectSchool() {
    const { selectedSchool, school, handleChange, handleContinue, err } = SelectSchool1();
    const history = useHistory();

    return (
        <>
            <div className="px-2 md:px-7 shadow-2 bg-main block md:hidden">
                <div className="p-2 flex justify-content-between align-items-center">
                    <div className="logo">
                        <div className="logo" onClick={() => history.push("/")}>
                            <img src={Logo} alt="" width="200" className="mr-2"></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="banner login-banner" style={{ height: "100vh" }}>
                    <div className="grid  m-0 h-auto md:h-full align-items-center">
                        <div className="bg-main lg:col-6 md:col-12 sm:col-12 flex align-items-center h-auto md:h-full relative">
                            <div className="p-2 md:px-8 absolute w-100 top-0 left-0 z-1 logo-desktop">
                                <div className="logo" onClick={() => history.push("/")}>
                                    <img src={Logo} alt="" width="200" className="mr-2"></img>
                                </div>
                            </div>
                            <img src={LessonImg} alt="" className="m-auto" style={{ width: "80%" }} />
                        </div>
                        <div className="lg:col-6 md:col-12 p-2 md:p-4 py-2 h-auto md:h-full ">
                            <div className="relative p-3 mt-5 ml-0 md:ml-5 flex align-items-center justify-content-center h-100">
                                <Link to="/" className="arrow-back absolute top-0 left-0">
                                    <img src={ArrowRight} width={20} alt="" />
                                </Link>
                                <img
                                    src={MusicLyric}
                                    alt="imh"
                                    className="absolute opacity-20 w-5"
                                    style={{ right: "2rem", top: "-2rem", zIndex: "-1" }}
                                />
                                <div className=" relative ">
                                    <div className="shadow-2 p-5 py-5  border-round-lg w-full ml-0 md:ml-3 relative z-3 bg-white">
                                        <h1 className="text-2xl mb-3">
                                            <b>Enroll Now in at -</b>
                                            <span className="text-main"> School Lesson</span>
                                        </h1>

                                        <div className="w-full">
                                            <p className="mb-2 open-sans">School</p>
                                            <DropDownComponent
                                                name="school"
                                                className="w-full "
                                                placeholder="Select School"
                                                value={selectedSchool?.school}
                                                options={school?.map((item, index) => {
                                                    return {
                                                        label: item?.name,
                                                        value: item?.id,
                                                    };
                                                })}
                                                onChange={handleChange}
                                                optionLabel="label"
                                            />
                                        </div>
                                        {err && <div className="text-danger">{err.selectedSchool}</div>}

                                        <button
                                            className="mt-3  btn bg-dark px-4 w-full p-3 border-none text-white font-semibold border-round-lg cursor-pointer open-sans"
                                            onClick={handleContinue}
                                        >
                                            Continue
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
