import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import EmailComponent from "../../../../pages/Email/EmailComponent";
import { useDispatch, useSelector } from "react-redux";
import { getTeacherProfileAction } from "../../../../redux/actions/TeacherPortal/teacherAction";

const StudentMessaging = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getTeacherProfileAction);
    }, []);

    const teacherDetail = useSelector((state) => state?.login?.getTeacherProfile);
    const fromOption = [
        {
            name: `${teacherDetail?.name}<${teacherDetail?.email}>`,
            item: `${teacherDetail?.name}<${teacherDetail?.email}>`,
            email: teacherDetail?.email,
        },
    ];

    const [emailData, setEmailData] = useState({
        from: {},
        to: [],
        subject: "",
        graphical_mode: true,
        cc_me: false,
        send_at: "immediately",
        scheduled_date: "",
        scheduled_time: "",
        extra_recipients: [],
    });
    const selectedOptions =
        location?.state?.role === "student"
            ? location?.state?.items
                  ?.filter((item) => item.email)
                  ?.map((item) => {
                      return { label: `${item?.name}<${item?.email}>`, email: item?.email };
                  }) || []
            : location?.state?.items
                  ?.filter((item) => item.parent_users_id?.email)
                  ?.map((item) => {
                      return {
                          label: `${item?.parent_users_id?.name}<${item?.parent_users_id?.email}>`,
                          email: item?.parent_users_id?.email,
                      };
                  }) || [];

    return <EmailComponent emailData={emailData} setEmailData={setEmailData} selected={selectedOptions} fromOption={fromOption} />;
};

export default StudentMessaging;
