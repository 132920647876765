import React from "react";
import { Dialog } from "primereact/dialog";
import ScheduleData from "./main";
import { Link } from "react-router-dom";

export const LoginDialog = ({
  selectUserType,
  setSelectUserType,
  visible,
  setVisible,
}) => {
  const { userData } = ScheduleData();
  return (
    <div>
      <Dialog
        className="login_tabs"
        header="Select User"
        visible={visible}
        style={{ width: "40vw" }}
        onHide={() => setVisible(false)}
      >
        <div className="flex justify-content-around mb-4">
          {userData.map((user, i) => (
            <div
              className="user-select text-center cursor-pointer"
              key={i}
              onClick={() => setSelectUserType(user.type)}
            >
              <div
                className={`mb-2 border-circle flex flex-column justify-content-center align-items-center ${selectUserType === user.type ? "select-usericon" : "usericon"
                  }`}
              >
                <img src={user.img} width={35} alt="" />
              </div>
              <p
                className={`text-sm text-main open-sans text-center font-semibold ${selectUserType === user.type ? "text-main" : "userheading"
                  }`}
              >
                {user.heading}
              </p>
            </div>
          ))}
        </div>
        {
          <Link
            to={
              selectUserType === "STUDENT"
                ? `/student-login`
                : selectUserType === "PARENT"
                  ? "/parent-login"
                  : selectUserType === "TEACHER"
                    ? "teacher-login"
                    : "/"
            }
          >
            <div className="text-center">
              <button className="text-dark bg-main border-round-lg text-sm border-none p-2 px-5 open-sans font-semibold">
                Continue
              </button>
            </div>
          </Link>
        }
      </Dialog>
    </div>
  );
};
