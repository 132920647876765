import React, { useState, useEffect } from "react";
import Index from "../../layout";
import IndexNotes from "../../../../assets/icons/sticky-notes.png";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import CalendarIcon from "../../../../assets/icons/Calenderred.png";
import ColumnsImg from "../../../../assets/icons/columns.png";
import Dropblack from "../../../../assets/icons/dropblack.png";
import { useDispatch, useSelector } from "react-redux";
import { getParentNotesAction } from "../../../../redux/actions/ParentPortal/attendenceAndNotesAction";
import { Dialog } from "primereact/dialog";
import InputComponent from "../../../../components/Fields/InputField";
import ButtonComponent from "../../../../components/Fields/Button/CustomButton";
import view from "../../../../assets/icons/eyegreen.png";
import moment from "moment";
import { capitalizeWithUnderscores } from "../../../../utils/commonFunctions";
import CardHeader from "../../../../pages/CardHeader";
import CustomCalendar from "../../../../components/Fields/CustomCalendar";

export default function Attendance() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getParentNotesAction(new Date()));
    }, []);

    const attendenceNotes = useSelector((state) => state?.attendenceNotes?.getParentNotes);
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState(null);
    const [date, setDate] = useState(new Date());

    const ActionTemplate = (r) => {
        return (
            <>
                <img
                    src={view}
                    width="25px"
                    alt=""
                    onClick={() => {
                        setVisible(true);
                        setData(r);
                    }}
                />
            </>
        );
    };

    return (
        <Index>
            <div className="p-4">
                <CardHeader icon={IndexNotes} heading="Attendance & Notes" />
                <h4 className="open-sans font-semibold mb-4">
                    {attendenceNotes?.attendance_per} % attendance over the last days.
                </h4>
                <h5 className="text-main font-medium">
                    You've attended {attendenceNotes?.total_presents} of {attendenceNotes?.total_attendance} scheduled events (
                    {attendenceNotes?.total_absences} absences)
                </h5>
                <div className="shadow-sm border-round-lg overflow-hidden">
                    <div className="table-top-header border-round-top-xl bg-main p-3">
                        <div className="flex justify-content-between">
                            <div className="flex justify-content-between">
                                <div className="flex align-items-center open-sans font-semibold text-xs ml-5">
                                    <CustomCalendar
                                        size="md:col-12"
                                        showWeek
                                        value={date}
                                        onChange={(e) => {
                                            setDate(e.value);
                                            dispatch(getParentNotesAction(e.value));
                                        }}
                                    />
                                </div>
                                {/* <div className="">
                                    <Link
                                        to="/add-teacher"
                                        className="flex align-items-center open-sans font-semibold text-xs ml-4 no-underline text-900"
                                    >
                                        <img src={CalendarIcon} alt="" className="bg-white p-2 border-round-lg" width={30} />
                                        &nbsp; 21/12/2022 &nbsp;
                                        <img src={Dropblack} alt="" className="" width={15} />
                                    </Link>
                                </div> */}
                            </div>
                            {/* <div className="flex align-items-center open-sans font-semibold text-xs ml-5">
                                <img src={ColumnsImg} alt="" className="bg-white p-2 border-round-lg" width={30} />
                                &nbsp; Columns &nbsp;
                                <img src={Dropblack} alt="" className="" width={15} />
                            </div> */}
                        </div>
                    </div>
                    <DataTable
                        className="student-info-table"
                        value={attendenceNotes?.student_attendance?.length && attendenceNotes?.student_attendance}
                        stripedRows
                        tableStyle={{ minWidth: "50rem" }}
                        paginator
                        responsiveLayout="scroll"
                        paginatorTemplate=" FirstPageLink  PageLinks  LastPageLink RowsPerPageDropdown "
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50]}
                        style={{ borderRadius: "10px" }}
                        emptyMessage="No attendence and notes found."
                    >
                        <Column field="student_name" header="Student"></Column>
                        <Column field="event_name" header="Event"></Column>
                        <Column
                            field="event_date"
                            body={(r) => {
                                return r.event_date + ` ${r.event_start_time}`;
                            }}
                            header="Date/Time"
                        ></Column>
                        <Column
                            // field="status"
                            body={(row) => capitalizeWithUnderscores(row?.status)}
                            header="Attendence"
                        ></Column>
                        <Column header="Actions" body={ActionTemplate}></Column>
                    </DataTable>
                    <Dialog
                        header="Attendence Details"
                        visible={visible}
                        onHide={() => setVisible(false)}
                        style={{ width: "48vw" }}
                        draggable={false}
                        resizable={false}
                    >
                        <InputComponent label="Event" value={data?.event_name} />
                        <InputComponent label="Date" value={moment(data?.event_date).format("MM-DD-YYYY")} />
                        <InputComponent label="Attendance Status" value={data?.status} />
                        <label>Notes</label>
                        <div
                            className="border border-round-lg border-1 border-700 p-2"
                            dangerouslySetInnerHTML={{ __html: data?.notes }}
                        ></div>

                        <label>Assignment</label>
                        <div
                            className="border border-round-lg border-1 border-700 p-2"
                            dangerouslySetInnerHTML={{ __html: data?.assignment }}
                        ></div>
                        <label>Linked To online Resources</label>
                        <div className="mb-3">{data?.online_resource.join(",")}</div>

                        <ButtonComponent label="Cancel" onClick={() => setVisible(false)} />
                    </Dialog>
                </div>
            </div>
        </Index>
    );
}
