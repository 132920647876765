import Home from "../../../assets/icons/home.png";
import StudentIcon from "../../../assets/icons/student.png";
import CalendarIcon from "../../../assets/icons/calender.png";
import ScheduleIcon from "../../../assets/icons/schedule.png";
import TeacherIcon from "../../../assets/icons/teacher.png";
import NewspaperIcon from "../../../assets/icons/newspaper.png";
import SettingIcon from "../../../assets/icons/setting.png";
import CompensationIcon from "../../../assets/icons/compensationIcon.png";
import Attendance from "../../../assets/icons/attandence.png";
import Assignments from "../../../assets/icons/Assignments.png";
import Messageicon from "../../../assets/icons/messagehistory.png";

import { useState } from "react";

const Sidemenu = () => {
    const [isFullSidebar, setIsFullSidebar] = useState(true);

    const menuItems = [
        {
            name: "Home",
            link: "/teacher-dashboard/",
            icon: Home,
            iconWidth: "30px",
            iconHeight: "30px",
        },
        {
            name: "Teachers",
            link: "/teachers/teacher",
            icon: TeacherIcon,
            iconWidth: "29px",
            iconHeight: "29px",
        },
        {
            name: "Assignments",
            link: "/teacher/assignments",
            icon: Assignments,
            iconWidth: "29px",
            iconHeight: "29px",
        },

        {
            name: "Compensation",
            link: "/teacher/compensation",
            icon: CompensationIcon,
            iconWidth: "29px",
            iconHeight: "29px",
        },
        {
            name: "Attendance & Notes",
            link: "/teacher/attandance-notes",
            icon: Attendance,
            iconWidth: "29px",
            iconHeight: "29px",
        },
        {
            name: "Students",
            link: "/teacher/students",
            icon: StudentIcon,
            iconWidth: "29px",
            iconHeight: "29px",
        },

        {
            name: "Schedule",
            link: "/teacher/schedule",
            icon: ScheduleIcon,
            iconWidth: "29px",
            iconHeight: "29px",
        },
        {
            name: "Calendar",
            link: "/teacher/calendar",
            icon: CalendarIcon,
            iconWidth: "29px",
            iconHeight: "29px",
        },
        {
            name: "Message History",
            link: "/teacher/message-history",
            icon: Messageicon,
            iconWidth: "30px",
            iconHeight: "30px",
        },
        {
            name: "News",
            link: "/teacher/news",
            icon: NewspaperIcon,
            iconWidth: "29px",
            iconHeight: "29px",
        },
        {
            name: "Settings",
            link: "/teacher/settings",
            icon: SettingIcon,
            iconWidth: "29px",
            iconHeight: "29px",
        },
    ];
    return { menuItems, isFullSidebar, setIsFullSidebar };
};
export default Sidemenu;
