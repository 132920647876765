import moment from "moment";

export function getTimeSlots(startTime, endTime, duration = 30) {
    const timeSlots = [];
    const start = moment(startTime, "HH:mm");
    const end = moment(endTime, "HH:mm");

    while (start <= end) {
        timeSlots.push(start.format("HH:mm"));
        start.add(duration, "minutes");
    }

    return timeSlots;
}

export function getDatesForWeekday(startDate, endDate, weekday) {
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    let dayNumber = days.findIndex((item) => item === weekday) + 1;
    const start = moment(startDate);
    const end = moment(endDate);
    const result = [];

    while (start.isoWeekday() !== dayNumber) {
        start.add(1, "days");
    }
    while (start.isSameOrBefore(end)) {
        result.push(start.clone().format("YYYY-MM-DD")); // Format the date as needed
        start.add(1, "weeks");
    }
    return result;
}

export function convertTo12HourFormat(time) {
    return moment(time, "HH:mm").format("hh:mm");
}
