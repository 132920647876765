import types from "../../types";
const initialState = {
    getteacherEmails: [],
    emailView: {},
};
const EmailTeacherReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SEND_EMAIL_LIST:
            return { ...state, getteacherEmails: action.payload };
        case types.TEACHER_VIEW_EMAIL:
            return { ...state, emailView: action.payload };

        default:
            return { ...state };
    }
};

export default EmailTeacherReducer;
