var base64 = require("base-64");
export const isAuthenticated = () => {
    if (typeof window == "undefined") {
        return false;
    }
    if (localStorage.getItem("token")) {
        return JSON.parse(localStorage.getItem("token"));
    } else {
        return false;
    }
};

export const authenticate = (token, next) => {
    if (typeof window !== "undefined") {
        localStorage.setItem("token", JSON.stringify(token));
        next();
    }
};
export const authenticate1 = (token, role, next) => {
    var role = base64.encode(role);
    if (typeof window !== "undefined") {
        localStorage.setItem("token", JSON.stringify(token));
        localStorage.setItem("Role", role);
        next();
    }
};

export const logout = (next) => {
    if (typeof window !== "undefined") {
        localStorage.clear();
        next();
    }
};
