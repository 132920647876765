import React from "react";

const TermsAndCondition = () => {
  return (
    <div>
      <div>
        <div className="student_banner relative">
          <h1 className="text-white relative text-center font-semibold">
            Terms and Condtions
          </h1>
        </div>
        <div className=" p-5">
          <p className="text-900 font-semibold text-xl inter mb-2">
            The 1st payment is processed during enrollment - if you enroll after
            September, the 2nd payment will also be added (This will ensure the
            tuition payments to not extend beyond May). If you choose the 1
            annual payment, a discount is given. (The lesson book / band music
            is automatically added with the initial enrollment).
            <br />  <br />In addition, you may choose an instrument purchase/rental
            during enrollment. Purchasing an instrument can be done in (1)
            school year or less by completing (1) rental cycle or purchasing in
            (1) payment with a discount added. (Note: there will be a deposit
            and 1st payment when you choose to Rent - the term rent is simply
            used to give you the option to return the instrument in good
            condition after 5 payments are made - You are actually purchasing
            the instrument and will own it once the payment cycle is finished)
            <br /> <br /> Please Note; this is a purchase plan not a rental plan. You
            will own the instrument after the payments (No finance or interest
            charges). You may return the instrument at any point after 5
            payments. However, there is no refund - the payments will stop once
            the instrument is returned to FMI in good condition. 
          </p> <br />
          <p className="font-semibold text-xl inter mb-2 text-red-600">
            We are offering this purchase program to keep costs low for you
            while ensuring students have quality instruments that are
            appropriate for beginning students. In most cases, a music store
            rental will be a higher price than these payments and will last 5
            years or more. FMI is investing in your child's musical future by
            securing quality instruments for a fraction of the cost.
          </p> <br />
          <p className="text-900 font-semibold text-xl inter mb-3">
            If you prefer not to use the Instrument Purchase Program, please
            simply use the rental options on our website or any local music
            store of your choice. Avoid the "Big Box Store / Online purchase" as
            many sources are poor quality and cannot be serviced by instrument
            repair services (Springs are usually substandard and construction
            materials are thinner and break easily)
          </p> 
          <h2 className="font-semibold inter mb-2 text-red-600">
            TERMS & CONDITIONS: INSTRUMENT PURCHASE (If Needed otherwise skip to
            General Terms)
          </h2>
          <ul className="condition-list ml-4 mb-4">
            <li>
              I understand this is a purchase program with no financing or
              interest charges.
            </li>
            <li>
              There will be a $50 deposit and 1st month's payment upon
              instrument order. The remaining balance of your purchase will be
              divided into 5-17 Payments (depending on instrument choice)
            </li>
            <li>
              After the deposit and 1st month's payment a Total of 5 Payments
              remain ($25 ea) 61 Key Digital Keyboard, Guitar, Tabletop Electric
              Drum Set.
            </li>
            <li>
              After the deposit and 1st month's payment a Total of 9 Payments
              remain ($25 ea) for Flute, Clarinet, snare drum kit
            </li>
            <li>
              After the deposit and 1st month's payment a Total of 11 Payments
              remain ($25 ea) Trumpet and Trombone 
            </li>
            <li>
              After the deposit and 1st month's payment a Total of 17 Payments
              remain ($38 ea) for Alto Saxophone and Cello
            </li>
          </ul>
          <p className="text-900 font-semibold text-xl inter mb-3">
            I understand there are no refunds for early instrument returns and
            instruments returned in need of repair will be repaired at my cost -
            this will not exceed the price of the instrument. 
          </p>
          <p className="text-900 font-semibold text-xl inter mb-3">
            Failure to make timely tuition and instrument purchase payments may
            result in removal from the program and early instrument collection.
          </p>
          <p className="text-900 font-semibold text-xl inter mb-3">
            I understand there is no "Cost to Cancel" class after 5 payments and
            full year payments will be prorated before February 15 (no full year
            refunds beyond Feb. 15)
          </p>
          <p className="text-900 font-semibold text-xl inter mb-3">
            I understand the Instrument Purchase Program may be terminated after
            5 payments by returning the instrument in "New Condition or Like New
            Condition" and that the cost of repairs needed will be charged to
            your card should there be damage to the instrument upon return.
          </p>
          <p className="font-semibold text-xl inter mb-2 text-red-600">
            Please Read:
          </p>
          <h3 className="text-900 font-semibold inter mb-3">
            GENERAL TERMS & CONDITIONS
          </h3>
          <ul className="condition-list ml-4 mb-4">
            <li>
              Tuition payments are due by the last day of each month. Failure to
              make timely payments may result in removal from the program. Late
              payments exceeding 30 days past due may result in late fees and/or
              reporting to the credit bureaus. 
            </li>
            <li>
              By registering online, I acknowledge that I am automatically
              enrolled in Autopay for my tuition payments and instrument rental
              payments (if applicable). I agree that my card will be charged
              each month on the monthly anniversary date of enrollment (ie.
              enrolled Sept 5th, payments are Oct. 5th, Nov 5th etc.... This can
              be altered upon request)
            </li>
            <li>
              I acknowledge that I am committing to a minimum of 5 payments for
              lessons and may discontinue beyond this point, and that full year
              payments may be prorated before February 15th (no refunds beyond
              this point)
            </li>
            <li>
              I acknowledge that by providing my email address or cell phone, I
              may receive communications from Fundamental Music Instruction.
            </li>
            <li>
              By providing a credit card number to Fundamental Music Instruction
              LLC and completing this registration, I consent to having this
              credit card charged for current and/or past due balances.
            </li>
            <li>
              By registering my child(ren), I agree to all of the terms and
              conditions listed above
            </li>
          </ul>
          <h3 className="text-900 font-semibold inter mb-3">
            IN-SCHOOL TERMS & CONDITIONS
          </h3>
          <ul className="condition-list ml-4 mb-4">
            <li>
              I understand that photos of concerts and/or classes could be used
              on the Fundamental Music Instruction website, social media
              outlets, print, or school websites. (If you would like to opt out
              of this - contact Mr. Kelly)
            </li>
            <li>
              I acknowledge that I am fully aware and personally responsible for
              my own safety as well as the safety of my child(ren) while
              participating in the music program. With full knowledge of the
              risks involved, I hereby release, waive, and discharge Fundamental
              Music Instruction and all affiliated personnel, its employees,
              officers, and any affiliated staff from any and all liabilities,
              claims, demands, or any actions whatsoever related to any loss,
              damage, injury, or death sustained by me and/or my child(ren), or
              any other family member, related to COVID-19 while participating
              in the music program provided by Fundamental Music Instruction.
            </li>
            <li>
              Fundamental Music Instruction acknowledges that all personnel will
              strictly adhere to all safety guidelines, rules, and regulations
              as provided by the Centers for Disease Control and Prevention. The
              handling, repairing, and/or servicing of any/all musical
              instruments shall also adhere to all safety guidelines, rules, and
              regulations as provided by the Centers for Disease Control and
              Prevention.
            </li>
            <li>
              I understand that all Fundamental Music Instruction Faculty
              members have been fingerprinted and have taken courses in
              recognizing and reporting abuse. 
            </li>
          </ul>
          <h3 className="text-900 font-semibold inter mb-3">
            ADDITIONAL ONLINE LESSON TERMS & CONDITIONS
          </h3>
          <p className="text-900 font-semibold text-xl inter mb-3">
            I understand that classes will take place online using Zoom and/or
            other digital platforms. I hereby release, waive, and discharge
            Fundamental Music Instruction and all affiliated personnel, its
            employees, officers, and any affiliated staff from any and all
            liabilities, claims, demands, or any actions whatsoever related to
            any loss, damage, or injury sustained by me and/or my child(ren), or
            any other family member, related to any unintentional content
            displayed on any of these platforms that may arise due to
            compromised security, the hacking of that platform being used, or
            any professional misrepresentation or misconduct by any teacher or
            participant. I understand that these classes may be comprised of
            children from more than one area to accommodate scheduling and/or
            educational needs. (i.e. Talent showcases)
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
