import types from "../types";
const initialBoard = {
    getParentEvents: [],
    getTeacherEvents:[],
    getStudentEvents:[]
    

};

const calendarReducer = (state = initialBoard, action) => {
    switch (action.type) {
        case types.FETCH_EVENTS:
            return { ...state, getParentEvents: action.payload };
        case types.GET_TEACHER_EVENTS:
            return {...state,getTeacherEvents:action.payload};
        case types.GET_STUDENT_EVENTS:
            return {...state,getStudentEvents:action.payload};
        default:
            return { ...state };
    }
};

export default calendarReducer;