import types from "../../types";
const initialBoard = {
    getParentStudents: [],
    getInvoices: {},
    dashboard: {},
    messageHistory: [],
    viewStudent: {},
    getCards: [],
    getUnpaidInvoices: {},
};

const parentReducer = (state = initialBoard, action) => {
    switch (action.type) {
        case types.GET_PARENT_STUDENTS:
            return { ...state, getParentStudents: action.payload };
        case types.GET_PARENT_INVOICES:
            return { ...state, getInvoices: action.payload };
        case types.GET_PARENT_DASHBOARD:
            return { ...state, dashboard: action.payload };
        case types.GET_MESSAGE_HISTORY:
            return { ...state, messageHistory: action.payload };
        case types.VIEW_STUDENT:
            return {
                ...state,
                viewStudent: action.payload,
            };
        case types.GET_PARENT_CARDS:
            return { ...state, getCards: action.payload };
        case types.UNPAID_INVOICES:
            return { ...state, getUnpaidInvoices: action.payload };
        default:
            return { ...state };
    }
};

export default parentReducer;
