import React from "react";
import { InputNumber } from "primereact/inputnumber";
import "./style.scss";

export default function NumberComponent({
    label,
    name,
    errorMessage,
    extraClassName,
    labelClassName,
    required,
    size,
    inputClass,
    inputId,
    dollar,
    min,
    dollarClass,
    ...props
}) {
    return (
        <div className={`relative ${extraClassName}`}>
            <label
                htmlFor={inputId}
                className={`absolute text-xs top-0 text-500 open-sans bg-white form-label ${labelClassName}`}
            >
                {label} {required && <span className="p-error">*</span>}
            </label>

            <InputNumber
                id={name}
                name={name}
                // className={`w-full border-round-lg open-sans border-1 border-700 overflow-hidden ${inputClass} ${
                //   errorMessage ? "p-invalid" : null
                // }`}
                className={`overflow-hidden w-full h-full ${dollar || min ? "" : "border-round-lg"}  border-400 border-1 ${
                    dollar
                        ? `border-round-right-lg border-noround ${dollarClass}`
                        : min
                        ? "border-round-left-lg border-noround"
                        : inputClass
                } ${errorMessage ? "p-invalid" : null}`}
                {...props}
            />
            {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
        </div>
    );
}

export const CustomNumberComponent = ({
    label,
    name,
    errorMessage,
    extraClassName,
    required,
    inputClass,
    labelClassName,
    ...props
}) => {
    return (
        <>
            <div className={`relative ${extraClassName}`}>
                {label && (
                    <label
                        htmlFor={name}
                        className={`text-base p-0 text-500 form-label mb-0 ${labelClassName} `}
                    >
                        {label} {required && <span className="p-error">*</span>}
                    </label>
                )}

                <InputNumber
                    id={name}
                    name={name}
                    filter
                    className={`w-full border-round-lg border-1 border-400 overflow-hidden ${inputClass}`}
                    {...props}
                />
                {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
            </div>
        </>
    );
};
