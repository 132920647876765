import { entries, notEqual, values } from "./javascript";
import { allValidations } from "./formValidations";
import { confirmDialog } from "primereact/confirmdialog";

export const showFormErrors = (data, setData, ignore) => {
    let formErrors = {};
    entries(data).forEach(([key, value]) => {
        formErrors = {
            ...formErrors,
            ...allValidations(key, value, data, ignore),
        };
    });
    ignore?.forEach((name) => {
        if (formErrors[name]) {
            formErrors[name] = "";
        }
    });
    console.log("formErrors==>", formErrors);
    setData({ ...data, formErrors });
    return !values(formErrors).some((v) => notEqual(v, ""));
};
export const showFormErrors1 = (data, index, setData) => {
    let formErrors = {};
    entries(data[index]?.student_detail).forEach(([key, value]) => {
        formErrors = {
            ...formErrors,
            ...allValidations(key, value, data[index]?.student_detail),
        };
    });
    setData({ ...data[index]?.student_detail, formErrors }, index);
    return !Object.values(formErrors).some((v) => notEqual(v, ""));
};

export const removeEmpty = (obj) => {
    const newObj = {};
    Object.entries(obj).forEach(([k, v]) => {
        if (v === Object(v)) {
            newObj[k] = removeEmpty(v);
        } else if (v !== "" && v !== null) {
            newObj[k] = obj[k];
        }
    });
    return newObj;
};

export function adjustTime(e) {
    let currentMin = e.value?.getMinutes();
    let startTime = e.value;
    if (currentMin % 5 !== 0) {
        startTime = new Date(e.value?.getTime() + (5 - (currentMin % 5)) * 60000);
    }
    return startTime;
}

export function capitalizeWithUnderscores(inputString) {
    let str = "";
    if (inputString) {
        str = inputString;
    }
    return str
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}
export function isNotNull(element) {
    return element !== null;
}

export const convertToFormdata = (obj) => {
    const formData = new FormData();
    Object.entries(obj).forEach(([k, v]) => {
        if (Array.isArray(v)) {
            v.forEach((item, i) => {
                Object.entries(item).forEach(([k1, v1], i1) => {
                    formData.append(`${k}[${i}][${k1}]`, v1);
                });
            });
        } else {
            formData.append(k, v);
        }
    });
    return formData;
};

export const getDays = (days) => {
    const names = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const result = [];

    for (let i = 0; i < days?.length; i++) {
        result.push(names[days[i]]);
    }

    return result;
};
export const formattedPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length === 0) {
        return phoneNumber;
    } else if (phoneNumber.length < 4) {
        return "(" + phoneNumber + ")";
    } else if (phoneNumber.length < 7) {
        return "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3);
    } else {
        return "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6);
    }
};
export const removeSpaces = (str) => {
    return str.replace(/\s/g, "");
};

export const confirmDelete = (onDeleteAction, confirmationMessage, position) => {
    confirmDialog({
        message: confirmationMessage,
        icon: "pi pi-info-circle",
        header: "Delete Confirmation",
        acceptClassName: "bg-main",
        position,
        accept: () => {
            onDeleteAction();
        },
        reject: () => {},
    });
};

export const confirmNext = (NextAction, confirmationMessage, position) => {
    confirmDialog({
        message: confirmationMessage,
        icon: "pi pi-info-circle",
        header: "Confirmation",
        className: "confirmation-next",
        position,
        accept: () => {
            NextAction();
        },
        reject: () => {},
    });
};
export const TimeShowFormat = (date) => {
    var hours = date?.getHours();
    var minutes = date?.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
};
export const convertTimeTo12HourFormat = (time) => {
    const splitTime = time.split(":");
    const hour = parseInt(splitTime[0]);

    if (hour === 0) {
        return `12:00 AM`;
    } else if (hour < 12) {
        return `${hour}:00 AM`;
    } else if (hour === 12) {
        return `12:00 PM`;
    } else {
        return `${hour - 12}:00 PM`;
    }
};
export const getSearchedData = (arr, keyword, keys) => {
    if (keyword.length) {
        arr = arr.filter((obj) => {
            return keys.some((k) => {
                const value = obj[k];
                const isString = typeof value === "string";
                const isNumber = !isNaN(value);
                const isDate = !isNaN(Date.parse(value)); // Check if the value is a valid date

                if (isDate) {
                    const dateObject = new Date(value);
                    const formattedDate = `${dateObject.getMonth() + 1}/${dateObject.getDate()}/${dateObject.getFullYear()}`;
                    return formattedDate.includes(keyword); // Check if the formatted date includes the keyword
                } else if (Array.isArray(value)) {
                    return value.some((element) => {
                        if (typeof element === "object") {
                            // If the element is an object, search its properties
                            return Object.values(element).some((nestedValue) => {
                                const nestedIsString = typeof nestedValue === "string";
                                const nestedIsNumber = !isNaN(nestedValue);
                                return (
                                    (nestedIsString && nestedValue.toLowerCase().includes(keyword.toLowerCase())) ||
                                    (nestedIsNumber && nestedValue === Number(keyword))
                                );
                            });
                        } else {
                            // If the element is not an object, perform regular search
                            const nestedIsString = typeof element === "string";
                            const nestedIsNumber = !isNaN(element);
                            return (nestedIsString && element.toLowerCase().includes(keyword.toLowerCase())) || (nestedIsNumber && element === Number(keyword));
                        }
                    });
                } else {
                    // If the value is not an array or date, perform regular search
                    return (isString && value.toLowerCase().includes(keyword.toLowerCase())) || (isNumber && value === Number(keyword));
                }
            });
        });
    }
    return arr;
};
// export const getSearchedData = (arr, keyword, keys) => {
//     if (keyword.length) {
//         arr = arr.filter((obj) => {
//             return keys.some((k) => {
//                 const value = obj[k];
//                 const isString = typeof value === "string";
//                 const isNumber = !isNaN(value);
//                 console.log(value, "vcal>>", keyword);
//                 return (
//                     (isString && value.toLowerCase().includes(keyword.toLowerCase())) || (isNumber && value === Number(keyword))
//                 );
//             });
//         });
//     }
//     return arr;
// };
export function isFileObject(obj) {
    return obj instanceof File;
}
