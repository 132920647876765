import React, { useEffect, useState } from "react";
import Index from "../../layout";
import StudentIcon from "../../../../assets/icons/student.png";
import TeacherEvents from "./TeacherEvents";
import OverdueAttandence from "./OverdueAttendance";
import DropDownComponent from "../../../../components/Fields/CustomDropdown";
import { useDispatch, useSelector } from "react-redux";
import pdfIcon from "../../../../assets/icons/pdf.png";
import CalendarIcon from "../../../../assets/icons/calender.png";
import ProjectRevenue from "../../../../assets/icons/project-revenue.png";

import { getTeacherClassTypesAction, getTeacherEvents, getTeacherSchoolsAction } from "../../../../redux/actions/TeacherPortal/teacherAction";
import CardHeader from "../../../../pages/CardHeader";
import TeacherIcon from "../../../../assets/icons/teacher.png";

export default function Home() {
    // const { TeacherDataInfo, lessonEventData, ClassSchedule } = TeacherData();
    const dispatch = useDispatch();
    const [schools, setSchools] = useState(null);
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        dispatch(getTeacherSchoolsAction());
    }, [dispatch]);
    useEffect(() => {
        if (schools) {
            dispatch(getTeacherClassTypesAction(schools));
        }
    }, [schools, dispatch]);
    useEffect(() => {
        dispatch(getTeacherEvents(date));
    }, [dispatch, date]);

    const teacherDashboard = useSelector((state) => state.teacher?.dashboard);

    const schoolDropdown = useSelector((state) => state?.teacher?.getSchools);

    const classTypes = useSelector((state) => state?.teacher?.getClassTypes);

    return (
        <Index>
            <div className="p-4">
                <CardHeader icon={TeacherIcon} heading="Teacher Dashboard" />
                <div className="grid mb-3">
                    <div className="md:col-3">
                        <div className="bg-whitesmoke border-round-lg p-3 h-100 flex flex-column justify-content-center align-items-center">
                            <img src={StudentIcon} alt="" className="mb-2" width={40} />
                            <h4 className="open-sans font-bold text-main mb-2">{teacherDashboard?.active_students}</h4>
                            <p className="open-sans text-center text-lg text-black-alpha-90">Active Students</p>
                        </div>
                    </div>
                    <div className="md:col-3">
                        <div className="bg-whitesmoke border-round-lg p-3 h-100 flex flex-column justify-content-center align-items-center">
                            <img src={CalendarIcon} alt="" className="mb-2" width={40} />
                            <h4 className="open-sans font-bold text-main mb-2">{teacherDashboard?.event_lessons_left_this_week}</h4>
                            <p className="open-sans text-center text-lg text-black-alpha-90">Events Lessons left this Week</p>
                        </div>
                    </div>
                    <div className="md:col-3">
                        <div className="bg-whitesmoke border-round-lg p-3 h-100 flex flex-column justify-content-center align-items-center">
                            <img src={ProjectRevenue} alt="" className="mb-2" width={40} />
                            <h4 className="open-sans font-bold text-main mb-2">{teacherDashboard?.monthly_revenue}</h4>
                            <p className="open-sans text-center text-lg text-black-alpha-90">Projected Monthly Revenue</p>
                        </div>
                    </div>
                </div>
                <TeacherEvents teacherDashboard={teacherDashboard} date={date} setDate={setDate} />
                {teacherDashboard?.show_overdue ? <OverdueAttandence /> : <></>}
                <div className="grid mb-3">
                    <div className="md:col-12 px-0 m-0 p-0 border-1 border-round-lg border-200">
                        <h3 className="open-sans p-3 text-main font-semibold m-0">View Class Schedule</h3>
                        <div className="grid px-3">
                            <div className="md:col-5">
                                <p className="text-lg open-sans font-semibold mb-2">Select School</p>
                                <div className="flex justify-content-between">
                                    <DropDownComponent
                                        inputClass="border-round-lg "
                                        extraClassName="w-10"
                                        options={schoolDropdown?.map((item, index) => {
                                            return { label: item?.name, value: item?.id };
                                        })}
                                        value={schools}
                                        optionLabel="label"
                                        onChange={(e) => setSchools(e.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        {classTypes?.attachments?.length > 0 && (
                            <div className="border-1 border-round-lg shadow-sm border-200 mb-3 overflow-hidden open-sans">
                                <div className="bg-dark text-white p-3 font-bold">{classTypes?.schedule_title}</div>
                                <div className="shadow-sm flex">
                                    {classTypes?.attachments?.map((attachment, index) => {
                                        return (
                                            <div className="col-3 p-3" key={index}>
                                                <a href={attachment?.file} target="_blank" rel="noreferrer">
                                                    <img src={pdfIcon} alt="PDF FILE ICON" style={{ width: "22px" }}></img>
                                                    <span className="text-dark">View File</span>
                                                </a>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {/* <div className="grid">
          <div className="md:col-12 px-0 border-1 border-round-lg border-300 p-3">
            <h3 className="open-sans font-semibold text-xl p-3 m-0">
              View Class Schedule
            </h3>
            
          </div>
        </div> */}
            </div>
        </Index>
    );
}
