import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import EmailComponent from "../../../../../pages/Email/EmailComponent";
import { useDispatch, useSelector } from "react-redux";
import { getTeacherProfileAction } from "../../../../../redux/actions/TeacherPortal/teacherAction";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getTeacherEmailViewAction } from "../../../../../redux/actions/TeacherPortal/emailAction";

const ResendMessaging = () => {
    const location = useLocation();
    const { id } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getTeacherProfileAction);
        dispatch(getTeacherEmailViewAction(id));
    }, [id]);

    const teacherDetail = useSelector((state) => state?.login?.getTeacherProfile);
    const emailView = useSelector((state) => state?.teacherEmail?.emailView);

    const fromOption = [
        {
            name: `${teacherDetail?.name}<${teacherDetail?.email}>`,
            item: `${teacherDetail?.name}<${teacherDetail?.email}>`,
            email: teacherDetail?.email,
        },
    ];

    const [selectedMessage, setSelectedMessage] = useState("");

    const [emailData, setEmailData] = useState({
        from: {},
        to: [],
        subject: "",
        graphical_mode: true,
        cc_me: false,
        send_at: "immediately",
        scheduled_date: "",
        scheduled_time: "",
        extra_recipients: [],
    });

    useEffect(() => {
        if (id && emailView) {
            setEmailData({
                from: {
                    name: `${teacherDetail?.name}<${teacherDetail?.email}>`,
                    item: `${teacherDetail?.name}<${teacherDetail?.email}>`,
                    email: teacherDetail?.email,
                },
                to:
                    emailView?.to?.map((item) => {
                        return { label: `${item?.name}<${item?.email}>`, email: item?.email };
                    }) || [],
                subject: emailView?.subject,
                graphical_mode: emailView?.graphical_mode === 1 ? true : false,
                cc_me: emailView?.cc_me === 1 ? true : false,
                send_at: "immediately",
                scheduled_date: "",
                scheduled_time: "",
                extra_recipients: [],
            });
            setSelectedMessage(emailView?.message);
        }
    }, [id, emailView]);

    return (
        <EmailComponent
            emailData={emailData}
            setEmailData={setEmailData}
            fromOption={fromOption}
            selectedMessage={selectedMessage}
            id={id}
        />
    );
};

export default ResendMessaging;
