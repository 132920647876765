import React, { useState, useEffect, useCallback } from "react";
import ButtonComponent from "../../../../components/Fields/Button/CustomButton";
import ChevronLeft from "../../../../assets/icons/chevronleft.png";
import Student from "../../../../assets/icons/student.png";
import FormImage from "../../../../components/Fields/FormImage";
import RadioButtonComponent from "../../../../components/Fields/Radio";
import InputComponent from "../../../../components/Fields/InputField";
import DropDownComponent from "../../../../components/Fields/CustomDropdown";
import CustomCalendar from "../../../../components/Fields/CustomCalendar";
import InputGroup from "../../../../components/Fields/InputGroup";
import NumberComponent from "../../../../components/Fields/NumberField";
import CustomFilesInput from "../../../../components/Fields/CustomFilesInput";
import CheckboxComponent from "../../../../components/Fields/Checkbox";
import Index from "../../layout";
import { Days, DropOptions, GradeOptions, InstrumentPurchaseType, genderOptions, skillLevel } from "../../../../utils/constants";
import { adjustTime, isNotNull } from "../../../../utils/commonFunctions";
import {
    AddStudentDeatilAction,
    getLessonsTypeAction,
    getOnlineLessonInstrumentsAction,
    getSchoolsAction,
    viewSchoolGradeClassType,
    viewSchoolInstrumentAction,
    viewSchoolPrograms,
} from "../../../../redux/actions/ParentPortal/studentAction";
import { useSelector, useDispatch } from "react-redux";
import Constants from "../../../../services/constant";
import { api } from "../../../../services/api";
import Eyeblack from "../../../../assets/icons/blackEye.png";
import DeleteIcon from "../../../../assets/icons/delete.png";
import StudentWaitingList from "./StudentWaitingList";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MultiSelectComponent from "../../../../components/Fields/CustomMultiSelect";
import types from "../../../../redux/types";

export default function AddStudent() {
    const dispatch = useDispatch();
    const history = useHistory();

    const [showAdditionalDetail, setShowAdditionalDetail] = useState(true);

    const [studentDetail, setStudentDetail] = useState({
        student_type: "child",
        first_name: "",
        last_name: "",
        student_since: "",
        skill_level: "",
        gender: "",
        face_time_id: "",
        skype_username: "",
        dob: "",
        aftercare_picked_up_time: "",
        lesson_type: "school_lessons",
        school_id: [],
        grade: null,
        instrument_id: null,
        class_type: "",
        instrument_on_purchase_or_rent: "",
        prefer_drop_picked_up_for_class: "",
        status: "active",
        order_book: true,
        lessonProgram: [],
        monthly_installment: "",
        one_time_payment: "",
        online_grade: "",
        instrument_purchase_plan_id: "",
        day: [],
        registration_date: "",
        time: "",
        picture: "",
        age: null,
        program_id: "",
        is_waiting: false,
        instruments: [
            {
                instrument_id: "",
                type: null,
                amount: null,
            },
        ],
    });
    useEffect(() => {
        dispatch(getSchoolsAction());
        dispatch(getLessonsTypeAction("online_lessons"));
    }, [dispatch]);
    const [selectedProgramme, setSelectedProgramme] = useState(false);

    useEffect(() => {
        if (studentDetail?.school_id?.id && studentDetail?.grade) {
            dispatch(viewSchoolGradeClassType(studentDetail?.school_id?.id, studentDetail?.grade));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentDetail?.school_id?.id, studentDetail?.grade]);

    useEffect(() => {
        if (studentDetail?.school_id?.id && studentDetail?.grade && studentDetail?.class_type) {
            dispatch(viewSchoolPrograms(studentDetail?.school_id?.id, studentDetail?.grade, studentDetail?.class_type));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentDetail?.school_id?.id, studentDetail?.grade, studentDetail?.class_type]);

    const [err, setErr] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "picture") {
            setStudentDetail((prev) => ({ ...prev, picture: e.target.files[0] }));
            setErr();
        } else {
            setStudentDetail({ ...studentDetail, [name]: value });
        }
        setErr({ ...err, [name]: "" });
    };
    const handleDeleteImage = (e) => {
        e.preventDefault();
        setStudentDetail((prev) => ({
            ...studentDetail,
            picture: null,
        }));
    };
    const handleChange1 = (e) => {
        const { name } = e.target;
        if (name === "lesson_type" && e.value === "school_lessons") {
            setStudentDetail({
                ...studentDetail,
                lessonProgram: [],
                instrument_on_purchase_or_rent: "",
                instrument_id: null,
                day: null,
                time: null,
                lesson_type: e.value,
            });
            setInstruments([]);
            dispatch({
                type: types.SCHOOL_INSTRUMENT,
                payload: {},
            });
        } else if (name === "lesson_type" && e.value === "online_lessons") {
            setStudentDetail({
                ...studentDetail,
                school_id: [],
                grade: "",
                instruments: [],
                class_type: "",
                program_id: "",
                lesson_type: e.value,
            });
            setInstruments([]);
            dispatch({
                type: types.SCHOOL_INSTRUMENT,
                payload: {},
            });
        } else if (name === "school_id") {
            setStudentDetail({
                ...studentDetail,
                grade: "",
                instrument_id: null,
                school_id: e.value,
                class_type: "",
                instruments: [],
                program_id: "",
                aftercare_picked_up_time: null,
                prefer_drop_picked_up_for_class: "",
            });
            setInstruments({});
        } else if (name === "grade") {
            setStudentDetail({
                ...studentDetail,
                grade: e.value,
                aftercare_picked_up_time: null,
                prefer_drop_picked_up_for_class: "",
                class_type: "",
                program_id: "",
            });
            setInstruments({});
        } else if (name === "time" || name === "aftercare_picked_up_time") {
            let time = adjustTime(e);
            setStudentDetail((prev) => ({
                ...prev,
                [name]: time,
            }));
        } else {
            setStudentDetail((prev) => ({ ...prev, [name]: e.value }));
        }

        setErr({ ...err, [name]: "" });
    };
    const { getSchools } = useSelector((state) => state?.parentStudent);

    const { classType, schoolPrograms, onlineInstruments } = useSelector((state) => state?.parentStudent);
    const { instrumentDetail } = useSelector((state) => state.parentStudent);

    const [ProgramInstruments, setInstruments] = useState();

    const ViewInstrument = async (id) => {
        const res = await api("get", Constants.END_POINT.SCHOOL_PROGRAM_INSTRUMENTS + id);
        if (res.success) {
            setInstruments(res.data);
        }
    };

    useEffect(() => {
        if (studentDetail?.program_id) {
            ViewInstrument(studentDetail?.program_id);
        }
        return () => {
            setInstruments([]);
        };
    }, [studentDetail?.program_id]);

    useEffect(() => {
        if (ProgramInstruments) {
            setStudentDetail({
                ...studentDetail,
                instruments: {
                    ...ProgramInstruments,
                    instrument_id: ProgramInstruments?.instruments?.[0]?.id,
                    type: ProgramInstruments?.instruments?.[0]?.name.includes("Jr. Jam") ? "nothing_needed" : "",
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ProgramInstruments]);
    useEffect(() => {
        let instr = studentDetail?.instruments?.instruments?.filter((item, i) => item.id === studentDetail?.instruments?.instrument_id);
        setStudentDetail((prevState) => ({
            ...prevState,
            instruments: {
                ...prevState.instruments,
                amount:
                    studentDetail?.instruments?.type === "rent"
                        ? instr[0]?.monthly_installment
                        : studentDetail?.instruments?.type === "purchase"
                        ? instr?.[0]?.purchase_amount
                        : 0,
            },
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentDetail?.instruments?.type, studentDetail?.instruments?.instrument_id]);

    let { getFilterLessonList } = useSelector((state) => state.parentStudent);

    getFilterLessonList = getFilterLessonList?.map((lessons) => ({
        id: lessons.id,
        name: lessons.name,
    }));

    useEffect(() => {
        if (studentDetail?.lessonProgram?.id || studentDetail?.instrument_purchase_plan_id) {
            dispatch(getOnlineLessonInstrumentsAction(studentDetail?.lessonProgram?.id));
            dispatch(viewSchoolInstrumentAction(studentDetail?.instrument_purchase_plan_id || studentDetail?.lessonProgram?.id));
        }
    }, [studentDetail?.lessonProgram?.id, studentDetail?.instrument_purchase_plan_id, dispatch]);

    useEffect(() => {
        if (studentDetail?.instrument_id) {
            dispatch(viewSchoolInstrumentAction(studentDetail?.instrument_id));
        }
    }, [studentDetail?.instrument_id, dispatch]);

    useEffect(() => {
        if (studentDetail?.instrument_on_purchase_or_rent === "rent") {
            setStudentDetail({
                ...studentDetail,
                monthly_installment: instrumentDetail?.monthly_installment,
                one_time_payment: 0,
            });
        } else if (studentDetail?.instrument_on_purchase_or_rent === "purchase") {
            setStudentDetail({
                ...studentDetail,
                one_time_payment: instrumentDetail?.purchase_amount,
                monthly_installment: 0,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentDetail.instrument_on_purchase_or_rent]);

    const filteredInstrumentsPurchaseType = [];
    const InstrumentsPurchaseType = [
        {
            name: "Rent",
            value: "rent",
        },
        { name: "Purchase", value: "purchase" },
        { name: "Nothing Needed", value: "nothing_needed" },
    ];

    for (const instrumentPurchaseType of InstrumentsPurchaseType) {
        if (ProgramInstruments?.mode_of_purchasing?.includes(instrumentPurchaseType.value)) {
            filteredInstrumentsPurchaseType.push(instrumentPurchaseType);
        }
    }
    const [selectedFiles, setSelectedFiles] = useState([]);
    const handlefileChange = (files) => {
        files.forEach((file) => {
            if (selectedFiles.findIndex((f) => f.name === file.name) === -1) {
                selectedFiles.push(file);
            }
        });
        setSelectedFiles([...selectedFiles]);
    };
    const handleFiles = (e) => {
        const chossenFile = e.target.files;
        handlefileChange(Object.values(chossenFile));
    };

    const onDelete = (id) => {
        setSelectedFiles((prevState) => prevState.filter((item, index) => index !== id));
    };
    const handleInstrumentChange = (e) => {
        const { name } = e.target;
        setStudentDetail((prevState) => ({
            ...prevState,
            instruments: {
                ...prevState.instruments,
                [name]: e.value,
            },
        }));
        setErr({ ...err, [name]: "" });
    };

    const validateForm = () => {
        let errors = {};
        if (!studentDetail?.first_name) {
            errors.first_name = "First Name is required";
        }
        if (!studentDetail?.last_name) {
            errors.last_name = "Last name is required";
        }
        if (!studentDetail?.skill_level) {
            errors.skill_level = "Please select skill level";
        }
        if (studentDetail?.lesson_type === "school_lessons" && studentDetail?.student_type === "child" && studentDetail?.school_id.length === 0) {
            errors.school_id = "Please select school";
        }
        if (studentDetail?.lesson_type === "school_lessons" && studentDetail?.student_type === "child" && !studentDetail?.grade) {
            errors.grade = "Please select grade";
        }
        if (studentDetail?.lesson_type === "school_lessons" && studentDetail?.student_type === "child" && !studentDetail?.class_type) {
            errors.class_type = "Please select class type";
        }
        if (
            studentDetail?.lesson_type === "school_lessons" &&
            studentDetail?.student_type === "child" &&
            studentDetail?.class_type &&
            !studentDetail?.program_id
        ) {
            errors.program_id = "Please choose program";
        }
        if (studentDetail?.lesson_type === "online_lessons" && !studentDetail?.instrument_id) {
            errors.instrument_id = "Please select Instrument";
        }
        if (studentDetail?.lesson_type === "school_lessons" && studentDetail?.class_type === "after_school" && !studentDetail?.aftercare_picked_up_time) {
            errors.aftercare_picked_up_time = "Please select Time";
        }
        if (
            studentDetail?.lesson_type === "school_lessons" &&
            studentDetail?.class_type === "after_school" &&
            !studentDetail?.prefer_drop_picked_up_for_class
        ) {
            errors.prefer_drop_picked_up_for_class = "Please select Drop off";
        }

        if (studentDetail?.lesson_type === "online_lessons" && studentDetail?.lessonProgram?.length === 0) {
            errors.lessonProgram = "Please select lesson program";
        }

        if (studentDetail?.lesson_type === "online_lessons" && !studentDetail?.instrument_id) {
            errors.instrument_id = "Please select Instrument";
        }
        if (studentDetail?.lesson_type === "online_lessons" && studentDetail?.day?.length === 0) {
            errors.day = "Please select day";
        }
        if (studentDetail?.lesson_type === "online_lessons" && !studentDetail?.time) {
            errors.time = "Please select time";
        }
        if (!studentDetail?.instruments?.type && studentDetail?.lesson_type === "school_lessons" && studentDetail?.student_type === "child") {
            errors.type = " Purchase Type is required";
        }
        if (studentDetail?.lesson_type === "online_lessons" && !studentDetail?.instrument_on_purchase_or_rent) {
            errors.instrument_on_purchase_or_rent = "Please select purchase type";
        }
        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSave = (e) => {
        e.preventDefault();
        const form = validateForm();
        if (form) {
            dispatch(AddStudentDeatilAction(studentDetail, selectedFiles, history));
        } else {
            const element = document.getElementById("main-content");
            element.scroll({
                top: 100,
                left: 100,
                behavior: "smooth",
            });
        }
    };

    return (
        <Index>
            <div className="p-4">
                <div className="border-1 border-300 border-round-lg p-5">
                    <div className="grid mb-3">
                        <h2 className="text-main md:col-4 text-2xl open-sans flex align-items-center font-semibold">
                            <img src={ChevronLeft} alt="" width={10} onClick={() => history.goBack(-1)} />
                            <img src={Student} alt="" width={30} className="ml-2" />
                            &nbsp; Add Student
                        </h2>
                    </div>
                    <div className="grid">
                        <div className="md:col-12 flex">
                            <FormImage name="picture" image={studentDetail?.picture} onChange={handleChange} handleDeleteImage={handleDeleteImage} />

                            <div className="lg:w-9 mt-1 ml-3">
                                <div className="grid mb-5">
                                    <div className="md:col-12 ">
                                        <p className="font-semibold mb-2">Student Type : &nbsp; Child</p>
                                    </div>
                                    <InputComponent
                                        placeholder="Enter Student Name"
                                        label="Student First Name"
                                        name="first_name"
                                        extraClassName=" md:col-6"
                                        required
                                        value={studentDetail?.first_name}
                                        onChange={handleChange}
                                        errorMessage={err?.first_name}
                                    />
                                    <InputComponent
                                        placeholder="Enter Last Name"
                                        label="Student Last Name"
                                        extraClassName=" md:col-6"
                                        name="last_name"
                                        required
                                        value={studentDetail?.last_name}
                                        onChange={handleChange}
                                        errorMessage={err?.last_name}
                                    />
                                    <DropDownComponent
                                        label="Skill Level"
                                        name="skill_level"
                                        extraClassName="md:col-6"
                                        value={studentDetail?.skill_level}
                                        options={skillLevel}
                                        onChange={handleChange1}
                                        optionLabel="name"
                                        required
                                        errorMessage={err?.skill_level}
                                    />
                                    <div className="md:col-6">
                                        <CustomCalendar
                                            label="Registration Date"
                                            labelclassName="ml-7 px-1 label-top"
                                            className="w-full border-round-lg overflow-hidden  border-1 border-400 relative"
                                            placeholder="Registration Date"
                                            showIcon
                                            iconPos="left"
                                            name="registration_date"
                                            maxDate={new Date()}
                                            value={studentDetail?.registration_date}
                                            onChange={handleChange1}
                                        />
                                    </div>
                                    <CustomCalendar
                                        label="Birthday"
                                        labelclassName="ml-7 px-1 label-top"
                                        className="w-full border-round-lg overflow-hidden  border-1 border-400 relative"
                                        placeholder="Birthday"
                                        showIcon
                                        size="md:col-6"
                                        iconPos="left"
                                        name="dob"
                                        maxDate={new Date()}
                                        value={studentDetail?.dob}
                                        onChange={handleChange1}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className="" />
                    <>
                        <p className="font-semibold mb-3">Select Location</p>
                        <div className="mb-4 flex">
                            <RadioButtonComponent
                                label="School"
                                name="lesson_type"
                                value="school_lessons"
                                checked={studentDetail?.lesson_type === "school_lessons"}
                                onChange={handleChange1}
                            />
                            <RadioButtonComponent
                                label="Online"
                                extraClassName="ml-3"
                                name="lesson_type"
                                value="online_lessons"
                                checked={studentDetail?.lesson_type === "online_lessons"}
                                onChange={handleChange1}
                            />
                        </div>
                    </>
                    {/* school options */}
                    {studentDetail?.lesson_type === "school_lessons" ? (
                        <div className="school">
                            <div className="grid mb-2">
                                <>
                                    <DropDownComponent
                                        label="School Name"
                                        placeholder="Select School"
                                        extraClassName="md:col-6"
                                        name="school_id"
                                        options={getSchools}
                                        optionLabel="name"
                                        value={studentDetail?.school_id}
                                        onChange={handleChange1}
                                        required
                                        errorMessage={err?.school_id}
                                    />
                                    <DropDownComponent
                                        extraClassName="md:col-6"
                                        name="grade"
                                        options={GradeOptions}
                                        optionLabel="label"
                                        value={studentDetail?.grade}
                                        onChange={handleChange1}
                                        placeholder="Select Grade"
                                        label="Select Grade"
                                        emptyMessage="Please Select School First "
                                        required
                                        errorMessage={err?.grade}
                                    />
                                    <DropDownComponent
                                        name="class_type"
                                        extraClassName="md:col-6"
                                        placeholder="Select Program Type"
                                        options={classType}
                                        required
                                        value={studentDetail?.class_type}
                                        onChange={handleChange1}
                                        optionLabel="name"
                                        errorMessage={err?.class_type}
                                    />
                                </>
                            </div>

                            {studentDetail?.student_type === "child" && (
                                <div className="grid">
                                    {schoolPrograms?.length > 0 &&
                                        schoolPrograms?.map((program, i) =>
                                            studentDetail?.class_type === "during_school" ? (
                                                <div className="col-12 md:col-4">
                                                    <div
                                                        className={`grid h-full m-0 border-round-lg overflow-hidden bg-whitesmoke ${
                                                            studentDetail?.program_id === program.id ? "programme-selected" : ""
                                                        }`}
                                                        onClick={() => {
                                                            setStudentDetail({
                                                                ...studentDetail,
                                                                program_id: program.id,
                                                                is_waiting: program?.is_full ? true : false,
                                                            });
                                                            setErr({ ...err, program_id: "" });
                                                        }}
                                                    >
                                                        <div className="col-4 p-0 bg-main flex justify-content-center align-items-center">
                                                            <small className="text-white">Flexible time</small>
                                                        </div>
                                                        <div className="col-8 p-3">
                                                            <h6 className="text-sm font-semibold text-900 mb-0">{program.school_lesson.name}</h6>
                                                            <small>
                                                                <span className="text-main">Instrument:</span> {program.instruments}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : studentDetail?.class_type === "after_school" || studentDetail?.class_type === "semi_private" ? (
                                                <div className="col-12 md:col-4 ">
                                                    <div
                                                        className={`grid h-full m-0 border-round-lg overflow-hidden bg-whitesmoke ${
                                                            studentDetail?.program_id === program.id ? "programme-selected" : ""
                                                        }`}
                                                        onClick={() => {
                                                            setStudentDetail({
                                                                ...studentDetail,
                                                                program_id: program.id,
                                                                is_waiting: program?.is_full ? true : false,
                                                            });
                                                            setErr({ ...err, program_id: "" });
                                                        }}
                                                    >
                                                        <div className="col-4 p-0 bg-main flex justify-content-center align-items-center">
                                                            <small className="text-white"> {program?.school_program_session?.session_start_time}</small>
                                                        </div>
                                                        <div className="col-8 p-3">
                                                            <h6 className="text-sm font-semibold text-900 mb-0">{program.school_lesson.name}</h6>
                                                            <p className="text-main small">
                                                                {program.is_full ? (
                                                                    <div className="text-danger font-semibold">Seats are full </div>
                                                                ) : (
                                                                    `Open to ${program?.school_lesson?.no_of_seats} Student`
                                                                )}
                                                            </p>

                                                            <small>
                                                                <span className="text-main">Instrument:</span> {program.instruments}
                                                                <br />
                                                                {program.is_full ? (
                                                                    <div className="float-right">
                                                                        <span
                                                                            onClick={() => setSelectedProgramme(true)}
                                                                            className="bg-main justify-content-end border-round-sm cursor-pointer p-1 m-auto"
                                                                        >
                                                                            Join Waitlist
                                                                        </span>
                                                                    </div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        )}
                                </div>
                            )}
                            {err?.program_id && <div className="text-sm text-danger open-sans">{err?.program_id}</div>}

                            {(studentDetail?.class_type === "after_school" || studentDetail?.class_type === "semi_private") && (
                                <>
                                    <p className="font-semibold text-dark mb-3 mt-2">Dismissal Programs</p>
                                    <label className="text-base text-500 open-sans bg-white-color form-label">
                                        What time does your child get picked up from aftercare?
                                    </label>
                                    <CustomCalendar
                                        extraClassName="md:col-3"
                                        name="aftercare_picked_up_time"
                                        value={studentDetail.aftercare_picked_up_time}
                                        onChange={handleChange1}
                                        icon="pi pi-clock"
                                        showIcon
                                        timeOnly
                                        stepMinute={5}
                                        hourFormat="12"
                                        errorMessage={err?.aftercare_picked_up_time}
                                    />

                                    <DropDownComponent
                                        label="Would you prefer to drop off your child and pick them up for class?"
                                        placeholder=""
                                        extraClassName="mb-4 md:col-10"
                                        inputClass="md:w-3"
                                        labelClassName="relative mb-2 left-0 text-base"
                                        name="prefer_drop_picked_up_for_class"
                                        options={DropOptions}
                                        optionLabel="name"
                                        value={studentDetail?.prefer_drop_picked_up_for_class}
                                        onChange={handleChange}
                                        required
                                        errorMessage={err?.prefer_drop_picked_up_for_class}
                                    />
                                </>
                            )}
                        </div>
                    ) : (
                        <>
                            <div className="online">
                                <div className="grid">
                                    <DropDownComponent
                                        label="Select Lesson Program"
                                        placeholder=""
                                        extraClassName="md:col-6"
                                        options={getFilterLessonList}
                                        optionLabel="name"
                                        name="lessonProgram"
                                        onChange={handleChange1}
                                        value={studentDetail?.lessonProgram}
                                        required
                                        errorMessage={err?.lessonProgram}
                                    />
                                </div>
                                <p className="font-semibold text-dark mb-3 mt-2">Select Preferred Day And Time For Class</p>
                                <div className="grid">
                                    <MultiSelectComponent
                                        label="Select Preferred Day"
                                        placeholder=""
                                        extraClassName="md:col-6"
                                        name="day"
                                        options={Days}
                                        optionLabel="name"
                                        value={studentDetail.day}
                                        onChange={handleChange1}
                                        required
                                        errorMessage={err?.day}
                                    />
                                    <CustomCalendar
                                        label=" Select Time For Class"
                                        extraClassName="md:col-6"
                                        name="time"
                                        value={studentDetail.time}
                                        onChange={handleChange1}
                                        icon="pi pi-clock"
                                        errorMessage={err?.time}
                                        hourFormat="12"
                                        showIcon
                                        stepMinute={5}
                                        required
                                        timeOnly
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    {studentDetail?.lesson_type === "online_lessons" && studentDetail?.student_type === "child" ? (
                        <>
                            <p className="font-semibold text-dark mb-3 mt-2">Instrument Purchase Program</p>
                            <div className="grid mb-2 mt-2">
                                <DropDownComponent
                                    label="Select Instrument"
                                    placeholder=""
                                    extraClassName="md:col-6"
                                    name="instrument_id"
                                    optionLabel="name"
                                    onChange={handleChange1}
                                    options={onlineInstruments?.map((item) => {
                                        return {
                                            name: item.name,
                                            value: item.id,
                                        };
                                    })}
                                    value={studentDetail?.instrument_id}
                                    errorMessage={err?.instrument_id}
                                />
                                <DropDownComponent
                                    label="Purchse Type"
                                    placeholder=""
                                    extraClassName="md:col-6"
                                    name="instrument_on_purchase_or_rent"
                                    options={InstrumentPurchaseType}
                                    optionLabel="name"
                                    value={studentDetail?.instrument_on_purchase_or_rent}
                                    onChange={handleChange1}
                                    required
                                    errorMessage={err?.instrument_on_purchase_or_rent}
                                />
                                {studentDetail?.instrument_on_purchase_or_rent !== "nothing_needed" && (
                                    <InputGroup dollar="dollar" size="md:col-6">
                                        <NumberComponent
                                            label="Amount"
                                            extraClassName="w-full"
                                            labelClassName="z-3"
                                            placeholder="0"
                                            required
                                            name="Price"
                                            value={
                                                studentDetail?.instrument_on_purchase_or_rent === "rent"
                                                    ? instrumentDetail?.monthly_installment
                                                    : studentDetail?.instrument_on_purchase_or_rent === "purchase"
                                                    ? instrumentDetail?.purchase_amount
                                                    : null
                                            }
                                            dollar="dollar"
                                            maxLength={4}
                                        />
                                    </InputGroup>
                                )}
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    {ProgramInstruments?.instruments?.length > 0 && (
                        <>
                            <div className="grid align-items-start mt-2">
                                <DropDownComponent
                                    label="Select Instrument"
                                    labelClassName="absolute text-xs z-3 text-500 open-sans bg-white form-label m-0"
                                    extraClassName="md:col-4 relative z-3"
                                    onChange={handleInstrumentChange}
                                    name="instrument_id"
                                    value={studentDetail?.instruments?.instrument_id}
                                    options={ProgramInstruments?.instruments?.map((item) => {
                                        return { name: item.name, value: item.id };
                                    })}
                                    optionLabel="name"
                                    errorMessage={err?.instrument_id}
                                />
                                <div className="md:col-4 relative z-3">
                                    <DropDownComponent
                                        name="type"
                                        label="Purchase type"
                                        labelClassName="absolute text-xs z-3 text-500 open-sans bg-white form-label m-0"
                                        optionLabel="name"
                                        value={studentDetail?.instruments?.type}
                                        onChange={handleInstrumentChange}
                                        options={filteredInstrumentsPurchaseType}
                                        errorMessage={err?.type}
                                    />
                                </div>

                                <InputGroup dollar="dollar" size="md:col-4">
                                    <NumberComponent
                                        label="Amount"
                                        placeholder="0"
                                        name="amount"
                                        value={studentDetail?.instruments?.amount}
                                        dollar="dollar"
                                        disabled
                                    />
                                </InputGroup>
                            </div>
                        </>
                    )}

                    <div className="grid mb-2 mt-2"></div>

                    <div className="grid align-items-start mt-2"></div>

                    <div className=" grid flex mb-3 mt-3 ">
                        <div className="radiobutton md:col-12 flex align-items-center">
                            <p className="font-bold text-900 mr-4">Status</p>

                            {studentDetail?.is_waiting ? "Student is on your waiting list" : "Student is active"}
                        </div>
                    </div>
                    <div className=" md:col-4">
                        <CustomFilesInput
                            label="+ New Student Attachment"
                            name="file"
                            accept=".pdf"
                            multiple
                            inputClass="ripple border-none bg-main font-semibold border-round-lg mb-2"
                            extraClassName="bg-light-green ml-2 mb-2 p-2 "
                            onChange={handleFiles}
                        />
                        {/* <ButtonComponent label="+ New Student Attachment"></ButtonComponent> */}
                    </div>
                    <div className=" mt-3 md:col-12">
                        {selectedFiles?.length ? (
                            <div className="p-2 mb-3 border-round-lg overflow-hidden">
                                <div className="grid bg-main align-items-center p-2 border-bottom-1 border-300 ">
                                    <div className="md:col-3 text-sm open-sans font-semibold">File Name</div>

                                    <div className="md:col-3 text-sm open-sans font-semibold">File size</div>
                                    <div className="md:col-3 text-sm open-sans font-semibold">Actions</div>
                                </div>
                                {Object.values(selectedFiles).map((file, i) => (
                                    <div className="grid bg-whitesmoke align-items-center p-2">
                                        <div className="md:col-3 text-main-color  ">
                                            <img src={File} alt="" width="15" />
                                            {file?.name || file?.file_name}
                                        </div>

                                        <div className="md:col-3 text-main-color ">{parseInt(file?.size / 1024)}kb</div>

                                        <div className="md:col-3 text-main-color ">
                                            <img
                                                src={Eyeblack}
                                                alt=""
                                                width="19px"
                                                height="19px"
                                                className="product-image m-1"
                                                onClick={() => window.open(URL.createObjectURL(file))}
                                            />
                                            <img className="pointer ml-2" onClick={() => onDelete(i)} src={DeleteIcon} alt="" width="15" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </div>

                    {studentDetail?.lesson_type === "school_lessons" && (
                        <>
                            <p className="font-bold text-900 mb-3">$30.00 Registration Fee (Which also covers lesson materials needed)</p>
                        </>
                    )}
                    <StudentWaitingList
                        selectedProgramme={selectedProgramme}
                        setSelectedProgramme={setSelectedProgramme}
                        setStudentDetail={setStudentDetail}
                        schoolPrograms={schoolPrograms}
                    />

                    <ButtonComponent label="Save" onClick={handleSave} />
                </div>
            </div>
        </Index>
    );
}
