import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useState } from "react";
import { allValidations } from "../utils/formValidations";
import Form2 from "./Form2";
import Form3 from "./Form3";
import { addAnotherSchoolFormAction, replaceKeys, updateStudentForm } from "../redux/actions/newEnrollmentAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Constants from "../services/constant";
import { api } from "../services/api";
import { InstrumentsPurchaseType, emptyState, emptyState1, onlineEmptyState, onlineEmptyState1 } from "./constants1";
import RemoveStudentDialog from "../pages/Enroll/onlinelesson/RemoveStudentDialog";
import ButtonComponent from "../components/Fields/Button/CustomButton";
import CustomButton from "../components/Fields/Button/CustomButton";
import SelectNewSchoolEnroll from "./SelectNewSchoolEnroll";
import { showFormErrors } from "../utils/commonFunctions";
import {
    ViewOnlineSchoolDetail,
    ViewOnlineSchoolGrades,
    getOnlineLessonProgramAction,
    getSchoolAction,
    getSchoolGradeInstrumentAction,
    getSchoolGradeInstrumentProgramsAction,
} from "../redux/actions/schoolAction";
import { getInstrumentsAction } from "../redux/actions/studentAction";

export default function New2({ initialState, index, activeIndex, setActiveIndex, isLast, length, isRemove }) {
    const dispatch = useDispatch();
    const [data, setData] = useState({});

    useEffect(() => {
        setData(initialState);
    }, [initialState]);

    const [admissionForm, setAdmissionForm] = useState([]);
    const [removeStudent, setRemoveStudent] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const [programType, setProgramType] = useState();
    const [ProgramList, setProgramList] = useState([]);
    const [instrument, setInstrument] = useState();
    const [enrollInstrument, setEnrollInstrument] = useState(null);
    const [validationForm, setValidateForm] = useState(true);
    const [addSchoolType, setAddSchoolType] = useState(null);
    const { getOnlineLessons, getOnlineGrades, viewOnlineLesson } = useSelector((state) => state?.school);

    console.log("admissionForm=>", admissionForm);

    const history = useHistory();
    useEffect(() => {
        if (data?.enroll_type) {
            setAddSchoolType(data?.enroll_type);
        }
    }, [data?.enroll_type]);
    useEffect(() => {
        dispatch(getOnlineLessonProgramAction());
        dispatch(getInstrumentsAction());
    }, [dispatch]);

    const handleClick = (type) => {
        setAddSchoolType(type);
        const keys = emptyState1;
        const keys1 = onlineEmptyState1;
        let enrollKeys = {};

        if (type === "school_lessons") {
            enrollKeys = keys;
        } else if (type === "online_lessons") {
            enrollKeys = keys1;
        }
        dispatch(replaceKeys(index, enrollKeys));
        dispatch(updateStudentForm(index, { enroll_type: type }));
    };

    useEffect(() => {
        if (data?.grade1?.name) {
            setData((prev) => ({ ...prev, ["grade"]: data?.grade1?.name }));
        }
    }, [data?.grade1?.name]);

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        if (name === "school_id") {
            setData((prev) => ({ ...prev, [name]: value, grade1: "", enrollInstrument: "", formErrors }));
        } else if (name === "grade1") {
            setData((prev) => ({ ...prev, [name]: value, enrollInstrument: "", formErrors }));
        } else {
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };
    const error = (validateForm) => {
        setValidateForm(validateForm);
    };

    useEffect(() => {
        if (data?.school_id) {
            ViewSchoolAdmissionForm(data?.school_id);
        }
    }, [data?.school_id]);

    const ViewSchoolAdmissionForm = async (id) => {
        const res = await api("get", Constants.END_POINT.VIEW_SCHOOL_ADMISSION_FORM + id);
        if (res.success) {
            setAdmissionForm(res.data);
        }
    };
    const handleExtraFields = (e, item) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            student_extra_fields: { ...prev?.student_extra_fields, [name]: { value, ...item } },
        }));
    };
    const ViewSchoolPrograms = async (id) => {
        setIsLoading(true);
        const res = await api("post", Constants.END_POINT.SCHOOL_PROGRAMS, {
            school_id: id,
            grade: data?.grade1?.name,
        });
        if (res.success) {
            setProgramType(res.data);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        if (data?.grade1?.name) {
            ViewSchoolPrograms(data?.school_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.grade1?.name, data?.school_id]);

    const ViewSchoolTypeProgramsList = async () => {
        if (data?.school_id && data?.instrument && data?.grade1?.name) {
            setIsLoading(true);
            const res = await api("post", Constants.END_POINT.SCHOOL_CLASSES, {
                school_id: data?.school_id,
                instrument_id: data?.instrument || data.enrollInstrument,
                grade: data?.grade1?.name,
            });
            if (res.success) {
                console.log("qwertyu==>", data?.school_id, data?.instrument, data?.grade1?.name);
                setProgramList(res.data);
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        ViewSchoolTypeProgramsList();
    }, [data?.school_id, data?.instrument, data?.grade1?.name]);

    useEffect(async () => {
        // let _programList = [];
        // if (data?.school_id) {
        //     _programList = await ViewSchoolTypeProgramsList(data?.school_id);
        // }

        if (data.program_id && data?.enroll_type === "school_lessons") {
            const classType = ProgramList?.find((item) => item.id === data?.program_id);

            // Create a new copy of data to avoid directly modifying state

            // Handle specific logic based on class_type
            if (classType?.school_lesson?.class_type === "during_school") {
                // Remove fields and clear their errors
                delete data["aftercare_picked_up_time"];
                delete data["prefer_drop_picked_up_for_class"];
                if (data?.formErrors?.["aftercare_picked_up_time"]) {
                    data.formErrors["aftercare_picked_up_time"] = "";
                }

                if (data?.formErrors?.["prefer_drop_picked_up_for_class"]) {
                    data.formErrors["prefer_drop_picked_up_for_class"] = "";
                }
            } else if (data?.prefer_drop_picked_up_for_class === "no") {
                delete data["aftercare_picked_up_time"];
                if (data?.formErrors?.["aftercare_picked_up_time"]) {
                    data.formErrors["aftercare_picked_up_time"] = "";
                }
            } else if (data?.prefer_drop_picked_up_for_class === "yes") {
                data.formErrors["aftercare_picked_up_time"] = "Pickup Time is required";
            } else {
                data["prefer_drop_picked_up_for_class"] = "";
            }

            // Update state with the new data
            setData(data);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.program_id, data?.prefer_drop_picked_up_for_class, data.instrument, ProgramList]);

    const handlePrograms = (program) => {
        const formErrors = allValidations("program_id", program.id, data);
        setData((prev) => ({ ...prev, program_id: program.id, class_type: program.school_lesson.class_type, formErrors }));
    };

    useEffect(() => {
        if (data.program_id) {
            console.log("ViewInstrument==>", data.program_id);
            ViewInstrument(data.program_id);
        }
    }, [data.program_id]);

    const ViewInstrument = async (id) => {
        setIsLoading(true);
        const res = await api("get", Constants.END_POINT.PROGRAM_INSTRUMENTS + id);
        if (res.success) {
            setInstrument(res.data);
        }
        setIsLoading(false);
    };

    const selectJrJamFun = (classType) => {
        if (classType.includes("Jr. Jam")) {
            let _instrument = [...instrument.instruments];
            _instrument[0].selected = "nothing_needed";
            setInstrument((prev) => ({ ...prev, instruments: _instrument }));
            // setAdmissionFields({ ...data, instruments: instrument });
        }
    };

    useEffect(() => {
        dispatch(getInstrumentsAction());
    }, []);

    useEffect(() => {
        if (data?.class_type) {
            selectJrJamFun(data?.class_type);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.class_type]);
    const filteredInstrumentsPurchaseType = [];

    for (const instrumentPurchaseType of InstrumentsPurchaseType) {
        if (instrument?.mode_of_purchasing?.includes(instrumentPurchaseType.value)) {
            filteredInstrumentsPurchaseType.push(instrumentPurchaseType);
        }
    }

    useEffect(() => {
        if (data?.program_id && data.enroll_type === "online_lessons") {
            dispatch(ViewOnlineSchoolGrades(data?.program_id));
            dispatch(ViewOnlineSchoolDetail(data?.program_id));
        }
    }, [data?.program_id]);

    useEffect(() => {
        if (instrument) {
            setData({ ...data, instruments: instrument });
        }
    }, [instrument]);

    useEffect(() => {
        if (data?.enroll_type === "school_lessons") {
            let instr = instrument?.instruments?.filter((item, i) => item.id === data?.instrument);
            setData((prevState) => ({
                ...prevState,
                amount: data?.type === "rent" ? instr?.[0]?.monthly_installment : data?.type === "purchase" ? instr?.[0]?.purchase_amount : 0,
            }));
        }
    }, [data?.type, data?.instrument, instrument]);
    useEffect(() => {
        if (data?.program_id && instrument && data.enroll_type === "school_lessons") {
            setData((prev) => ({
                ...prev,
                type: instrument?.instruments?.[0]?.name.includes("Jr. Jam") ? "nothing_needed" : instrument?.mode_of_purchasing?.[0],
                // instrument: instrument?.instruments?.[0]?.id,
                formErrors: {},
            }));
        }
    }, [data?.program_id, instrument]);
    const handleNext = (i) => {
        const isValidCurrentForm = showFormErrors(data, setData);
        if (!validationForm) {
            return;
        }
        let isValidPreviousForms = true;
        for (let j = 0; j < i; j++) {
            isValidPreviousForms = isValidPreviousForms && showFormErrors(data, setData);
        }
        if (isValidCurrentForm && isValidPreviousForms) {
            data.type = data.amount === 0 ? "nothing_needed" : data.type;
            history.push("/parent-information");
            dispatch(updateStudentForm(index, data));
        }
    };

    const handleAnother = (i) => {
        const isValidCurrentForm = showFormErrors(data, setData);
        if (!validationForm) {
            return;
        }
        let isValidPreviousForms = true;
        for (let j = 0; j < i; j++) {
            isValidPreviousForms = isValidPreviousForms && showFormErrors(data, setData);
        }
        if (isValidCurrentForm && isValidPreviousForms) {
            dispatch(updateStudentForm(index, data));
            setActiveIndex(index + 1);
            dispatch(addAnotherSchoolFormAction());
        }
    };

    const [visible, setVisible] = useState("");
    // const [instrument, setInstrument] = useState("");
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        dispatch(getSchoolAction());
    }, [dispatch, data?.school_id]);

    const [inst, setInst] = useState({});

    let { getSchoolGrades, getSchools, getSchoolInstruments, getSchoolInstrumentsPrograms } = useSelector((state) => state?.school);
    let { getAllInstruments } = useSelector((state) => state?.studentInfo);

    useEffect(() => {
        let oneInst = getAllInstruments?.find((item) => item.id === (data.instrument || data.enrollInstrument));
        setInst(oneInst);
    }, [data.instrument, data.enrollInstrument]);

    // getAllInstruments = getAllInstruments?.map((item) => ({ name: item.name, value: item.id, icon: item.icon }));

    useEffect(() => {
        if (data?.enroll_type === "online_lessons" && data.instrument) {
            setData((prev) => ({
                ...prev,
                monthly_installment: data?.type === "rent" ? inst?.monthly_installment : 0,
                one_time_payment: data?.type === "purchase" ? inst?.purchase_amount : 0,
            }));
        }
    }, [data.type, data.instrument, data.enrollInstrument, inst]);

    getSchoolGrades = getSchoolGrades
        ?.filter((item) => item.id === data?.school_id)
        ?.flatMap((item) => item.grade_instrument_id)
        ?.map((item) => ({ name: item.grade, id: item.id }));

    let gradeIntruments = getOnlineGrades;
    gradeIntruments = gradeIntruments
        ?.filter((item) => item.grade === data?.grade_id)
        ?.flatMap((item) => item.grade_instruments)
        ?.map((item) => ({ name: item.name, value: item.id, icon: item.icon }));

    useEffect(() => {
        if (data?.school_id && data?.grade1?.id) {
            dispatch(getSchoolGradeInstrumentAction(data?.school_id, data?.grade1?.id));
            const schoolName = getSchools?.find((item) => item.value === data?.school_id);
            setData((prev) => ({ ...prev, ["school_name"]: schoolName?.name }));
        }
    }, [data?.school_id, dispatch, data?.grade1?.id]);

    useEffect(() => {
        if (data?.school_id && data?.grade1?.id && (data?.instrument || data?.enrollInstrument)) {
            dispatch(getSchoolGradeInstrumentProgramsAction(data?.school_id, data?.grade1?.id, data?.instrument || data?.enrollInstrument));
        }
    }, [data?.school_id, dispatch, data?.grade1?.id, data?.instrument, data?.enrollInstrument]);

    const handleEnroll = () => {
        setVisible(false);
        if (data?.enroll_type === "school_lessons") {
            dispatch(updateStudentForm(index, { ...data, ...emptyState, enrollNow: true }));
        } else if (data?.enroll_type === "online_lessons") {
            dispatch(updateStudentForm(index, { ...data, ...onlineEmptyState, enrollNow: true }));
        }
    };

    const ActionTemplate = (r) => {
        return (
            <div className="flex gap-3">
                <ButtonComponent
                    label="More Info"
                    className="bg-dark border-none primary-btn"
                    onClick={() => (data?.enroll_type === "online_lessons" ? setVisible(data?.program_id) : setVisible(r.id))}
                />
                <ButtonComponent label={"Continue Enrollment"} className="bg-dark border-none primary-btn" onClick={handleEnroll} disable={disable} />
            </div>
        );
    };

    const handleInstrumentClick = (value) => {
        const formErrors = allValidations("enrollInstrument", value, data);
        setData((prev) => ({ ...prev, enrollInstrument: value, instrument: value, formErrors }));
    };

    const InstrumentTemplate = (r) => {
        setEnrollInstrument(r.instrument_id);
        return <>{r.instrument_name}</>;
    };

    // online

    return (
        <div className=" md:p-0">
            <div className="grid justify-content-center  m-0">
                <div className="md:col-9">
                    <Accordion
                        activeIndex={activeIndex === index ? 0 : null}
                        onTabChange={(e) => {
                            setActiveIndex(index);
                        }}
                    >
                        <AccordionTab key={0} header={data.first_name ? data.first_name : "Student Information"} className="text-white border-none">
                            <div>
                                {index ? (
                                    <SelectNewSchoolEnroll
                                        data={data}
                                        handleChange={handleChange}
                                        addSchoolType={addSchoolType}
                                        setAddSchoolType={setAddSchoolType}
                                        handleClick={handleClick}
                                        getSchools={getSchools}
                                        getSchoolGrades={getSchoolGrades}
                                        getSchoolInstruments={getSchoolInstruments}
                                        handleInstrumentClick={handleInstrumentClick}
                                        getSchoolInstrumentsPrograms={getSchoolInstrumentsPrograms}
                                        InstrumentTemplate={InstrumentTemplate}
                                        ActionTemplate={ActionTemplate}
                                        visible={visible}
                                        setVisible={setVisible}
                                        enrollInstrument={enrollInstrument}
                                        handleEnroll={handleEnroll}
                                        index={index}
                                        getOnlineGrades={getOnlineGrades}
                                        getOnlineLessons={getOnlineLessons}
                                        gradeIntruments={gradeIntruments}
                                        getAllInstruments={getAllInstruments}
                                        viewOnlineLesson={viewOnlineLesson}
                                    />
                                ) : null}
                                {(index == 0 && data.enroll_type === "online_lessons") || (data.enrollNow && data.enroll_type === "online_lessons") ? (
                                    <Form2 data={data} handleChange={handleChange} instrument={inst} />
                                ) : (index == 0 && data.school_id) || (data.enrollNow && data.school_id) ? (
                                    <Form3
                                        data={data}
                                        handleChange={handleChange}
                                        programType={programType}
                                        loading={loading}
                                        admissionForm={admissionForm}
                                        handleExtraFields={handleExtraFields}
                                        ProgramList={ProgramList}
                                        handlePrograms={handlePrograms}
                                        instrument={instrument}
                                        filteredInstrumentsPurchaseType={filteredInstrumentsPurchaseType}
                                        setData={setData}
                                    />
                                ) : null}
                            </div>
                        </AccordionTab>
                    </Accordion>{" "}
                    <div className="flex align-items-center justify-content-center mt-3">
                        {length === index + 1 && index < 3 && (
                            <CustomButton className="border-none bg-dark text-white" onClick={() => handleAnother(index)} label="Add Another" />
                        )}
                        {length === index + 1 && (
                            <CustomButton
                                onClick={() => handleNext(index)}
                                className="btn font-semibold p-button p-component border-none bg-dark text-white ml-2"
                            >
                                Next {">>"}
                            </CustomButton>
                        )}
                        {isLast && index > 0 && index < 4 && (
                            <ButtonComponent
                                label="Remove Student"
                                className="btn no-underline bg-main text-white ml-2 border-none border-round-lg open-sans"
                                onClick={() => setRemoveStudent(index)}
                            />
                        )}
                        {removeStudent && (
                            <RemoveStudentDialog
                                removeStudent={removeStudent}
                                setRemoveStudent={setRemoveStudent}
                                index={index}
                                setActiveIndex={setActiveIndex}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
