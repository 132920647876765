import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/logo/logo.png";
import MusicLyric from "../../../assets/icons/music-lyric.png";
import CustomPassword from "../../../components/Fields/CustomPassword";
import {
  changePasswordAction,
  getParentProfileApi,
} from "../../../redux/actions/loginAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonComponent from "../../../components/Fields/Button/CustomButton";
import { clearStateFn } from "../../../utils/localStorage";

export default function ChangePassword() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState({
    password: "",
    new_password: "",
    confirm_password: "",
  });

  const profile = useSelector((state) => state.login.getParentProfile);

  useEffect(() => {
    dispatch(getParentProfileApi());
    if (profile.is_update_password === 1) {
      history.push("parent/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
    setErr({ ...err, [name]: "" })

  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    const form = validateForm();
    if (form) {
      dispatch(changePasswordAction(data, history));
    }
  };
  const [err, setErr] = useState({});

  const validateForm = () => {
    let errors = {};
    if (!data?.password) {
      errors.password = "Password is required";
    }
    if (!data?.new_password) {
      errors.new_password = "New Password is required";
    }
    if (!data.confirm_password) {
      errors.confirm_password = " Confirm Password is required";
    } else if (data.new_password !== data?.confirm_password) {
      errors.confirm_password = "Password and confirm password does not match";
    }

    setErr(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <div className="px-8 shadow-2 flex justify-content-between align-items-center">
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="" width="200" className="mr-2"></img>
          </Link>
        </div>
        <ButtonComponent
          label="Logout"
          onClick={() => {
            clearStateFn();
            history.push("/");
          }}
        />
      </div>
      <div className="banner login-banner" style={{ height: "94vh" }}>
        <div className="grid  m-0 h-full align-items-center">
          <div className="left-banner login lg:col-6 md:col-12 sm:col-12 relative  h-full vertical-center ">
            <div className="relative">
              <div className="spacer sm-hidden md-block"></div>
              <h1 className="heading mb-3 z-2 font-semibold text-white">
                <span className="text-white-border">Music </span>
                <br />
                is life
                <br />
                <span className="text-white-border"> Changing</span>
              </h1>
            </div>
          </div>
          <div className=" flex align-items-center lg:col-6 p-5 md:col-12 p-0 h-100 relative">
            <img
              src={MusicLyric}
              alt=""
              className="absolute opacity-20 w-5"
              style={{ right: "2rem", top: "-2rem", zIndex: "-1" }}
            />
            <div className=" relative flex flex-column align-items-center justify-content-center w-full">
              <div className="shadow-1 p-3 py-6 border-round-lg w-9 relative z-3 bg-white">
                <h3 className="text-2xl mb-3 font-bold">
                  Change<span className="text-main"> Password</span>
                </h3>
                <form action="">
                  <div className="mb-3">
                    <label className="open-sans text-sm mb-2 ">
                      Current Password
                    </label>
                    <div className="border-set p-2 flex  border-round-lg align-items-center">
                      <i className="pi pi-lock text-dark-gray font-bold"></i>
                      <CustomPassword
                        extraClassName="w-full"
                        placeholder="Current Password"
                        className="border-none w-full ml-2"
                        name="password"
                        onChange={handleChange}
                        value={data?.password}
                        toggleMask
                      />
                    </div>
                    <div className="text-danger text-sm open-sans">{err?.password}</div>
                  </div>
                  <div className="mb-3">
                    <label className="open-sans text-sm mb-2 ">
                      New Password
                    </label>
                    <div className="border-set p-2 flex  border-round-lg align-items-center">
                      <i className="pi pi-lock text-dark-gray font-bold"></i>
                      <CustomPassword
                        extraClassName="w-full"
                        placeholder="New Password"
                        className="border-none w-full ml-2"
                        name="new_password"
                        onChange={handleChange}
                        value={data?.new_password}
                        toggleMask
                      />
                    </div>
                    <div className="text-danger text-sm open-sans">{err?.new_password}</div>
                  </div>

                  <div className="mb-3">
                    <label className="open-sans text-sm mb-2 ">
                      Confirm New Password
                    </label>
                    <div className="border-set p-2 flex  border-round-lg align-items-center">
                      <i className="pi pi-lock text-dark-gray font-bold"></i>
                      <CustomPassword
                        extraClassName="w-full"
                        placeholder="Confirm password"
                        className="border-none w-full ml-2"
                        name="confirm_password"
                        onChange={handleChange}
                        value={data?.confirm_password}
                        toggleMask
                      />
                    </div>
                    <div className="text-danger text-sm open-sans">{err?.confirm_password}</div>
                  </div>

                  <button
                    className="bg-dark w-full p-3 border-none text-white font-semibold border-round-lg"
                    onClick={(e) => handleChangePassword(e)}
                  >
                    Change Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
