import { api } from "../../../services/api";
import Constants from "../../../services/constant";
import { loadStateFn } from "../../../utils/localStorage";
import { showToast } from "../../../utils/showToast";
import types from "../../types";
import { hideLoaderAction, showLoaderAction } from "../loadAction";
import moment from "moment";

export const ChangeCalendarDateAction = (date) => async (dispatch) => {
    dispatch(showLoaderAction());
    dispatch({
        type: types.CHANGE_CALENDAR_DATE,
        payload: date,
    });
    dispatch(hideLoaderAction());
};
export const addCalendarFilterAction = (filter) => async (dispatch) => {
    dispatch(showLoaderAction());
    dispatch({
        type: types.GET_CALENDAR_FILTER,
        payload: filter,
    });
    dispatch(hideLoaderAction());
};
export const ViewAgendaAction = (view) => async (dispatch) => {
    dispatch(showLoaderAction());
    dispatch({
        type: types.GET_CALENDAR_AGENDA,
        payload: view,
    });
    dispatch(hideLoaderAction());
};

export const editEventAction = (events, checkedDays, id, history, setEventId, e, date, setDate) => async (dispatch, getState) => {
    dispatch(showLoaderAction());

    let attendees = [];
    events.current_attendees.map((item, i) => {
        let obj = {
            id: item.id,
        };
        return attendees.push(obj);
    });

    dispatch(showLoaderAction());
    const payload = {
        teacher_id: events?.teacher_id,
        subsititute: events?.subsititute,
        visibility: events?.visibility,
        student_portal: events?.student_portal,
        select_location: events?.select_location,
        date: moment(events.date).format("DD-MM-YYYY"),
        school_duration: events?.school_duration,

        all_day: events.all_day === true ? 1 : 0,

        days: checkedDays.length ? checkedDays : null,
        // current_attendees: attendees,
        calendar_category_id: events?.calendar_category_id,
        frequency: events.frequency,
        every_years: events.every_years,
        every_weeks: events.every_weeks,
        every_months: events.every_months,

        price_per_student: events.price_per_student,
        event_price: events.event_price,
        public_description: events.public_description,
        private_description: events.private_description,
        show_public_description: events?.show_public_description === true ? 1 : 0,
        event_repeat: events.event_repeat === true ? 1 : 0,
    };

    if (attendees?.length) {
        payload.current_attendees = attendees;
    }

    if (events?.select_location === "others") {
        payload.others_duration = events?.others_duration;
    }

    if (e) {
        payload.event_change = e;
    }
    if (events?.event_repeat && events?.repeat_until) {
        payload.repeat_until = moment(events.repeat_until).format("DD-MM-YYYY");
    }
    if (events?.event_repeat && events?.every_months) {
        payload.month_day = moment(events.date).add(1, "M").format("DD-MM-YYYY");
    }
    if (events?.school_id && events?.select_location === "school") {
        payload.school_id = events.school_id;
        payload.class_type = events.class_type;
    }

    if (events?.select_location === "others" || events?.class_type === "after_school" || events?.class_type === "semi_private") {
        payload.start_time = new Date(events.start_time).getHours() + ":" + ("00" + new Date(events.start_time).getMinutes()).slice(-2);
    }

    const res = await api("post", Constants.END_POINT.EDIT_EVENT + id, payload, loadStateFn("token"));

    if (res.success) {
        showToast([res.message], "success");
        setEventId(null);
        dispatch(ChangeCalendarDateAction(date));
        history.goBack(-1);
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
export const getCategoryAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.GET_CATEGORY, {}, loadStateFn("token"));

    if (res.success) {
        dispatch({
            type: types.FETCH_CATEGORIES,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
