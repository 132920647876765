import React, { useState } from "react";
import VideoRecorder from "react-video-recorder";
import Index from "../../layout";
import ButtonComponent from "../../../../components/Fields/Button/CustomButton";
import { addPracticeLogs } from "../../../../redux/actions/StudentPortal/practiceLog";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function RecordPracticeLog() {
  const history = useHistory();
  const [file, setFile] = useState(null);
  const [local, setLocal] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(
      addPracticeLogs(file, () => {
        setFile(null);
        history.goBack();
      })
    );
  };
  return (
    <>
      <Index>
        <div className="p-4">
          <h3 className="open-sans font-semibold mb-3 text-main">
            Practice Log
          </h3>
          <div className="flex justify-content-between mb-4">
            <ButtonComponent
              onClick={() => {
                setLocal(false);
                setFile(null);
              }}
              label="Record"
              severity="secondary"
            />
            <ButtonComponent
              onClick={() => {
                setLocal(true);
                setFile(null);
              }}
              label="Upload Video file"
            />
          </div>
          <VideoRecorder
            timeLimit={120000}
            showReplayControls={true}
            replayVideoAutoplayAndLoopOff={true}
            useVideoInput={local}
            isOnInitially={local}
            onRecordingComplete={(videoBlob) => {
              setFile(videoBlob);
            }}
          />
          <br />
          {file ? (
            <ButtonComponent onClick={handleSubmit} label="Upload On Sever" />
          ) : null}
        </div>
      </Index>
    </>
  );
}
