import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { sendResetPasswordApi, teacherSendResetPasswordApi, parentSendResetPasswordApi } from "../redux/actions/loginAction";

const ResetPassword = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    var base64 = require("base-64");

    const match = useRouteMatch();

    const params = useParams();
    const { encodedEmail } = params;
    const history = useHistory();
    const dispatch = useDispatch();
    const email = base64.decode(encodedEmail);

    const [resetPassword, setResetPassword] = useState({
        email: email,
        new_password: "",
        confirm_password: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setResetPassword({ ...resetPassword, [name]: value });
    };

    const handleResetPassword = (e) => {
        e.preventDefault();
        if (match.path === "/student-reset-password/:encodedEmail") {
            dispatch(sendResetPasswordApi(resetPassword?.email, resetPassword?.new_password, history));
        } else if (match.path === "/parent-reset-password/:encodedEmail") {
            dispatch(
                parentSendResetPasswordApi(resetPassword?.email, resetPassword?.new_password, () => {
                    history.push("/");
                })
            );
        } else {
            dispatch(teacherSendResetPasswordApi(resetPassword?.email, resetPassword?.new_password, history));
        }
    };

    return {
        resetPassword,
        handleChange,
        handleResetPassword,
    };
};

export default ResetPassword;
