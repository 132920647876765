import React from "react";
import InputMask from "react-input-mask";

const InputMaskComponent = ({
  label,
  name,
  errorMessage,
  extraClassName,
  labelClassName,
  required,
  inputClass,
  inputId,
  ...props
}) => {
  return (
    <div className={`relative mb-2 ${extraClassName}`}>
      <label htmlFor={inputId} className={`absolute text-xs text-500 m-0 top-0 bg-white open-sans  bg-white-color form-label z-1 ${labelClassName}`}>
        {label} {required && <span className="p-error">*</span>}
      </label>
      <InputMask
        id={name}
        name={name}
        className={`overflow-hidden p-3 ${inputClass} ${errorMessage ? "p-invalid" : null
          }`}
        maskChar={null}
        {...props}
      />

      {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
    </div>
  );
};

export default InputMaskComponent;
