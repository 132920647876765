import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ButtonComponent from "../../../components/Fields/Button/CustomButton";
import CheckboxComponent from "../../../components/Fields/Checkbox";
import InputComponent from "../../../components/Fields/InputField";
import DropDownComponent from "../../../components/Fields/CustomDropdown";
import NumberComponent from "../../../components/Fields/NumberField";
import RadioButtonComponent from "../../../components/Fields/Radio";
import { useDispatch, useSelector } from "react-redux";

import states1 from "states-us";
import { addBillingAction } from "../../../redux/actions/studentAction";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import useUnload from "../../../components/useUnload";
import { confirmNext } from "../../../utils/commonFunctions";

export default function BillingInformation() {
    const billing_info = useSelector((state) => state?.studentInfo?.student_billing_info);
    useUnload((e) => {
        e.preventDefault();
        e.returnValue = "";
    });

    const schoolForms = useSelector((state) => state?.enroll?.students);

    useEffect(() => {
        if (!schoolForms?.[0].enroll_type) {
            history.push("/enroll");
        }
    }, [schoolForms?.[0]?.enroll_type]);
    useEffect(() => {
        const disableBackButton = () => {
            window.history.pushState(null, document.title, window.location.href);
        };

        disableBackButton();

        window.addEventListener("popstate", disableBackButton);

        return () => {
            window.removeEventListener("popstate", disableBackButton);
        };
    }, []);
    useEffect(() => {
        getUpdatedStates();
    }, []);
    const [states, setStates] = useState([]);
    const getUpdatedStates = () => {
        let res = states1.filter((x) => x.contiguous);
        setStates(res);
    };
    const history = useHistory();
    const dispatch = useDispatch();

    const [terms, setTerms] = useState(false);

    const [registrationAddress, setRegistrationAddress] = useState(false);

    const [billingInformation, setBillingInformation] = useState({
        payment_type: "monthly_payment",
        address: "",
        city: "",
        state: "",
        zip_code: null,
    });

    const handleChange = (e) => {
        const { name } = e.target;
        setBillingInformation((prev) => ({ ...prev, [name]: e.value }));
    };
    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setBillingInformation((prev) => ({ ...prev, [name]: value }));
    };
    const handleChange3 = (e) => {
        const { name, value } = e.target;
        setBillingInformation((prev) => ({ ...prev, [name]: value }));
    };

    const handleContinue = (e) => {
        e.preventDefault();
        confirmNext(
            () => {
                dispatch(addBillingAction(billingInformation, registrationAddress));
                history.push("/payment-information");
            },
            "Would you like to proceed? Please review your information carefully, as once you advance, there's no turning back!",
            "center"
        );
    };

    return (
        <div className="">
            <div className="student_banner relative">
                <h1 className="text-white relative text-center">Billing Information</h1>
            </div>
            <div className="py-4">
                <p className="text-900 font-semibold mb-3 text-center inter">
                    If you begin the program with us and decide to discontinue, tuition billing will stop once 5 payments are made. To discontinue,
                    <span className="text-main">you must notify Mr. Kelly</span> (Program Administrator)
                </p>
                <div className="grid justify-content-center p-3 md:p-0">
                    <div className="col-12 md:col-9">
                        <div className="shadow-1 p-3 md:p-4 border-round-lg">
                            <p className="text-red inter font-semibold mb-3 text-center">Please read Lesson Policies and Terms prior to enrollment.</p>
                            <p className="bg-main p-3 border-round-lg font-bold text-white mb-3">Billing Information</p>
                            <form action="" className="mb-3">
                                <div className="grid mb-4">
                                    <div className="md:col-3 mb-2 md:m-0">
                                        <RadioButtonComponent
                                            name="payment_type"
                                            label="Recurring Payment"
                                            labelClassName="mb-0 ml-2 open-sans text-base font-semibold"
                                            value="monthly_payment"
                                            checked={billingInformation?.payment_type === "monthly_payment"}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="md:col-3">
                                        <RadioButtonComponent
                                            name="payment_type"
                                            label="One Time Payment"
                                            labelClassName="mb-0 ml-2 open-sans text-base font-semibold"
                                            value="one_time_payment"
                                            checked={billingInformation?.payment_type === "one_time_payment"}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                {billing_info?.billing_info?.map((billing, i) => (
                                    <div key={i} className="multiple-billing">
                                        <h6 className="font-semibold text-lg mt-4">Student {i + 1}</h6>
                                        {billing?.is_waiting === 0 ? (
                                            <>
                                                {billingInformation?.payment_type === "monthly_payment" ? (
                                                    <div className="flex justify-content-between align-items-center py-3 mb-3">
                                                        <p className="open-sans text-gray">First Tuition Installment</p>
                                                        <h1 className="text-xl open-sans font-bold m-0">${billing?.first_tution_installment}</h1>
                                                    </div>
                                                ) : (
                                                    <div className="flex justify-content-between align-items-center py-3 mb-3">
                                                        <p className="open-sans text-gray">Full Tuition Fee</p>
                                                        <h1 className="text-xl open-sans font-bold m-0">${billing?.full_tution_payment}</h1>
                                                    </div>
                                                )}
                                                <hr className="mb-3" style={{ borderColor: "rgb(206 212 218 / 30%)" }} />
                                                <div className="flex justify-content-between align-items-center py-3">
                                                    <p className="open-sans text-gray">Registration Fee</p>
                                                    <h1 className="text-xl  open-sans font-bold m-0">${billing?.material_fees}</h1>
                                                </div>
                                                <hr className="mb-3" style={{ borderColor: "rgb(206 212 218 / 30%)" }} />
                                                {billingInformation?.payment_type === "monthly_payment" && billing?.deposit_fee !== 0 && (
                                                    <>
                                                        <div className="flex justify-content-between align-items-center py-3">
                                                            <p className="open-sans text-gray">Deposit Fee</p>
                                                            <h1 className="text-xl  open-sans font-bold m-0">${billing?.deposit_fee}</h1>
                                                        </div>
                                                        <hr
                                                            className="mb-3"
                                                            style={{
                                                                borderColor: "rgb(206 212 218 / 30%)",
                                                            }}
                                                        />
                                                    </>
                                                )}
                                                {billing?.selected_instruments.filter((item) => item.total_amount > 0).length > 0 && (
                                                    <>
                                                        <p className="open-sans text-gray mb-3">Instruments</p>
                                                        <DataTable
                                                            className="student-info-table1 bg-gray-light"
                                                            value={
                                                                billing?.selected_instruments?.length &&
                                                                billing?.selected_instruments.filter((item) => item.total_amount > 0)
                                                            }
                                                            stripedRows
                                                            tableStyle={{ minWidth: "50rem" }}
                                                        >
                                                            <Column field="instrument_name" header="Name"></Column>
                                                            <Column
                                                                field="type"
                                                                body={(r) =>
                                                                    r.type === "rent" ? "Installment" : r.type === "purchase" ? "Purchase" : "Nothing Needed"
                                                                }
                                                                header="Mode of Payment"
                                                            ></Column>
                                                            <Column field="deposit_amount" header="Deposit Amount"></Column>
                                                            <Column field="amount" header="Amount"></Column>
                                                            <Column field="total_amount" header="Total Amount"></Column>
                                                        </DataTable>
                                                    </>
                                                )}
                                                {billing?.school_tax !== 0 && (
                                                    <>
                                                        <div className="flex justify-content-between align-items-center py-3">
                                                            <p className="open-sans text-gray">School Tax</p>
                                                            <h1 className="text-xl open-sans font-bold m-0">${billing?.school_tax}</h1>
                                                        </div>

                                                        <hr className="mb-3" style={{ borderColor: "rgb(206 212 218 / 30%)" }} />
                                                    </>
                                                )}{" "}
                                            </>
                                        ) : (
                                            <>
                                                <div className="flex justify-content-between align-items-center py-3 mb-3">
                                                    <p className="open-sans text-gray">Waitlist Deposit Fees</p>
                                                    <h1 className="text-xl open-sans font-bold m-0">${billing?.total_payment}</h1>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ))}
                                <div className="flex justify-content-between align-items-center py-3">
                                    <p className="open-sans text-gray">Total Payment </p>
                                    <h1 className="text-xl open-sans font-bold m-0">
                                        $
                                        {billingInformation?.payment_type === "monthly_payment"
                                            ? billing_info?.total_payment
                                            : billing_info?.full_total_payment}
                                    </h1>
                                </div>
                                {billing_info?.is_note ? (
                                    <p className="text-danger mb-3">
                                        Note - During this time, no charges will be deducted from your card, Once we create the class we will start charging
                                        your card. If, for any reason, we are unable to create the classes, the amount will be credited back to your account.
                                    </p>
                                ) : (
                                    <></>
                                )}
                                {/* end */}

                                <CheckboxComponent
                                    extraClassName="flex align-items-center mb-3"
                                    label="Same as registration address?"
                                    labelClassName="mb-0 ml-2 font-bold"
                                    checked={registrationAddress}
                                    onChange={(e) => setRegistrationAddress(e.checked)}
                                />
                                <div className="grid flex-wrap mb-2">
                                    <div className="col-12 md:col-6 mb-3">
                                        <InputComponent
                                            inputClass="w-full border-1 border-round-lg border-700"
                                            placeholder="Billing Address Street"
                                            required
                                            name="address"
                                            onChange={handleChange1}
                                            value={registrationAddress ? billing_info?.parent?.address : billingInformation?.address}
                                        />
                                    </div>
                                    <div className="col-12 md:col-6 mb-3">
                                        <InputComponent
                                            inputClass="w-full border-1 border-round-lg border-700"
                                            placeholder="City"
                                            required
                                            name="city"
                                            onChange={handleChange1}
                                            value={registrationAddress ? billing_info?.parent?.city : billingInformation?.city}
                                        />
                                    </div>
                                    <div className="col-12 md:col-6 mb-3">
                                        <DropDownComponent
                                            name="state"
                                            inputClass="w-full border-1 border-round-lg border-700"
                                            placeholder="State"
                                            value={registrationAddress ? billing_info?.parent?.state : billingInformation?.state}
                                            options={states?.map((item, index) => {
                                                return { label: item?.name, value: item?.name };
                                            })}
                                            onChange={handleChange1}
                                        />
                                    </div>
                                    <div className="col-12 md:col-6 mb-3">
                                        <InputComponent
                                            placeholder="Zipcode"
                                            inputClass="w-full border-1 border-round-lg border-700"
                                            name="zip_code"
                                            value={registrationAddress ? billing_info?.parent?.zip_code : billingInformation?.zip_code}
                                            useGrouping={false}
                                            onChange={handleChange3}
                                            keyfilter="int"
                                        />
                                    </div>
                                </div>
                                <div className="flex mb-3">
                                    <CheckboxComponent
                                        extraClassName="flex align-items-center mb-3"
                                        label=""
                                        labelClassName=""
                                        onChange={(e) => setTerms(e.checked)}
                                        checked={terms}
                                    />
                                    <label className="mb-0 ml-2">
                                        By checking this box, you agree to{" "}
                                        <Link to="/terms-and-condition" target="_blank" className="text-main font-bold">
                                            Terms and Conditions.
                                        </Link>
                                    </label>
                                </div>
                                <div className="flex justify-content-center">
                                    <ButtonComponent
                                        label="Continue"
                                        className="btn no-underline bg-main text-white p-2 ml-2 px-5 border-none border-round-lg open-sans"
                                        disabled={terms ? false : true}
                                        onClick={handleContinue}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
