import types from "../../types";
import Constants from "../../../services/constant";
import { showLoaderAction, hideLoaderAction } from "../loadAction";
import { api, multipartApi } from "../../../services/api";
import { showToast } from "../../../utils/showToast";
import moment from "moment";
import { getParentStudentsAction } from "./parentAction";
import { convertToFormdata, removeEmpty } from "../../../utils/commonFunctions";

export const editStudentAction = (id, student, history) => async (dispatch, getState) => {
    const formData = new FormData();

    formData.append("first_name", student?.first_name);
    formData.append("last_name", student.last_name);
    formData.append("skill_level", student?.skill_level);
    formData.append("skype_username", student?.skype_username);
    formData.append("face_time_id", student?.face_time_id);
    formData.append("gender", student?.gender);
    formData.append("dob", student?.dob ? moment(student?.dob).format("DD-MM-YYYY") : "");
    formData.append("is_picture_delete", student?.is_picture_delete);

    formData.append("student_since", student?.student_since ? moment(student?.student_since).format("DD-MM-YYYY") : "");
    if (student.picture && typeof student.picture == "object") {
        formData.append("picture", student.picture);
    }

    const state = getState();

    dispatch(showLoaderAction());
    const res = await multipartApi("post", Constants.END_POINT.EDIT_STUDENT + id, formData);

    if (res.success) {
        showToast([res.message], "success");
        dispatch(getParentStudentsAction());
        history.goBack();
    }

    dispatch(hideLoaderAction());
};

export const getSchoolsAction = () => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.PARENT_SCHOOL_DROPDOWN, {});
    if (res.success) {
        dispatch({
            type: types.PARENT_SCHOOL_DROPDOWN,
            payload: res.data,
        });
    }
    dispatch(hideLoaderAction());
};

export const viewSchoolGradeClassType = (school_id, grade) => async (dispatch) => {
    dispatch(showLoaderAction());
    const payload = {
        school_id: school_id,
        grade: grade,
    };

    const res = await api("post", Constants.END_POINT.GRADE_CLASS_TYPE, payload);

    if (res.success) {
        dispatch({
            type: types.VIEW_SCHOOL_GRADE_CLASS_TYPE,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const viewSchoolPrograms = (school_id, grade, class_type) => async (dispatch) => {
    dispatch(showLoaderAction());
    const payload = {
        school_id: school_id,
        grade: grade,
        class_type: class_type,
    };

    const res = await api("post", Constants.END_POINT.PARENT_CLASS_TYPE_PROGRAMS, payload);

    if (res.success) {
        dispatch({
            type: types.PARENT_CLASS_TYPE_PROGRAMS,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const getLessonsTypeAction = (type) => async (dispatch) => {
    dispatch(showLoaderAction());
    const params = {
        lesson_type: type,
    };

    const res = await api("get", Constants.END_POINT.SCHOOL_LESSONS, {}, {}, params);

    if (res.success) {
        dispatch({
            type: types.GET_FILTER_LESSON,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const viewSchoolInstrumentAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.SCHOOL_INSTRUMENT + id, {});

    if (res.success) {
        dispatch({
            type: types.SCHOOL_INSTRUMENT,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const getOnlineLessonInstrumentsAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.ONLINE_INSTRUMENTS + id, {});

    if (res.success) {
        dispatch({
            type: types.ONLINE_INSTRUMENTS,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const AddStudentDeatilAction = (addStudent, selectedFiles, history) => async (dispatch) => {
    const { dob, day, student_since, aftercare_picked_up_time, time, lessonProgram, instruments, class_type, selectedItems, ...rest } = addStudent;

    removeEmpty(addStudent);
    const payload = {
        ...rest,
        ...(dob !== "" && { dob: moment(dob).format("DD-MM-YYYY") }),
        ...(class_type !== "" && { class_type: class_type }),

        ...(day?.length > 0 && { day: JSON.stringify(day) }),
        ...(addStudent?.lesson_type == "school_lessons" && {
            order_book: addStudent?.order_book == true ? "yes" : "no",
        }),
        ...(addStudent?.registration_date !== "" && { registration_date: moment(addStudent?.registration_date).format("MM-DD-YYYY") }),
        is_waiting: addStudent?.is_waiting ? 1 : 0,
        ...(addStudent?.order_book == true && { cost_per_book: 30 }),
        lesson_type: addStudent?.student_type === "child" ? addStudent?.lesson_type : "online_lessons",
        ...(addStudent?.lesson_type == "school_lessons" && addStudent?.student_type == "child"
            ? {
                  school_id: addStudent?.school_id?.id,
                  grade_id: addStudent?.grade_id,
              }
            : addStudent?.lesson_type == "online_lessons" && addStudent?.student_type == "child"
            ? { grade_id: addStudent?.online_grade, instrument_id: addStudent?.instrument_purchase_plan_id }
            : addStudent?.lesson_type == "school_lessons" && addStudent?.student_type == "adult"
            ? { age: addStudent?.age }
            : { age: addStudent?.age }),
        instrument_purchase_plan_id: "",
        ...(addStudent?.instrument_id !== "" && {
            instrument_id: addStudent?.instrument_id,
        }),
        program_id: addStudent?.lesson_type == "online_lessons" ? addStudent?.lessonProgram?.id : addStudent?.program_id,
        ...(addStudent?.picture !== "" && { picture: addStudent?.picture }),
        ...(student_since !== "" && {
            student_since: moment(student_since).format("DD-MM-YYYY"),
        }),
        ...(aftercare_picked_up_time !== "" && {
            aftercare_picked_up_time: new Date(aftercare_picked_up_time).getHours() + ":" + ("00" + new Date(aftercare_picked_up_time).getMinutes()).slice(-2),
        }),
        ...(time !== "" && {
            time: new Date(time).getHours() + ":" + ("00" + new Date(time).getMinutes()).slice(-2),
        }),
        student_type: addStudent?.student_type,
    };
    dispatch(showLoaderAction());

    if (selectedFiles.length) {
        payload.attachment = selectedFiles.map((item) => ({ file: item }));
    }
    if (instruments?.instruments?.length) {
        payload.instrument = instruments?.instruments
            ?.filter((ins) => ins.id === instruments?.instrument_id)
            ?.map((inst, i) => ({
                instrument_id: inst.id,
                type: instruments?.type,
                amount: instruments?.type === "rent" ? inst?.monthly_installment : instruments?.type === "purchase" ? inst?.purchase_amount : 0,
            }));
    }
    convertToFormdata(payload);
    const res = await multipartApi("post", Constants.END_POINT.PARENT_ADD_STUDENTS, payload);
    if (res.success) {
        showToast([res.message], "success");
        dispatch(getParentStudentsAction());
        history.goBack();
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};
