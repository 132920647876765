import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Accordion, AccordionTab } from "primereact/accordion";

const OnlineSchoolDetail = ({ viewOnlineSchoolDetail }) => {
    const payment = (rowData) => {
        return <div>${rowData?.payment_per_class}</div>;
    };

    const classDuration = (rowData) => {
        return <div>{rowData?.class_duration} minutes</div>;
    };
    const purchaseAmount = (rowData) => {
        return <div>${rowData?.purchase_amount}</div>;
    };
    const MonthlyInstallment = (rowData) => {
        return <div>${rowData?.monthly_installment}</div>;
    };

    const depositAmount1 = (rowData) => {
        return <div>${rowData?.deposit_amount}</div>;
    };

    return (
        <div>
            <Accordion className="enrollform">
                <AccordionTab header="Grade" className="border-none">
                    <div className="card mb-3 border-none">
                        <DataTable value={viewOnlineSchoolDetail?.grade} showGridlines className="border-round-lg overflow-hidden text-dark">
                            <Column field="grade" header="Grade" style={{ width: "2rem" }}></Column>
                            <Column field="instruments_name" header="Instruments"></Column>
                        </DataTable>
                    </div>
                </AccordionTab>
                <AccordionTab header="Class Schedule">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: viewOnlineSchoolDetail?.online_lesson?.class_schedule,
                        }}
                        className="text-xs open-sans"
                    ></div>
                </AccordionTab>
                <AccordionTab header="Program Tuition" className="mt-3">
                    <p className="text-main mb-2">{viewOnlineSchoolDetail?.online_lesson?.tution_description}</p>
                    <div className="card mb-3">
                        <DataTable
                            value={viewOnlineSchoolDetail?.tution_per_class}
                            showGridlines
                            // className="text-dark online-lesson"
                        >
                            <Column body={payment} field="payment_per_class" header="Payment Per Class"></Column>
                            <Column body={classDuration} header="Class Duration"></Column>
                        </DataTable>
                    </div>

                    <div className="tex mb-3">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: viewOnlineSchoolDetail?.program_tution?.description,
                            }}
                        ></div>
                    </div>
                </AccordionTab>
                <AccordionTab header="Instrument Purchase Plan-*If Needed*">
                    <p className="text-900 font-bold text-lg mb-2">Instrument Included</p>
                    <div className="card mb-3">
                        <DataTable value={viewOnlineSchoolDetail?.instruments} showGridlines>
                            <Column field="name" header="Instrument"></Column>
                            <Column body={purchaseAmount} field="purchase_amount" header="Purchase Amount"></Column>
                            <Column body={MonthlyInstallment} field="monthly_installment" header="Monthly Installment"></Column>
                            <Column field="number_of_installment" header="No of Installment"></Column>
                            <Column body={depositAmount1} field="deposit_amount" header="Deposit Amount"></Column>
                        </DataTable>
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: viewOnlineSchoolDetail?.instrument_prurchase_plan?.purchase_plan,
                        }}
                    ></div>
                </AccordionTab>
            </Accordion>
        </div>
    );
};

export default OnlineSchoolDetail;
