import React from "react";
import Index from "../../layout";
import SettingsIcon from "../../../../assets/icons/settingred.png";
import { TabPanel, TabView } from "primereact/tabview";
import { PersonalSetting } from "./PersonalSetting";
import PersonalImg from "../../../../assets/icons/person.png";
import ClockImg from "../../../../assets/icons/clockIcon.png";
import { Availability } from "./Availability";
import CardHeader from "../../../../pages/CardHeader";
const TeacherSettings = () => {
  const DetailTemplate = (options) => {
    return (
      <>
        <div
          className="p-2"
          style={{ cursor: "pointer" }}
          onClick={options.onClick}
        >
          <img src={PersonalImg} className="mr-2" width="20px" alt="" />
          Personal
        </div>
      </>
    );
  };
  const AvailabilityTemplate = (options) => {
    return (
      <>
        <div
          className="p-2"
          style={{ cursor: "pointer" }}
          onClick={options.onClick}
        >
          <img src={ClockImg} className="mr-2" width="20px" alt="" />
          Availability
        </div>
      </>
    );
  };
  return (
    <>
      <Index>
        <div className="p-4">
          <CardHeader icon={SettingsIcon} heading="My Prefrences"/>
        </div>

        <div className="shadow-sm border-round-lg mx-3 p-3 detail_tabs">
          <TabView>
            <TabPanel headerTemplate={DetailTemplate}>
              <PersonalSetting />
            </TabPanel>
            <TabPanel headerTemplate={AvailabilityTemplate}>
              <Availability />
            </TabPanel>
          </TabView>
        </div>
      </Index>
    </>
  );
};
export default TeacherSettings;
