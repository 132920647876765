import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import ButtonComponent from "../Fields/Button/CustomButton";
import { useDispatch } from "react-redux";
import { ChangeParentPassword } from "../../redux/actions/ParentPortal/parentAction";
import CustomPassword from "../Fields/CustomPassword";
import { ChangeTeacherPassword } from "../../redux/actions/TeacherPortal/teacherAction";

export default function ChangePassword({visible,setVisible, role}) {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    current_password:"",
    password: "",
    confirm_password: "",
  });
  const [err, setErr] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setErr({...err,[name]:""})
  };

  const validateForm = () => {
    let errors = {};
    if (!data?.password) {
      errors.password = "Password is required";
    }
    if (!data?.current_password) {
      errors.current_password = " Current Password is required";
    }
    if (!data.confirm_password) {
      errors.confirm_password = " Confirm Password is required";
    } else if (data.password !== data?.confirm_password) {
      errors.confirm_password = "Password and confirm password does not match";
    }

    setErr(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };
  const handleSave = () => {
    const form = validateForm();
    if (form) {
      if(role ==="parent"){
        dispatch(ChangeParentPassword(data, setVisible));

      }else{
        dispatch(ChangeTeacherPassword(data, setVisible));
      }
    }
  };
  return (
    <div>
      <Dialog
        className="header-none bordxer-round-lg overflow-hidden"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={()=>setVisible(false)}
      >
        <h5 className="text-lg open-sans font-semibold mb-3">Change Password</h5>
        <div className="grid mb-3">
          <div className="md:col-4">
            <CustomPassword
              className="bg-whitesmoke password_feild border-round-lg text-sm"
              placeholder="Current Password"
              name="current_password"
              value={data.current_password}
              onChange={handleChange}
              feedback={false}
              errorMessage={err?.current_password}
            />
          </div>
          <div className="md:col-4">
            <CustomPassword
              className="bg-whitesmoke password_feild border-round-lg text-sm"
              placeholder="New Password"
              name="password"
              value={data.password}
              onChange={handleChange}
              errorMessage={err?.password}
              feedback={false}
            />
          </div>
          <div className="md:col-4">
            <CustomPassword
              className="bg-whitesmoke password_feild border-round-lg text-sm"
              placeholder="Confirm New Password"
              name="confirm_password"
              value={data?.confirm_password}
              onChange={handleChange}
              errorMessage={err?.confirm_password}
              feedback={false}
            />
          </div>
        </div>
        <div className="">
            <ButtonComponent label="Cancel" className="bg-gray-light border-none text-lg" onClick ={()=>setVisible(false)}/>
            <ButtonComponent label="Change Password" className="text-lg ml-2 border-none" onClick={handleSave}/>
        </div>
      </Dialog>
    </div>
  );
}
