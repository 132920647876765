import Constants from "../../../services/constant";
import { api } from "../../../services/api";
import types from "../../types";
import { hideLoaderAction, showLoaderAction } from "../loadAction";
import { showToast } from "../../../utils/showToast";


export const getEmailTemplateAction = (setLoading, type) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }
    const res = await api("get", Constants.END_POINT.GET_EMAIL_TEMPLATE + type, {}, {});
    if (res.success) {
        dispatch({
            type: types.GET_EMAIL_TEMPLATE,
            payload: res.data,
        });
    }

    if (setLoading) {
        setLoading(false);
    }
};
export const ViewTemplateAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.VIEW_TEMPLATE + id, {}, {});

    if (res.success) {
        dispatch({
            type: types.VIEW_TEMPLATE,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
export const addEmailTemplate = (templateData, type, setopenEmailCustomTemplate) => async (dispatch) => {
    dispatch(showLoaderAction());
    const payload = {
        ...templateData,
        type: type,
    };

    const res = await api("post", Constants.END_POINT.ADD_EMAIL_TEMPLATE, payload, {});
    if (res.success) {
        showToast([res.message], "success");

        dispatch(getEmailTemplateAction(null, "custom"));
        setopenEmailCustomTemplate(false);
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};