import types from "../../types";
const initialBoard = {
    teacherAssignments: [],
    studentDropdown: [],
    viewAssignment: [],
    feedback: []
};

const teacherAssignmentReducer = (state = initialBoard, action) => {
    switch (action.type) {
        case types.TEACHER_ASSIGNMENT:
            return { ...state, teacherAssignments: action.payload };
        case types.STUDENT_DROPDOWN:
            return { ...state, studentDropdown: action.payload }
        case types.VIEW_TEACHER_ASSIGNMENTS:
            return { ...state, viewAssignment: action.payload }
        case types.TEACHER_FEEDBACK:
            return { ...state, feedback: action.payload }
        default:
            return { ...state };
    }
};

export default teacherAssignmentReducer;
