import React, { useEffect } from "react";
import Index from "../../layout";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import groupmessage from "../../../../assets/icons/groupmessage.png";
import send from "../../../../assets/icons/send.png";
import { getParentMessageHistoryAction } from "../../../../redux/actions/ParentPortal/parentAction";
import CardHeader from "../../../../pages/CardHeader";
import MessageIcon from "../../../../assets/icons/messageHistory (2).png";

const MessageHistory = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getParentMessageHistoryAction());
    }, [dispatch]);
    const messageHistory = useSelector((state) => state.parent.messageHistory);

    const toTemplate = (rowData) => {
        return (
            <>
                {rowData?.student_name}
                {`<${
                    rowData?.receiver_emails
                        ? rowData?.receiver_emails
                        : rowData?.receiver_emails === "null"
                        ? ""
                        : rowData?.receiver_emails
                }>`}
            </>
        );
    };
    const fromTemplate = (rowData) => {
        return (
            <>
                {rowData?.name}
                {`<${rowData?.from}>`}
            </>
        );
    };
    const TypeTemplate = (rowData) => {
        return (
            <>
                <img src={groupmessage} alt="" width="19"></img>
            </>
        );
    };
    const StatusTemplate = (rowData) => {
        return (
            <>
                <img src={send} width="19" alt=""></img>
            </>
        );
    };

    const dateTemplate = (rowData) => {
        return (
            <>
                {rowData?.scheduled_date
                    ? moment(rowData?.scheduled_date).format("MM/DD/YYYY") + " " + rowData?.send_time_formatted
                    : moment(rowData?.created_at).format("MM/DD/YYYY") + "  " + rowData?.send_time_formatted}
            </>
        );
    };
    return (
        <>
            <Index>
                <div className="md:col-12 border-1 border-round-lg border-300 p-3 mb-3 ">
                    <CardHeader icon={MessageIcon} heading="Message History" />
                    <DataTable
                        value={messageHistory}
                        paginator
                        className="bg-light-main"
                        stripedRows
                        rows={5}
                        scrollable
                        scrollHeight="400px"
                        style={{ minWidth: "50rem" }}
                    >
                        <Column field="current_attendees" body={TypeTemplate} header="Type "></Column>
                        <Column field="date" body={StatusTemplate} header="Status"></Column>
                        <Column field="category_name" body={toTemplate} header="To"></Column>
                        <Column field="category_name" body={fromTemplate} header="From"></Column>
                        <Column field="subject" header="Subject"></Column>

                        <Column field="category_name" body={dateTemplate} header="Date"></Column>
                    </DataTable>
                </div>
            </Index>
        </>
    );
};
export default MessageHistory;
