import Home from "../../../assets/icons/home.png";
import NewsPapericon from "../../../assets/icons/newspaper.png";
import Attendance from "../../../assets/icons/attandence.png";
import Calendar from "../../../assets/icons/calendermain.png";
import WatchIcon from "../../../assets/icons/Watchred.png";
import Messageicon from "../../../assets/icons/messagehistory.png";
import SettingIcon from "../../../assets/icons/setting.png";
import { useState } from "react";

const Sidemenu = () => {
  const [isFullSidebar, setIsFullSidebar] = useState(true);
  const menuItems = [
    {
      name: "Home",
      link: "/student/dashboard",
      icon: Home,
      iconWidth: "30px",
      iconHeight: "30px",
    },
    {
      name: "Calendar",
      link: "/student/calendar",
      icon: Calendar,
      iconWidth: "29px",
      iconHeight: "29px",
    },
    {
      name: "News",
      link: "/student/news",
      icon: NewsPapericon,
      iconWidth: "24px",
      iconHeight: "24px",
    },
    {
      name: "Attendance & Notes",
      link: "/student/attendence-notes",
      icon: Attendance,
      iconWidth: "24px",
      iconHeight: "24px",
    },
    {
      name: "Practice Log",
      link: "/student/practice-logs",
      icon: WatchIcon,
      iconWidth: "24px",
      iconHeight: "24px",
    },
    {
      name: "Message History",
      link: "/student/notifications",
      icon: Messageicon,
      iconWidth: "20px",
      iconHeight: "21px",
    },
    {
      name: "Profile Settings",
      link: "/student/profile-settings",
      // link: "#",
      icon: SettingIcon,
      iconWidth: "17px",
      iconHeight: "17px",
      //     subMenu: [
      //         {
      //         name: "My Profile",
      //         link: "#"
      //        },
      // ]
    }
  ];
  return { menuItems,isFullSidebar,setIsFullSidebar };
};
export default Sidemenu;
