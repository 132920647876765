import React, { useState, useEffect } from "react";
import CheckboxComponent from "../../../../components/Fields/Checkbox";
import ButtonComponent from "../../../../components/Fields/Button/CustomButton";
import InputComponent from "../../../../components/Fields/InputField";
import InputTextAreaComponent from "../../../../components/Fields/CustomInputTextArea";
import CustomImageInput from "../../../../components/Fields/CustomImageInput";
import { TabPanel, TabView } from "primereact/tabview";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getTeacherProfileApi } from "../../../../redux/actions/loginAction";
import { formattedPhoneNumber } from "../../../../utils/commonFunctions";
import InputMaskComponent from "../../../../components/Fields/InputMaskComponent";
import { TeacherSettings } from "../../../../redux/actions/TeacherPortal/teacherAction";
import ChangePassword from "../../../../components/popup/ChangePassword";

export const PersonalSetting = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getTeacherProfileApi());
  }, [dispatch]);

  const teacherProfile = useSelector(
    (state) => state?.login?.getTeacherProfile
  );
  const [visible, setVisible] = useState(false);

  const [profile, setProfile] = useState({
    title: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    picture_url: [],
    is_picture_delete: 0,
    address: "",
    zoom_url: "",
    show_overdue:false
  });

  useEffect(() => {
    setProfile({
      title: teacherProfile?.title,
      first_name: teacherProfile?.first_name,
      last_name: teacherProfile?.last_name,
      email: teacherProfile?.email,
      phone_number: teacherProfile.phone_number
        ? formattedPhoneNumber(teacherProfile.phone_number)
        : "",
      address: teacherProfile?.address,
      zoom_url:
        teacherProfile?.zoom_url !== "null" ? teacherProfile?.zoom_url : "",
      is_picture_delete: 0,
      picture_url: teacherProfile.picture ? [teacherProfile.picture] : [],
      show_overdue:teacherProfile?.show_overdue ?true:false
    });
  }, [teacherProfile]);

  const profilehandleChange = ({ name, value }) => {
    setProfile((prev) => ({ ...prev, [name]: value }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheck =(name)=>{
    setProfile((prev)=>({...prev,[name]:!prev[name]}))
  }

  const AssignedLessonTemplate = (options) => {
    return (
      <>
        <div
          className="p-3 font-bold text-xs"
          style={{ cursor: "pointer" }}
          onClick={options.onClick}
        >
          Assigned Lesson Category
        </div>
      </>
    );
  };
  const AssignedInstrumentTemplate = (options) => {
    return (
      <>
        <div
          className="p-3 font-bold text-xs"
          style={{ cursor: "pointer" }}
          onClick={options.onClick}
        >
          Assigned Instruments
        </div>
      </>
    );
  };
  const AssignedSchoolTemplate = (options) => {
    return (
      <>
        <div
          className="p-3 font-bold text-xs"
          style={{ cursor: "pointer" }}
          onClick={options.onClick}
        >
          Assigned Schools
        </div>
      </>
    );
  };
  return (
    <div>
      <>
        <div className="grid mb-3 mt-4">
          <CustomImageInput
            extraClassName=""
            data={profile}
            onFilesChange={profilehandleChange}
            name="picture_url"
            editable={true}
            view={false}
            removeable
          />
          <div className="col grid align-items-start mr-3">
            <InputComponent
              label="Title"
              name="title"
              extraClassName="mb-2 md:col-5"
              inputClass="w-full border-1  border-round-lg border-400"
              placeholder=""
              required
              value={profile?.title}
              onChange={handleChange}
            />
            <InputComponent
              label="First Name"
              name="first_name"
              extraClassName="mb-2 md:col-5"
              inputClass="w-full border-1 border-round-lg border-400"
              placeholder=""
              required
              value={profile?.first_name}
              onChange={handleChange}
            />
            <InputComponent
              label=" Last Name"
              labelClassName="absolute text-xs text-500 open-sans bg-white form-label"
              name="last_name"
              extraClassName="mb-2 md:col-5"
              inputClass="w-full border-1 border-round-lg border-400"
              placeholder=""
              required
              value={profile?.last_name}
              onChange={handleChange}
            />
            <InputComponent
              label="Email Address"
              name="Email Address"
              extraClassName="mb-2 md:col-5"
              inputClass="w-full border-1 border-round-lg border-400"
              placeholder=""
              value={profile?.email}
              disabled
            />
            {visible && (
              <ChangePassword
                visible={visible}
                setVisible={setVisible}
                role="teacher"
              />
            )}
            <div className="md:col-10 mb-3">
              <ButtonComponent
                type="button"
                label="Change password"
                labelClassName="open-sans font-semibold  text-base"
                className="bg-main p-2 border-round-lg border-0 px-3
                                       open-sans font-semibold  text-base"
                onClick={() => setVisible(true)}
              />
            </div>
          </div>
        </div>
        <>
          <div className="grid">
            <InputMaskComponent
              label="Phone"
              labelClassName="absolute text-xs text-500 open-sans  bg-white form-label"
              name="phone_number"
              id="phone_number"
              mask="(999)999-9999"
              placeholder="___-___-____"
              extraClassName=" relative md:col-4"
              inputClass="w-full border-1 p-2 border-round-lg border-400"
              value={profile?.phone_number}
              onChange={handleChange}
            />
            <InputComponent
              label="Zoom Personal Meeting URL"
              name="zoom_url"
              extraClassName="mb-2 md:col-6"
              inputClass="w-full border-1 border-round-lg border-400"
              placeholder=""
              required
              value={profile?.zoom_url}
              onChange={handleChange}
            />
            <InputTextAreaComponent
              label="Address"
              name="address"
              rows="4"
              extraClassName="mb-2 md:col-10"
              inputClass="w-full border-1 border-round-lg border-400"
              placeholder=""
              required
              value={profile?.address}
              onChange={handleChange}
            />
            <div className="md:col-10 detail_tabs">
              <TabView className="setting-tabs">
                <TabPanel headerTemplate={AssignedLessonTemplate}>
                  <div className="bg-whitesmoke p-4 border-round-lg mb-3">
                    {teacherProfile?.categories?.map((item, i) => {
                      return (
                        <>
                          <p className="text-xs"></p>
                          {item.name}
                          <hr />
                        </>
                      );
                    })}
                  </div>
                </TabPanel>
                <TabPanel headerTemplate={AssignedInstrumentTemplate}>
                  <div className="bg-whitesmoke p-4 border-round-lg mb-3">
                    {teacherProfile?.instrument_info?.map((item, i) => {
                      return (
                        <>
                          <p className="text-xs"></p>
                          {item.name}
                          <hr />
                        </>
                      );
                    })}
                  </div>
                </TabPanel>
                <TabPanel headerTemplate={AssignedSchoolTemplate}>
                  <div className="bg-whitesmoke p-4 border-round-lg mb-3">
                    {teacherProfile?.schools?.map((item, i) => {
                      return (
                        <>
                          <p className="text-xs"></p>
                          {item.name}
                          <hr />
                        </>
                      );
                    })}
                  </div>
                </TabPanel>
              </TabView>
            </div>
            <div className="md:col-12">
              <div className="bg-whitesmoke p-5 border-round-lg mb-3">
                <h5 className="text-lg font-bold text-900">
                  Attendance Preferences
                </h5>
                <small className="font-semibold text-900 text-xs">
                  Overdue Attendance
                </small>
                <CheckboxComponent
                  label="Show overdue attendance on homepage"
                  extraClassName="mt-2"
                  name="show_overdue"
                  checked={profile?.show_overdue}
                  onChange={()=>handleCheck("show_overdue")}
                />
              </div>
              {/* <div className="bg-whitesmoke p-5 border-round-lg mb-3">
                <h5 className="text-lg font-bold text-900">
                  Email Notification Preferences
                </h5>
                <small className="font-semibold text-gray text-xs">
                  Email Notification Preferences
                </small>
                <CheckboxComponent
                  label="Show overdue attendance on homepage"
                  extraClassName="mt-2"
                />
              </div> */}
            </div>
          </div>

          <ButtonComponent
            type="button"
            label="Save"
            labelClassName="open-sans font-semibold  text-base"
            className="bg-main p-2 border-round border-0 px-5
                             open-sans font-semibold  text-base"
            onClick={() => {
              dispatch(TeacherSettings(profile, history));
            }}
          />
        </>
      </>
    </div>
  );
};
