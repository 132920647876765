import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
    sendEmailApi,
    sendOtpApi,
    parentSendOtpApi,
    teacherSendOtpApi,
    teacherSendEmailApi,
    parentSendEmailApi,
} from "../redux/actions/loginAction";
import { useRouteMatch } from "react-router-dom";

const VerifyOTP = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    var base64 = require("base-64");
    const match = useRouteMatch();

    const params = useParams();
    const { encodedEmail } = params;
    const history = useHistory();
    const dispatch = useDispatch();
    const email = base64.decode(encodedEmail);
    const ResendOtp = () => {
        var encodedEmail = base64.encode(email);

        if (match.path === "/student-verify-otp/:encodedEmail") {
            dispatch(sendEmailApi(email, history));
        } else if (match.path === "/parent-verify-otp/:encodedEmail") {
            dispatch(
                parentSendEmailApi(email, () => {
                    history.push(`/parent-verify-otp/${encodedEmail}`);
                })
            );
        } else {
            dispatch(teacherSendEmailApi(email, history));
        }
    };
    const [otp, setOtp] = useState({
        studentOtp: "",
        teacherOtp: "",
        parentOtp: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOtp({ ...otp, [name]: value });
    };

    const handleVerifyOpt = (e) => {
        e.preventDefault();
        if (match.path === "/student-verify-otp/:encodedEmail") {
            dispatch(sendOtpApi(otp?.studentOtp, email, history));
        } else if (match.path === "/parent-verify-otp/:encodedEmail") {
            dispatch(
                parentSendOtpApi(otp?.parentOtp, email, () => {
                    history.push(`/parent-reset-password/${encodedEmail}`);
                })
            );
        } else {
            dispatch(teacherSendOtpApi(otp?.teacherOtp, email, history));
        }
    };

    return {
        otp,
        handleChange,
        handleVerifyOpt,
        ResendOtp,
        email,
        match,
    };
};

export default VerifyOTP;
