import React, { useState, useEffect } from "react";
import FormImage from "../../../../components/Fields/FormImage";
import InputComponent from "../../../../components/Fields/InputField";
import DropDownComponent from "../../../../components/Fields/CustomDropdown";
import CustomCalendar from "../../../../components/Fields/CustomCalendar";
import { useDispatch, useSelector } from "react-redux";
import {
  useParams,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { ViewStudentAction } from "../../../../redux/actions/ParentPortal/parentAction";
import { adjustTime } from "../../../../utils/commonFunctions";
import { genderOptions, skillLevel } from "../../../../utils/constants";
import ButtonComponent from "../../../../components/Fields/Button/CustomButton";
import Index from "../../layout";
import { editStudentAction } from "../../../../redux/actions/ParentPortal/studentAction";

const StudentDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  useEffect(() => {
    dispatch(ViewStudentAction(id));
  }, [id, dispatch]);
  const studentDetail = useSelector((state) => state?.parent?.viewStudent);
  const [student, setStudent] = useState({
    first_name: "",
    last_name: "",
    picture: "",
    student_since: "",
    skype_username: "",
    face_time_id: "",
    skill_level: "",
    gender: "",
    dob: "",
    registration_date: "",
    student_type: "",
    is_picture_delete: 0,
  });

  useEffect(() => {
    if (studentDetail) {
      setStudent({
        first_name: studentDetail?.first_name,
        last_name: studentDetail?.last_name,
        student_since: studentDetail?.student_since
          ? new Date(studentDetail?.student_since)
          : "",
        skill_level: studentDetail?.skill_level,
        gender: studentDetail?.gender ?? "",
        registration_date: studentDetail?.registration_date
          ? new Date(studentDetail?.registration_date)
          : "",
        skype_username: studentDetail?.skype_username ?? "",
        face_time_id: studentDetail?.face_time_id ?? "",
        dob: studentDetail?.dob ? new Date(studentDetail?.dob) : "",
        student_type: studentDetail?.student_type,
        picture: studentDetail?.picture,
        is_picture_delete: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentDetail]);
  const [err, setErr] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "picture") {
      setStudent((prev) => ({
        ...prev,
        picture: e.target.files[0],
        is_picture_delete: 0,
      }));
      setErr();
    } else {
      setStudent({ ...student, [name]: value });
    }
    setErr({ ...err, [name]: "" });
  };
  const handleDeleteImage = (e) => {
    e.preventDefault();
    setStudent((prev) => ({
      ...student,
      picture: null,
      is_picture_delete: 1,
    }));
  };
  const handleChange1 = (e) => {
    const { name } = e.target;

    if (name === "student_type") {
      setStudent({ ...student, student_type: e.value });
      if (e.value === "child") {
        setStudent({
          ...student,
          student_type: e.value,
          email: "",
        });
      }
    } else if (name === "lesson_type" && e.value === "school_lessons") {
      setStudent({
        ...student,
        lessonProgram: [],
        instrument_on_purchase_or_rent: "",
        instrument_id: null,
        day: null,
        time: null,
        lesson_type: e.value,
      });
    } else if (name === "lesson_type" && e.value === "online_lessons") {
      setStudent({
        ...student,
        schoo: [],
        grade: "",
        instruments: [],
        class_type: "",
        program_id: "",
        lesson_type: e.value,
      });
    } else if (name === "school_id") {
      setStudent({
        ...student,
        grade: "",
        instrument_id: null,
        class_type: "",
        school_id: e.value,
        aftercare_picked_up_time: null,
        prefer_drop_picked_up_for_class: "",
      });
    } else if (name === "grade") {
      setStudent({
        ...student,
        grade: e.value,
        aftercare_picked_up_time: null,
        prefer_drop_picked_up_for_class: "",
        class_type: "",
        program_id: "",
      });
    } else if (name === "time" || name === "aftercare_picked_up_time") {
      let time = adjustTime(e);
      setStudent((prev) => ({
        ...prev,
        [name]: time,
      }));
    } else if (name === "class_type") {
      setStudent({
        ...student,
        aftercare_picked_up_time: null,
        prefer_drop_picked_up_for_class: "",
        class_type: e.value,
      });
    } else {
      setStudent({ ...student, [name]: e.value });
    }
    setErr({ ...err, [name]: "" });
  };


  return (
    <>
      <Index>
        <div className="p-4 detail_tabs ">
          <div className="grid">
            <div className="md:col-12 flex">
              <FormImage
                name="picture"
                image={student?.picture}
                handleDeleteImage={handleDeleteImage}
                onChange={handleChange}
              />
              <div className="lg:w-9 xl:w-10 mt-1 ml-3">
                <div className="grid align-items-end">
                  <div className="md:col-12 ">
                    <div className="flex align-items-center mb-3">
                      <p className="font-semibold">
                        Student Type : &nbsp; {student?.student_type}
                      </p>
                    </div>
                  </div>
                  <InputComponent
                    label="Student First Name"
                    name="first_name"
                    extraClassName="md:col-6"
                    placeholder="Enter your first name"
                    required
                    value={student?.first_name}
                    onChange={handleChange}
                    errorMessage={err?.first_name}
                  />
                  <InputComponent
                    label="Student Last Name"
                    extraClassName="md:col-6"
                    placeholder="Enter your last name"
                    required
                    name="last_name"
                    onChange={handleChange}
                    value={student?.last_name}
                    errorMessage={err?.last_name}
                  />
                  <DropDownComponent
                    label="Skill Level"
                    name="skill_level"
                    extraClassName="md:col-6"
                    value={student?.skill_level}
                    options={skillLevel}
                    onChange={handleChange1}
                    optionLabel="name"
                    required
                    errorMessage={err?.skill_level}
                  />
                  <CustomCalendar
                    label="Birthday"
                    size="md:col-6"
                    labelclassName="ml-6 px-1 label-top"
                    placeholder="Birthday"
                    showIcon
                    iconPos="left"
                    name="dob"
                    maxDate={new Date()}
                    value={student?.dob}
                    onChange={handleChange1}
                  />
                </div>
              </div>
            </div>
            <div className="md:col-12">
              <ButtonComponent
                label="Save"
                onClick={() =>
                  dispatch(editStudentAction(id, student, history))
                }
              />
              <ButtonComponent
                label="Cancel"
                className="surface-300 border-300 ml-2"
                onClick={() => history.goBack()}
              />
            </div>
          </div>
        </div>
      </Index>
    </>
  );
};
export default StudentDetail;
