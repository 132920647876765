import { Carousel } from "primereact/carousel";
import ReviewImg from "../../assets/images/user.png";
import StarImg from "../../assets/icons/star-yellow.png";
import StarGrayImg from "../../assets/icons/star-gray.png";

const ReviewCarousel = () => {
  let ReviewsData = [
    {
      image: ReviewImg,
      reviewStar: [StarImg, StarImg, StarImg, StarImg, StarGrayImg],
      // grayStar: StarGrayImg,
      description:
        "“ As an artist, bring able to produce my own music and develop my own sound gives me greater creative control. This music course has given me the knowledge and confidence to begin building my own productions. It has given me valuable tips that I believe many seasoned Logic producers would not be aware of!”",
    },
    {
      image: ReviewImg,
      reviewStar: [StarImg, StarImg, StarImg, StarGrayImg, StarGrayImg],
      grayStar: StarGrayImg,
      description:
        "“ As someone with a teaching background, I was impressed with the course structure and how well it was planned. The tutors really care about giving you as much information as they can, generously imparting tricks & tips, and sharing their experience and knowledge.”",
    },
    {
      image: ReviewImg,
      reviewStar: [StarImg, StarImg, StarImg, StarImg, StarImg],
      grayStar: StarGrayImg,
      description:
        "“ I learned so much and find now that I work in a kind of optimized way. I save so much time when I work on music by getting what I want more quickly. I know now just what to do to improve a song with production. There's so much more scope now, so many more options than I had before.”",
    },
    {
      image: ReviewImg,
      reviewStar: [StarImg, StarImg, StarImg, StarImg, StarImg],
      grayStar: StarGrayImg,
      description:
        "“ I learned so much and find now that I work in a kind of optimized way. I save so much time when I work on music by getting what I want more quickly. I know now just what to do to improve a song with production. There's so much more scope now, so many more options than I had before.”",
    },
  ];

  const responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 2,
      numScroll: 1
  },
  {
      breakpoint: '991px',
      numVisible: 2,
      numScroll: 1
  },
  {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1
  }
    
  ];

  const CarouselTemplate = (ReviewsData, index) => {
    return (
      <div className=" p-5" key={index}>
       
        <div className="reviews-para relative mb-3">
          <p className="open-sans text-900">{ReviewsData.description}</p>
        </div>
        <div className="flex align-items-center">
          <div className="avatar_client rounded-circle">
            <img
              src={ReviewsData.image}
              alt=""
              className="w-full h-full cover-img rounded-circle"
            />
          </div>
          <div className="client-detail ml-2">
            <p className="text-sm open-sans font-bold">Jorden</p>
            {ReviewsData.reviewStar?.map((item, i) => {
              return <img src={item} key={i} width={15} alt="" />;
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-8">
      <h2 className="text-main text-4xl text-center font-semibold">
        Client Review
      </h2>
      <Carousel
        value={ReviewsData}
        numVisible={3}
        numScroll={1}
        responsiveOptions={responsiveOptions}
        itemTemplate={CarouselTemplate}
      />
    </div>
  );
};

export default ReviewCarousel;
