import React from "react";
import { Menubar } from "primereact/menubar";
import Navitems from "./navabr";
export default function Navbar({ visible, setVisible, handleEnroll }) {
    const { items, start } = Navitems(visible, setVisible, handleEnroll);
    return (
        <>
            <div className="px-7 shadow-2 main-menu">
                <Menubar model={items} className="flex justify-content-between border-0 bg-transparent" start={start} />
            </div>
        </>
    );
}
