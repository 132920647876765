import React from "react";
import SideBar from "./SideBar";
// import TopBar from "./TopBar";
import layoutData from "./Layout";
import SidebarIcons from "./SidebarIcons";
import TopBar from "./TopBar";

export default function Index({ children }) {
    const { isFullSidebar, setIsFullSidebar } = layoutData();
    return (
        <div>
            <TopBar isFullSidebar={isFullSidebar} toggleSidebar={setIsFullSidebar} />
            <div className="grid m-0 relative top-0 overflow-hidden">
                <div className="sidebar-container p-0 relative" style={{ width: isFullSidebar ? "15%" : "5%" }}>
                    {isFullSidebar ? <SideBar /> : <SidebarIcons />}
                </div>
                <div id="main-content" className="main-content p-0 " style={{ width: isFullSidebar ? "85%" : "95%" }}>
                    {children}
                </div>
            </div>
        </div>
    );
}
