import types from "../../types";
const initialBoard = {
  getNews: [],
  
};

const newsReducer = (state = initialBoard, action) => {
  switch (action.type) {
    case types.GET_PARENT_NEWS:
      return { ...state, getNews: action.payload };
    default:
      return { ...state };
  }
};

export default newsReducer;