import types from "../types";

export const Enrollmentlessons = (data) => ({
    type: types.UPDATE_ENROLLMENT_DATA,
    payload: data,
});
export const updateStudentForm = (index, data) => {
    return {
        type: types.UPDATE_STUDENT_FORM,
        payload: {
            index,
            data,
        },
    };
};
export const updateParentForm = (data) => {
    return {
        type: types.UPDATE_PARENT_FORM,
        payload: data,
    };
};

export const replaceKeys = (index, keys) => {
    return {
        type: types.REPLACE_KEYS,
        payload: {
            index,
            keys,
        },
    };
};

export const resetFields = () => ({
    type: types.RESET_STUDENT_FIELDS,
});
export const updateSchoolFormsAction = (schoolForms) => ({
    type: types.UPDATE_SCHOOL_FORMS,
    payload: { schoolForms },
});
export const addSchoolFormsFieldsAction = (index, schooldata) => ({
    type: types.INITIALIZE_SCHOOL_FIELDS,
    payload: { index, schooldata },
});
export const addAnotherSchoolFormAction = (index) => ({
    type: types.ADD_ANOTHER_SCHOOL_FORM,
});
export const setAdmissionFields = (fields) => ({
    type: types.SET_ADMISSION_FIELDS,
    payload: fields,
});

export const updateAdmissionField = (index, fieldName, value, formErrors) => ({
    type: types.UPDATE_ADMISSION_FIELD,
    payload: { index, fieldName, value, formErrors },
});

export const updateExtraFields = (index, name, value, item, extraFields) => ({
    type: types.UPDATE_EXTRA_FIELDS,
    payload: { index, name, value, item, extraFields },
});

export const setExtraFields = (fields) => ({
    type: types.SET_EXTRA_FIELDS,
    payload: fields,
});

export const admissionFormAction = (form) => ({
    type: types.SET_ADMISSION_FORM,
    payload: form,
});
export const resetAdmissionFieldsAtIndex = (index) => ({
    type: types.RESET_ADMISSION_FIELDS,
    payload: index,
});
export const removeStudentAction = (indexToRemove) => ({
    type: types.REMOVE_STUDENT,
    payload: indexToRemove,
});
