import React from "react";
import MDI_PLAY from "../../../../assets/icons/mdi_play.png";
import { ConfirmPopup } from "primereact/confirmpopup";
// import { deletePracticeLogs } from "../../../../redux/actions/StudentPortal/practiceLog";

export default function VideoCard({ item, videoUrl }) {
  // const dispatch = useDispatch();
  // const confirm1 = (event) => {
  //   confirmPopup({
  //     target: event.currentTarget,
  //     message: "Are you sure you want to delete?",
  //     icon: "pi pi-exclamation-triangle",
  //     accept: () => {
  //       dispatch(deletePracticeLogs(item?.id));
  //     },
  //     reject: () => { },
  //   });
  // };
  return (
    <>
      <ConfirmPopup />
      <div className=" col-4 p-2">
        <div className="card video-card">
          {/* <img src={videoUrl} alt="" /> */}
          <video src={videoUrl}></video>
          <img
            className="playIcon cursor-pointer"
            src={MDI_PLAY}
            onClick={() => window.open(videoUrl)}
            alt=""
            width="15%"
          />
          {/* <img
            className="deleteVideo cursor-pointer"
            src={Delete_Video}
            alt=""
            width="10%"
            onClick={confirm1}
          /> */}
        </div>
      </div>
    </>
  );
}
