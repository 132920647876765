import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo/logo.png";
import MusicLyric from "../assets/icons/music-lyric.png";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoginDialog } from "../pages/Home/loginDialog";
import { replaceKeys, updateStudentForm } from "../redux/actions/newEnrollmentAction";
import { emptyState, emptyState1, onlineEmptyState, onlineEmptyState1 } from "./constants1";

export default function Enroll1() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [selectUserType, setSelectUserType] = useState("STUDENT");

    const handleContinue = (type) => {
        const keys = emptyState;
        const keys1 = onlineEmptyState;
        let enrollKeys = {};

        enrollKeys = {};

        if (type === "school_lessons") {
            enrollKeys = keys;
        } else if (type === "online_lessons") {
            enrollKeys = keys1;
        }
        dispatch(replaceKeys(0, enrollKeys));
        dispatch(updateStudentForm(0, { enroll_type: type }));
        history.push(`/select/${type}`);
    };

    return (
        <div>
            <div className="px-2 md:px-7 shadow-2 ">
                <div className="p-2 flex justify-content-between align-items-center">
                    <div className="logo">
                        <Link to="/">
                            <img src={Logo} alt="" width="200" className="mr-2"></img>
                        </Link>
                    </div>
                    <div className="p-menuitem-link" onClick={() => setVisible(true)}>
                        <button
                            className="p-2 px-3 ml-2 bg-dark text-white border-none text-sm border-round-lg cursor-pointer open-sans flex align-items-center flex-column justify-content-center"
                            style={{ width: "8rem", height: "40px" }}
                        >
                            Login
                        </button>
                    </div>
                </div>
            </div>
            <LoginDialog selectUserType={selectUserType} setSelectUserType={setSelectUserType} visible={visible} setVisible={setVisible} />
            <div className="banner login-banner" style={{ height: "94vh" }}>
                <div className="grid  m-0 h-auto md:h-full align-items-center">
                    <div className="left-banner enroll lg:col-6 md:col-12 col-12 relative  h-full vertical-center "></div>
                    <div className=" lg:col-6 p-2 md:p-5 md:col-12 col-12 p-0 h-auto md:h-100 relative">
                        <img src={MusicLyric} alt="" className="absolute opacity-20 w-5" style={{ right: "2rem", top: "-2rem", zIndex: "-1" }} />
                        <div className=" relative flex h-auto md:h-full align-items-center justify-content-center">
                            <div className="shadow-1 p-3 py-6 border-round-lg w-full md:w-9 relative z-3 bg-white">
                                <h1 className="font-bold mb-3 text-2xl">
                                    Enrollment information for Lessons <span className="text-main">At</span> Your School or Online
                                </h1>

                                <div className="no-underline text-color">
                                    <div
                                        className={`mb-3 border-set border-round-lg enrollBtn cursor-pointer`}
                                        onClick={() => {
                                            handleContinue("school_lessons");
                                        }}
                                    >
                                        <p className="text-color font-semibold text-center p-4 open-sans no-underline">Enrollment Info - at School Lessons</p>
                                    </div>
                                </div>
                                <div className="no-underline text-color">
                                    <div
                                        className={`mb-3 border-set border-round-lg enrollBtn cursor-pointer `}
                                        onClick={() => {
                                            handleContinue("online_lessons");
                                        }}
                                    >
                                        <p className="text-color font-semibold text-center p-4 open-sans no-underline">
                                            Enrollment info - Private or Online Lesson
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
