import React from 'react'

const CardHeader = ({ icon, heading }) => {
    return (
        <>
            <h2 className="flex align-items-center">
                <img src={icon} alt="" width={30} height={30} />
                &nbsp; &nbsp;
                <span className=" flex text-main text-2xl open-sans font-semibold">
                    {heading}
                </span>
            </h2>
        </>
    )
}

export default CardHeader
