import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { sendEmailApi, parentSendEmailApi, teacherSendEmailApi } from "../redux/actions/loginAction";
import { useRouteMatch } from "react-router-dom";
var base64 = require("base-64");

const SendOTP = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch();

    const [email, setEmail] = useState({
        studentEmail: "",
        parentEmail: "",
        teacherEmail: "",
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmail({ ...email, [name]: value });
    };

    const handleSendOpt = (e) => {
        e.preventDefault();
        if (match.url === "/student-forgot-password") {
            dispatch(sendEmailApi(email?.studentEmail, history));
        } else if (match.url === "/parent-forgot-password") {
            var encodedEmail = base64.encode(email?.parentEmail);
            dispatch(
                parentSendEmailApi(email?.parentEmail, () => {
                    history.push(`/parent-verify-otp/${encodedEmail}`);
                })
            );
        } else if (match.url === "/teacher-forgot-password") {
            dispatch(teacherSendEmailApi(email?.teacherEmail, history));
        }
    };

    return {
        email,
        handleChange,
        handleSendOpt,
        match,
    };
};

export default SendOTP;
