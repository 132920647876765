import React from "react";
import Logo from "../../assets/logo/logo.png";
import MusicLyric from "../../assets/icons/music-lyric.png";
import { InputText } from "primereact/inputtext";
import VerifyOTP from "../../containers/VerifyOTP";

export default function SendCode() {
    const { otp, handleChange, handleVerifyOpt, ResendOtp, email, match } = VerifyOTP();

    return (
        <>
            <div className="px-8 shadow-2 flex justify-content-between align-items-center">
                <div className="logo">
                    <img src={Logo} alt="" width="200" className="mr-2"></img>
                </div>
            </div>
            <div className="banner login-banner" style={{ height: "94vh" }}>
                <div className="grid  m-0 h-full align-items-center">
                    <div className="left-banner login lg:col-6 md:col-12 sm:col-12 relative  h-full vertical-center ">
                        <div className="relative">
                            <div className="spacer sm-hidden md-block"></div>
                            <h1 className="heading mb-3 z-2 font-semibold text-white">
                                <span className="text-white-border">Music </span>
                                <br />
                                is life
                                <br />
                                <span className="text-white-border"> Changing</span>
                            </h1>
                        </div>
                    </div>
                    <div className=" flex align-items-center lg:col-6 p-5 md:col-12 p-0 h-100 relative">
                        <img
                            src={MusicLyric}
                            alt=""
                            className="absolute opacity-20 w-5"
                            style={{ right: "1rem", top: "3rem", zIndex: "-1" }}
                        />
                        <div className="relative w-full ml-5">
                            <div className="shadow-1 p-5 mt-5 py-5 border-round-lg w-9 relative z-3 bg-white">
                                <h3 className="text-2xl mb-3 font-bold">
                                    We send a code to
                                    <span className="text-main"> your email</span>
                                </h3>
                                <p className="font-medium mb-3 open-sans">
                                    Enter the 6-digit verification code sent to<br></br>
                                    <span className="text-main font-bold">{email}</span>{" "}
                                </p>

                                <form action="">
                                    <div className="mb-2">
                                        {match.path === "/student-verify-otp/:encodedEmail" ? (
                                            <InputText
                                                className="w-full"
                                                name="studentOtp"
                                                onChange={handleChange}
                                                value={otp?.studentOtp}
                                            />
                                        ) : match.path === "/parent-verify-otp/:encodedEmail" ? (
                                            <InputText
                                                className="w-full"
                                                name="parentOtp"
                                                onChange={handleChange}
                                                value={otp?.parentOtp}
                                            />
                                        ) : (
                                            <InputText
                                                className="w-full"
                                                name="teacherOtp"
                                                onChange={handleChange}
                                                value={otp?.teacherOtp}
                                            />
                                        )}
                                    </div>

                                    <p className="text-main font-semibold mb-3 open-sans" onClick={ResendOtp}>
                                        Resend code
                                    </p>

                                    <button
                                        className="bg-dark w-full p-3 mb-3 border-none text-white font-semibold border-round-lg open-sans"
                                        onClick={(e) => handleVerifyOpt(e)}
                                    >
                                        Verify OTP
                                    </button>

                                    <div className="">
                                        <p className="open-sans">
                                            If you don’t see the email in your inbox, check your spam folder.
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
