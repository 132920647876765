import React, { useEffect, useRef, useState } from "react";
import TopBar from "../shared/Navbar/TopBar";
import { Card } from "primereact/card";
import { useDispatch, useSelector } from "react-redux";
import { getSchoolAction, getSchoolGradeInstrumentAction, getSchoolGradeInstrumentProgramsAction } from "../redux/actions/schoolAction";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ButtonComponent from "../components/Fields/Button/CustomButton";
// import MoreInfo from "./MoreInfo";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MoreInfo1 from "./MoreInfo1.jsx";
import { updateStudentForm } from "../redux/actions/newEnrollmentAction.js";
import { CustomDropdownComponent } from "./AllInputs.js";
import { getImageURL } from "../utils/javascript.js";

const EnrollmentLessons = ({ initialState, index, error }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [data, setData] = useState({});

    useEffect(() => {
        setData(initialState);
    }, [initialState]);

    const [visible, setVisible] = useState("");
    const [instrument, setInstrument] = useState("");
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        dispatch(getSchoolAction());
    }, [dispatch, data?.school_id]);

    let { getSchoolGrades, getSchools, getSchoolInstruments, getSchoolInstrumentsPrograms } = useSelector((state) => state?.school);
    getSchoolGrades = getSchoolGrades
        ?.filter((item) => item.id === data?.school_id)
        ?.flatMap((item) => item.grade_instrument_id)
        ?.map((item) => ({ name: item.grade, id: item.id }));

    const handleChange = ({ name, value }) => {
        if (name === "school_id") {
            setData((prev) => ({ ...prev, [name]: value, grade1: "", instrument: "" }));
        } else if (name === "grade1") {
            setData((prev) => ({ ...prev, [name]: value, instrument: "" }));
        } else {
            setData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const emptyState = {
        student_type: "child",
        first_name: "",
        last_name: "",
        // email: "",
        phone_no: null,
        skill_level: {},
        current_school: "",
        aftercare_picked_up_time: "",
        grade: "",
        prefer_drop_picked_up_for_class: "",
        class_type: "",
        order_book: true,
        is_waiting: false,
        // instrument_on_purchase_or_rent: "",
        cost_per_book: 30,
        monthly_installment: "",
        program_id: "",
        one_time_payment: "",
        instrument_id: "",
        type: null,
        amount: null,
        instrument_needed: "yes",
    };

    useEffect(() => {
        if (data?.school_id && data?.grade1?.id) {
            dispatch(getSchoolGradeInstrumentAction(data?.school_id, data?.grade1?.id));
            const schoolName = getSchools?.find((item) => item.value === data?.school_id);
            setData((prev) => ({ ...prev, ["school_name"]: schoolName?.name }));
        }
    }, [data?.school_id, dispatch, data?.grade1?.id]);

    useEffect(() => {
        if (data?.school_id && data?.grade1?.id && data?.instrument) {
            dispatch(getSchoolGradeInstrumentProgramsAction(data?.school_id, data?.grade1?.id, data?.instrument));
        }
    }, [data?.school_id, dispatch, data?.grade1?.id, data?.instrument]);

    const handleEnroll = (programId) => {
        if (index === 0) {
            if (data?.enroll_type === "school_lessons") {
                //sukhwinder2/7/24
                if (programId) {
                    data.program_id = programId;
                }
                dispatch(updateStudentForm(index, data));
                history.push(`/student-form/school_lessons/${data?.school_id}/`);
            }
        } else {
            setVisible(false);
            dispatch(updateStudentForm(index, { ...data, ...emptyState, enrollNow: true }));
        }
    };

    const ActionTemplate = (r) => {
        return (
            <div className="flex gap-3">
                <ButtonComponent label="More Info" className="bg-dark border-none primary-btn" onClick={() => setVisible(r.id)} />
                <ButtonComponent
                    label={index === 0 ? "Enroll Now" : "Continue Enrollment"}
                    className="bg-dark border-none primary-btn"
                    onClick={() => handleEnroll(r?.id)} //sukhwinder2/7/24
                    disable={disable}
                />
            </div>
        );
    };

    const handleInstrumentClick = (value) => {
        setData((prev) => ({ ...prev, instrument: value }));
    };

    const InstrumentTemplate = (r) => {
        setInstrument(r.instrument_id);
        return <>{r.instrument_name}</>;
    };

    const programRef = useRef(null);

    const scrollToPrograms = () => {
        programRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        if (getSchoolInstrumentsPrograms) {
            scrollToPrograms();
        }
    }, [getSchoolInstrumentsPrograms]);

    return (
        <>
            {index === 0 && <TopBar />}
            {index === 0 ? (
                <div className="p-2 md:p-7 enroll-main-div">
                    <Card
                        className="border-1 shadow-1 border-300 p-2 md:p-7 md:w-10 m-auto min-h-screen md:min-h-full"
                        title={
                            <h1 className="text-4xl font-bold lora">
                                Enrollment Info for Lessons <span className="text-main">At School</span>
                            </h1>
                        }
                    >
                        <div className="grid my-3 align-items-end">
                            <CustomDropdownComponent
                                label="Select School"
                                name="school_id"
                                extraClassName="col-12 md:col-6"
                                placeholder="Select school"
                                value={data?.school_id}
                                options={getSchools}
                                onChange={handleChange}
                                optionLabel="name"
                            />
                            {data?.school_id && (
                                <CustomDropdownComponent
                                    label="Select grade (of the 1st Student)"
                                    extraClassName="col-12 md:col-6"
                                    placeholder="Select grade"
                                    name="grade1"
                                    sublabel="Other ages can be chosen on the next page"
                                    value={data?.grade1}
                                    options={getSchoolGrades}
                                    onChange={handleChange}
                                    optionLabel="name"
                                />
                            )}
                        </div>
                        {data?.grade1 && (
                            <>
                                <div className="my-3">
                                    <h2 className="text-dark font-bold text-2xl">Choose Instrument</h2>
                                    <div className="flex mt-3 flex-wrap gap-3">
                                        {getSchoolInstruments?.map((item, i) => {
                                            return (
                                                <>
                                                    <div
                                                        className={`bg-skinlight-color flex flex-column align-items-center justify-content-center instrument-box p-3 cursor-pointer ${
                                                            data?.instrument == item.value && "programme-selected"
                                                        }`}
                                                        onClick={() => {
                                                            handleInstrumentClick(item.value);
                                                        }}
                                                        key={item.value}
                                                    >
                                                        {item.icon ? <img src={item.icon ? getImageURL(item.icon) : ""} alt="" className="mb-2" /> : null}
                                                        <p className="text-sm text-dark ">{item.name}</p>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                                {data?.instrument && (
                                    <div ref={programRef}>
                                        <h2 className="text-dark font-bold text-2xl mb-3">Program Types</h2>
                                        <DataTable value={getSchoolInstrumentsPrograms} className="border-round-lg overflow-hidden text-dark">
                                            <Column body={ActionTemplate} header="Action" style={{ minWidth: "280px" }}></Column>
                                            <Column field="program_name" header="Program Name" style={{ minWidth: "280px" }}></Column>
                                            <Column field="instrument_name" body={InstrumentTemplate} header="Instrument"></Column>
                                            <Column field="grade_name" header="Grade"></Column>
                                        </DataTable>
                                    </div>
                                )}
                                <MoreInfo1
                                    visible={visible}
                                    setVisible={setVisible}
                                    instrumentId={instrument}
                                    handleEnroll={handleEnroll}
                                    btnText="Enroll now"
                                />
                            </>
                        )}
                    </Card>
                </div>
            ) : null}
        </>
    );
};

export default EnrollmentLessons;
