import React from "react";
import moment from "moment";
import { Calendar } from "primereact/calendar";

export default function CustomCalendar({ label, name, errorMessage, labelclassName, extraClassName, required, inputClass, size, showIcon, ...props }) {
    return (
        <div className={size}>
            <div className={`relative  ${extraClassName}`}>
                <label htmlFor={name} className={`absolute text-xs text-500 open-sans bg-white form-label z-1 ${labelclassName}`}>
                    {label} {required && <span className="p-error">*</span>}
                </label>
                <div className="">
                    <Calendar
                        id={name}
                        name={name}
                        {...props}
                        showWeek={false}
                        className={`w-full border-1 border-round-lg border-400 overflow-hidden ${inputClass} ${errorMessage ? "" : null}`}
                        showIcon
                    ></Calendar>
                    {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
                </div>
            </div>
        </div>
    );
}

export function CustomCalendarTime({ label, name, errorMessage, required, value, onChange, ...props }) {
    const handleChange = (e) => {
        let time = moment(e.value).format("HH:mm");
        if (onChange) {
            onChange({ target: { name, value: time } });
        }
    };
    let _value = "";

    if (value) {
        _value = new Date();
        value = value.trim();
        let [hour, min] = value.split(":");
        _value.setHours(hour, min, 0);
    }

    return (
        <div>
            <div className={`relative`}>
                <label htmlFor={name} className={`absolute text-xs text-500 open-sans bg-white form-label z-1`}>
                    {label} {required && <span className="p-error">*</span>}
                </label>
                <div className="">
                    <Calendar
                        id={name}
                        name={name}
                        showWeek={false}
                        className={`w-full border-1 border-round-lg border-400 overflow-hidden ${errorMessage ? "" : null}`}
                        showIcon
                        timeOnly
                        value={_value}
                        onChange={handleChange}
                        icon="pi pi-clock"
                        {...props}
                    ></Calendar>
                    {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
                </div>
            </div>
        </div>
    );
}
