import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import TeacherIcon from "../../../../assets/icons/student.png";
import CalendarIcon from "../../../../assets/icons/calender.png";
import ButtonComponent from "../../../../components/Fields/Button/CustomButton";
import ClockIcon from "../../../../assets/icons/clock-white.png";
import moment from "moment";
import { useHistory } from "react-router-dom";

const TeacherEvents = ({ teacherDashboard, date, setDate }) => {
    const history = useHistory();

    const getPreviousDate = () => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() - 1);
        setDate(newDate);
    };

    const getNextDate = () => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        setDate(newDate);
    };
    const dayOfWeek = date.getDay();
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    return (
        <>
            <div className="grid mb-3 border-1 border-round-lg border-300 p-3">
                <div className="md:col-11">
                    <div className="flex justify-content-between mb-4">
                        <div className="">
                            <img src={CalendarIcon} width={40} alt="" />
                            <span className="text-lg open-sans">
                                {days[dayOfWeek]} Agenda: {moment(date).format("MM-DD-YYYY")}
                            </span>
                        </div>
                        <div className="flex bg-light-main align-items-center p-2 border-round-lg">
                            <i className="pi pi-chevron-left mr-3" onClick={getPreviousDate}></i>
                            <p className="text-main font-bold open-sans">
                                {new Date().toDateString() === date.toDateString() ? "Today" : moment(date).format("MM-DD-YYYY")}
                            </p>
                            <i className="pi pi-chevron-right ml-3" onClick={getNextDate}></i>
                        </div>
                    </div>
                    <div className="grid">
                        {teacherDashboard?.events?.length > 0
                            ? teacherDashboard?.events?.map((item, i) => {
                                  return (
                                      <div className="md:col-6 p-2" key={i}>
                                          <div className="bg-light-main p-0 relative border-round-lg overflow-hidden w-full">
                                              <Accordion className="m-0 upcoming-event teacher-upcoming">
                                                  <AccordionTab
                                                      header={
                                                          <div className="h-full w-100 grid m-0">
                                                              <div className="md:col-4 bg-main">
                                                                  <p className="text-xs text-white justify-content-center font-semibold open-sans p-3 text-center flex flex-column align-items-center h-100">
                                                                      <img src={ClockIcon} width={20} className="mb-2" alt="" />
                                                                      {item.select_location !== "others" ? (
                                                                          <>
                                                                              <div className="mb-1">Flex Classes</div>
                                                                              <div>
                                                                                  {item.class_type === "after_school_program"
                                                                                      ? item.start_time
                                                                                      : ""}
                                                                              </div>
                                                                          </>
                                                                      ) : (
                                                                          item.start_time
                                                                      )}
                                                                      <br />
                                                                      {/* {item.select_location === "others" ||
                                                                      (item.class_type === "after_school_program" &&
                                                                          item.select_location !== "others")
                                                                          ? `${item.start_time}`
                                                                          : ""} */}
                                                                  </p>
                                                              </div>
                                                              <div className="md:col-8 p-0">
                                                                  <div className="p-3">
                                                                      <p className="vertical-align-middle open-sans text-dark font-semibold mb-2  headingsize">
                                                                          {item.category_name}
                                                                      </p>
                                                                      <small className="open-sans text-900 datasize">
                                                                          {item.location}
                                                                      </small>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      }
                                                      className="m-0 border-none "
                                                  >
                                                      <div className="grid m-0 h-full">
                                                          <div className="md:col-4 p-1 bg-main">
                                                              <hr className="m-0 border-white" />
                                                              <p className="flex h-100 align-items-center flex-column justify-content-center datasize text-white">
                                                                  Students
                                                              </p>
                                                          </div>
                                                          <div className="md:col-8 p-1">
                                                              <hr className="m-0 border-white" />
                                                              <div className="p-2 mb-5">
                                                                  {item?.attendees?.length > 0 &&
                                                                      item?.attendees?.map((attendee, index) => {
                                                                          return (
                                                                              <div className="flex align-items-end justify-content-between mb-2">
                                                                                  <div className="flex">
                                                                                      <img src={TeacherIcon} width={20} alt="" />
                                                                                      <p className="open-sans ml-2 headingsize">
                                                                                          {attendee.name}
                                                                                      </p>
                                                                                  </div>
                                                                              </div>
                                                                          );
                                                                      })}
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </AccordionTab>
                                              </Accordion>
                                              <ButtonComponent
                                                  label="Attendance"
                                                  className="absolute bottom-0 right-0 bg-main text-white border-0 open-sans fw-normal datasize radius-max"
                                                  onClick={() => {
                                                      history.push(`/teacher-dashboard/teacher-attendence/${item.id}`);
                                                  }}
                                              />
                                          </div>
                                      </div>
                                  );
                              })
                            : "No Event Found"}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TeacherEvents;
