import Constants from "../../../services/constant";
import { loadStateFn } from "../../../utils/localStorage";
import { api, multipartApi } from "../../../services/api";
import { showLoaderAction, hideLoaderAction } from "../loadAction";
import { showToast } from "../../../utils/showToast";
import types from "../../types";
import moment from "moment";

export const getTeacherEmailsAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("post", Constants.END_POINT.SEND_EMAIL_LIST);
    if (res.success) {
        dispatch({
            type: types.SEND_EMAIL_LIST,
            payload: res.data,
        });
    } else {
        showToast([res.message], "error");
    }
    dispatch(hideLoaderAction());
};

export const getTeacherEmailViewAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.TEACHER_VIEW_EMAIL + id, {}, loadStateFn("token"));
    if (res.success) {
        dispatch({
            type: types.TEACHER_VIEW_EMAIL,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
