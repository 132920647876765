import types from "../../types";
import Constants from "../../../services/constant";
import { showLoaderAction, hideLoaderAction } from "../loadAction";
import { api } from "../../../services/api";

export const getParentAssignmentAction = (id) => async (dispatch) => {

  dispatch(showLoaderAction());
  const url = id ? Constants.END_POINT.GET_ASSIGNMENTS + "/" + id : Constants.END_POINT.GET_ASSIGNMENTS;
  const res = await api("get", url);

  if (res.success) {
    dispatch({
      type: types.GET_ASSIGNMENTS,
      payload: res.data,
    });
  }

  dispatch(hideLoaderAction());
};

export const ViewParentAssignmentAction = (id) => async (dispatch) => {

  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.VIEW_PARENT_ASSIGNMENTS + id);

  if (res.success) {
    dispatch({
      type: types.VIEW_PARENT_ASSIGNMENTS,
      payload: res.data,
    });
  }

  dispatch(hideLoaderAction());
}
