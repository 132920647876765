import { toast } from "react-toastify";

export const showToast = (
  message = ["Something went wrong ! try again later."],
  messageType = "error"
) => {
  message.map((msg) =>
    toast(msg, {
      type: messageType,
    })
  );
};
