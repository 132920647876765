import { combineReducers } from "redux";
import loaderReducer from "./loadReducer";
import schoolReducer from "./schoolReducer";
import studentReducer from "./studentReducer";
import loginReducer from "./loginReducer";
import parentReducer from "./ParentPortal/parentReducer";
import teacherReducer from "./TeacherPortal/teacherReducer";
import contactReducer from "./ParentPortal/contactReducer";
import newsReducer from "./ParentPortal/newsReducer";
import AttendanceReducer from "./attendanceReducer";
import teachersReducer from "./TeacherPortal/teachersTabReducer";
import assignmentReducer from "./ParentPortal/assignmentReducer";
import parentattendenceAndNotesReducer from "./ParentPortal/atendenceAndNotesReducer";
import accountsReducer from "./ParentPortal/accountsandInvoicesReducer";
import calendarReducer from "./calendarReducer";
import teacherAssignmentReducer from "./TeacherPortal/teacherAssignmentReducer";
import practicelogReducer from "./StudentPortal/practiceReducer";
import teacherStudentReducer from "./TeacherPortal/studentReducer";
import ParentStudentReducer from "./ParentPortal/studentReducer";
import EventReducer from "./TeacherPortal/eventsReducer";
import EmailTemplateReducer from "./TeacherPortal/emailTemplateReducer";
import EmailTeacherReducer from "./TeacherPortal/emailReducer";
import EnrollmentReducer from "./newEnrollmentReducer";
import EnrollmentReducer1 from "./newEnrollmentReducer1";

export default combineReducers({
    loader: loaderReducer,
    school: schoolReducer,
    studentInfo: studentReducer,
    login: loginReducer,
    parent: parentReducer,
    teacher: teacherReducer,
    contact: contactReducer,
    news: newsReducer,
    events: AttendanceReducer,
    teachers: teachersReducer,
    assignment: assignmentReducer,
    attendenceNotes: parentattendenceAndNotesReducer,
    accounts: accountsReducer,
    calendar: calendarReducer,
    practice: practicelogReducer,
    teacherAssignment: teacherAssignmentReducer,
    teacherStudent: teacherStudentReducer,
    parentStudent: ParentStudentReducer,
    teacherEvents: EventReducer,
    emailTemplate: EmailTemplateReducer,
    teacherEmail: EmailTeacherReducer,
    enroll: EnrollmentReducer,
    enroll1: EnrollmentReducer1,
});
