import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Index from "../../../layout";
import Assignments from "../../../../../assets/icons/Assignments.png";
import VideoCard from "../../../../parents-dashboard/parent/PracticeLog/VideoCard";
import { useDispatch, useSelector } from "react-redux";
import { TeacherFeedbackAction, ViewTeacherAssignmentAction } from "../../../../../redux/actions/TeacherPortal/teacherAssignmentAction";
import InputComponent from "../../../../../components/Fields/InputField";
import CheckboxComponent from "../../../../../components/Fields/Checkbox";
import ButtonComponent from "../../../../../components/Fields/Button/CustomButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ViewTeacherAssignment = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory()

  useEffect(() => {
    dispatch(ViewTeacherAssignmentAction(id));
  }, []);

  const viewTeacherAssignment = useSelector(
    (state) => state?.teacherAssignment?.viewAssignment
  );

  const [data, setData] = useState({
    assignment_id: parseInt(id),
    feedback: "",
    is_parent: false,
    is_student: false
  })

  const handleCheck = (name, e) => {
    setData({ ...data, [name]: !data[name] });
  };

  const handleSend = (e) => {
    dispatch(TeacherFeedbackAction(data, history));
  }


  return (
    <Index>
      <div className="grid mb-3 p-4 m-3">
        <div className="grid">
          <div className="flex align-items-center mb-3">
            <h2 className="flex align-items-center">
              <img src={Assignments} alt="" width={30} height={30} />
            </h2>
            &nbsp; &nbsp;
            <span className=" flex text-main text-2xl open-sans font-semibold">
              Assignment
            </span>
          </div>
        </div>

        <div className="md:col-12 px-0 ">
          <div className="border-1 border-round-lg border-300 p-3">
            <h6 className="open-sans  font-semibold mb-3">Assignment</h6>
            <div className="border-1 border-round-lg border-200 mb-3 p-3">
              {viewTeacherAssignment?.practice_log_file && (
                <VideoCard
                  baseUrl={viewTeacherAssignment?.base_url}
                  item={viewTeacherAssignment}
                  videoUrl={
                    viewTeacherAssignment?.base_url +
                    viewTeacherAssignment?.practice_log_file
                  }
                />
              )}
            </div>
            <h6 className="open-sans  font-semibold mb-3 mt-2">Feedback</h6>
            <InputComponent
              name="feedback"
              inputClass="border-0"
              extraClassName="border-1 border-round-lg border-200 mb-3"
              onChange={(e) => setData({ ...data, feedback: e.target.value })}
              value={viewTeacherAssignment?.feedback}
            />

            {!viewTeacherAssignment?.feedback && <div className="border-1 border-round-lg border-200 p-3">
              <h6 className="open-sans  font-semibold mb-3 mt-2">
                Email Notes
              </h6>
              <div className="flex">
                <CheckboxComponent label="Parents" name="is_parent"
                  checked={data?.is_parent}
                  onChange={(event) => handleCheck("is_parent", event)} />
                <CheckboxComponent label="Student" extraClassName="ml-2" name="is_student" checked={data?.is_student}
                  onChange={(event) => handleCheck("is_student", event)} />
              </div>
              <ButtonComponent label="Send" className="mt-3" onClick={handleSend} />
            </div>}
          </div>
        </div>
      </div>
    </Index>
  );
};

export default ViewTeacherAssignment;
