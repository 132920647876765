import Constants from "../../services/constant";
import { loadStateFn } from "../../utils/localStorage";
import { api } from "../../services/api";
import { showLoaderAction, hideLoaderAction } from "./loadAction";
import types from "../types";
import { showToast } from "../../utils/showToast";

export const getSchoolDetail = (data) => async (dispatch) => {
    dispatch({
        type: "School_enroll",
        payload: data,
    });
};

export const getSchoolAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.GET_SCHOOLS);

    if (res.success) {
        dispatch({
            type: types.FETCH_SCHOOLS,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
export const getSchoolGradeInstrumentAction = (school_id, grade_id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("post", Constants.END_POINT.GRADE_INSTRUMENTS, { school_id, grade_id });

    if (res.success) {
        dispatch({
            type: types.FETCH_SCHOOLS_GRADE_INSTRUMENT,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const getSchoolAllClasses = (school_id, instrument_id, grade) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("post", Constants.END_POINT.SCHOOL_CLASSES, { school_id, instrument_id, grade });

    if (res.success) {
        dispatch({
            type: types.FETCH_SCHOOLS_CLASSES,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
export const getSchoolGradeInstrumentProgramsAction = (school_id, grade_id, instrument_id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("post", Constants.END_POINT.GRADE_INSTRUMENTS_PROGRAMS, { school_id, grade_id, instrument_id });

    if (res.success) {
        dispatch({
            type: types.FETCH_SCHOOLS_GRADE_INSTRUMENT_PROGRAMS,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const getMoreProgramInfo = (program_type_id, instrument_id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.MORE_PROGRAM_INFO + program_type_id + "/" + instrument_id);

    if (res.success) {
        dispatch({
            type: types.FETCH_MORE_PROGRAM_INFO,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const getOnlineLessonProgramAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.GET_ONLINE_LESSONS);

    if (res.success) {
        dispatch({
            type: types.FETCH_ONLINE_LESSONS,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
export const ViewSchoolAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.VIEW_SCHOOL + id);

    if (res.success) {
        dispatch({
            type: types.VIEW_SCHOOL,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const ViewSchoolGradesAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.VIEW_SCHOOL + id);

    if (res.success) {
        dispatch({
            type: types.VIEW_SCHOOL,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const ViewSchoolLessonsAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.VIEW_SCHOOL_LESSONS + id);

    if (res.success) {
        dispatch({
            type: types.VIEW_SCHOOL_LESSONS,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const ViewSchoolAdmissionForm = (id, setAdmissionForm) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.VIEW_SCHOOL_ADMISSION_FORM + id);

    if (res.success) {
        setAdmissionForm(res.data);
    }

    dispatch(hideLoaderAction());
};
export const ViewStudentExtraForm = (id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.VIEW_SCHOOL_ADMISSION_FORM + id);

    if (res.success) {
        dispatch({
            type: types.VIEW_STUDENT_EXTRA_FIELD,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const ViewSchoolGrades = (id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.SCHOOL_GRADES + id);

    if (res.success) {
        dispatch({
            type: types.VIEW_SCHOOL_GRADES,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
export const ViewInstrument = (id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.VIEW_INSTRUMENT + id);

    if (res.success) {
        dispatch({
            type: types.VIEW_INSTRUMENT,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};
export const ViewOnlineSchoolDetail = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.VIEW_ONLINE_LESSONS + id);

    if (res.success) {
        dispatch({
            type: types.VIEW_ONLINE_LESSON,
            payload: res.data,
        });
    }
    dispatch(hideLoaderAction());
};
export const ViewOnlineEnrollmentSchoolDetail = (online_id, instrument_id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.VIEW_ONLINE_ENROLLMENT_LESSONS + online_id + "/" + instrument_id);

    if (res.success) {
        dispatch({
            type: types.VIEW_ONLINE_ENROLLMENT_LESSONS,
            payload: res.data,
        });
    }
    dispatch(hideLoaderAction());
};

export const viewSchoolAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.VIEW_TEACHER_SCHOOL + id, {}, loadStateFn("jwt"));

    if (res.success) {
        dispatch({
            type: types.VIEW_SCHOOL,
            payload: res.data,
        });
    } else {
        dispatch({
            type: types.VIEW_SCHOOL,
            payload: null,
        });
        showToast(["Something went wrong!"], "error");
    }

    dispatch(hideLoaderAction());
};
export const getSchoolTeachers = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.VIEW_SCHOOL_TEACHERS + id);

    if (res.success) {
        dispatch({
            type: types.VIEW_SCHOOL_TEACHERS,
            payload: res.data,
        });
    }
    dispatch(hideLoaderAction());
};

export const ViewOnlineSchoolGrades = (id) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.ONLINE_LESSON_GRADES + id);
    if (res.success) {
        dispatch({
            type: types.VIEW_ONLINE_GRADES,
            payload: res.data,
        });
    }
    dispatch(hideLoaderAction());
};
