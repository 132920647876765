import React, { useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import WomenIcon from "../../../../assets/icons/womenicon.png";
import VideoCam from "../../../../assets/icons/videocamicon.png";
import CalendarIcon from "../../../../assets/icons/calendar-white.png";
import moment from "moment";
import CustomCalendar from "../../../../components/Fields/CustomCalendar";

const ParentEvents = ({ parentDashboard, date, setDate }) => {
    return (
        <div className=" border border-300 border-round-lg border border-300 border-round-lg p-3 mb-3">
            <div className="flex  align-items-center justify-content-between mb-4">
                <h3 className="open-sans text-black font-semibold mb-4 lg:mb-0">Upcoming Classes</h3>
                <div>
                    <CustomCalendar value={date} onChange={(e) => setDate(e.value)} view="month" dateFormat="mm/yy" />
                </div>
            </div>

            <div className="grid mb-3">
                {parentDashboard?.upcoming_classes?.length > 0 ? (
                    parentDashboard?.upcoming_classes?.map((item, i) => {
                        return (
                            <div className="md:col-4 p-2" key={i}>
                                <div
                                    className="bg-light-main p-0 relative border-round-lg overflow-hidden w-full"
                                    // style={{ alignItems: "stretch" }}
                                >
                                    <Accordion className="m-0 upcoming-event teacher-upcoming">
                                        <AccordionTab
                                            header={
                                                <div className="h-full w-100 grid m-0">
                                                    <div className="md:col-4 bg-main">
                                                        <p className="text-xs text-white justify-content-center font-semibold open-sans p-3 text-center flex flex-column align-items-center h-100">
                                                            <img alt="" src={CalendarIcon} width={20} className="mb-2" />
                                                            {moment(item.date).format("DD MMMM")}
                                                        </p>
                                                    </div>
                                                    <div className="md:col-8 p-0">
                                                        <div className="p-3 w-11">
                                                            <p className="vertical-align-middle open-sans text-dark font-semibold mb-2  headingsize">
                                                                {item.category_name}
                                                            </p>
                                                            <small className="open-sans  font-semibold  text-900 datasize">
                                                                {item.start_time ? item.start_time : "Rotating Schedule"}
                                                            </small>{" "}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            className="m-0 border-none "
                                        >
                                            <div className="grid m-0 h-full">
                                                <div className="md:col-4 p-1 bg-main">
                                                    <hr className="m-0 border-white" />
                                                    <p className="flex h-100 align-items-center flex-column justify-content-center datasize text-white"></p>
                                                </div>
                                                <div className="md:col-8 p-1">
                                                    <hr className="m-0 border-white" />
                                                    <div className="p-2 mb-5">
                                                        <div className="flex align-items-end justify-content-between mb-2">
                                                            <div className="flex">
                                                                <img src={WomenIcon} alt="" width={20} />
                                                                <p className="open-sans ml-2 headingsize">{item.teacher}</p>
                                                            </div>
                                                        </div>
                                                        {item?.students?.length &&
                                                            item?.students.flatMap((stud) => (
                                                                <div className="flex align-items-end justify-content-between mb-2">
                                                                    <div className="flex">
                                                                        <img src={VideoCam} alt="" width={20} />
                                                                        <p className="open-sans ml-2 headingsize">{stud?.name}</p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionTab>
                                    </Accordion>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className=" col-12  border border-300 border-round-lg h-8rem flex flex-column justify-content-center align-item-center mb-3">
                        <h5 className="text-400 open-sans font-semibold text-center m-0">No Upcoming Classes for this month</h5>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ParentEvents;
