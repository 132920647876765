import { equal, length } from "./javascript";

import { firstLetterToUppercase, stringValidation, emailValidation, number, removeUnderScore } from "./regex";

export const allValidations = (name, value, state, ignore = []) => {
    let formErrors = { ...state.formErrors };
    if (ignore.includes(name)) {
        if (formErrors[name]) {
            formErrors[name] = "";
        }
        return formErrors;
    }

    switch (name) {
        case "first_name":
        case "enroll_type":
        case "last_name":
        case "age":
        case "phone_number":
        case "time":
            if (equal(length(value))) {
                formErrors[name] = `${removeUnderScore(name)} is required`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "program_id":
            if (equal(length(value))) {
                formErrors.program_id = state.enroll_type === "school_lessons" ? `Click on your class` : "Program is required";
            } else {
                formErrors.program_id = "";
                formErrors.class_type = "";
            }
            break;

        case "enrollInstrument":
            if (equal(length(value))) {
                formErrors[name] = `Instrument is required`;
            } else {
                formErrors[name] = "";
            }
            break;

        // case "type":
        //   if (value.some((item) => item.type === "" || item.type === null)) {
        //     formErrors[name] = `Type is required`;
        //   } else {
        //     formErrors[name] = "";
        //   }
        //   break;
        case "time":
            if (!value) {
                formErrors[name] = `Time is required`;
            } else {
                formErrors[name] = "";
            }
            break;

        case "email":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required`;
            } else if (!emailValidation(value)) {
                formErrors[name] = `Please enter valid email`;
            } else {
                formErrors[name] = "";
            }
            break;

        case "code":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required`;
            } else if (!stringValidation(value)) {
                formErrors[name] = `Unnecessary space or special chracter in word`;
            } else {
                formErrors[name] = "";
            }
            break;

        case "skill_level":
            if (!value.length) {
                formErrors[name] = `Skill Level is required`;
            } else {
                formErrors[name] = "";
            }
            break;

        case "day":
            if (!value.length) {
                formErrors[name] = `Day is required`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "school_lessons":
            if (!value.length) {
                formErrors[name] = `School Lesson is required`;
            } else {
                formErrors[name] = "";
            }
            break;

        case "grade1":
            if (!value) {
                formErrors[name] = `Grade  is required`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "school_id":
            if (!value) {
                formErrors[name] = `School  is required`;
            } else {
                formErrors[name] = "";
            }
            break;
        // case "instrument_id":
        //   if (!value) {
        //     formErrors[name] = `Instrument is required`;
        //   } else {
        //     formErrors[name] = "";
        //   }
        //   break;
        // case "type":
        //   if (!value) {
        //     formErrors[name] = `Purchase Type is required`;
        //   } else {
        //     formErrors[name] = "";
        //   }
        //   break;
        case "class_type":
            if (!value) {
                formErrors[name] = `Class Type is required`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "aftercare_picked_up_time":
            if (!value) {
                formErrors[name] = `Pickup Time is required`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "prefer_drop_picked_up_for_class":
            if (!value) {
                formErrors[name] = `Option required`;
            } else {
                formErrors[name] = "";
            }
            break;

        default:
            break;
    }

    return formErrors;
};
