import React, { useEffect } from 'react'
import FormImage from '../../../../components/Fields/FormImage';
import Index from '../../layout';
import { Card } from "primereact/card";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ViewStudentAction } from '../../../../redux/actions/ParentPortal/parentAction';
import moment from "moment";
import UserProfile from "../../../../assets/images/ProfilePictureViewer.png";
import { getDays } from '../../../../utils/commonFunctions';
import ChevronLeft from "../../../../assets/icons/chevronleft.png";
import Student from "../../../../assets/icons/student.png";
import { firstLetterToUppercase } from '../../../../utils/regex';

const ViewStudent = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  useEffect(() => {
    dispatch(ViewStudentAction(id));
  }, [id, dispatch]);
  const studentDetail = useSelector((state) => state?.parent?.viewStudent);

  return (
    <>
      <Index>
        <div className="p-4">
          <div className="grid mb-3">
            <h2 className="text-main md:col-4 text-2xl open-sans flex align-items-center font-semibold">
              <img src={ChevronLeft} alt="" width={10} onClick={() => history.goBack(-1)} />
              <img src={Student} alt="" width={30} className="ml-2" />
              &nbsp; Student Detail
            </h2>
          </div>
          <Card className="px-4 py-2">
            <div className="grid">
              <FormImage
                name="picture"
                view={true}
                image={false ? UserProfile : studentDetail?.picture}
              />
              <div className="md:col-9">
                <p className="text-main text-lg font-semibold mb-3">
                  Student Details
                </p>
                <div className="grid">
                  <div className="md:col-6 grid">
                    <div className="md:col-6 text-900 font-semibold">
                      Student Type
                    </div>
                    <div className="md:col-6">{studentDetail?.student_type}</div>
                  </div>
                  <div className="md:col-6 grid">
                    <div className="md:col-6 text-900 font-semibold">
                      Student First Name
                    </div>
                    <div className="md:col-6">{studentDetail?.first_name}</div>
                  </div>
                  <div className="md:col-6 grid">
                    <div className="md:col-6 text-900 font-semibold">
                      Student Last Name
                    </div>
                    <div className="md:col-6">{studentDetail?.last_name}</div>
                  </div>
                  <div className="md:col-6 grid">
                    <div className="md:col-6 text-900 font-semibold">
                      Skill Level
                    </div>
                    <div className="md:col-6">{studentDetail?.skill_level}</div>
                  </div>
                  <div className="md:col-6 grid">
                    <div className="md:col-6 text-900 font-semibold">
                      Registration Date
                    </div>
                    <div className="md:col-6">{studentDetail?.registration_date ? moment(studentDetail?.registration_date).format("MMMM/DD/YYYY") : "-----------------"}</div>
                  </div>
                </div>
                <p className="text-main text-lg font-semibold mb-3">
                  Unused Details
                </p>
                <div className="grid">
                  {/* <div className="md:col-6 grid">
                  <div className="md:col-6 text-900 font-semibold">
                    Skype Username
                  </div>
                  <div className="md:col-6">{studentDetail?.skype_username ?? "-----------------"}</div>
                </div> */}
                  {/* <div className="md:col-6 grid">
                  <div className="md:col-6 text-900 font-semibold">
                    Face Time id
                  </div>
                  <div className="md:col-6">{studentDetail?.face_time_id ?? "-----------------"}</div>
                </div> */}
                  {/* <div className="md:col-6 grid">
                  <div className="md:col-6 text-900 font-semibold">Gender</div>
                  <div className="md:col-6">{studentDetail?.gender ?? "--------------"}</div>
                </div> */}
                  <div className="md:col-6 grid">
                    <div className="md:col-6 text-900 font-semibold">
                      Birthday
                    </div>
                    <div className="md:col-6">{studentDetail?.dob ? moment(studentDetail?.dob).format("MMMM/DD/YYYY") : "-----------------"}</div>
                  </div>
                  <div className="md:col-6 grid">
                    <div className="md:col-6 text-900 font-semibold">
                      Student Since
                    </div>
                    <div className="md:col-6">{studentDetail?.student_since ? moment(studentDetail?.student_since).format("MMMM/DD/YYYY") : "-----------------"}</div>
                  </div>
                </div>
                <p className="text-main text-lg font-semibold mb-3">
                  Location Details
                </p>
                {studentDetail?.enroll_type === "school_lessons" && <div className="grid">
                  <div className="md:col-6 grid">
                    <div className="md:col-6 text-900 font-semibold">
                      Location
                    </div>
                    <div className="md:col-6">{studentDetail?.enroll_type === "school_lessons" ? "School" : "Online"}</div>
                  </div>
                  <div className="md:col-6 grid">
                    <div className="md:col-6 text-900 font-semibold">
                      School Name
                    </div>
                    <div className="md:col-6">
                      {studentDetail?.school?.name}
                    </div>
                  </div>
                  <div className="md:col-6 grid">
                    <div className="md:col-6 text-900 font-semibold">Grade</div>
                    <div className="md:col-6">{studentDetail?.school?.grade}</div>
                  </div>
                  <div className="md:col-6 grid">
                    <div className="md:col-6 text-900 font-semibold">
                      Class type
                    </div>
                    <div className="md:col-6">
                      {studentDetail?.school?.class_type === "after_school"
                        ? "Set Class Time"
                        : studentDetail?.school?.class_type === "during_school"
                          ? "Flex Class"
                          : studentDetail?.school?.class_type === "semi_private_during_school"
                            ? "Flex Class"
                            : "Set Class Time"
                      } ({studentDetail?.school?.program_name})</div>
                  </div>
                </div>}
                {studentDetail?.enroll_type === "online_lessons" && <div className="grid">
                  <div className="md:col-6 grid">
                    <div className="md:col-6 text-900 font-semibold">
                      Location
                    </div>
                    <div className="md:col-6">{studentDetail?.enroll_type === "school_lessons" ? "School" : "Online"}</div>
                  </div>
                  <div className="md:col-6 grid">
                    <div className="md:col-6 text-900 font-semibold">
                      Lesson Program
                    </div>
                    <div className="md:col-6">
                      {studentDetail?.program_id?.name}
                    </div>
                  </div>
                  <div className="md:col-6 grid">
                    <div className="md:col-6 text-900 font-semibold">Day</div>
                    <div className="md:col-6">{getDays(studentDetail?.program_id?.day).join(",")}</div>
                  </div>
                  <div className="md:col-6 grid">
                    <div className="md:col-6 text-900 font-semibold">
                      Time
                    </div>
                    <div className="md:col-6">{studentDetail?.program_id?.time_format}</div>
                  </div>
                </div>}
                <p className="text-main text-lg font-semibold mb-3">
                  Instrument Purchase Program
                </p>
                <div className="grid">
                  <div className="md:col-6 grid">
                    <div className="md:col-6 text-900 font-semibold">
                      Instrument
                    </div>
                    <div className="md:col-6">{studentDetail?.enroll_type === "school_lessons" ? studentDetail?.school?.program?.instruments[0]?.name : studentDetail?.instrument_id?.name}</div>
                  </div>
                  <div className="md:col-6 grid">
                    <div className="md:col-6 text-900 font-semibold">
                      Status
                    </div>
                    <div className="md:col-6">
                      {firstLetterToUppercase(studentDetail?.status)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </Index>
    </>
  )
}

export default ViewStudent
