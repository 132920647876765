import types from "../../types";
const initialBoard = {
    getSchools: [],
    classType: [],
    schoolPrograms: [],
    getFilterLessonList: [],
    instrumentDetail: {},
    onlineInstruments: []
};

const studentReducer = (state = initialBoard, action) => {
    switch (action.type) {
        case types.PARENT_SCHOOL_DROPDOWN:
            return { ...state, getSchools: action.payload };
        case types.VIEW_SCHOOL_GRADE_CLASS_TYPE:
            return { ...state, classType: action.payload };
        case types.PARENT_CLASS_TYPE_PROGRAMS:
            return { ...state, schoolPrograms: action.payload };
        case types.GET_FILTER_LESSON:
            return { ...state, getFilterLessonList: action.payload };
        case types.SCHOOL_INSTRUMENT:
            return { ...state, instrumentDetail: action.payload };
        case types.ONLINE_INSTRUMENTS:
            return { ...state, onlineInstruments: action.payload }

        default:
            return { ...state };
    }
};

export default studentReducer;