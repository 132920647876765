import React from "react";
import { Link } from "react-router-dom";
import { Card } from "primereact/card";

export default function ProgramCard({ image, title, description, path, to }) {
    return (
        <a href={to} className="no-underline">
            <Card className="programs-card border-set shadow-none border-round-xl h-full">
                <div className="card-header p-0">
                    <img src={image} alt={title} className="w-full h-full card-img cover-img" />
                </div>
                <div className="card-body p-0 py-3">
                    <h5 className="text-900 font-semibold text-lg mb-3 open-sans">{title}</h5>
                    <p className="text-sm text-900 font-normal mb-3 open-sans">{description}</p>
                </div>
                <div className="card-footer border-none bg-transparent p-0">
                    <div className="flex align-items-center no-underline text-base font-normal open-sans">
                        See more &nbsp;
                        <i className="pi pi-arrow-right" style={{ fontSize: "1rem" }}></i>
                    </div>
                </div>
            </Card>
        </a>
    );
}
