import types from "../../types";
const initialBoard = {
    getParentAccounts: {},

};

const accountsReducer = (state = initialBoard, action) => {
    switch (action.type) {
        case types.GET_ACCOUNTS:
            return { ...state, getParentAccounts: action.payload };
        default:
            return { ...state };
    }
};

export default accountsReducer;