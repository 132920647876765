import React from "react";
import { MultiSelect } from "primereact/multiselect";

export default function MultiSelectComponent({ label, name, errorMessage, extraClassName, labelClassName, required, inputClass, ...props }) {
    return (
        <div className={`relative ${extraClassName}`}>
            <label htmlFor={name} className={`absolute text-xs text-500 z-3 open-sans bg-white-color form-labe z-3 top-0  bg-white ${labelClassName}`}>
                {label} {required && <span className="p-error">*</span>}
            </label>
            <MultiSelect id={name} name={name} className={`w-full border-400 ${inputClass} ${errorMessage ? "p-invalid" : null}`} {...props} />
            {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
        </div>
    );
}
