import React, { useState, useEffect } from "react";
import Logo from "../../../assets/logo/logo.png";
import LessonImg from "../../../assets/images/Select-amico1.png";
import MusicLyric from "../../../assets/icons/music-lyric.png";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getOnlineLessonProgramAction } from "../../../redux/actions/schoolAction";
import DropDownComponent from "../../../components/Fields/CustomDropdown";
import ArrowRight from "../../../assets/icons/arrow-right.png";
import { Link } from "react-router-dom";

export default function SelectOnlineLesson() {
  const [selectedOnlineLessons, setSelectedOnlineLessons] = useState(null);
  // const enroll_type = useSelector(
  //   (state) => state.school?.enrollType?.enroll_type
  // );

  const history = useHistory();
  const dispatch = useDispatch();
  const onlineLessonProgram = useSelector(
    (state) => state?.school?.getOnlineLessons
  );
  useEffect(() => {
    dispatch(getOnlineLessonProgramAction());
  }, [dispatch]);

  const [err, setErr] = useState({});
  const validateForm = () => {
    let errors = {};
    if (!selectedOnlineLessons) {
      errors.selectedOnlineLessons = "Please Select Private Online Lessons ";
    }

    setErr(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleContinue = (e) => {
    e.preventDefault();
    const form = validateForm();
    if (form) {
      history.push(
        `/school-online-lesson/online_lessons/${selectedOnlineLessons?.school}`
      );
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedOnlineLessons({ ...selectedOnlineLessons, [name]: value });
    setErr({ selectedOnlineLessons: "" });
  };

  return (
    <>
      <div className="px-2 md:px-7 shadow-2 bg-main block md:hidden">
        <div className="p-2 flex justify-content-between align-items-center">
          <div className="logo">
            <div className="logo" onClick={() => history.push("/")}>
              <img src={Logo} alt="" width="200" className="mr-2"></img>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div
          className="banner login-banner "
          style={{ height: "100vh" }}
        >
          <div className="grid  m-0 h-auto  md:h-full align-items-center overflow-auto">
            <div className="bg-main lg:col-6 md:col-12 sm:col-12 flex align-items-center h-full relative">
              <div className="px-8 absolute w-100 top-0 left-0 z-1 logo-desktop">
                <div className="logo" onClick={() => history.push("/")}>
                  <img src={Logo} alt="" width="200" className="mr-2"></img>
                </div>
              </div>
              <img
                src={LessonImg}
                alt=""
                className="m-auto p-0"
                style={{ width: "80%" }}
              />
            </div>
            <div
              className="
              lg:col-6 md:col-12 p-2 md:p-4 py-2 h-auto md:h-full relative"
            >
              <div className="relative p-3 mt-5 ml-0 md:ml-5 flex flex-column h-full align-items-center justify-content-center">
                <Link to="" className="arrow-back absolute top-0 left-0">
                  <img src={ArrowRight} width={20} alt="" />
                </Link>
                <img
                  src={MusicLyric}
                  alt=""
                  className="absolute opacity-20 w-5"
                  style={{ right: "2rem", top: "-2rem", zIndex: "-1" }}
                />
                <div className=" relative ">
                  <div className="shadow-2 p-5 py-5  border-round-lg w-full ms-3 relative z-3 bg-white">
                    <h1 className="text-2xl mb-3">
                      <b>Enroll Now in  - </b>
                      <span className="text-main">Private lessons</span>
                    </h1>

                    <div className="w-full mb-2">
                      <p className="mb-2 open-sans">Lesson Programs</p>
                      <DropDownComponent
                        name="school"
                        className="w-full "
                        placeholder="Select Lesson Programs"
                        value={selectedOnlineLessons?.school}
                        options={onlineLessonProgram?.map((item, index) => {
                          return {
                            label: item?.name,
                            value: item?.id,
                          };
                        })}
                        onChange={handleChange}
                        optionLabel="label"
                      />
                    </div>
                    {err && (
                      <div className="text-danger">
                        {err?.selectedOnlineLessons}
                      </div>
                    )}

                    <button
                      className="mt-2  btn bg-dark px-4 w-full p-3 border-none text-white font-semibold border-round-lg cursor-pointer open-sans"
                      onClick={handleContinue}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
