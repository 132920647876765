import React from "react";
import { Dropdown } from "primereact/dropdown";

export default function DropDownComponent({
    label,
    name,
    errorMessage,
    extraClassName,
    required,
    inputClass,
    labelClassName,
    ...props
}) {
    return (
        <div className={`relative ${extraClassName}`}>
            {label && (
                <label
                    htmlFor={name}
                    className={`absolute text-xs text-500 z-3 open-sans bg-white-color form-label z-3 top-0  bg-white ${labelClassName}`}
                >
                    {label} {required && <span className="p-error">*</span>}
                </label>
            )}
            <div className="d-md-none">
                <Dropdown
                    id={name}
                    name={name}
                    className={`w-full border-round-lg border-1 border-400 overflow-hidden ${inputClass}`}
                    {...props}
                />
                {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
            </div>
            <div className="d-none d-md-block">
                <Dropdown
                    id={name}
                    name={name}
                    filter
                    className={`w-full border-round-lg border-1 border-400 overflow-hidden ${inputClass}`}
                    {...props}
                />
                {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
            </div>
        </div>
    );
}

export const CustomDropdownComponent = ({
    label,
    name,
    errorMessage,
    extraClassName,
    required,
    inputClass,
    labelClassName,
    sublabel,
    ...props
}) => {
    return (
        <>
            <div className={`relative ${extraClassName}`}>
                {label && (
                    <label
                        htmlFor={name}
                        className={`text-base font-semibold p-0 text-dark open-sans form-label  ${labelClassName} ${sublabel ? "mb-0" : "mb-3"}`}
                    >
                        {label} {required && <span className="p-error">*</span>}
                    </label>
                )}
                {sublabel && (
                  <>
                    <p className="text-xs text-main ">{sublabel}</p>
                  </>
                )}

                <Dropdown
                    id={name}
                    name={name}
                    filter
                    className={`w-full mt-1 border-round-lg border-1 border-400 overflow-hidden ${inputClass}`}
                    {...props}
                />
                {errorMessage ? <small className="p-error open-sans text-sm">{errorMessage}</small> : null}
            </div>
        </>
    );
};
