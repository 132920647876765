import types from "../../types";
const initialBoard = {
  getStudentList: [],
  viewStudentDetail: {},
  getEmails: [],
  getEmailHistory: []
};

const teacherStudentReducer = (state = initialBoard, action) => {
  switch (action.type) {
    case types.VIEW_TEACHER_STUDENTS:
      return { ...state, getStudentList: action.payload };
    case types.VIEW_TEACHER_STUDENTS_DETAILS:
      return { ...state, viewStudentDetail: action.payload };
    case types.EMAIL_DROPDOWN:
      return { ...state, getEmails: action.payload }
    case types.GET_TEACHER_MESSAGE_HISTORY:
      return { ...state, getEmailHistory: action.payload }
    default:
      return { ...state };
  }
};

export default teacherStudentReducer;