import Constants from "../../../services/constant";
import { loadStateFn } from "../../../utils/localStorage";
import { api, multipartApi } from "../../../services/api";
import { showLoaderAction, hideLoaderAction } from "../loadAction";
import { showToast } from "../../../utils/showToast";
import types from "../../types";
import { getTeacherOverDues } from "./teacherAction";
import { isFileObject } from "../../../utils/commonFunctions";

export const ViewEventAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.VIEW_EVENT + id, {}, loadStateFn("token"));

    if (res.success) {
        dispatch({
            type: types.VIEW_EVENT,
            payload: res.data,
        });
    }

    dispatch(hideLoaderAction());
};

export const viewAttendenceAction = (eventId, studentId, type) => async (dispatch) => {
    dispatch(showLoaderAction());
    const payload = { event_id: eventId, student_id: studentId, type: type };

    const res = await api("post", Constants.END_POINT.VIEW_ATTENDENCE, payload, loadStateFn("token"));

    if (res.success) {
        dispatch({
            type: types.VIEW_ATTENDENCE,
            payload: res.data,
        });
    }
    dispatch(hideLoaderAction());
};

export const addAttendenceAction =
    (
        events,
        attandence,
        selectedFiles,
        attendeeOptions,
        type,
        notes,
        history,
        studentId,
        assignments,
        url,
        deletedFileIDs,
        status
    ) =>
    async (dispatch) => {
        dispatch(showLoaderAction());
        const formData = new FormData();
        for (let i = 0; i < selectedFiles?.length; i++) {
            isFileObject(selectedFiles[i]) && formData.append(`attachment[${i}][file]`, selectedFiles[i]);
        }

        formData.append("event_id", attandence.event_id);
        formData.append("type", type);
        formData.append("attendance", JSON.stringify(attendeeOptions));
        formData.append("is_billable", attandence?.is_billable === true ? 1 : 0);
        formData.append("teacher_absent", status === "teacher_absent" ? 1 : 0);

        if (notes) {
            formData.append("notes", notes);
        }
        if (assignments) {
            formData.append("assignment", assignments);
        }
        if (url?.length) {
            formData.append("url", JSON.stringify(url.toString()));
        }
        if (deletedFileIDs?.length) {
            formData.append("deleted_attachment", deletedFileIDs);
        }

        formData.append("online_resource", attandence?.online_resource);
        formData.append("parents", attandence?.parents === true ? 1 : 0);
        formData.append("students", attandence?.students === true ? 1 : 0);
        formData.append("teachers", attandence?.teachers === true ? 1 : 0);
        formData.append("parents_ids", attandence?.parents === true ? JSON.stringify(attandence?.parents_ids) : null);
        formData.append("students_ids", attandence?.students === true ? JSON.stringify(attandence?.students_ids) : null);
        formData.append("teachers_ids", attandence?.teachers === true ? JSON.stringify(events?.teacher_arr) : null);

        if (type === "single") {
            formData.append("student_id", studentId);
        }

        const res = await multipartApi("post", Constants.END_POINT.ADD_ATTENDENCE, formData, loadStateFn("token"));
        if (res.success) {
            showToast([res.message], "success");

            if (history) {
                history.goBack(-1);
            }
        } else {
            showToast([res.data], "error");
        }
        dispatch(hideLoaderAction());
    };
export const viewTeacherAttendenceAction = () => async (dispatch) => {
    dispatch(showLoaderAction());

    const res = await api("get", Constants.END_POINT.VIEW_TEACHER_ATTENDENCE, {}, loadStateFn("token"));

    if (res.success) {
        dispatch({
            type: types.VIEW_TEACHER_ATTENDENCE,
            payload: res.data,
        });
    }
    dispatch(hideLoaderAction());
};
export const SetAllUnrecordedAction = (selectedRow, record, setSelectedRecord) => async (dispatch) => {
    dispatch(showLoaderAction());

    const payload = {
        events_ids: selectedRow?.map((item, i) => item.id),
        status: record,
    };

    const res = await api("post", Constants.END_POINT.SET_ALL_UNRECORDED, payload);

    if (res.success) {
        showToast([res.message], "success");
        setSelectedRecord([]);
        dispatch(getTeacherOverDues());
    } else {
        showToast([res.message], "error");
    }

    dispatch(hideLoaderAction());
};
