import types from "../../types";
const initialBoard = {
  practicelog: {},
};

const practicelogReducer = (state = initialBoard, action) => {
  switch (action.type) {
    case types.STUDENT_PRACTICE_LOGS:
      return { ...state, practicelog: action.payload };

    default:
      return { ...state };
  }
};

export default practicelogReducer;
